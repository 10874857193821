import { ObjectHash, Theme } from '../../../types/shared'
import { IChartThemeColors, chartDarkTheme, chartLightTheme } from '../constants'

function normalizeHexAlpha(hexString = '') {
  const hexValue = hexString.replace('#', '')
  if (hexValue.length === 6) {
    return `${hexString}FF`
  }
  return hexString
}

export const getColorOverwrites = ({
  theme,
  colors,
}: {
  theme: Theme
  colors: ObjectHash<Partial<IChartThemeColors>, Theme>
}) => {
  const customColors = colors[theme]
  const customColorKeys = Object.keys(customColors) as Array<keyof Partial<IChartThemeColors>>
  const defaultColors = theme === Theme.light ? chartLightTheme : chartDarkTheme

  return customColorKeys.reduce((acc, colorKey) => {
    const isDefaultOverWritten =
      normalizeHexAlpha(customColors[colorKey]).toLowerCase() !==
      normalizeHexAlpha(defaultColors[colorKey]).toLowerCase()

    return isDefaultOverWritten
      ? {
          ...acc,
          [colorKey]: customColors[colorKey],
        }
      : acc
  }, {})
}
