import Text from '../canvas/text'
import { OFFSET, PADDING, TextBaseline } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import { AroonCalculation } from '../indicator-calculation/aroon'
import Pane from '../models/pane'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { AroConfig, Attrs } from './configs/aro'
import Indicator from './indicator'

const COLORS = {
  aroonUp: '#00a000',
  aroonDown: '#c80000',
}

class Aroon extends Indicator<Attrs> {
  static config = AroConfig

  aroonCalculation: AroonCalculation | null = null
  declare period: number

  constructor(attrs: Attrs, model: Pane) {
    super(attrs, model)
    this.renderYAxis = this.renderYAxis.bind(this)
  }

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (!this.isComputeNecessary() && this.aroonCalculation !== null) return

    this.aroonCalculation = new AroonCalculation({
      quote: this.data,
      options: { period: this.period },
    })

    this.aroonCalculation.calculate()

    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      aroonUp: COLORS.aroonUp,
      aroonDown: COLORS.aroonDown,
    }
  }

  getValueLabelsAtIndex(index: number) {
    if (this.data.close.length === 0 || !this.aroonCalculation) return []

    const lineColors = this.getLineColors()
    const { aroonUp, aroonDown } = this.aroonCalculation.calculatedValues
    const dataIndex = this.data.barToDataIndex[index]
    return [
      { color: lineColors.aroonDown, text: this.getValueLabel(aroonDown[dataIndex]) },
      { color: lineColors.aroonUp, text: this.getValueLabel(aroonUp[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0 || !this.aroonCalculation) return

    const lineColors = this.getLineColors()

    context.translate(0.5, 0.5)
    context.set('strokeStyle', lineColors.aroonUp)
    context.beginPath()
    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period,
    }
    const { aroonUp, aroonDown } = this.aroonCalculation.calculatedValues
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(aroonUp[i])))
      },
    })
    context.stroke()

    context.set('strokeStyle', lineColors.aroonDown)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(aroonDown[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  renderYAxis(context: CanvasRenderingContext2D) {
    super.renderYAxis(context)
    if (!this.aroonCalculation) return
    const { IndicatorSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const { aroonUp, aroonDown } = this.aroonCalculation.calculatedValues

    const translate = getTranslate({
      context,
      xOffset: IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })
    translate.do()
    const text = new Text(
      {
        x: this.contentWidth + OFFSET.M - PADDING.XXS + IndicatorSettings.right.axis.margin.left!,
        font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.right.axis.font),
        lineHeight: IndicatorSettings.right.axis.font.lineHeight,
        padding: {
          top: IndicatorSettings.right.axis.font?.padding?.top ?? PADDING.XXS,
          right: IndicatorSettings.right.axis.font?.padding?.right ?? PADDING.XXS,
          bottom: IndicatorSettings.right.axis.font?.padding?.bottom ?? PADDING.XXS,
          left: IndicatorSettings.right.axis.font?.padding?.left ?? PADDING.XXS,
        },
        textBaseline: TextBaseline.middle,
        fillStyle: Colors.indicatorCurrentText,
      },
      this.model
    )
    const textLabels = []
    if (aroonDown.length > 0) {
      textLabels.push({
        text: aroonDown.last()!.toFixed(2),
        y: Math.round(this.fy(aroonDown.last()!)),
        background: COLORS.aroonDown,
      })
    }
    if (aroonUp.length > 0) {
      textLabels.push({
        text: aroonUp.last()!.toFixed(2),
        y: Math.round(this.fy(aroonUp.last()!)),
        background: COLORS.aroonUp,
      })
    }

    const minY = 0
    const maxY = this.height - IndicatorSettings.bottom.height - IndicatorSettings.top.height
    textLabels.forEach((labelSetting) => {
      if (labelSetting.y >= minY && labelSetting.y <= maxY) {
        text.set(labelSetting).render(context)
      }
    })

    translate.undo()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 25,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: AroConfig.label,
      inputs: AroConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default Aroon
