import { InputColorPicker, Paragraph, RangeSlider } from '@finviz/website'
import React, { useCallback } from 'react'

import { ObjectHash } from '../../types/shared'

interface ColorRangeRowInputProps {
  label: string
  colorValue: string
  colorTestId: string
  rangeValue: string
  rangeTestId: string
  rangeLabel: string
  rangeProps: ObjectHash
  onChange: (colorValue: string, value: string) => void
}

export function ColorRangeRowInput({
  label,
  colorValue,
  colorTestId,
  rangeValue,
  rangeTestId,
  rangeLabel,
  rangeProps,
  onChange,
}: ColorRangeRowInputProps) {
  const handleRangeChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.currentTarget.value
      onChange(colorValue, value)
    },
    [onChange, colorValue]
  )
  const handleColorChange = useCallback(
    (color: string) => {
      onChange(color, rangeValue)
    },
    [onChange, rangeValue]
  )
  return (
    <div className="flex items-end">
      <div className="flex flex-1 items-center">
        <InputColorPicker label={label} color={colorValue} inputDataTestId={colorTestId} onChange={handleColorChange} />
      </div>
      <div className="ml-2 flex flex-1 items-center">
        <RangeSlider
          {...rangeProps}
          label={rangeLabel}
          rightContent={
            <Paragraph as="div" size="small" className="text-right tabular-nums text-gray-900 dark:text-white">
              {rangeValue}
            </Paragraph>
          }
          onChange={handleRangeChange}
          value={Number(rangeValue)}
          data-testid={rangeTestId}
        />
      </div>
    </div>
  )
}
