/**
 * This file sets up the relationships between models and solves circular dependencies
 * This file needs to be imported in the index file so that the relationships are
 * called in time for instantiation.
 */
import Chart from './chart'
import ChartEventElement from './chart-event-element'
import ChartLayout from './chart_layout'
import DrawingsInternalStore from './drawings-internal-store'
import Element from './element'
import Indicator from './indicator'
import Overlay from './overlay'
import Pane from './pane'
import Quote from './quote'

Chart.initClass(Pane, Quote, ChartLayout)
ChartLayout.initClass(Chart, DrawingsInternalStore)
DrawingsInternalStore.initClass()
Element.initClass(Pane)
ChartEventElement.initClass(Pane)
Indicator.initClass(Chart)
Overlay.initClass(Chart)
Pane.initClass(Element, ChartEventElement, Chart)
