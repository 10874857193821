import Spine from '@finviz/spine'

import { ChartSyncablePart } from '../chart/contstants'
import Pane from '../pane'
import { roundTimeForSyncedPane } from './mouse-utils'

export class Mouse extends Spine.Model {
  static initClass() {
    this.configure('Mouse', 'position')
    this.belongsTo('pane', Pane)
  }

  declare position: { x: number; y: number } | undefined
  declare pane: () => Pane | null

  getIsCrossSyncOn = () => !!this.pane()?.chart().getIsChartPartInSync(ChartSyncablePart.Crosshair)

  getIsMouseInChartOfPane = (pane: Pane) => !!this.pane()?.chart().eql(pane.chart())

  getShouldRenderCrossInPane = (pane: Pane) => this.getIsCrossSyncOn() || this.getIsMouseInChartOfPane(pane)

  getCrossIndexForPane = (pane: Pane) => {
    if (this.position) {
      if (this.getIsMouseInChartOfPane(pane)) {
        return Math.round(this.position.x)
      }
      const paneOfMouse = this.pane()
      if (this.getIsCrossSyncOn() && paneOfMouse) {
        const mouseInChart = paneOfMouse.chart()
        const currentPaneChart = pane.chart()
        const mousePositionTimestamp = mouseInChart.quote().getTimestampFomPositionX(Math.round(this.position.x))
        const currentPanePositionX = currentPaneChart.quote().getPositionXFromTimestamp(
          roundTimeForSyncedPane({
            sourceChart: mouseInChart,
            targetChart: currentPaneChart,
            timestamp: mousePositionTimestamp,
          })
        )
        return Math.floor(currentPanePositionX)
      }
    }

    return Number.NaN
  }

  getPriceForPane = (pane: Pane) => {
    const positionY = this.position?.y ?? null

    if (this.pane()?.eql(pane)) {
      return positionY
    }

    if (this.getIsCrossSyncOn() && this.pane()) {
      const mousePaneIndex = this.pane()?.getIndexInChart()
      const currentPaneIndex = pane.getIndexInChart()
      return mousePaneIndex === currentPaneIndex ? positionY : null
    }

    return null
  }
}

/**
 * Mouse is a singleton, so `initClass` must be called before we create the instance
 * otherwise it wouldn’t work
 */
Mouse.initClass()

const mouse = Mouse.create<Mouse>()
mouse.save()

export default mouse as Mouse
