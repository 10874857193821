import { CCICalculation } from '../indicator-calculation/cci'
import { Attrs, CCIConfig } from './configs/cci'
import Indicator from './indicator'

class CommodityChannelIndex extends Indicator<Attrs> {
  static config = CCIConfig

  declare cciCalculation: CCICalculation
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.cciCalculation = new CCICalculation({
        quote: this.data,
        options: { period: this.period },
      })

      this.cciCalculation.calculate()
      this.lastValue = this.cciCalculation.calculatedValues.cci.last() ?? null
    }

    const { min, max } =
      this.cciCalculation.calculatedValues.cci.length > 0
        ? this.computeVisibleMinMax(this.cciCalculation.calculatedValues.cci)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.cciCalculation.calculatedValues.cci)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.cciCalculation.calculatedValues.cci, this.period - 1, -100, 0, 100)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 20,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: CCIConfig.label,
      inputs: CCIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default CommodityChannelIndex
