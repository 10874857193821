import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  conversion: number
  base: number
  spanB: number
}

export interface Attrs extends Options {
  period?: string
  spanAStrokeColor: string
  spanAFillColor: string
  spanBStrokeColor: string
  spanBFillColor: string
  conversionLineColor: string
  baseLineColor: string
  laggingSpanColor: string
}

export class ICConfig extends BaseConfig {
  static type = OverlayType.Ic
  static abbreviation = 'ic'
  static label = 'Ichimoku Cloud'
  static shortLabel = 'Ichimoku'
  static optionsOrder = ['conversion', 'base', 'spanB'] as const

  static optionsLabels = {
    conversion: 'Conversion Line',
    base: 'Base Line Length',
    spanB: 'Leading Span B Length',
  }

  static inputsOrder = [
    ...this.optionsOrder,
    'spanAStrokeColor',
    'spanAFillColor',
    'spanBStrokeColor',
    'spanBFillColor',
    'conversionLineColor',
    'baseLineColor',
    'laggingSpanColor',
  ] as Array<keyof Omit<Attrs, 'period'>>

  static inputsLabels = {
    ...this.optionsLabels,
    spanAStrokeColor: 'Span A Stroke Color',
    spanAFillColor: 'Span A Fill Color',
    spanBStrokeColor: 'Span B Stroke Color',
    spanBFillColor: 'Span B Fill Color',
    conversionLineColor: 'Conversion Line Color',
    baseLineColor: 'Base Line Color',
    laggingSpanColor: 'Lagging Span Color',
  }

  static calculatedValuesOrder = ['baseLine', 'conversionLine', 'senkouSpanA', 'senkouSpanB'] as const
  static calculatedValuesLabels = {
    baseLine: 'Base Line',
    conversionLine: 'Conversion Line',
    senkouSpanA: 'Leading Span A',
    senkouSpanB: 'Leading Span B',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
