import * as React from 'react'

import { SpecificChartFunctionality, TIMEFRAME } from '../../constants/common'
import math from '../../helpers/math'
import ChartLayoutModel from '../../models/chart_layout'
import Quote from '../../models/quote'

function useChartPageTitle() {
  const defaultDocumentTitleRef = React.useRef(document.title)

  const { current: updateChartsTitle } = React.useRef((chartLayoutModel: ChartLayoutModel) => {
    const charts = chartLayoutModel
      .getAllCharts()
      .reduce(
        (acc, current) => {
          if (acc.some(({ ticker }) => ticker === current.ticker)) {
            return acc
          }
          return [...acc, { ticker: current.ticker, timeframe: current.timeframe, instrument: current.instrument }]
        },
        [] as Array<{ ticker: string; timeframe: TIMEFRAME; instrument: string }>
      )
      .map((chart) => {
        const { ticker, timeframe, instrument } = chart
        const quotes = Quote.select(
          (quote: Quote) =>
            quote.ticker === ticker.replace('@', '') &&
            quote.timeframe === timeframe &&
            quote.instrument === instrument &&
            chartLayoutModel.isIdeaId(quote.ideaID)
        )
        if (quotes.length > 0) {
          const { lastClose, instrument, afterClose, prevClose, name } = quotes[0]
          let percentageChange: number
          if (!lastClose) {
            return null
          }
          if (prevClose !== null) {
            percentageChange = (lastClose / prevClose - 1) * 100
          }
          if (afterClose !== null && afterClose !== lastClose) {
            percentageChange = (afterClose / lastClose - 1) * 100
          }
          return `${name || ticker} ${math.round({
            value: lastClose,
            lastClose,
            instrument,
            overridePlaces: undefined,
          })} ${percentageChange! > 0 ? '+' : '-'}${math.round({
            value: Math.abs(percentageChange!),
            overridePlaces: 2,
          })}%`
        }
        return null
      })
      .filter((chart) => chart !== null)

    if (charts && charts.length > 0) {
      const title = charts.join(' | ')
      if (document.title !== title) {
        document.title = title
      }
    } else if (document.title !== defaultDocumentTitleRef.current) {
      document.title = defaultDocumentTitleRef.current
    }
  })

  return { updateChartsTitle }
}

export function withChartPageTitle<P extends { chartLayoutModel: ChartLayoutModel }>(
  Component: React.ComponentType<P>
) {
  return function WrappedComponent(props: P) {
    const { updateChartsTitle } = useChartPageTitle()

    const handleChartsUpdate = React.useCallback(() => {
      updateChartsTitle(props.chartLayoutModel)
    }, [props.chartLayoutModel, updateChartsTitle])

    React.useEffect(() => {
      if (props.chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage) {
        props.chartLayoutModel.charts().bind('change', handleChartsUpdate)

        handleChartsUpdate()

        return () => {
          props.chartLayoutModel.charts().unbind('change', handleChartsUpdate)
        }
      }
    }, [props.chartLayoutModel, handleChartsUpdate])

    return <Component {...props} />
  }
}
