import { IndicatorType } from '../../constants/common'
import { HLC } from '../../utils/hlc'

export type DrawingChangeTimestamp = HLC
export type DrawingContainerType = IndicatorType | 'chart' | 'note' | null
export type AutoSaveChangeType = 'update' | 'destroy'

export interface AutoSaveElement {
  elementId: string
  ticker: string
  zIndex: number
  lastChange: DrawingChangeTimestamp
  changeType: AutoSaveChangeType
  containerType: DrawingContainerType
  elementAttrs: string
}

export type AutoSaveElementDBRecord = Omit<AutoSaveElement, 'lastChange'> & { lastChange: string } & {
  lastChangeTimestamp: number
  lastChangeCounter: number
  lastChangeNodeUUID: string
}

export const DRAWINGS_UPDATE_INTERVAL_MS = process.env.IS_E2E_TESTING ? 3000 : 15000
export const DRAWINGS_SAVE_INTERVAL_MS = 250
