import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartModel from '../../models/chart'

export function withWatchedPanesOnChartModel<P extends { chartModel: ChartModel }>(Component: React.ComponentType<P>) {
  return function WrappedComponent(props: P) {
    const model = useModelState(props.chartModel, { watchProperties: ['panes'] })

    return <Component {...props} model={model} />
  }
}
