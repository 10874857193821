import { Button, Dropdown, DropdownItem, Icon, IconNameType, MenuGroup, Paragraph } from '@finviz/website'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LAYOUTS } from '../../constants/common'
import { sortObjects } from '../../helpers/sorter'
import { useModelState } from '../../model-hooks/use-model-state'
import { ChartSyncableParts } from '../../models/chart/contstants'
import ChartLayout from '../../models/chart_layout'
import { decodeQueryString } from '../../utils/query_string'
import { useChartsPathLocation } from '../chart-controls/use-charts-path-location'

const SORTED_LAYOUT_KEYS = sortObjects(
  Object.entries(LAYOUTS).map(([key, value]) => ({ ...value, key })),
  [{ sortBy: 'columns' }, { sortBy: 'count' }]
).map(({ key }) => key)

export function ChartLayoutDropdown({ model }: { model: ChartLayout }) {
  const location = useLocation()
  const navigate = useNavigate()
  const parsedQuery = decodeQueryString(location.search)
  const chartLayoutModel = useModelState(model, { watchProperties: ['layout', 'syncChartParts'] })
  const { getChartsToPath } = useChartsPathLocation({ chartLayoutModel })

  return (
    <Dropdown
      placement="bottom"
      orientation="horizontal"
      aria-label="Layout"
      trigger={
        <Button
          theme="headerItem"
          data-testid="chart-layout-layout"
          leftContent={`layout${parsedQuery.l}` as IconNameType}
          contentClass="hidden lg:flex"
          title="Change layout"
        >
          Layout
        </Button>
      }
    >
      <div>
        <div className="flex">
          {SORTED_LAYOUT_KEYS.map((layout) => (
            <DropdownItem
              key={layout}
              appearance="square"
              data-testid={`chart-layout-${layout}`}
              onClick={() => {
                navigate(getChartsToPath({ layout }))
              }}
            >
              <Icon name={`layout${layout}` as IconNameType} className="text-gray-500 dark:text-white" />
            </DropdownItem>
          ))}
        </div>
        <MenuGroup>
          <div className="flex space-x-1">
            <div className="flex items-center justify-center pl-1">
              <Paragraph size="small">Sync:</Paragraph>
            </div>
            {ChartSyncableParts.map(({ value, label }) => {
              const isChartPartInSync = chartLayoutModel.getIsChartPartInSync(value)
              return (
                <Button
                  key={value}
                  theme="chip"
                  size="regular"
                  active={isChartPartInSync}
                  data-testid={`chart-syncable-${value}`}
                  onClick={() => {
                    chartLayoutModel.setSyncChartParts(value, !isChartPartInSync)
                    if (!isChartPartInSync) window.gtag?.('event', 'sync', { event_label: value })
                  }}
                >
                  {label}
                </Button>
              )
            })}
          </div>
        </MenuGroup>
      </div>
    </Dropdown>
  )
}
