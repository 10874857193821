import * as dateFns from 'date-fns'

import { getChartStartEndDates } from '../controllers/renderUtils'
import Quote from '../models/quote'

// **************START**************
// Functions from here until END have to be in sync with
// Finviz-Website: components/timeframe-bar/custom-date-range-utils.ts
// *********************************

export enum CustomDateRangePrefixEnum {
  Range = 'range',
  Prev = 'prev',
}

export const CUSTOM_DATE_RANGE = 'custom'
export const DATE_RANGE_PREFIX_DELMITER = '_'
const CUSTOM_DATE_RANGE_DELIMITER = 'x'
const CUSTOM_PREV_RANGE_DELIMITER = '-'

export function getParsedDateRange(dateRange: string) {
  return dateRange.split(DATE_RANGE_PREFIX_DELMITER)
}

export function parseCustomDateRangeUrlParam({ dateRange, anchorDate }: { dateRange: string; anchorDate: Date }) {
  const [dateRangePrefix, dateRangeValue] = getParsedDateRange(dateRange)

  if (dateRangePrefix === CustomDateRangePrefixEnum.Range) {
    const [dateFromString, dateToString] = dateRangeValue.replace(/-/g, '/').split(CUSTOM_DATE_RANGE_DELIMITER)
    const dateFrom = dateFromString ? new Date(dateFromString) : null
    const dateTo = dateToString ? new Date(dateToString) : null
    return {
      start: dateFrom && !Number.isNaN(dateFrom.getTime()) ? dateFrom : null,
      end: dateTo && !Number.isNaN(dateTo.getTime()) ? dateFns.endOfDay(dateTo) : null,
    }
  }

  if (dateRangePrefix === CustomDateRangePrefixEnum.Prev) {
    const [years, months, days] = dateRangeValue.split(CUSTOM_PREV_RANGE_DELIMITER).map(Number)

    if ([years, months, days].some(Number.isNaN)) {
      return { start: null, end: null }
    }

    return {
      start: dateFns.startOfDay(dateFns.sub(anchorDate, { years, months, days: days - 1 })),
      end: anchorDate,
    }
  }

  return { start: null, end: anchorDate }
}

// **************END**************
// Functions below don't need to be
// in sync with Website repository
// *******************************

export function getParsedDateRangeMetaData({ dateRange, quote }: { dateRange: string; quote: Quote }) {
  const parsedRange = getChartStartEndDates({ dateRange, quote })
  const [dateRangePrefix] = getParsedDateRange(dateRange)

  let startQuoteDate: Date | null = null
  let endQuoteDate: Date | null = null
  let numOfDaysToRender: number | null = null

  if (parsedRange.start && dateRangePrefix === CustomDateRangePrefixEnum.Prev) {
    const daysInQuote = quote.getDaysInQuote()
    const reversedDaysInQuote = [...daysInQuote].reverse()
    const startQuoteDayIndex = reversedDaysInQuote.findIndex((date) => date.getTime() <= parsedRange.start!.getTime())
    const endQuoteDayIndex = reversedDaysInQuote.findIndex((date) => date.getTime() <= parsedRange.end!.getTime())
    startQuoteDate = reversedDaysInQuote[startQuoteDayIndex] ?? null
    endQuoteDate = reversedDaysInQuote[endQuoteDayIndex] ?? null
    numOfDaysToRender = quote.interval <= 5 ? startQuoteDayIndex - endQuoteDayIndex + 1 : null
  }

  return {
    start: parsedRange.start,
    end: parsedRange.end,
    startQuoteDate,
    endQuoteDate,
    numOfDaysToRender,
    numOfBarsToRender: (numOfDaysToRender ?? 0) * (quote.getBarsInDay() ?? 0) || undefined,
    renderDateStart: parsedRange.start && dateFns.startOfDay(parsedRange.start),
    renderDateEnd: parsedRange.end && dateFns.endOfDay(parsedRange.end),
  }
}
