import endOfDay from 'date-fns/endOfDay'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import lastDayOfWeek from 'date-fns/lastDayOfWeek'
import setDate from 'date-fns/set'

import { TIMEFRAME, TIMEFRAMES_WITH_PRE_MARKET } from '../../constants/common'
import { convertLocalToNyTime, dateFromUnixTimestamp } from '../../utils'
import Chart from '../chart'

const MARKET_START = { hours: 9, minutes: 30 }
const END_OF_DAY = { hours: 23, minutes: 59 }

function getIsDateBeforeMarketStart(date: Date) {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return hours < MARKET_START.hours || (hours === MARKET_START.hours && minutes < MARKET_START.minutes)
}

export function roundTimeForSyncedPane({
  sourceChart,
  targetChart,
  timestamp,
}: {
  sourceChart: Chart
  targetChart: Chart
  timestamp: number
}) {
  let dateToReturn = dateFromUnixTimestamp(timestamp)
  const sourceChartHasPremarket =
    sourceChart.quote().premarket && TIMEFRAMES_WITH_PRE_MARKET.includes(sourceChart.timeframe)
  const targetChartHasPremarket =
    targetChart.quote().premarket && TIMEFRAMES_WITH_PRE_MARKET.includes(targetChart.timeframe)

  if ([TIMEFRAME.d, TIMEFRAME.w, TIMEFRAME.m].includes(sourceChart.timeframe)) {
    dateToReturn = setDate(dateToReturn, MARKET_START)
  }

  if (targetChart.timeframe === TIMEFRAME.m) {
    dateToReturn = setDate(lastDayOfMonth(dateToReturn), END_OF_DAY)
  } else if (targetChart.timeframe === TIMEFRAME.w) {
    dateToReturn = setDate(lastDayOfWeek(dateToReturn, { weekStartsOn: 1 }), END_OF_DAY)
  } else if (targetChart.timeframe === TIMEFRAME.d && targetChart.quote().instrument !== 'stock') {
    dateToReturn = endOfDay(dateToReturn)
  }

  if (sourceChartHasPremarket && !targetChartHasPremarket && targetChart.quote().instrument === 'stock') {
    dateToReturn = getIsDateBeforeMarketStart(dateToReturn) ? setDate(dateToReturn, MARKET_START) : dateToReturn
  }

  return ~~(convertLocalToNyTime(dateToReturn, false).getTime() / 1000)
}
