import {
  Button,
  Dialog,
  Dropdown,
  DropdownItem,
  Icon,
  Spinner,
  TickerSearch,
  createAlertAsyncModule,
  saveToPortfolioAsyncModule,
  useDialogState,
} from '@finviz/website'
import * as React from 'react'

import { Instrument } from '../../../types/shared'
import { useAsyncModule } from '../../utils/async-modules-utils'

interface SearchProps {
  ticker: string
  instrument: Instrument
  onTickerSelected: (ticker: string) => void
  chartIndex: number
}

export default function Search({ ticker, instrument, onTickerSelected, chartIndex }: SearchProps) {
  const saveToPortfolioDialogState = useDialogState()
  const isSaveToPortfolioVisible = saveToPortfolioDialogState.useState('open')
  const createAlertDialogState = useDialogState()
  const isCreateAlertVisible = createAlertDialogState.useState('open')
  const [saveToPortfolioModule, { isLoading: isLoadingSaveToPortfolio }] = useAsyncModule({
    ...saveToPortfolioAsyncModule,
    shouldLoadModule: isSaveToPortfolioVisible,
    onError: () => saveToPortfolioDialogState.hide(),
  })
  const [createAlertModule, { isLoading: isLoadingCreateAlert }] = useAsyncModule({
    ...createAlertAsyncModule,
    shouldLoadModule: isCreateAlertVisible,
    onError: () => createAlertDialogState.hide(),
  })
  const SaveToPortfolio = saveToPortfolioModule?.SaveToPortfolio
  const CreateAlert = createAlertModule?.CreateAlert

  const detailLink = React.useMemo(() => {
    const formattedTicker = ticker.replace('@', '')

    switch (instrument) {
      case Instrument.Stock:
        return `/quote.ashx?t=${formattedTicker}`
      case Instrument.Futures:
        return `/futures_charts.ashx?t=${formattedTicker}`
      case Instrument.Forex:
        return `/forex_charts.ashx?t=${formattedTicker}`
      case Instrument.Crypto:
        return `/crypto_charts.ashx?t=${formattedTicker}`
    }
  }, [ticker, instrument])

  return (
    <div className="search flex w-32 flex-none items-center">
      <TickerSearch
        chartIndex={chartIndex}
        placeholder={ticker}
        size="medium"
        inputClass="pr-7 placeholder:text-gray-900 focus:placeholder:text-gray-500 dark:placeholder:text-white dark:focus:placeholder:text-gray-400"
        hasSearchIcon={false}
        isSearchItemLink={false}
        isShowAllVisible={false}
        onTickerSelected={onTickerSelected}
        rightContent={
          <Dropdown
            placement="bottom-end"
            aria-label="More options"
            trigger={
              <Button
                type="button"
                size="none"
                className="h-full w-7 text-gray-400 hover:text-gray-500"
                theme="transparent"
                appearance="square"
                rightContent={<Icon width={20} name="more" />}
              />
            }
          >
            <DropdownItem
              as="a"
              target="_blank"
              href={detailLink}
              onClick={() => {
                window.gtag?.('event', 'click', { event_category: 'open-stock-detail' })
              }}
            >
              {instrument === Instrument.Stock ? 'Open Stock Detail' : 'Open Detail'}
            </DropdownItem>
            {instrument === Instrument.Stock && (
              <DropdownItem
                onClick={() => {
                  saveToPortfolioDialogState.show()
                  window.gtag?.('event', 'click', { event_category: 'save-to-portfolio' })
                }}
              >
                Save to Portfolio
              </DropdownItem>
            )}
            {instrument === Instrument.Stock && (
              <DropdownItem
                onClick={() => {
                  createAlertDialogState.show()
                  window.gtag?.('event', 'click', { event_category: 'create-alert' })
                }}
              >
                Create Alert
              </DropdownItem>
            )}
          </Dropdown>
        }
      />
      <Dialog state={saveToPortfolioDialogState} aria-label="Save to Portfolio" className="w-110">
        {isLoadingSaveToPortfolio && (
          <div className="flex h-56 items-center justify-center">
            <Spinner />
          </div>
        )}
        {SaveToPortfolio && <SaveToPortfolio ticker={ticker} />}
      </Dialog>
      <Dialog state={createAlertDialogState} aria-label="Create Alert" className="w-[366px]">
        {isLoadingCreateAlert && (
          <div className="flex h-96 items-center justify-center">
            <Spinner />
          </div>
        )}
        {CreateAlert && <CreateAlert ticker={ticker} />}
      </Dialog>
    </div>
  )
}
