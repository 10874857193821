import { DchConfig, Options } from '../overlays/configs/dch'
import utils from '../utils'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  upperBand: number[]
  lowerBand: number[]
  medianBand: number[]
}

export class DchCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = DchConfig

  calculate() {
    const { period } = this.options
    const { high, low, close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return
    const startingIndex = period - 1
    for (let i = startingIndex; i < close.length; i++) {
      this._calculatedValues.upperBand[i] = utils.max(high.slice(Math.max(0, i - startingIndex), i + 1))
      this._calculatedValues.lowerBand[i] = utils.min(low.slice(Math.max(0, i - startingIndex), i + 1))
      this._calculatedValues.medianBand[i] =
        (this._calculatedValues.upperBand[i] + this._calculatedValues.lowerBand[i]) / 2
    }
  }
}
