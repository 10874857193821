import { ChartEventBadgeType, ChartEventLabels, ChartEventType } from '../constants/common'
import { IEarnings } from '../utils/chart-events-utils'
import ChartEvent from './chart-event'

class Earnings extends ChartEvent {
  static type = ChartEventType.Earnings
  static label = ChartEventLabels[ChartEventType.Earnings]

  getChartEvenBadgeType(): ChartEventBadgeType {
    const eventItem = this.getChartEventModel()?.getChartEventData() as IEarnings | undefined

    if (!eventItem) {
      return ChartEventBadgeType.EarningsNeutral
    }

    if (eventItem.epsActual === undefined || eventItem.epsEstimate === undefined) {
      const now = Date.now() / 1000
      return eventItem.dateTimestamp > now ? ChartEventBadgeType.EarningsFuture : ChartEventBadgeType.EarningsNeutral
    }
    return eventItem.epsActual - eventItem.epsEstimate < 0
      ? ChartEventBadgeType.EarningsNegative
      : ChartEventBadgeType.EarningsPositive
  }
}

export default Earnings
