import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  period: number
}

export interface Attrs extends Options {
  channelStrokeColor: string
  channelFillColor: string
  medianBandColor: string
}

export class DchConfig extends BaseConfig {
  static type = OverlayType.Dch
  static abbreviation = 'dch'
  static label = 'Donchian Channels'
  static shortLabel = 'Donchian'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = ['period', 'channelStrokeColor', 'channelFillColor', 'medianBandColor'] as Array<keyof Attrs>
  static inputsLabels = {
    period: 'Period',
    channelStrokeColor: 'Channels Stroke Color',
    channelFillColor: 'Channels Fill Color',
    medianBandColor: 'Median Band Color',
  }

  static calculatedValuesOrder = ['upperBand', 'lowerBand', 'medianBand'] as const
  static calculatedValuesLabels = {
    upperBand: 'Upper Band',
    lowerBand: 'Lower Band',
    medianBand: 'Median',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
