import { PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType, TextBaseline } from '../constants/common'
import { getRoundedObject } from '../controllers/renderUtils'
import PaneModel from '../models/pane'
import Element from './element'
import Text, { ITextAttrs } from './text'
import Thumb from './thumb'

interface Coordinates {
  x1: number
  x2: number
  y1: number
  y2: number
}

export interface IPositionAttrs extends Coordinates {
  targetColor: string
  stopColor: string
  targetY: number
  centerY: number
  stopY: number
}

class Position<Attrs extends IPositionAttrs = IPositionAttrs> extends Element<Attrs> {
  static type = CanvasElementType.position

  name = 'Position'

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2' | 'targetY' | 'centerY' | 'targetY' | 'stopY'>

  x1Dif: number
  x2Dif: number

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        't1',
        () => this.attrs.x1,
        () => this.attrs.targetY,
        this.resize,
        this.model
      ),
      new Thumb(
        't2',
        () => this.attrs.x2,
        () => this.attrs.targetY,
        this.resize,
        this.model
      ),
      new Thumb(
        's1',
        () => this.attrs.x1,
        () => this.attrs.stopY,
        this.resize,
        this.model
      ),
      new Thumb(
        's2',
        () => this.attrs.x2,
        () => this.attrs.stopY,
        this.resize,
        this.model
      ),
      new Thumb(
        'c1',
        () => this.attrs.x1,
        () => this.attrs.centerY,
        this.resize,
        this.model
      ),
      new Thumb(
        'c2',
        () => this.attrs.x2,
        () => this.attrs.centerY,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
    this.x1Dif = this.x2Dif = 0
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['targetY', 'centerY', 'stopY'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, targetY, centerY, stopY } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, targetY, centerY, stopY })
    let sign, stopBaseline, targetBaseline
    const width = roundedXY.x2 - roundedXY.x1

    context.set('fillStyle', this.attrs.targetColor)
    context.fillRect(roundedXY.x1, roundedXY.targetY, width, roundedXY.centerY - roundedXY.targetY)

    context.set('fillStyle', this.attrs.stopColor)
    context.fillRect(roundedXY.x1, roundedXY.centerY, width, roundedXY.stopY - roundedXY.centerY)

    const targetDif = Math.abs(this.attrs.targetY - this.attrs.centerY)
    const targetPerc = (100 / this.attrs.centerY) * targetDif
    const targetStr = `Target: ${targetDif.toFixed(2)} (${targetPerc.toFixed(2)}%)`

    const stopDif = Math.abs(this.attrs.stopY - this.attrs.centerY)
    const stopPerc = (100 / this.attrs.centerY) * stopDif
    const stopStr = `Stop: ${stopDif.toFixed(2)} (${stopPerc.toFixed(2)}%)`

    if (this.attrs.targetY > this.attrs.centerY) {
      sign = 1
      targetBaseline = TextBaseline.bottom
      stopBaseline = TextBaseline.top
    } else {
      sign = -1
      targetBaseline = TextBaseline.top
      stopBaseline = TextBaseline.bottom
    }

    const text = new Text(
      {
        text: targetStr,
        fillStyle: this.getChartLayoutSettings().ElementSettings.Colors.textWithoutBackground,
        font: { size: 10, family: 'Arial', weight: 'bold' },
        y: roundedXY.targetY - sign * 4,
        textBaseline: targetBaseline,
      },
      this.model
    )
    let textWidth = text.measure(context)
    text.set({ x: roundedXY.x1 + width / 2 - textWidth / 2 } as Partial<ITextAttrs>)
    text.render(context)

    text.set({
      text: stopStr,
      y: roundedXY.stopY + sign * 4,
      textBaseline: stopBaseline,
    })
    textWidth = text.measure(context)
    text.set({ x: roundedXY.x1 + width / 2 - textWidth / 2 } as Partial<ITextAttrs>)
    text.render(context)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  addToCoordinates(difs: Partial<IPositionAttrs>) {
    let middle, middle1, result
    if (difs.targetY) {
      result = this.attrs.targetY + difs.targetY
      if (
        (this.attrs.targetY > this.attrs.centerY && result < this.attrs.centerY) ||
        (this.attrs.targetY < this.attrs.centerY && result > this.attrs.centerY)
      ) {
        this.attrs.stopY = this.attrs.centerY - (this.attrs.stopY - this.attrs.centerY) // swap target&stop
      }
      this.attrs.targetY = result
    }

    if (difs.stopY) {
      result = this.attrs.stopY + difs.stopY
      if (
        (this.attrs.stopY > this.attrs.centerY && result < this.attrs.centerY) ||
        (this.attrs.stopY < this.attrs.centerY && result > this.attrs.centerY)
      ) {
        this.attrs.targetY = this.attrs.centerY - (this.attrs.targetY - this.attrs.centerY) // swap target&stop
      }
      this.attrs.stopY = result
    }

    if (
      difs.centerY &&
      ((this.attrs.stopY < (middle = this.attrs.centerY + difs.centerY) && middle < this.attrs.targetY) ||
        (this.attrs.stopY > (middle1 = this.attrs.centerY + difs.centerY) && middle1 > this.attrs.targetY))
    ) {
      this.attrs.centerY += difs.centerY
    }

    this.x1Dif += difs.x1 || 0
    this.x2Dif += difs.x2 || 0
    const roundDif = ~~this.x1Dif || ~~this.x2Dif
    if (roundDif !== 0) {
      if (difs.x1 != null) {
        this.attrs.x1 += roundDif
      }
      if (difs.x2 != null) {
        this.attrs.x2 += roundDif
      }
      this.x1Dif = this.x2Dif = 0
    }
  }

  moveBy(x: number, y: number) {
    return this.addToCoordinates({
      x1: x,
      x2: x,
      targetY: y,
      centerY: y,
      stopY: y,
    })
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    const [coordType, coordIndex] = (type as string).split('')
    const yPoints: Record<string, keyof IPositionAttrs> = { t: 'targetY', c: 'centerY', s: 'stopY' }
    const obj: Partial<IPositionAttrs> = {
      [`x${coordIndex}`]: difX,
      [yPoints[coordType]]: difY,
    }
    return this.addToCoordinates(obj)
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const x1 = Math.round(this.attrs.x1)
    const x2 = Math.round(this.attrs.x2)
    const left = Math.min(x1, x2)
    const right = Math.max(x1, x2)
    const top = Math.min(this.attrs.targetY, this.attrs.stopY)
    const bottom = Math.max(this.attrs.targetY, this.attrs.stopY)
    if (left < area.x && area.x < right && top < area.y && area.y < bottom) {
      return true
    }
    return super.isInArea(area)
  }
}

Position.prototype.defaults = {
  targetColor: 'rgba(0,200,0,0.3)',
  stopColor: 'rgba(200,0,0,0.3)',
}

Position.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'targetColor' },
    { type: 'background', name: 'stopColor' },
  ],
}

export default Position
