import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  period: number
  multiplier: number
}

export interface Attrs extends Options {
  uptrendColor: string
  downtrendColor: string
}

export class StrConfig extends BaseConfig {
  static type = OverlayType.Str
  static abbreviation = 'str'
  static label = 'Supertrend'
  static shortLabel = 'Supertrend'
  static optionsOrder = ['period', 'multiplier'] as const
  static optionsLabels = { period: 'Period', multiplier: 'Period' }
  static inputsOrder = [...this.optionsOrder, 'uptrendColor', 'downtrendColor'] as Array<keyof Attrs>
  static inputsLabels = {
    ...this.optionsLabels,
    uptrendColor: 'Uptrend Color',
    downtrendColor: 'Downtrend Color',
  }
}
