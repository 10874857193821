import { PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import { getRoundedObject, getXYOffsetFromLine } from '../controllers/renderUtils'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

interface ILineAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  dashLength: number
  lineWidth: number
  strokeStyle: string
}

class Line<Attrs extends ILineAttrs = ILineAttrs> extends Element<Attrs> {
  static type = CanvasElementType.line

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.render = this.render.bind(this)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'l',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'r',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
    ]
  }

  getDefaults() {
    return {
      lineWidth: 1,
      strokeStyle: this.getChartLayoutSettings().ElementSettings.Colors.line,
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  render(context: CanvasRenderingContext2D) {
    const { x1, y1, x2, y2, lineWidth } = this.attrs
    const roundedXY = getRoundedObject({ x1, y1, x2, y2 })
    const translateXY = getXYOffsetFromLine({ lineWidth, ...roundedXY })

    context.translate(translateXY.x, translateXY.y)
    context.beginPath()
    context.set('lineWidth', this.attrs.lineWidth)
    context.set('strokeStyle', this.attrs.strokeStyle)

    context.moveTo(roundedXY.x1, roundedXY.y1)
    if (this.attrs.dashLength) {
      context.setLineDash([this.attrs.dashLength])
    }
    context.lineTo(roundedXY.x2, roundedXY.y2)
    context.stroke()
    if (this.attrs.dashLength) {
      context.setLineDash([])
    }
    context.translate(translateXY.x * -1, translateXY.y * -1)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.x2 += x
    this.attrs.y1 += y
    this.attrs.y2 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    if (type === 'l') {
      this.attrs.x1 += difX
      this.attrs.y1 += difY
    } else {
      this.attrs.x2 += difX
      this.attrs.y2 += difY
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    if (math.distanceToSegment(area, this.attrs) <= 10) {
      return true
    }
    return super.isInArea(area)
  }
}

export default Line
