import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ChartsOrQuotePageQueryParams, SpecificChartFunctionality } from '../../constants/common'
import { useModelState } from '../../model-hooks/use-model-state'
import { useModelRef } from '../../model-hooks/useModelRef'
import Chart from '../../models/chart'
import Quote from '../../models/quote'
import { QuoteFetchType } from '../../models/quote/constants'
import { createRequestAbortController, removeFromAbortCache } from '../../utils/abort_controller'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'
import { getLeftOffset } from '../renderUtils'

const getQuoteRequestKey = (chartModel: Chart) =>
  `quoteReq-${chartModel.ticker}-${chartModel.instrument}-${chartModel.id}`

export function useChartControls({
  chartModel: unwatchedChartModel,
  isInteractive,
}: {
  chartModel: Chart | null
  isInteractive: boolean
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const chartModel = useModelState(unwatchedChartModel, {
    watchProperties: ['ticker', 'timeframe', 'dateRange'],
  })
  const chartLayoutModel = useModelState(chartModel?.chart_layout() ?? null, {
    watchProperties: ['idea', 'settings'],
  })
  const chartModelRef = useModelRef(chartModel)
  const [isLoading, setIsLoading] = React.useState(false)
  const abortControllerKeyRef = React.useRef('')
  const isIdeaChart = !!chartLayoutModel?.idea?.id

  const updateChartModelWithNewQuote = React.useCallback((quote: Quote) => {
    const chart = chartModelRef.current
    if (!chart) {
      return
    }

    const chartLayoutModel = chart.chart_layout()
    const isSameTicker = chart.getQuoteRawTicker() === quote.getRawTicker()

    chart.getAllPanes().forEach((pane) => {
      if (!!pane.getChartOrIndicatorElement()) {
        pane.updateAttribute('scaleRange', null)
      }
    })

    chart.updateAttributes({ leftOffset: getLeftOffset({ quote, chartModel: chart }), quote, isScrolled: true })

    // Refetch all other chart quotes as the main one is already refetched so we can filter it out but other quotes (from perf for example) should be refetched as well
    chart
      .getAllQuotes()
      .filter((chartQuote) => !chartQuote.eql(quote))
      .forEach((quote) => {
        quote.fetchData({ fetchType: QuoteFetchType.NewerData })
      })

    const chartPane = chart.getChartPane()
    chartLayoutModel.activeChartEvent?.instance.toggleIsOpen(false)

    const elements = [...chart.getAllElements(), ...(chartPane?.getAllChartEvents(false) ?? [])]
    for (const element of elements) {
      const isDrawing = element.isDrawing()
      const isChartEvent = element.isChartEvent()
      if (isDrawing || isChartEvent) {
        // We want to remove all drawings on ticker change but if autosave is enabled we let the autosave hook take care of that
        if (isSameTicker && (isChartEvent || chartLayoutModel.isPreserveDrawingsActive)) {
          element.instance.updateScales()
        } else {
          element.destroyCascade()
        }
      }
    }

    chart.setChartEvents(quote.chartEvents, true)

    chartPane?.updateChartEventsZIndexes()

    // The only dependency here is a ref , so no need to add it to dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchQuote = React.useCallback(async () => {
    if (!isInteractive || !chartModel || isIdeaChart) {
      return
    }

    const { ticker, instrument, timeframe, hasChartEvents } = chartModel
    const { premarket, aftermarket } = Quote.first()
    const patterns = chartModel.getHasPatterns()
    const currentQuote = chartModel.quote()
    if (currentQuote.getRawTicker() !== ticker || currentQuote.timeframe !== timeframe) {
      setIsLoading(true)
      abortControllerKeyRef.current = getQuoteRequestKey(chartModel)
      const abortController = createRequestAbortController({
        key: abortControllerKeyRef.current,
        shouldAbortRunningRequest: !Quote.select<Quote>(
          (quote) =>
            quote.ticker === ticker &&
            quote.timeframe === timeframe &&
            quote.isFetching &&
            chartModel.chart_layout().isIdeaId(quote.ideaID)
        ),
      })

      const quote = await Quote.get({
        ticker,
        instrument,
        timeframe,
        options: {
          premarket,
          aftermarket,
          events: hasChartEvents,
          financialAttachments: chartModel.getQuoteFinancialAttachments(),
          patterns,
          cachePredicate: (quote) => quote.ideaID === undefined,
          fetchNewDataOnCachedQuote: true,
        },
        abortController,
      })

      if (quote.wasFetchAborted) {
        // Check if the quote is also used in other chars, as the quote may be reused if we already have a quote with the same options
        const isQuoteUsedInOtherCharts = chartModel
          .chart_layout()
          .getAllCharts()
          .filter((chart) => !chartModel.eql(chart))
          .some((chart) => quote.eql(chart.quote()))

        // If the quote hasn't been fetched yet and is not anywhere else we can destroy the model
        if (quote.fetchedAt === -1 && !isQuoteUsedInOtherCharts) {
          quote.destroy()
        }
      } else if (quote.timeframe === chartModel.timeframe && quote.getRawTicker() === chartModel.ticker) {
        removeFromAbortCache(abortControllerKeyRef.current)
        updateChartModelWithNewQuote(quote)
        setIsLoading(false)
      }
    }

    // We don't want to create new function on any chartModel change as it may be the same chart with different object instance
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartModel?.id,
    chartModel?.ticker,
    chartModel?.timeframe,
    chartModel?.instrument,
    isIdeaChart,
    updateChartModelWithNewQuote,
    isInteractive,
  ])

  React.useEffect(() => {
    fetchQuote()
  }, [fetchQuote])

  // There are several ways how to turn off dateRange, e.g. moving the charts
  // This effect listens to dateRange changes on chartmodel and updates the url if there's a change
  // Consider refactoring this part so we only update chart models on route changes not the other way around
  React.useEffect(() => {
    if (chartModel) {
      const decodedQueryString = decodeQueryString<ChartsOrQuotePageQueryParams>(location.search)
      const rangesArray = chartModel
        .chart_layout()
        .getAllCharts()
        .map((chart) => chart.dateRange)
      const ranges = rangesArray.every((i) => !i) ? undefined : rangesArray.join(',')

      if (decodedQueryString.r !== ranges) {
        navigate({
          search: `?${encodeQueryString({
            ...decodedQueryString,
            r: ranges,
          })}`,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartModel?.dateRange])

  // https://github.com/finvizhq/charts/pull/1253/files#r1052266805
  // React.useEffect(
  //   () => () => {
  //     if (isInteractive && abortControllerKeyRef.current !== '') {
  //       abortRequest(abortControllerKeyRef.current)
  //     }
  //   },
  //   [isInteractive]
  // )

  React.useEffect(() => {
    const chart = chartModelRef.current
    const quote = chart?.quote()

    let shouldRecalculateOnCharts = false
    if (chartLayoutModel?.specificChartFunctionality === SpecificChartFunctionality.chartPage && !isIdeaChart) {
      const settings = chartLayoutModel?.settings
      const barWidth = 3
      const barMargin = 1
      if (settings) {
        const hasSameBarWidth = [
          settings.ChartSettings.center.barWidth,
          settings.IndicatorSettings.center.barWidth,
        ].every((value) => value === barWidth)
        const hasSameBarMargin = [
          settings.ChartSettings.center.barMargin,
          settings.IndicatorSettings.center.barMargin,
        ].every((value) => value === barMargin)

        shouldRecalculateOnCharts = !hasSameBarWidth || !hasSameBarMargin
        if (shouldRecalculateOnCharts) {
          settings.ChartSettings.center.barWidth = barWidth
          settings.IndicatorSettings.center.barWidth = barWidth
          settings.ChartSettings.center.barMargin = barMargin
          settings.IndicatorSettings.center.barMargin = barMargin

          chartLayoutModel.updateAttributes({ settings })
        }
      }
    }

    const isQuoteOrQuoteFinancials =
      chartLayoutModel &&
      [SpecificChartFunctionality.quotePage, SpecificChartFunctionality.quoteFinancials].includes(
        chartLayoutModel.specificChartFunctionality
      )

    if (chart && quote && (isQuoteOrQuoteFinancials || shouldRecalculateOnCharts) && !isIdeaChart) {
      const leftOffset = getLeftOffset({ quote, chartModel: chart })
      chart.updateAttributes({ leftOffset })
    }
    // This effect should run only on timeframe change & idea change
    // The reason we need to recalculate the left offset is that quote timeframe change changes chart and chart bars sizes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartModel?.quote()?.timeframe, chartLayoutModel?.settings, isIdeaChart])

  return { isLoading }
}
