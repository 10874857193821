import { getIsSSr } from './helpers'

// Extracted from isMobile, and modified to fit usage
function isFirefoxDesktop() {
  const matchesAndroid = navigator.userAgent.match(/Android/i)
  const matchesWebOS = navigator.userAgent.match(/webOS/i)
  const matchesIPhone = navigator.userAgent.match(/iPhone/i)
  const matchesiPad = navigator.userAgent.match(/iPad/i)
  const matchesIPod = navigator.userAgent.match(/iPod/i)
  const matchesBlackBerry = navigator.userAgent.match(/BlackBerry/i)
  const matchesWindowsPhone = navigator.userAgent.match(/Windows Phone/i)
  const matchesIOSLike = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints > 0

  const isMobileDevice =
    matchesAndroid ||
    matchesWebOS ||
    matchesIPhone ||
    matchesiPad ||
    matchesIPod ||
    matchesBlackBerry ||
    matchesWindowsPhone ||
    matchesIOSLike

  const matchesFirefox = !!navigator.userAgent.match(/firefox/i)

  return matchesFirefox && !isMobileDevice
}

// Due bug in FireFox, when if HW acceleration is used canvas is blurry
// we need to set canvas as CPU only
const shouldUseCPUOnly = getIsSSr() ? false : isFirefoxDesktop()

export function getContextWithCache(canvas: HTMLCanvasElement | null): CanvasRenderingContext2D | null {
  if (!canvas) {
    return null
  }

  const context = canvas.getContext('2d', shouldUseCPUOnly ? { willReadFrequently: true } : undefined)
  if (!context) {
    window.Sentry?.captureMessage('Context unavailable')
    return null
  }

  context._cache = {}

  const _restore = CanvasRenderingContext2D.prototype.restore
  context.restore = function () {
    this._cache = {}
    return _restore.apply(this)
  }

  context.set = function (key, value) {
    if (this._cache[key] !== value) {
      this[key] = value
      this._cache[key] = value
    }
  }

  return context
}
