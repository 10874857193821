import { ADXConfig, Attrs } from '../indicators/configs/adx'
import { getTrueRange } from './atr'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  /**
   * Average Directional Index
   */
  adx: number[]
  /**
   * Plus Directional Indicator
   */
  pdi: number[]
  /**
   * Minus Directional Indicator
   */
  mdi: number[]
}

function getDX(pdi: number, mdi: number) {
  return (Math.abs(pdi - mdi) / Math.abs(pdi + mdi)) * 100
}

export class ADXCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = ADXConfig

  calculate() {
    const { period } = this.options
    const { high, low, close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    /**
     * Smoothed Average True Range
     */
    const sATR: number[] = []
    let firstAtrAccumulator = 0
    /**
     * Smoothed Plus Directional Movement
     */
    const sPDM: number[] = []
    let firstPdmAccumulator = 0
    /**
     * Smoothed Minus Directional Movement
     */
    const sMDM: number[] = []
    let firstMdmAccumulator = 0
    /**
     * Directional Index
     */
    const dx: number[] = []

    /**
     * First ADX: Sum of n periods of TR
     */
    let firstAdxAccumulator = 0

    // Not enough data for calculation
    if (close.length < period + 1) return

    for (let i = 1; i < close.length; i++) {
      const tr = getTrueRange(high[i], low[i], close[i - 1])

      const upMove = high[i] - high[i - 1]
      const downMove = low[i - 1] - low[i]

      const plusDM = upMove > downMove && upMove > 0 ? upMove : 0
      const minusDM = downMove > upMove && downMove > 0 ? downMove : 0

      if (i <= period) {
        firstAtrAccumulator += tr
        firstPdmAccumulator += plusDM
        firstMdmAccumulator += minusDM
        if (i === period) {
          // First value of smoothed ATR/PDM/MDM is just SUM of n periods
          sATR[i] = firstAtrAccumulator
          sPDM[i] = firstPdmAccumulator
          sMDM[i] = firstMdmAccumulator
          this._calculatedValues.pdi[period] = (sPDM[i] / sATR[i]) * 100
          this._calculatedValues.mdi[period] = (sMDM[i] / sATR[i]) * 100
          dx[i] = getDX(this._calculatedValues.pdi[i], this._calculatedValues.mdi[i])
          firstAdxAccumulator = dx[i]
        }
        continue
      }

      sATR[i] = sATR[i - 1] - sATR[i - 1] / period + tr
      sPDM[i] = sPDM[i - 1] - sPDM[i - 1] / period + plusDM
      sMDM[i] = sMDM[i - 1] - sMDM[i - 1] / period + minusDM

      this._calculatedValues.pdi[i] = (sPDM[i] / sATR[i]) * 100
      this._calculatedValues.mdi[i] = (sMDM[i] / sATR[i]) * 100

      dx[i] =
        (Math.abs(this._calculatedValues.pdi[i] - this._calculatedValues.mdi[i]) /
          Math.abs(this._calculatedValues.pdi[i] + this._calculatedValues.mdi[i])) *
        100

      // Because ADX is double smoothed adx valus start at "double period index",
      // whole calculation is starting at index 1 because we always compare current with previous
      // eg. for period 5:
      // first smoothing is from index 1 to 5 becuase 1 to 5 is 5 indexes
      // then second smooting is from index 5 to 9 becuase again it is 5 indexes
      // thus (period * 2 - 1) === (5 * 2 - 1) => 9
      if (i <= period * 2 - 1) {
        firstAdxAccumulator += dx[i]
        if (i === period * 2 - 1) {
          // First ADX is n period average of DX
          this._calculatedValues.adx[period * 2 - 1] = firstAdxAccumulator / period
        }
        continue
      }

      this._calculatedValues.adx[i] = (this._calculatedValues.adx[i - 1] * (period - 1) + dx[i]) / period
    }
  }
}
