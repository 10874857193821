import { FICalculation } from '../indicator-calculation/fi'
import { Attrs, FIConfig } from './configs/fi'
import Indicator from './indicator'

class ForceIndex extends Indicator<Attrs> {
  static config = FIConfig

  fiCalculation: FICalculation | null = null
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.fiCalculation = new FICalculation({
        quote: this.data,
        options: {
          period: this.period,
        },
      })
      this.fiCalculation.calculate()
      this.lastValue = this.fiCalculation.calculatedValues.fi.last() ?? null
    }
    const fi = this.fiCalculation?.calculatedValues.fi ?? []
    const { min, max } = fi.length > 0 ? this.computeVisibleMinMax(fi) : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.fiCalculation?.calculatedValues.fi ?? [])
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.fiCalculation?.calculatedValues.fi ?? [], 1, 0, 0, 0, false)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 13,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: FIConfig.label,
      inputs: FIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default ForceIndex
