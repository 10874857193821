import { ChartConfigChartPaneElement } from '../../types/shared'
import { drawingToolsByType } from '../canvas/drawingTools'
import { chartEventsByType } from '../chart-events'
import { chartsByType } from '../charts/charts'
import { indicatorsByType } from '../indicators/indicators'
import { overlaysByType } from '../overlays/overlays'

const canvasElements = {
  ...overlaysByType,
  ...indicatorsByType,
  ...drawingToolsByType,
  ...chartsByType,
  ...chartEventsByType,
}

export function getCanvasElementByType(elementType: ChartConfigChartPaneElement['type']) {
  return canvasElements[elementType]
}
