import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  price: number
}

export interface Attrs extends Options {
  period: number
  color: string
}

export class HLineConfig extends BaseConfig {
  static type = OverlayType.Hline
  static abbreviation = 'hline'
  static label = 'Horizontal Line'
  static shortLabel = 'HL'
  static optionsOrder = ['price'] as const
  static optionsLabels = { price: 'Price' }
  static inputsOrder = ['price', 'color'] as Array<keyof Omit<Attrs, 'period'>>
  static inputsLabels = {
    price: 'Price',
    color: 'Color',
  }
}
