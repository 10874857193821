import logo from './app/shared/assets/logo.png'
import logo_light from './app/shared/assets/logo_light.png'

export * from './main/components'

export { getSanitizedTicker } from './app/shared/ticker-sanitizer'
export * from './app/queryString'
export * from './main/modules/portfolio/types'

export { getFormattedDateString } from './app/header/utils'
export { decodeQueryString, encodeQueryString } from './app/queryString'
export { createAlertAsyncModule } from './main/modules/create-alert/async-modules'
export { saveToPortfolioAsyncModule } from './main/modules/save-to-portfolio/async-modules'
export { formatDateToStringUS } from './app/shared/utils'
export { portfolioWatchlistTableAsyncModule } from './main/components/portfolio/async-modules'
export { useTrackPageView } from './main/hooks/use-track-pageview'
export {
  useAsyncModule,
  withSuspense,
  type ControlledModuleConfig,
  type SuspenseModuleConfig,
} from './main/services/async-modules-utils'
export { importWithRetry } from './main/services/import-with-retry'
export * as localStorage from './main/services/local-storage'
export { waitForPreviousPromise } from './main/services/wait-for-previous-promise'
export { setCookieRequest } from './main/services/api'
export { intervalCallbackOnWindowVisible } from './app/shared/documentVisibility'

export const assets = { logo, logo_light }
