import { Button, ButtonComponentProps } from '@finviz/website'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { SpecificChartFunctionality } from '../../constants/common'
import ChartLayout from '../../models/chart_layout'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'

function getTickersString(tickers: string[], tickersCount: number) {
  return Array.from({ length: Math.min(tickersCount, 6) })
    .map((_, index) => tickers[index] ?? tickers[tickers.length - 1])
    .join(',')
}

function getLayoutType(tickersCount: number) {
  switch (tickersCount) {
    case 0: // shouldn't happen
    case 1:
      return '1h1v'
    case 2:
      return '2h1v'
    case 3:
      return '3h1v'
    case 4:
      return '2h2v'
    case 5:
    default:
      // 6 and more
      return '3h2v'
  }
}

const utmCampaign: Partial<Record<SpecificChartFunctionality, string>> = {
  [SpecificChartFunctionality.quotePage]: 'quote-fullscreen-button',
  [SpecificChartFunctionality.futuresPage]: 'futures-fullscreen-button',
  [SpecificChartFunctionality.forexPage]: 'forex-fullscreen-button',
  [SpecificChartFunctionality.cryptoPage]: 'crypto-fullscreen-button',
}

interface FullscreenButtonProps extends Pick<ButtonComponentProps, 'theme' | 'size' | 'rounding'> {
  model: ChartLayout
  isPremium: boolean
  title?: string
}

export function FullscreenButton({ model, isPremium, ...props }: React.PropsWithChildren<FullscreenButtonProps>) {
  const location = useLocation()
  const { i, p = 'd', r } = decodeQueryString(location.search)
  const urlTickers = model
    .charts()
    .all()
    .map((chart) => chart.quote().getRawTicker())

  return (
    <Button
      {...props}
      as="a"
      leftContent="fullscreen"
      target={isPremium ? '_blank' : undefined}
      appearance={props.children ? undefined : 'square'}
      title={props.children ? undefined : 'Fullscreen'}
      data-testid="chart-layout-fullscreen"
      href={
        isPremium
          ? `/charts?${encodeQueryString({
              i,
              t: getTickersString(urlTickers, urlTickers.length),
              p: urlTickers.map(() => p).join(','),
              l: getLayoutType(urlTickers.length),
              r,
            })}`
          : `/elite.ashx?${encodeQueryString({
              utm_source: 'finviz',
              utm_medium: 'banner',
              utm_campaign: utmCampaign[model.specificChartFunctionality],
            })}`
      }
    />
  )
}
