import { ChartConfigChartPane } from '../../../types/shared'
import { IndicatorType } from '../../constants/common'
import ChartLayout from '../../models/chart_layout'
import Element from '../../models/element'
import PaneModel from '../../models/pane'
import { HLC, compareHlc, incrementHlc, receiveHlc } from '../../utils/hlc'
import {
  AutoSaveChangeType,
  AutoSaveElement,
  AutoSaveElementDBRecord,
  DrawingChangeTimestamp,
  DrawingContainerType,
} from './constants'

export function getContainerTypeFromPaneConfig(pane: ChartConfigChartPane) {
  const indicatorElement = pane.elements.find((el) => el.type.startsWith('indicators/'))
  if (indicatorElement) {
    return indicatorElement.type as DrawingContainerType
  }
  const chartElement = pane.elements.find((el) => el.type.startsWith('charts/'))
  if (chartElement) {
    return 'chart'
  }
  return null
}

export function getContainerType(model: PaneModel) {
  const mainElement = model.mainElement()
  const indicatorType = mainElement?.instance?.type ?? null
  if (mainElement?.isIndicator() && indicatorType !== IndicatorType.None) {
    return indicatorType as DrawingContainerType
  }
  if (mainElement?.isChart()) {
    return 'chart'
  }
  return null
}

export function updateInternalStoreWithChangedElement({
  element,
  changeType,
  currentInternalStore,
  lastUpdateTimestamp,
}: {
  element: Element
  changeType: AutoSaveChangeType
  currentInternalStore: AutoSaveElement[]
  lastUpdateTimestamp: HLC
}) {
  const paneModel = element.pane()
  const ticker = paneModel.getQuoteRawTicker()
  const containerType = getContainerType(paneModel)
  if (ticker && paneModel && containerType && (!element.destroyed || changeType === 'destroy')) {
    const instanceJson = element.instance.toAutosaveConfig()
    const newDrawing: AutoSaveElement = {
      elementId: element.elementId!,
      zIndex: element.zIndex,
      ticker,
      lastChange: lastUpdateTimestamp,
      changeType,
      containerType,
      elementAttrs: JSON.stringify(instanceJson),
    }

    const newInternalStore = [...currentInternalStore]
    const updateDrawingIndex = newInternalStore.findIndex(
      (drawingElement) => drawingElement.elementId === newDrawing.elementId
    )

    let shouldSyncAndSave = false
    if (updateDrawingIndex > -1) {
      const updatedRecord = newInternalStore[updateDrawingIndex]
      const hasChangedZIndex = updatedRecord.zIndex !== newDrawing.zIndex
      const hasChangedType = updatedRecord.changeType !== newDrawing.changeType
      const hasChangedAttrs = updatedRecord.elementAttrs !== newDrawing.elementAttrs
      const hasChange = hasChangedType || hasChangedAttrs || hasChangedZIndex
      if (
        !element.instance.isEditInProgress &&
        updatedRecord.ticker === ticker &&
        hasChange &&
        updatedRecord.changeType !== 'destroy'
      ) {
        newInternalStore[updateDrawingIndex] = newDrawing
        shouldSyncAndSave = true
      }
    } else {
      newInternalStore.push(newDrawing)
      shouldSyncAndSave = true
    }

    return {
      newInternalStore,
      shouldSyncAndSave,
      newDrawing,
    }
  }
}

export function getTickersAndContainerTypesInLayoutModel(chartLayout: ChartLayout) {
  const tickers: string[] = []
  const containerTypes: Array<DrawingContainerType> = ['note']

  chartLayout.getAllPanes().forEach((paneModel) => {
    const ticker = paneModel.getQuoteRawTicker()
    const containerType = getContainerType(paneModel)
    if (ticker && !tickers.includes(ticker)) {
      tickers.push(ticker)
    }
    if (containerType && !containerTypes.includes(containerType)) {
      containerTypes.push(containerType)
    }
  })
  return { tickers, containerTypes }
}

export function mergeUpdatedDrawingsToInternalStore({
  updatedDrawings,
  currentInternalStore,
  lastUpdateTimestamp,
  isRefetchAll,
}: {
  updatedDrawings: AutoSaveElement[]
  currentInternalStore: AutoSaveElement[]
  lastUpdateTimestamp: DrawingChangeTimestamp
  isRefetchAll: boolean
}) {
  let newLastUpdateTimestamp = lastUpdateTimestamp
  const newInternalStore = [...currentInternalStore]
  const updatedElementIds: string[] = []

  updatedDrawings.forEach((drawing) => {
    newLastUpdateTimestamp = receiveHlc(newLastUpdateTimestamp, drawing.lastChange)
    const drawingIndex = newInternalStore.findIndex(({ elementId }) => drawing.elementId === elementId)
    const currentDrawing = drawingIndex > -1 ? newInternalStore[drawingIndex] : null
    if (currentDrawing === null) {
      newInternalStore.push(drawing)
      updatedElementIds.push(drawing.elementId)
    } else if (currentDrawing.containerType === 'note' && isRefetchAll) {
      const currentNote = getElementNoteText(currentDrawing)
      const autoSavedNote = getElementNoteText(drawing)
      if (currentNote !== autoSavedNote) {
        newInternalStore[drawingIndex] = {
          ...drawing,
          lastChange: incrementHlc(receiveHlc(currentDrawing.lastChange, drawing.lastChange), Date.now()),
          elementAttrs: JSON.stringify({
            ...JSON.parse(drawing.elementAttrs),
            text: `${currentNote}\r\n${autoSavedNote}`,
          }),
        }
      }
      updatedElementIds.push(drawing.elementId)
    } else if (compareHlc(newInternalStore[drawingIndex].lastChange, drawing.lastChange) < 0) {
      newInternalStore[drawingIndex] = drawing
      updatedElementIds.push(drawing.elementId)
    }
  })
  return { newLastUpdateTimestamp, newInternalStore, updatedElementIds }
}

export function getInitialStoreDrawings(): null | AutoSaveElement[] {
  const initialDrawings = (
    window.FinvizSettings?.initialDrawingsStore ?? window.FinvizChartsSettings?.initialDrawingsStore
  )?.drawings as null | AutoSaveElementDBRecord[]

  return initialDrawings?.map((item) => getAutosaveElementFromDBRecord(item)) ?? null
}

export function handleCallMethodOnAllDrawings({
  chartLayoutModel,
  elementMethod,
}: {
  chartLayoutModel: ChartLayout
  elementMethod: keyof Pick<Element, 'destroyCascade' | 'refreshElementId'>
}) {
  chartLayoutModel.getAllElements().forEach((element) => {
    if (element.isDrawing()) {
      element[elementMethod]()
    }
  })
}

export function getNoteElementId(ticker: string) {
  return `note-${ticker}`
}

export function getElementNoteText(noteElement?: AutoSaveElement) {
  return (noteElement ? JSON.parse(noteElement.elementAttrs)?.text : '') ?? ''
}

export function getTickerNoteText({ ticker, elements }: { ticker: string; elements: AutoSaveElement[] }) {
  const noteElement = elements.find(({ elementId }) => elementId === getNoteElementId(ticker))
  return getElementNoteText(noteElement)
}

export function getAutosaveElementFromDBRecord(item: AutoSaveElementDBRecord): AutoSaveElement {
  return {
    elementId: item.elementId,
    ticker: item.ticker,
    zIndex: item.zIndex,
    changeType: item.changeType,
    containerType: item.containerType,
    elementAttrs: item.elementAttrs,
    lastChange: {
      ts: item.lastChangeTimestamp,
      count: item.lastChangeCounter,
      uuid: item.lastChangeNodeUUID,
    },
  }
}

export function getDBRecordFromAutosaveElement(item: AutoSaveElement): Omit<AutoSaveElementDBRecord, 'lastChange'> {
  return {
    elementId: item.elementId,
    ticker: item.ticker,
    zIndex: item.zIndex,
    changeType: item.changeType,
    containerType: item.containerType,
    elementAttrs: JSON.stringify(item.elementAttrs),
    lastChangeTimestamp: item.lastChange.ts,
    lastChangeCounter: item.lastChange.count,
    lastChangeNodeUUID: item.lastChange.uuid,
  }
}
