import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

type RotatedRectangleCoordiantes = [[number, number], [number, number], [number, number], [number, number]]

export interface IRotatedRectangleAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  height: number
  fill: string
  border: DrawingBorder
}

// OLD RotatedRectangle "v0" kept for backward-compatibiluty for old saved ideas
// current RotatedRectangle tool located in rotated_rectangle folder
class RotatedRectangle<Attrs extends IRotatedRectangleAttrs = IRotatedRectangleAttrs> extends Element<Attrs> {
  static type = CanvasElementType.rotatedRectangle

  name = 'Rotated rectangle'

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2' | 'height'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    const x = this.model.scale.x.invert
    const y = this.model.scale.y.invert
    this._thumbs = [
      new Thumb(
        'ml',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'mr',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'tl',
        () => x(this.getRectCoordinates()[0][0]),
        () => y(this.getRectCoordinates()[0][1]),
        this.resize,
        this.model
      ),
      new Thumb(
        'tr',
        () => x(this.getRectCoordinates()[1][0]),
        () => y(this.getRectCoordinates()[1][1]),
        this.resize,
        this.model
      ),
      new Thumb(
        'bl',
        () => x(this.getRectCoordinates()[2][0]),
        () => y(this.getRectCoordinates()[2][1]),
        this.resize,
        this.model
      ),
      new Thumb(
        'br',
        () => x(this.getRectCoordinates()[3][0]),
        () => y(this.getRectCoordinates()[3][1]),
        this.resize,
        this.model
      ),
    ]
    const boundingPointKeys = this.getBoundingPointKeys()
    this.scale({ x: [...boundingPointKeys.x, 'height'], y: boundingPointKeys.y })
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  renderContent(context: CanvasRenderingContext2D) {
    context.set('fillStyle', this.attrs.fill)

    context.beginPath()
    const coords = this.getRectCoordinates()
    for (const point of coords) {
      context.lineTo(point[0], point[1])
    }
    context.lineTo(coords[0][0], coords[0][1])
    context.fill()

    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    if ((this.attrs.border != null ? this.attrs.border.width : undefined) !== 0) {
      context.stroke()
    }

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getRectCoordinates(): RotatedRectangleCoordiantes {
    const mx1 = this.scaled.x1
    const mx2 = this.scaled.x2
    const my1 = this.scaled.y1
    const my2 = this.scaled.y2
    const { height } = this.scaled

    const k = (my2 - my1) / (mx2 - mx1)
    const nk = -1 / k

    const sign = my1 > my2 ? 1 : -1
    const dx = (sign * Math.sqrt((height * height) / (1 + nk * nk))) / 2
    const dy = nk * dx

    let x1 = mx1 - dx
    let y1 = my1 - dy
    let x2 = mx2 - dx
    let y2 = my2 - dy

    let x3 = mx2 + dx
    let y3 = my2 + dy
    let x4 = mx1 + dx
    let y4 = my1 + dy

    if (my1 === my2) {
      x1 = mx1
      y1 = my1 - height / 2
      x2 = mx2
      y2 = my2 - height / 2

      x3 = mx2
      y3 = my2 + height / 2
      x4 = mx1
      y4 = my1 + height / 2
    }

    return [
      [x1, y1],
      [x2, y2],
      [x3, y3],
      [x4, y4],
    ]
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.y1 += y
    this.attrs.x2 += x
    this.attrs.y2 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'ml':
        this.attrs.x1 += difX
        this.attrs.y1 += difY
        break
      case 'mr':
        this.attrs.x2 += difX
        this.attrs.y2 += difY
        break
      case 'tl':
      case 'tr':
        this.attrs.height += difY
        break
      case 'bl':
      case 'br':
        this.attrs.height -= difY
        break
      default:
        return
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const coords = this.getRectCoordinates()
    const polygon = coords.map((c) => ({ x: c[0], y: c[1] }))
    if (math.pointInPolygon(area.scaled, polygon)) {
      return true
    }
    return super.isInArea(area)
  }

  getIsInChartView() {
    return true
  }
}

RotatedRectangle.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, default: {} },
  ],
}

export default RotatedRectangle
