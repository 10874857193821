import { RequireByKey, ResizeByThumbFuncProps } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import Chart from '../models/chart'
import PaneModel from '../models/pane'
import ScaledLine, { IScaledLineAttrs } from './scaled_line'

class VerticalLine<Attrs extends IScaledLineAttrs = IScaledLineAttrs> extends ScaledLine<Attrs> {
  static type = CanvasElementType.verticalLine

  name = 'Vertical line'

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.renderContent = this.renderContent.bind(this)

    this.attrs.x2 = this.attrs.x1
    this._thumbs = [this._thumbs[0]]
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  renderContent(context: CanvasRenderingContext2D) {
    const domain = this.model.scale.y.domain()
    this.attrs.y1 = domain[0]
    this.attrs.y2 = domain[1]
    this._thumbs[0].fy = () => (this.attrs.y1 + this.attrs.y2) / 2
    super.renderContent(context)
  }

  moveBy(x: number) {
    return (this.attrs.x1 = this.attrs.x2 += x)
  }

  resize({ difX }: RequireByKey<ResizeByThumbFuncProps, 'difX'>) {
    return this.moveBy(difX)
  }

  getIsInChartView(chart: Chart) {
    return super.getIsInChartView(chart, { omitY: true })
  }
}

export default VerticalLine
