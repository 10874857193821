import merge from 'lodash.merge'
import React from 'react'

import { IVisibility, MacroTimeframe, MicroTimeframeRange } from '../../constants/common'
import { isInRange } from '../../utils/helpers'
import { InputProps, VisibilityInput } from './visibility-input'

interface VisibilityProps {
  label: string
  name: string
  value: IVisibility
  onChange: (value: IVisibility) => void
  handleDisableSubmit: (value: string | false) => void
}

type Micro = { isOff: boolean; from: number; to: number }
export type DEFAULTS_TYPE = { [key in MacroTimeframe]: Micro }

export const DEFAULTS: DEFAULTS_TYPE = {
  // [MacroTimeframe.seconds]: { isOff: false, from: 1, to: 59 },
  [MacroTimeframe.minutes]: { isOff: false, from: 1, to: 59 },
  [MacroTimeframe.hours]: { isOff: false, from: 1, to: 24 },
  [MacroTimeframe.days]: { isOff: false, from: 1, to: 366 },
  [MacroTimeframe.weeks]: { isOff: false, from: 1, to: 52 },
  [MacroTimeframe.months]: { isOff: false, from: 1, to: 12 },
}

const INPUTS_AVAILABLE = [MacroTimeframe.minutes, MacroTimeframe.hours]

const getInitialState = (value: IVisibility) => {
  const visibilities = merge({}, DEFAULTS)
  for (const visibility in visibilities) {
    const macroTimeframe = visibility as MacroTimeframe
    const valueMacroTimeframe = value?.[macroTimeframe]
    if (!valueMacroTimeframe) continue
    if (Object.keys(valueMacroTimeframe).length === 0) {
      visibilities[macroTimeframe] = { ...visibilities[macroTimeframe], isOff: true }
    } else {
      visibilities[macroTimeframe] = { ...visibilities[macroTimeframe], ...valueMacroTimeframe }
    }
  }
  return visibilities
}

const getIsVisibilityOutsideAvailableTimeframes = (value: IVisibility) => {
  const result = Object.keys(MacroTimeframe).every((macrotimeframe) => {
    const macroRange = value[macrotimeframe as MacroTimeframe]

    return (
      macroRange &&
      (Object.keys(macroRange).length === 0 ||
        !MicroTimeframeRange[macrotimeframe as MacroTimeframe].some((item) =>
          isInRange({
            value: item,
            min: macroRange?.from ?? DEFAULTS[macrotimeframe as MacroTimeframe].from,
            max: macroRange?.to ?? DEFAULTS[macrotimeframe as MacroTimeframe].to,
          })
        ))
    )
  })
  return result
}

export function Visibility(props: VisibilityProps) {
  const initialVisibilityRef = React.useRef<DEFAULTS_TYPE>(getInitialState(props.value))

  const handleChange = (value: InputProps) => {
    const result: IVisibility = props.value ? merge({}, props.value) : {}
    result[value.macroKey] = {}

    if (value.isOff) {
      props.onChange(result)
    } else if (value.from === DEFAULTS[value.macroKey].from && value.to === DEFAULTS[value.macroKey].to) {
      delete result[value.macroKey]
      props.onChange(result)
    } else {
      if (value.from !== DEFAULTS[value.macroKey].from) {
        result[value.macroKey]!.from = value.from
      }
      if (value.to !== DEFAULTS[value.macroKey].to) {
        result[value.macroKey]!.to = value.to
      }
      props.onChange(result)
    }

    props.handleDisableSubmit(
      getIsVisibilityOutsideAvailableTimeframes(result)
        ? 'The chosen timeframes would make the drawing invisible on all supported timeframes.'
        : false
    )
  }

  return Object.keys(initialVisibilityRef.current).map((macroKey) => (
    <VisibilityInput
      key={`visibility-${macroKey}`}
      value={{ ...initialVisibilityRef.current[macroKey as MacroTimeframe], macroKey: macroKey as MacroTimeframe }}
      isCheckboxOnly={!INPUTS_AVAILABLE.includes(macroKey as MacroTimeframe)}
      onChange={handleChange}
    />
  ))
}
