import merge from 'lodash.merge'

import { PaneArea } from '../../types/shared'
import { PADDING } from '../constants/common'
import { CanvasElementType } from '../constants/common'
import Chart from '../models/chart'
import PaneModel from '../models/pane'
import Text, { ITextAttrs } from './text'

interface FontFamilyHeightCoefficient {
  Arial: {
    firstLine: number
    otherLines: number
  }
  Verdana: {
    firstLine: number
    otherLines: number
  }
}

class ScaledText<Attrs extends ITextAttrs = ITextAttrs> extends Text<Attrs> {
  static type = CanvasElementType.scaledText

  name = 'Text'
  declare fontFamilyHeightCoeficient: FontFamilyHeightCoefficient
  declare lastAttrs: Attrs
  declare scaled: Partial<Attrs>
  declare measuredHeight: number

  declare font: string
  declare context?: CanvasRenderingContext2D
  declare lines: {
    text: string
    metrics: TextMetrics
  }[]

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.scale({ x: ['x'], y: ['y'] })
  }

  getBoundingPointKeys = () => ({ x: ['x'], y: ['y'] })

  renderContent(context: CanvasRenderingContext2D) {
    this.render(context)
  }

  setupTextMetricsCache() {
    const ctx = this.context

    if (
      (this.attrs.text !== this.lastAttrs?.text ||
        JSON.stringify(this.attrs.font) !== JSON.stringify(this.lastAttrs?.font)) &&
      ctx
    ) {
      this.lastAttrs = merge({}, this.attrs)
      this.lines = this.getLines().map((line) => ({
        text: line,
        metrics: ctx.measureText(line),
      }))
      this.measuredHeight = this.lines.reduce((acc, current, currentIndex) => {
        const lineHeight =
          current.metrics.actualBoundingBoxAscent +
          current.metrics.actualBoundingBoxDescent +
          (currentIndex !== this.lines.length - 1 ? PADDING.XXXS : 0)
        return acc + lineHeight
      }, 0)
    }
  }

  // private
  setupContext(context: CanvasRenderingContext2D) {
    this.context = context
    context.set('font', this.font)
    context.set('fillStyle', this.attrs.fillStyle!)
    context.set('textAlign', this.attrs.textAlign!)
    context.set('textBaseline', this.attrs.textBaseline)
    this.setupTextMetricsCache()
  }

  // private
  renderText() {
    if (!this.context) return
    let y = this.scaled.y! + this.attrs.padding.top
    let previousDescent = 0
    for (let i = 0; i < this.lines.length; i++) {
      y += i === 0 ? 0 : this.lines[i].metrics.actualBoundingBoxAscent + previousDescent + PADDING.XXXS
      this.context.fillText(this.lines[i].text, this.scaled.x! + this.attrs.padding.left, y)
      previousDescent = this.lines[i].metrics.actualBoundingBoxDescent
    }
  }

  // private
  renderBackground() {
    if (!this.attrs.background || !this.context) {
      return
    }
    const padding = this.attrs.border!.width / 2 + PADDING.XXS

    this.context.beginPath()
    this.context.rect(
      this.scaled.x! - padding,
      this.scaled.y! - this.lines[0].metrics.actualBoundingBoxAscent - padding,
      this.width + padding * 2,
      this.measuredHeight + padding * 2
    )
    this.context.set('fillStyle', this.attrs.background)
    this.context.fill()
    this.context.set('fillStyle', this.attrs.fillStyle!)
    this.renderBorder()
    this.context.closePath()
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    // TODO
    // if @attrs.textBaseline == 'middle'
    //  rect.y = @attrs.y - rect.height/2
    if (
      this.lines &&
      this.scaled.x! < area.scaled.x &&
      area.scaled.x < this.scaled.x! + this.width &&
      this.scaled.y! - this.lines[0].metrics.actualBoundingBoxAscent < area.scaled.y &&
      area.scaled.y < this.scaled.y! - this.lines[0].metrics.actualBoundingBoxAscent + this.measuredHeight
    ) {
      return true
    }
    return this.thumbsAreInArea(area)
  }

  getIsInChartView(chart: Chart) {
    if (this.getIsCreator()) {
      return true
    }

    if (!this.lines) {
      return true
    }

    const padding = this.attrs.border!.width / 2 + PADDING.XXS
    const textBoxX = this.scaled.x! - padding
    const textBoxY = this.scaled.y! - this.lines[0].metrics.actualBoundingBoxAscent - padding
    const textBoxWidth = this.width + padding * 2
    const textBoxHeight = this.measuredHeight + padding * 2

    if (
      textBoxX <= -chart.leftOffset + chart.width &&
      textBoxX + textBoxWidth >= -chart.leftOffset &&
      textBoxY <= this.model.height &&
      textBoxY + textBoxHeight >= 0
    ) {
      return true
    }

    return false
  }

  moveBy(x: number, y: number) {
    this.attrs.x! += x
    this.attrs.y! += y
  }
}

export default ScaledText
