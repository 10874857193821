import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartLayoutModel from '../../models/chart_layout'

export function withIdeaModelState<P extends { chartLayoutModel: ChartLayoutModel }>(
  Component: React.ComponentType<P>
) {
  return function WrappedComponent(props: P) {
    const chartLayoutModel = useModelState(props.chartLayoutModel, { watchProperties: ['idea'] })
    return <Component {...props} idea={chartLayoutModel?.idea} />
  }
}
