import { Options, SMAConfig } from '../overlays/configs/sma'
import { MainCalculation } from './main'

export class SimpleMovingAverage {
  period: number
  sum: number
  count: number
  value: number
  trailingValues: number[]

  constructor(period: number) {
    this.period = period
    this.sum = 0
    this.count = 0
    this.value = 0
    this.trailingValues = []
  }

  add(value: number) {
    if (this.count < this.period) {
      this.sum += value
      this.count++
      this.trailingValues.push(value)
      if (this.count === this.period) this.value = this.sum / this.period
    } else {
      const firstTrailingValue = this.trailingValues.shift() ?? 0
      this.sum = this.sum - firstTrailingValue + value
      this.value = this.sum / this.period
      this.trailingValues.push(value)
    }
  }
}

type DefaultCalculatedValuesType = {
  sma: number[]
}

export class SMACalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = SMAConfig
  declare options: Options

  calculate() {
    const { period } = this.options
    const { close } = this.quote

    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return

    const sma = new SimpleMovingAverage(period)
    for (let i = 0; i < close.length; i++) {
      sma.add(close[i])
      if (i >= period - 1) {
        this._calculatedValues.sma[i] = sma.value
      }
    }
  }
}
