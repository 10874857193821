import classnames from 'classnames'
import React from 'react'

import { Instrument } from '../../../types/shared'
import { ChartEditorEnum, SpecificChartFunctionality } from '../../constants/common'
import math from '../../helpers/math'
import ChartModel from '../../models/chart'
import ChartLayout from '../../models/chart_layout'
import MouseModel from '../../models/mouse'
import PaneModel from '../../models/pane'
import Quote from '../../models/quote'
import { QuoteFetchType } from '../../models/quote/constants'
import utils, { isRedesignEnabled } from '../../utils'
import { getBarWidthWithMargin, getZoomFactorForBarsToDisplay } from '../../utils/chart'
import { ChartControls } from '../chart-controls'
import Pane from '../pane'
import {
  getBarIndex,
  getIsSmallIndexChart,
  getLeftOffset,
  getMinMaxLeftOffset,
  updateZoomAndLeftOffsetByDateRange,
} from '../renderUtils'
import { ChartProps } from './constants'
import { withChartInit } from './with-chart-init'
import { withWatchedPanesOnChartModel } from './with-watched-panes-on-chart-model'

function preventDefaultFunc(event: Event) {
  event.preventDefault()
}

function getZoomFactor({
  chartLayout,
  currentZoomFactor,
  diff,
  minBarWidthInPx = 1,
  maxBarWidthInPx = 66,
}: {
  chartLayout: ChartLayout
  currentZoomFactor: number
  diff: number
  minBarWidthInPx?: number
  maxBarWidthInPx?: number
}) {
  const minZoomFactor = getZoomFactorForBarsToDisplay({
    chartLayout,
    chartVisibleWidth: minBarWidthInPx,
    numOfVisibleBars: 1,
  })
  const maxZoomFactor = getZoomFactorForBarsToDisplay({
    chartLayout,
    chartVisibleWidth: maxBarWidthInPx,
    numOfVisibleBars: 1,
  })
  const isNegativeDirection = diff < 0
  const newZoomFactor = currentZoomFactor + diff
  const newZoomFactorInRange = Math.min(Math.max(minZoomFactor, newZoomFactor), maxZoomFactor)

  if (newZoomFactor > newZoomFactorInRange && currentZoomFactor > newZoomFactorInRange) {
    return isNegativeDirection ? newZoomFactor : currentZoomFactor
  }

  if (newZoomFactor < newZoomFactorInRange && currentZoomFactor < newZoomFactorInRange) {
    return !isNegativeDirection ? newZoomFactor : currentZoomFactor
  }

  return newZoomFactorInRange
}

interface ChartState {
  panesWrapperElement: HTMLDivElement | null
}

class Chart extends React.Component<ChartProps, ChartState> {
  lastWidth: number
  mouseWheelTimeout?: number
  setStateWithPanesTimeout?: number
  lastHeight = 0
  lastY: number | null = null
  lastX: number | null = null
  resizerPaneIndex = -1
  minimalPaneHeight = 0
  zoomerRightAlign: {
    barIndex?: number
    index: number
    offsetDiff: number
  } | null = null

  // expects model, config, layoutModel
  constructor(props: ChartProps) {
    super(props)

    this.lastWidth = props.chartModel.width
    this.props.layoutModel.bind('change', this.onLayoutModelChange)
    this.state = { panesWrapperElement: null }
  }

  componentDidMount() {
    const { chartModel } = this.props
    this.lastWidth = this.props.layoutModel.width

    chartModel.trigger('change', chartModel)
    chartModel.bind('createPane', this.onPaneCreate)

    this.calculateMinimalPaneHeight()
    this.handleQuoteChange(chartModel.quote()!)
    this.onModelChange()

    chartModel.bind('update', this.onModelChange)
    chartModel.bind('destroy', () => {
      Quote.unbind('olderData', this.onOlderDataLoaded)
      Quote.unbind(`${QuoteFetchType.Refetch} ${QuoteFetchType.NewerData}`, this.onDataLoaded)
    })

    Quote.bind('olderData', this.onOlderDataLoaded)
    Quote.bind<Quote>(`${QuoteFetchType.Refetch} ${QuoteFetchType.NewerData}`, this.onDataLoaded)
  }

  componentDidUpdate(prevProps: Readonly<ChartProps>, prevState: Readonly<ChartState>) {
    if (
      prevState.panesWrapperElement !== this.state.panesWrapperElement ||
      prevProps.layoutModel.isWheelZoomEnabled !== this.props.layoutModel.isWheelZoomEnabled
    ) {
      prevState.panesWrapperElement?.removeEventListener('wheel', preventDefaultFunc)

      if (this.props.layoutModel.isWheelZoomEnabled) {
        // can't use onWheel as it has passive: true
        this.state.panesWrapperElement?.addEventListener('wheel', preventDefaultFunc, { passive: false })
      }
    }
  }

  componentWillUnmount() {
    const { chartModel, layoutModel } = this.props
    if (this.setStateWithPanesTimeout) {
      clearTimeout(this.setStateWithPanesTimeout)
    }
    layoutModel.unbind('change', this.onLayoutModelChange)
    chartModel.unbind('update', this.onModelChange)

    this.state.panesWrapperElement?.removeEventListener('wheel', preventDefaultFunc)
  }

  handleSetPanesWrapperRef = (panesWrapperElement: HTMLDivElement | null) => {
    this.setState({ panesWrapperElement })
  }

  render() {
    const { activeTool, chartIndex, layoutModel, chartModel, renderChartToolbar } = this.props
    const isChartPage = layoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage
    const panes =
      layoutModel.specificChartFunctionality === SpecificChartFunctionality.futuresPage
        ? chartModel.getAllValidPanes()
        : chartModel.getAllPanes()
    const showSettings = layoutModel.editors.includes(ChartEditorEnum.settings)
    const showTimeframe = showSettings || layoutModel.editors.includes(ChartEditorEnum.timeframe)

    return (
      <>
        <div
          onClick={isChartPage ? () => this.onClick(undefined) : undefined}
          data-testid={`chart-${chartIndex}-settings`}
          onMouseEnter={this.resetMouseModel}
        >
          {showTimeframe && <ChartControls key={layoutModel.layout} chartIndex={chartIndex} chartModel={chartModel} />}
        </div>
        {layoutModel.editable && showSettings && renderChartToolbar && (
          <div
            className={classnames('border-y', {
              'border-primary': isRedesignEnabled(),
              'border-x border-[#f3f3f3]': !isRedesignEnabled(),
            })}
          >
            {renderChartToolbar()}
          </div>
        )}
        <div
          ref={this.handleSetPanesWrapperRef}
          onTouchStart={this.onPinchStart}
          onMouseLeave={!utils.isMobile() ? this.resetMouseModel : undefined}
          onWheel={this.onMouseWheel}
        >
          {panes.map((pane, index) => (
            <Pane
              key={pane.id}
              paneIndex={index}
              chartIndex={chartIndex}
              model={pane}
              activeTool={activeTool}
              chartModel={chartModel}
              onAddToZoomFactor={this.addToZoomFactor}
              onPaneClick={isChartPage ? this.onClick : undefined}
              onPaneDestroy={this.onPaneDestroy}
              onResizerMouseDown={this.onResizerMouseDown}
              onZoomerMouseDown={this.onZoomerMouseDown}
              onZoomerReset={this.onZoomerReset}
              onZoomerTouchStart={this.onZoomerTouchStart}
              setActiveChartInteraction={this.props.setActiveChartInteraction}
              activeChartInteraction={this.props.activeChartInteraction}
              touchEventsDisabled={this.props.touchEventsDisabled}
            />
          ))}
        </div>
      </>
    )
  }

  onMouseWheel = (e: React.WheelEvent) => {
    const { chartModel, layoutModel } = this.props
    const isLoading = chartModel.panes().count() < 1
    if (!layoutModel.isWheelZoomEnabled || isLoading) {
      return
    }
    if (!this.zoomerRightAlign) {
      this.calcZoomerRightAlign()
    }
    clearTimeout(this.mouseWheelTimeout)
    this.mouseWheelTimeout = window.setTimeout(() => {
      this.zoomerRightAlign = null
    }, 250)
    this.onZoomerMouseMove(e, undefined, true)
  }

  onClick = (activePane: PaneModel | undefined) => {
    if (
      !this.props.layoutModel.activeChart.eql(this.props.chartModel) ||
      !(this.props.layoutModel.activePane?.eql(activePane) || this.props.layoutModel.activePane === activePane)
    ) {
      this.props.layoutModel.updateAttributes({ activeChart: this.props.chartModel, activePane })
    }
  }

  onPaneCreate = () => {
    const { chartModel } = this.props
    this.onModelChange()
    this.calculateMinimalPaneHeight()
    chartModel.trigger('change', chartModel)
  }

  onPaneDestroy = (paneId: string) => {
    const { chartModel } = this.props
    const pane = chartModel.panes().find(paneId)

    if (pane) {
      pane.destroyCascade()
      this.onModelChange()
      chartModel.trigger('change', chartModel)
    }
  }

  onModelChange = (chartModelParam?: ChartModel) => {
    const { chartModel, config } = this.props
    const panes = chartModel.getAllPanes()
    const quote = chartModel.quote()

    if (panes.length === 0 || !quote || chartModel.leftOffset === undefined) {
      return
    }

    const { width, height } = chartModel
    let panesHeight = panes.reduce((sum, pane) => sum + (pane.height ?? 0), 0)
    if (panesHeight !== height) {
      panesHeight = panes.reduce((sum, pane) => {
        const newPaneHeight = Math.round((pane.height / panesHeight) * height)
        if (!Number.isNaN(newPaneHeight)) {
          pane.updateAttribute('height', newPaneHeight)
        }
        return sum + newPaneHeight
      }, 0)
      const chartPane = chartModel.getChartPane()
      if (chartPane && panesHeight !== height) {
        const newChartPaneHeight = chartPane.height + height - panesHeight
        if (!Number.isNaN(newChartPaneHeight)) {
          chartPane.updateAttribute('height', newChartPaneHeight)
        }
      }
    }

    const newLeftOffset = getLeftOffset({
      quote,
      chartModel,
      config: config,
    })
    const newIsScrolled = chartModel.leftOffset !== newLeftOffset
    const isScrolled = chartModel.isScrolled
    if (!isScrolled && this.lastWidth !== width) {
      this.lastWidth = width
      chartModel.updateAttribute('leftOffset', newLeftOffset)
    } else if (isScrolled !== newIsScrolled) {
      chartModel.updateAttributes({ isScrolled: newIsScrolled })
    }

    if (this.lastHeight !== height) {
      this.lastHeight = height
      this.calculateMinimalPaneHeight()
    }

    if (chartModelParam) {
      this.handleQuoteChange(chartModelParam.quote())
    }
  }

  onLayoutModelChange = ({ activeChart, activePane }: { activeChart: ChartModel; activePane: PaneModel }) => {
    const { chartModel } = this.props
    if (!activeChart) {
      return
    }
    for (const pane of chartModel.getAllPanes()) {
      if (activePane?.id !== pane.id && pane.selection?.isSelected === true) {
        pane.selection.setIsSelected(false)
        pane.updateAttribute('selection', undefined)
      }
    }
  }

  onResizerMouseDown = (e: React.MouseEvent | MouseEvent, model: PaneModel) => {
    if (e.button !== 0) {
      return
    }
    this.lastY = e.clientY
    this.resizerPaneIndex = this.props.chartModel
      .panes()
      .all()
      .findIndex((pane) => pane.eql(model))
    document.addEventListener('mousemove', this.onResizerMouseMove)
    document.addEventListener('mouseup', this.onResizerMouseUp)
  }

  onResizerMouseMove = (e: MouseEvent) => {
    if (this.resizerPaneIndex > 0 && this.lastY !== null && this.resizePanesAccordingly(this.lastY! - e.clientY)) {
      this.lastY = e.clientY
    }
  }

  onResizerMouseUp = (e: MouseEvent) => {
    const { chartModel } = this.props
    if (this.resizerPaneIndex > 0 && this.lastY !== null) {
      this.resizePanesAccordingly(this.lastY - e.clientY)
      this.lastY = null

      chartModel.trigger('change', chartModel)
    }

    document.removeEventListener('mousemove', this.onResizerMouseMove)
    document.removeEventListener('mouseup', this.onResizerMouseUp)
  }

  calculateMinimalPaneHeight() {
    const { chartModel } = this.props
    const { minHeight, total } = chartModel
      .panes()
      .all()
      .reduce(
        ({ minHeight, total }, pane) => {
          if (minHeight > pane.height) {
            minHeight = pane.height
          }
          total += pane.getIsChartPane() ? 3 : 1
          return { minHeight, total }
        },
        { minHeight: chartModel.height, total: 0 }
      )
    const optiMinHeight = Math.floor(chartModel.height / total)
    this.minimalPaneHeight = Math.min(minHeight, optiMinHeight)
  }

  resizePanesAccordingly(difY: number) {
    const { chartModel } = this.props
    const panes = chartModel.panes().all()
    let basePane = this.resizerPaneIndex
    if (!difY) {
      return true
    } else if (difY > 0) {
      let i = basePane
      let pane = panes[i]
      if (pane.height + difY >= this.minimalPaneHeight) {
        pane.updateAttribute('height', pane.height + difY)
      } else {
        pane.updateAttribute('height', this.minimalPaneHeight)
      }
      for (--i; i >= 0; i--) {
        pane = panes[i]
        if (pane.height - difY >= this.minimalPaneHeight) {
          pane.updateAttribute('height', pane.height - difY)
          break
        } else {
          pane.updateAttribute('height', this.minimalPaneHeight)
        }
      }
    } else if (difY < 0) {
      basePane = Math.max(0, basePane - 1)
      let i = basePane
      let pane = panes[i]
      if (pane.height - difY >= this.minimalPaneHeight) {
        pane.updateAttribute('height', pane.height - difY)
      } else {
        pane.updateAttribute('height', this.minimalPaneHeight)
      }
      for (++i; i < panes.length; i++) {
        pane = panes[i]
        if (pane.height + difY >= this.minimalPaneHeight) {
          pane.updateAttribute('height', pane.height + difY)
          break
        } else {
          pane.updateAttribute('height', this.minimalPaneHeight)
        }
      }
    }

    const panesHeight = panes.reduce((sum, pane) => sum + pane.height, 0)
    if (panesHeight !== chartModel.height) {
      difY = chartModel.height - panesHeight

      const pane = panes[basePane]
      pane.updateAttribute('height', pane.height + difY)

      // if we exceeded total height we need to stop updating lastY - return false
      return false
    }

    return true
  }

  onZoomerTouchStart = (e: React.TouchEvent | TouchEvent) => {
    if (!this.props.layoutModel.scrollable || e.touches.length !== 1 || this.props.touchEventsDisabled) {
      return
    }
    this.onZoomerMouseDown(e.targetTouches[0])
  }

  onZoomerTouchMove = (e: TouchEvent) => {
    if (!this.props.layoutModel.scrollable || e.touches.length !== 1) {
      return
    }
    this.onZoomerMouseMove(e.targetTouches[0])
  }

  onZoomerTouchEnd = () => {
    if (!this.props.layoutModel.scrollable) {
      return
    }
    this.onZoomerMouseUp()
  }

  getRawDistance = (e: Pick<TouchEvent, 'touches'>) => {
    const p1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    const p2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }
    return math.distance(p1, p2)
  }

  onPinchStart = (e: React.TouchEvent) => {
    const { chartModel } = this.props
    const { activeChartInteraction, setActiveChartInteraction, touchEventsDisabled } = this.props
    const isLoading = chartModel.panes().count() < 1

    if (
      this.props.layoutModel.scrollable &&
      e.touches.length === 2 &&
      !activeChartInteraction &&
      !isLoading &&
      !touchEventsDisabled
    ) {
      setActiveChartInteraction('pinch')
      this.onZoomerMouseDown({ clientX: this.getRawDistance(e as unknown as TouchEvent) })

      document.addEventListener('touchmove', this.onPinchMove)
      document.addEventListener('touchend', this.onPinchEnd)
    }
  }

  onPinchMove = (e: TouchEvent) => {
    if (e.touches.length === 2) {
      this.onZoomerMouseMove({ clientX: this.getRawDistance(e) })
    }
  }

  onPinchEnd = (e: TouchEvent) => {
    if (e.touches.length === 1) {
      this.onZoomerMouseUp()
      document.removeEventListener('touchmove', this.onPinchMove)
      document.removeEventListener('touchend', this.onPinchEnd)
    }
  }

  calcZoomerRightAlign = () => {
    const { chartModel } = this.props
    const { ChartSettings } = chartModel.getChartLayoutSettings()
    const quote = chartModel.quote()
    const fx = chartModel.panes().first().scale.x
    const barWidth = getBarWidthWithMargin({ zoomFactor: chartModel.zoomFactor, chartLayout: this.props.layoutModel })
    const currentLeftOffset = getLeftOffset({ quote, chartModel, config: this.props.config, barWidth })
    this.zoomerRightAlign = null

    if (quote.isIntraday && quote.instrument === Instrument.Stock) {
      const barIndex = quote.getBarIndex(quote.close.length - 1)
      let offsetDiff =
        chartModel.leftOffset -
        getLeftOffset({
          quote,
          chartModel: chartModel,
          config: this.props.config,
          barWidth,
          barIndex,
          shouldAlignDaysToLeftApplied: false,
        })
      const offsetBars = ~~(offsetDiff / barWidth)
      offsetDiff -= offsetBars * barWidth
      this.zoomerRightAlign = {
        barIndex,
        index: barIndex - offsetBars,
        offsetDiff,
      }
    } else if (chartModel.leftOffset <= currentLeftOffset) {
      this.zoomerRightAlign = {
        index: quote.getBarIndex(quote.open.length - 1),
        offsetDiff: chartModel.leftOffset - currentLeftOffset,
      }
    } else {
      const offset = chartModel.leftOffset
      for (let i = 0; i < quote.open.length; i += 1) {
        const x = fx(i)
        if (x + offset < 0) {
          continue
        }
        if (x + offset > chartModel.width - ChartSettings.left.width - ChartSettings.right.width) {
          const barIndex = quote.getBarIndex(i - 1)
          let offsetDiff =
            offset -
            getLeftOffset({
              quote,
              chartModel,
              config: this.props.config,
              barWidth,
              barIndex,
            })
          const offsetBars = ~~(offsetDiff / barWidth)
          offsetDiff -= offsetBars * barWidth
          this.zoomerRightAlign = {
            barIndex,
            index: barIndex - offsetBars,
            offsetDiff,
          }
          break
        }
      }
    }
  }

  onZoomerMouseDown = (e: Partial<React.MouseEvent | MouseEvent>) => {
    if ((e.button !== 0 && 'button' in e) || !this.props.layoutModel.scrollable) {
      return
    }
    switch (this.props.activeChartInteraction) {
      case 'pinch':
        break
      case null:
        this.props.setActiveChartInteraction('zoomer')
        break
      default:
        return
    }

    this.lastX = e.clientX ?? null
    this.calcZoomerRightAlign()

    document.addEventListener('mousemove', this.onZoomerMouseMove)
    document.addEventListener('mouseup', this.onZoomerMouseUp)
    document.addEventListener('touchmove', this.onZoomerTouchMove)
    document.addEventListener('touchend', this.onZoomerTouchEnd)
  }

  normalizeWheelDeltaY = (e: WheelEvent) => {
    // because wheel deltaY on mouse and touchpad is quite different we need to normalize it
    const abs = Math.abs(e.deltaY)
    if (abs === 0) return 0
    return Math.log(abs) * Math.sign(e.deltaY) * 10
  }

  onZoomerMouseMove = (
    e: React.WheelEvent | MouseEvent | Touch | { clientX: number },
    _?: unknown,
    isMouseWheel = false
  ) => {
    const { chartModel, layoutModel } = this.props
    let difX
    if (!layoutModel.scrollable) return
    if (!isMouseWheel && this.lastX !== null) {
      difX = (this.lastX - e.clientX) / chartModel.width
      this.lastX = e.clientX
    } else {
      // standard difX calculation result is around 0.00x, wheel deltaY is whole px
      // because of that we divide px with 1000
      difX = this.normalizeWheelDeltaY(e as WheelEvent) / 1000
    }

    this.addToZoomFactor({ diff: -difX })
  }

  addToZoomFactor = ({
    diff,
    shouldCalculateRightAlign = false,
  }: {
    diff: number
    shouldCalculateRightAlign?: boolean
  }) => {
    const { chartModel, layoutModel } = this.props
    if (shouldCalculateRightAlign) {
      this.calcZoomerRightAlign()
    }
    // 6 is default bar width with margin on charts page, so to get the similar feeling when zooming we're multiplying the zoom factor by the coefficient
    const diffCoefficient = chartModel.zoomFactor * (6 / getBarWidthWithMargin({ chartLayout: this.props.layoutModel }))
    const zoomFactor = getZoomFactor({
      chartLayout: layoutModel,
      currentZoomFactor: chartModel.zoomFactor,
      diff: diff * diffCoefficient,
    })
    const barWidth = getBarWidthWithMargin({
      zoomFactor,
      chartLayout: this.props.layoutModel,
    })

    let leftOffset
    if (this.props.config.stretch) {
      leftOffset = 0
    } else if (getIsSmallIndexChart(this.props.layoutModel.specificChartFunctionality)) {
      leftOffset = barWidth
    } else if (this.zoomerRightAlign) {
      leftOffset = getMinMaxLeftOffset({
        newLeftOffset:
          getLeftOffset({
            quote: chartModel.quote(),
            chartModel: chartModel,
            config: this.props.config,
            barWidth,
            barIndex: this.zoomerRightAlign.index,
            shouldAlignDaysToLeftApplied: false,
          }) + this.zoomerRightAlign.offsetDiff,
        chartModel: chartModel,
        barWidth,
        barIndex: getBarIndex(chartModel.quote()),
      })
    } else {
      return
    }
    chartModel.updateAttributes({
      zoomFactor,
      leftOffset,
      dateRange: null,
    })
    if (shouldCalculateRightAlign) {
      this.zoomerRightAlign = null
    }
  }

  onZoomerMouseUp = () => {
    this.zoomerRightAlign = null
    document.removeEventListener('mousemove', this.onZoomerMouseMove)
    document.removeEventListener('mouseup', this.onZoomerMouseUp)
    document.removeEventListener('touchmove', this.onZoomerTouchMove)
    document.removeEventListener('touchend', this.onZoomerTouchEnd)
    this.props.setActiveChartInteraction(null)
  }

  onZoomerReset = () => {
    const { chartModel, layoutModel } = this.props
    const zoomFactor = chartModel.chart_layout().defaultZoomFactor
    const barWidth = getBarWidthWithMargin({ zoomFactor, chartLayout: layoutModel })
    const leftOffset = getLeftOffset({
      quote: chartModel.quote(),
      chartModel,
      config: this.props.config,
      barWidth,
    })

    chartModel.updateAttributes({
      zoomFactor,
      leftOffset,
      dateRange: null,
    })
  }

  onOlderDataLoaded = () => {
    throw new Error('onOlderDataLoaded not implemented')
    // TODO implement similarly to onDataLoaded
  }

  resetMouseModel = () => {
    const isLoading = this.props.chartModel.panes().count() < 1
    if (!isLoading) {
      MouseModel.updateAttributes({
        position: null,
        pane: null,
      })
    }
  }

  handleQuoteChange = (quote: Quote) => {
    const { chartModel } = this.props

    // Update only date range chart
    if (chartModel.dateRange) {
      if (!quote.eql(chartModel.quote()) || chartModel.timeframe !== quote.timeframe || quote.date.length === 0) {
        return
      }
      updateZoomAndLeftOffsetByDateRange({ chartModel, quote })
    }
  }

  onDataLoaded = (newQuote: Quote, fetchType: QuoteFetchType) => {
    const { chartModel } = this.props
    const quote = chartModel.quote()
    if (!quote?.eql(newQuote)) {
      return
    }

    const shouldForceLeftOffsetUpdate = fetchType === QuoteFetchType.Refetch
    let newLeftOffset = chartModel.leftOffset // TODO find proper fix. If this line is removed (if we don't trigger leftOffset update), the chart stops being updated for the user until they move chart around.
    if (
      (this.props.layoutModel.scrollable === false && !this.props.layoutModel.isTouchCrossActive) ||
      !chartModel.isScrolled ||
      shouldForceLeftOffsetUpdate
    ) {
      newLeftOffset = getLeftOffset({
        quote,
        chartModel: chartModel,
        config: this.props.config,
      })
    }

    chartModel.setChartEvents(quote.chartEvents, !quote.events)

    if (newLeftOffset !== chartModel.leftOffset) {
      chartModel.getAllElements().forEach((element) => {
        if (element.isDrawing() && !element.isMouseDown()) {
          element.instance.updateScales()
        }
      })
    }

    chartModel.updateAttribute('leftOffset', newLeftOffset)

    this.handleQuoteChange(quote)
  }
}

export default withChartInit(withWatchedPanesOnChartModel(Chart))
