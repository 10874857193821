import { convertColorToHEX } from '@finviz/website'
import * as React from 'react'

import { ColorRangeRowInput } from './color_range_row'

interface LineInputValue {
  color: string
  width: string
}

interface LineInputProps {
  label: string
  value: LineInputValue
  onChange: (value: LineInputValue) => void
}

export function LineInput({ label = 'Line', value, onChange }: LineInputProps) {
  return (
    <ColorRangeRowInput
      label={label}
      colorValue={convertColorToHEX(value.color)}
      colorTestId="chart-modal-line-color"
      rangeValue={value.width}
      rangeProps={{ min: 1, max: 5, step: 1 }}
      rangeLabel="Width"
      rangeTestId="chart-modal-line-range"
      onChange={(color, width) => onChange({ color, width })}
    />
  )
}
