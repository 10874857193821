export const FONT_SIZE = {
  XXS: 5,
  XS: 7,
  S: 8,
  M: 10,
  L: 11,
  XXL: 18,
  XXXL: 23,
}
export const LINE_HEIGHT = {
  XS: 7,
  S: 8,
  M: 10,
}
export const PADDING = {
  XXXS: 1,
  XXS: 2,
  XS: 3,
  S: 5,
}
export const MARGIN = {
  XS: 4,
}
export const CHART_MARGIN = {
  S: 15,
  M: 30,
  L: 35,
  XL: 38,
  XXL: 48,
}
export const SETTINGS_HEIGHT = 44
export const TICKER_TIMEFRAME_CHANGE_HEIGHT = 20
export const OFFSET = {
  XXS: 2,
  XS: 4,
  S: 6,
  M: 8,
  XXL: 20,
}
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export enum ChartEditorEnum {
  publish = 'publish',
  tools = 'tools',
  ideas = 'ideas',
  settings = 'settings',
  timeframe = 'timeframe',
}

export enum LayoutType {
  '1h1v' = '1h1v',
  '1h2v' = '1h2v',
  '1h3v' = '1h3v',
  '2h1v' = '2h1v',
  '2h2v' = '2h2v',
  '3h1v' = '3h1v',
  '3h2v' = '3h2v',
  '1l2r' = '1l2r',
  '1t2b' = '1t2b',
}

export enum CanvasElementType {
  element = 'canvas/element',
  arrow = 'canvas/arrow',
  brushV1 = 'canvas/brush/brush_v1',
  curve = 'canvas/curve',
  ellipse = 'canvas/ellipse',
  fibonacciRetracements = 'canvas/fibonacci_retracements',
  horizontalLine = 'canvas/horizontal_line',
  line = 'canvas/line',
  measure = 'canvas/measure',
  pitchforkV1 = 'canvas/pitchfork/pitchfork_v1',
  polygon = 'canvas/polygon',
  position = 'canvas/position',
  rectangle = 'canvas/rectangle',
  rotatedRectangle = 'canvas/rotated_rectangle',
  scaledLine = 'canvas/scaled_line',
  scaledText = 'canvas/scaled_text',
  symbol = 'canvas/symbol',
  text = 'canvas/text',
  triangle = 'canvas/triangle',
  verticalLine = 'canvas/vertical_line',
  xabcd = 'canvas/xabcd',
  calloutV1 = 'canvas/callout/callout_v1',
  elliottWaves = 'canvas/elliott_waves/elliott_waves',
  ellipseV1 = 'canvas/ellipse/ellipse_v1',
  rotatedRectangleV1 = 'canvas/rotated_rectangle/rotated_rectangle_v1',
}

export enum ChartElementType {
  CandleStick = 'charts/candle_stick',
  HeikinAshi = 'charts/heikin_ashi',
  HollowCandleStick = 'charts/candle_stick_hollow',
  LineChart = 'charts/line_chart',
  OhlcChart = 'charts/ohlc_chart',
  MarketSentiment = 'charts/market_sentiment',
  MultiLineChart = 'charts/multi_line_chart',
  PerfChart = 'charts/perf_chart',
}

export enum IndicatorType {
  Indicator = 'indicators/indicator',
  None = 'indicators/none',
  Adx = 'indicators/adx',
  Aro = 'indicators/aro',
  Aroosc = 'indicators/aroosc',
  Atr = 'indicators/atr',
  Cci = 'indicators/cci',
  Cot = 'indicators/cot',
  Fi = 'indicators/fi',
  Macd = 'indicators/macd',
  Mfi = 'indicators/mfi',
  Obv = 'indicators/obv',
  Perf = 'indicators/perf',
  Rmi = 'indicators/rmi',
  Roc = 'indicators/roc',
  Rsi = 'indicators/rsi',
  Rvol = 'indicators/rvol',
  Rwi = 'indicators/rwi',
  Stofa = 'indicators/stofa',
  Stofu = 'indicators/stofu',
  Stosl = 'indicators/stosl',
  Trix = 'indicators/trix',
  Ult = 'indicators/ult',
  Vol = 'indicators/vol',
  Wr = 'indicators/wr',
  Shrtfl = 'indicators/shrtfl',
  Shrtra = 'indicators/shrtra',
}

export enum OverlayType {
  Overlay = 'overlays/overlay',
  None = 'overlays/none',
  Bb = 'overlays/bb',
  Dch = 'overlays/dch',
  Ema = 'overlays/ema',
  Ema2 = 'overlays/ema2',
  Hilo = 'overlays/hilo',
  Hline = 'overlays/hline',
  Ic = 'overlays/ic',
  Icf = 'overlays/icf',
  Kch = 'overlays/kch',
  Patterns = 'overlays/patterns',
  Pp = 'overlays/pp',
  Sar = 'overlays/sar',
  Sma = 'overlays/sma',
  Sma2 = 'overlays/sma2',
  Str = 'overlays/str',
  Vp = 'overlays/vp',
  Vwap = 'overlays/vwap',
}

export enum ChartEventDbType {
  Dividends = 'chartEvent/dividends',
  Split = 'chartEvent/split',
  Earnings = 'chartEvent/earnings',
}

export enum ChartEventType {
  ChartEvent = 'chartEvent/chartEvent',
  Dividends = 'chartEvent/dividends',
  Split = 'chartEvent/split',
  Earnings = 'chartEvent/earnings',
}

export enum ChartEventBadgeType {
  Dividends = 'chartEvent/dividends',
  Split = 'chartEvent/split',
  EarningsPositive = 'chartEvent/earnings',
  EarningsNegative = 'chartEvent/earningsNegative',
  EarningsNeutral = 'chartEvent/earningsNeutral',
  EarningsFuture = 'chartEvent/earningsFuture',
}

export const ChartEventLabels: Record<ChartEventBadgeType, string> = {
  [ChartEventBadgeType.Dividends]: 'Dividends',
  [ChartEventBadgeType.Split]: 'Split',
  [ChartEventBadgeType.EarningsPositive]: 'Earnings & Revenue',
  [ChartEventBadgeType.EarningsNegative]: 'Earnings & Revenue',
  [ChartEventBadgeType.EarningsNeutral]: 'Earnings & Revenue',
  [ChartEventBadgeType.EarningsFuture]: 'Earnings & Revenue',
}

interface LayoutRule {
  columns: number
  rows: number
  count: number
  gridAreas?: string[]
}

export const LAYOUTS: Record<LayoutType, LayoutRule> = {
  '1h1v': { columns: 1, rows: 1, count: 1 },
  '1h2v': { columns: 1, rows: 2, count: 2 },
  '1h3v': { columns: 1, rows: 3, count: 3 },
  '2h1v': { columns: 2, rows: 1, count: 2 },
  '2h2v': { columns: 2, rows: 2, count: 4 },
  '3h1v': { columns: 3, rows: 1, count: 3 },
  '3h2v': { columns: 3, rows: 2, count: 6 },
  '1l2r': {
    columns: 2,
    rows: 2,
    count: 3,
    gridAreas: ['1 / 1 / 3 / 2', '1 / 2 / 2 / 3', '2 / 2 / 3 / 3'],
  },
  '1t2b': {
    columns: 2,
    rows: 2,
    count: 3,
    gridAreas: ['1 / 1 / 2 / 3', '2 / 1 / 3 / 2', '2 / 2 / 3 / 3'],
  },
}

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const ICON_SIZE = {
  L: 32,
}

// Keep in sync with the copy in Website repo - Website/js/main/constants.ts
export enum TIMEFRAME {
  i1 = 'i1',
  i2 = 'i2',
  i3 = 'i3',
  i5 = 'i5',
  i10 = 'i10',
  i15 = 'i15',
  i30 = 'i30',
  h = 'h',
  h2 = 'h2',
  h4 = 'h4',
  d = 'd',
  w = 'w',
  m = 'm',
}

export type TimeframeAndIntradayType = TIMEFRAME | 'intraday'

export const TIMEFRAMES_WITH_PRE_MARKET = [TIMEFRAME.i1, TIMEFRAME.i2, TIMEFRAME.i3, TIMEFRAME.i5]

export const IntradayTimeframeInterval = {
  [TIMEFRAME.i1]: 1,
  [TIMEFRAME.i2]: 2,
  [TIMEFRAME.i3]: 3,
  [TIMEFRAME.i5]: 5,
  [TIMEFRAME.i10]: 10,
  [TIMEFRAME.i15]: 15,
  [TIMEFRAME.i30]: 30,
  [TIMEFRAME.h]: 60,
  [TIMEFRAME.h2]: 120,
  [TIMEFRAME.h4]: 240,
}

export const TimeframeLabelShort = {
  [TIMEFRAME.i1]: '1M',
  [TIMEFRAME.i2]: '2M',
  [TIMEFRAME.i3]: '3M',
  [TIMEFRAME.i5]: '5M',
  [TIMEFRAME.i10]: '10M',
  [TIMEFRAME.i15]: '15M',
  [TIMEFRAME.i30]: '30M',
  [TIMEFRAME.h]: '1H',
  [TIMEFRAME.h2]: '2H',
  [TIMEFRAME.h4]: '4H',
  [TIMEFRAME.d]: 'D',
  [TIMEFRAME.w]: 'W',
  [TIMEFRAME.m]: 'M',
}

export enum DateRangeType {
  d1 = 'd1',
  d5 = 'd5',
  m1 = 'm1',
  m3 = 'm3',
  m6 = 'm6',
  ytd = 'ytd',
  y1 = 'y1',
  y2 = 'y2',
  y5 = 'y5',
  max = 'max',
}

export enum QuoteFinancialAttachment {
  shortInterest = 'shortInterest',
}

export const TIMEFRAME_SECONDS = {
  [TIMEFRAME.i1]: 60,
  [TIMEFRAME.i2]: 120,
  [TIMEFRAME.i3]: 180,
  [TIMEFRAME.i5]: 300,
  [TIMEFRAME.i10]: 600,
  [TIMEFRAME.i15]: 900,
  [TIMEFRAME.i30]: 1800,
  [TIMEFRAME.h]: 3600,
  [TIMEFRAME.h2]: 7200,
  [TIMEFRAME.h4]: 14400,
  [TIMEFRAME.d]: 86400,
  [TIMEFRAME.w]: 604800,
  [TIMEFRAME.m]: 2592000,
}

export const CRYPTOS = {
  BTCUSD: 'BTCUSD',
  LTCUSD: 'LTCUSD',
  ETHUSD: 'ETHUSD',
  XRPUSD: 'XRPUSD',
  BCHUSD: 'BCHUSD',
  BTCEUR: 'BTCEUR',
  LTCEUR: 'LTCEUR',
  ETHEUR: 'ETHEUR',
  XRPEUR: 'XRPEUR',
  BCHEUR: 'BCHEUR',
  LTCBTC: 'LTCBTC',
  ETHBTC: 'ETHBTC',
  XRPBTC: 'XRPBTC',
  BCHBTC: 'BCHBTC',
}

export enum SpecificChartFunctionality {
  default = 'default',
  quotePage = 'quotePage',
  quotePerf = 'quotePerf',
  quoteFinancials = 'quoteFinancials',
  chartPage = 'chartPage',
  smallIndex = 'smallIndex',
  offScreen = 'offScreen',
  forexPage = 'forexPage',
  futuresPage = 'futuresPage',
  cryptoPage = 'cryptoPage',
}

export enum TextAlign {
  left = 'left',
  right = 'right',
  center = 'center',
  start = 'start',
  end = 'end',
}

export enum TextBaseline {
  top = 'top',
  hanging_dont_use = 'hanging', // not supported - https://github.com/Automattic/node-canvas/wiki/Compatibility-Status#text-styles
  middle = 'middle',
  alphabetic = 'alphabetic',
  ideographic_dont_use = 'ideographic', // not supported - https://github.com/Automattic/node-canvas/wiki/Compatibility-Status#text-styles
  bottom = 'bottom',
}

export enum ScaleType {
  Linear = 'linear',
  Percentage = 'percentage',
  Logarithmic = 'logarithmic',
}

export enum QuotePollingIntervalInMs {
  Default = 2000,
  Free = 180000,
  Elite = Default,
  EliteStocksReduced = 60000,
}

export const EXTRA_MARKET_BUFFER_MINUTES = 30

export const ScaleLabel = {
  [ScaleType.Linear]: 'Linear',
  [ScaleType.Percentage]: 'Percentage',
  [ScaleType.Logarithmic]: 'Logarithmic',
}

export const PANE_HEIGHT_DEFAULT = {
  chart: 400,
  indicator: 120,
}

export const INDICATOR_LABEL_HEIGHT = 24

export const MOBILE_BARS_TO_VIEW = 150 // 5 months of daily bars

export const MOBILE_VIEW_BREAKPOINT_WIDTH = 768

export const FREE_DATE_RANGES = ['m1', 'm3', 'm6', 'ytd', 'y1', 'y2', 'y5', 'max']

export const getTimeframeLabel = (timeframe: string) => {
  switch (timeframe) {
    case TIMEFRAME.i1:
      return '1 MIN'
    case TIMEFRAME.i2:
      return '2 MIN'
    case TIMEFRAME.i3:
      return '3 MIN'
    case TIMEFRAME.i5:
      return '5 MIN'
    case TIMEFRAME.i10:
      return '10 MIN'
    case TIMEFRAME.i15:
      return '15 MIN'
    case TIMEFRAME.i30:
      return '30 MIN'
    case TIMEFRAME.h:
      return '1 HR'
    case TIMEFRAME.h2:
      return '2 HR'
    case TIMEFRAME.h4:
      return '4 HR'
    case TIMEFRAME.d:
      return 'DAILY'
    case TIMEFRAME.w:
      return 'WEEKLY'
    case TIMEFRAME.m:
      return 'MONTHLY'
    default:
      return ''
  }
}

export type ChartsPageQueryParams = {
  p: string
  t: string
  l: string
  r?: string
  i?: string
  sidebar?: string
}

export type QuotePageQueryParams = {
  p?: string
  t: string
  r?: string
  i?: string
  ty?: QuotePageQueryChartTypes
}

export enum QuotePageQueryChartTypes {
  l = 'l',
  c = 'c',
  cs = 'cs',
  hc = 'hc',
  ha = 'ha',
  o = 'o',
  p = 'p',
}

export type ChartsOrQuotePageQueryParams = ChartsPageQueryParams | QuotePageQueryParams

export enum MacroTimeframe {
  // seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
  months = 'months',
}

export const MicroMacroTimeframe = {
  [TIMEFRAME.i1]: {
    micro: 1,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.i2]: {
    micro: 2,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.i3]: {
    micro: 3,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.i5]: {
    micro: 5,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.i10]: {
    micro: 10,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.i15]: {
    micro: 15,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.i30]: {
    micro: 30,
    macro: MacroTimeframe.minutes,
  },
  [TIMEFRAME.h]: {
    micro: 1,
    macro: MacroTimeframe.hours,
  },
  [TIMEFRAME.h2]: {
    micro: 2,
    macro: MacroTimeframe.hours,
  },
  [TIMEFRAME.h4]: {
    micro: 4,
    macro: MacroTimeframe.hours,
  },
  [TIMEFRAME.d]: {
    micro: 1,
    macro: MacroTimeframe.days,
  },
  [TIMEFRAME.w]: {
    micro: 1,
    macro: MacroTimeframe.weeks,
  },
  [TIMEFRAME.m]: {
    micro: 1,
    macro: MacroTimeframe.months,
  },
}

export const MicroTimeframeRange = (Object.keys(MicroMacroTimeframe) as TIMEFRAME[]).reduce(
  (prev, curr) => ({
    ...prev,
    [MicroMacroTimeframe[curr].macro]: prev[MicroMacroTimeframe[curr].macro]
      ? [...prev[MicroMacroTimeframe[curr].macro], MicroMacroTimeframe[curr].micro]
      : [MicroMacroTimeframe[curr].micro],
  }),
  {} as { [key in MacroTimeframe]: number[] }
)

type IRange = {
  from?: number
  to?: number
}

export type IVisibility = {
  [key in MacroTimeframe]?: IRange
}

export enum SetVisibilityTo {
  currentAndAbove,
  currentAndBelow,
  currentOnly,
  all,
}

export const QUOTE_FETCH_EVENT = 'quoteFetchEvent'

// Keep in sync with the clone in Website repo in shared/is-stock-fast-refresh-available.ts
export const PREMARKET_AFTERMARKET_HOURS = 2.5

export const CHART_ELEMENT_ID = 'chart'

export const CHART_SETTINGS_DIALOG_TEST_ID = 'chart-settings-dialog'
