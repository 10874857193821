/*
 * it converts it to numbered index and it causes problems later on
 * the reason it's not just numbered enum is that it when used in const below
 * */
export enum ChartSyncablePart {
  Crosshair = 'crosshair',
  Ticker = 'ticker',
  Timeframe = 'timeframe',
}

export const ChartPartToAttrsSyncMap = {
  [ChartSyncablePart.Ticker]: ['ticker'],
  [ChartSyncablePart.Timeframe]: ['timeframe', 'dateRange'],
}

export const ChartSyncableParts = [
  { value: ChartSyncablePart.Ticker, label: 'Ticker' },
  { value: ChartSyncablePart.Timeframe, label: 'Timeframe' },
  { value: ChartSyncablePart.Crosshair, label: 'Crosshair' },
]
