import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, UltConfig } from './configs/ult'
import Indicator from './indicator'

class UltimateOscillator extends Indicator<Attrs> {
  static config = UltConfig

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [period1 = 0, period2 = 0, period3 = 0] = getParsedIntegersFromPeriodString(period)
    return Math.max(period1, period2, period3)
  }

  declare period1: number
  declare period2: number
  declare period3: number
  ult: number[] = []
  tr: number[] = []
  bp: number[] = []

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [period1 = 0, period2 = 0, period3 = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period1, period2, period3 })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (!this.isComputeNecessary()) return

    this.ult = []
    let bpSum2, bpSum3, count2, count3, trSum2, trSum3
    const tr = []
    const bp = []
    let bpSum1 = (bpSum2 = bpSum3 = 0)
    let trSum1 = (trSum2 = trSum3 = 0)
    let count1 = (count2 = count3 = 0)
    let avg2, avg3
    for (let i = 1; i < this.data.close.length; i++) {
      bp[i] = this.data.close[i] - Math.min(this.data.low[i], this.data.close[i - 1])
      tr[i] = Math.max(this.data.high[i], this.data.close[i - 1]) - Math.min(this.data.low[i], this.data.close[i - 1])

      bpSum1 += bp[i]
      trSum1 += tr[i]
      count1++
      if (count1 > this.period1) {
        bpSum1 -= bp[i - this.period1]
        trSum1 -= tr[i - this.period1]
      }

      bpSum2 += bp[i]
      trSum2 += tr[i]
      count2++
      if (count2 > this.period2) {
        bpSum2 -= bp[i - this.period2]
        trSum2 -= tr[i - this.period2]
      }

      bpSum3 += bp[i]
      trSum3 += tr[i]
      count3++
      if (count3 > this.period3) {
        bpSum3 -= bp[i - this.period3]
        trSum3 -= tr[i - this.period3]
      }

      let avg1 = (avg2 = avg3 = 0)
      if (trSum1 > 0) {
        avg1 = bpSum1 / trSum1
      }
      if (trSum2 > 0) {
        avg2 = bpSum2 / trSum2
      }
      if (trSum3 > 0) {
        avg3 = bpSum3 / trSum3
      }

      this.ult[i] = (100 * (4 * avg1 + 2 * avg2 + avg3)) / 7
    }

    this.lastValue = this.ult.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.ult)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.ult, 1, 30, 50, 70)
  }

  getModalConfig() {
    const options = {
      period1: {
        type: 'number',
        label: 'Period 1',
        name: 'period1',
        value: this.period1 ?? 7,
        required: true,
        min: 1,
        max: 999999,
      },
      period2: {
        type: 'number',
        label: 'Period 2',
        name: 'period2',
        value: this.period2 ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
      period3: {
        type: 'number',
        label: 'Period 3',
        name: 'period3',
        value: this.period3 ?? 28,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: UltConfig.label,
      inputs: UltConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period1: `${options.period1.label} must be a whole number between ${options.period1.min} and ${options.period1.max}`,
        period2: `${options.period2.label} must be a whole number between ${options.period2.min} and ${options.period2.max}`,
        period3: `${options.period3.label} must be a whole number between ${options.period3.min} and ${options.period3.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period1':
      case 'period2':
      case 'period3':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default UltimateOscillator
