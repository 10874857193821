import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  period: number
}

export interface Attrs extends Options {
  lowColor: string
  highColor: string
}

export class HILOConfig extends BaseConfig {
  static type = OverlayType.Hilo
  static abbreviation = 'hilo'
  static label = 'High/Low'
  static shortLabel = 'HILO'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = ['period', 'highColor', 'lowColor'] as Array<keyof Attrs>
  static inputsLabels = {
    period: 'Period',
    highColor: 'High Color',
    lowColor: 'Low Color',
  }

  static calculatedValuesOrder = ['highestHigh', 'lowestLow'] as const
  static calculatedValuesLabels = {
    highestHigh: 'Highest high',
    lowestLow: 'Lowest low',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
