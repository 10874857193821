import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartLayout from '../../models/chart_layout'
import { getIsPreserveDrawingsAndAutosaveAvailable } from '../../utils/chart'

export function useIsAutoSaveActive(chartLayout: ChartLayout) {
  const chartLayoutModel = useModelState(chartLayout, {
    watchProperties: ['isInit', 'isPreserveDrawingsActive', 'idea'],
  })
  const { idea, isInit, isPreserveDrawingsActive } = chartLayoutModel
  return React.useMemo(
    () =>
      getIsPreserveDrawingsAndAutosaveAvailable(chartLayoutModel) && isPreserveDrawingsActive && !!isInit && !idea?.id,
    [isPreserveDrawingsActive, isInit, idea?.id, chartLayoutModel]
  )
}
