import { HILOConfig, Options } from '../overlays/configs/hilo'
import utils from '../utils'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  highestHigh: number[]
  lowestLow: number[]
}

export class HILOCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = HILOConfig

  calculate() {
    const { period } = this.options
    const { high, low, close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return
    const startingIndex = period - 1
    for (let i = startingIndex; i < close.length; i++) {
      this._calculatedValues.highestHigh[i] = utils.max(high.slice(Math.max(0, i - startingIndex), i + 1))
      this._calculatedValues.lowestLow[i] = utils.min(low.slice(Math.max(0, i - startingIndex), i + 1))
    }
  }
}
