import { QuoteFinancialAttachment } from '../constants/common'
import { ShrtraConfig } from './configs/shrtra'
import FinancialIndicator from './financialIndicator'

class Shrtra extends FinancialIndicator {
  static config = ShrtraConfig

  getComputedValuesAtDateIndex(dateIndex: number) {
    const { timestampToFinancialsIndexesMap, alignedFinancialAttachmentsData } = this.data
    const timestampKey = this.data.date[dateIndex]?.toString() ?? ''
    const sourceIndexes = timestampToFinancialsIndexesMap[QuoteFinancialAttachment.shortInterest]?.[timestampKey] ?? []
    const alignedData = alignedFinancialAttachmentsData[QuoteFinancialAttachment.shortInterest] ?? []
    let isBeforeData = false

    if (sourceIndexes.length === 0 && dateIndex === 0) {
      const lastBeforeDateFinancialsDataIndex = alignedData.findLastIndex(
        ({ timestamp }) => timestamp < this.data.date[dateIndex]
      )
      if (lastBeforeDateFinancialsDataIndex >= 0) {
        sourceIndexes.push(lastBeforeDateFinancialsDataIndex)
        isBeforeData = true
      }
    }

    return sourceIndexes.map((si) => {
      const { timestamp, shortInterest, averageVolume } = alignedData[si]
      let value: number | null = null
      if (shortInterest !== null && averageVolume !== null) {
        value = (shortInterest / averageVolume) * 1_000_000
      }

      return {
        isBeforeData,
        timestamp,
        value,
        sourceIndex: si,
      }
    })
  }

  getIsValid(): boolean {
    return true
  }
}

export default Shrtra
