import { Resizable } from 're-resizable'
import React from 'react'

import Utils from '../../utils'
import { DEFAULT_WIDTHS, SidebarDirection } from './constants'
import { useSidebarContext } from './hooks/sidebar-context'

type SidebarContentProps = {
  children: React.ReactNode
}

export default function SidebarContent({ children }: SidebarContentProps) {
  const { direction, onResize, close, width } = useSidebarContext()
  const minWidth = width?.min ?? DEFAULT_WIDTHS.min
  const maxWidth = width?.max ?? DEFAULT_WIDTHS.max
  const defaultMobile = width?.default?.mobile ?? minWidth
  const defaultDesktop = width?.default?.desktop ?? maxWidth

  return (
    <>
      {direction === SidebarDirection.vertical && (
        <Resizable
          minWidth={minWidth}
          maxWidth={maxWidth}
          enable={{
            left: width?.min !== width?.max || minWidth < maxWidth,
          }}
          handleClasses={{
            left: '!w-1 !-left-0.5 hover:bg-blue-400 active:bg-blue-400',
          }}
          className="flex flex-col items-center"
          onResize={onResize}
          defaultSize={{
            width: Utils.isMobile() && !Utils.isIPad() ? defaultMobile : defaultDesktop,
            height: 'auto',
          }}
        >
          {children}
        </Resizable>
      )}
      {direction === SidebarDirection.horizontal && (
        <>
          <div className="fixed top-0 z-10 h-[52px] w-full" onClick={close} />
          <div className="fixed bottom-inset-bottom top-[52px] z-10 flex w-full flex-col items-center bg-white [--inset-b:47px] dark:bg-gray-800">
            {children}
          </div>
        </>
      )}
    </>
  )
}
