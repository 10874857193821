import { Textarea } from '@finviz/website'
import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartLayout from '../../models/chart_layout'
import { isRedesignEnabled } from '../../utils'
import { useDrawingAutoSaveControls } from '../autosave/use-drawing-auto-save-controls'

interface Props {
  chartLayoutModel: ChartLayout
  ideaNoteRef?: React.RefObject<HTMLTextAreaElement>
}

const hasRedesign = isRedesignEnabled()

export function ChartLayoutAutoSaveNote({ chartLayoutModel: chartLayoutModelUnwatched, ideaNoteRef }: Props) {
  const chartLayoutModel = useModelState(chartLayoutModelUnwatched, {
    watchProperties: ['idea'],
  })!
  const { handleIdeaNoteChange } = useDrawingAutoSaveControls(chartLayoutModel)

  if (!chartLayoutModel.editable) {
    return null
  }

  return (
    <div className="chart-idea-note text-left text-xs">
      {!hasRedesign && <div>Note:</div>}
      <div className="chart-idea-note_wrapper">
        <Textarea
          ref={ideaNoteRef}
          rounding={hasRedesign ? undefined : 'none'}
          inputClass="block w-full"
          placeholder="Write notes about the stock here"
          data-testid="idea-note-textarea"
          value={chartLayoutModel.idea?.note ?? ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleIdeaNoteChange(e.target.value)
          }}
        />
      </div>
    </div>
  )
}
