export enum ChartMinWidthBreakpoints {
  xs = 0,
  s = 480,
  m = 768,
  l = 1024,
}

export function getChartBreakpoints(chartWidthInPx: number) {
  return {
    isXs: chartWidthInPx >= ChartMinWidthBreakpoints.xs,
    isS: chartWidthInPx >= ChartMinWidthBreakpoints.s,
    isM: chartWidthInPx >= ChartMinWidthBreakpoints.m,
    isL: chartWidthInPx >= ChartMinWidthBreakpoints.l,
  }
}
