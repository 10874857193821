import Spine from '@finviz/spine'
import merge from 'lodash.merge'
import mergewith from 'lodash.mergewith'
import omit from 'lodash.omit'

import {
  ChartConfigChartPaneElement,
  ObjectHash,
  PaneArea,
  RequireByKey,
  TodoObjectHashAnyType,
} from '../../types/shared'
import {
  CanvasElementType,
  ChartElementType,
  ChartEventType,
  IVisibility,
  IndicatorType,
  MacroTimeframe,
  MicroMacroTimeframe,
  OverlayType,
  SetVisibilityTo,
  TIMEFRAME,
} from '../constants/common'
import Chart from '../models/chart'
import PaneModel, { ScaleAxis } from '../models/pane'
import Quote from '../models/quote'
import { getChartLayoutSettings } from '../models/settings'
import { isInRange } from '../utils/helpers'
import ElementBaseConfig from './ElementBaseConfig'
import Thumb from './thumb'

export type TodoModalConfig = TodoObjectHashAnyType
type TodoPositionTimestamps = TodoObjectHashAnyType

export interface DefaultAttrs extends ObjectHash {
  positionTimestamps?: TodoPositionTimestamps
  visibility?: IVisibility
}

export type EdgeValues = { minX?: number; maxX?: number; minY?: number; maxY?: number }

class Element<Attrs extends DefaultAttrs = DefaultAttrs, Model extends PaneModel = PaneModel> extends Spine.Module {
  static type: CanvasElementType | OverlayType | ChartElementType | IndicatorType | ChartEventType =
    CanvasElementType.element

  static fromObject<T extends Element>(obj: T['attrs'], model: T['model']) {
    return new this(obj, model) as T
  }

  static getNumOfBarsBuffer(
    _?: RequireByKey<ChartConfigChartPaneElement, 'overlays'> | RequireByKey<ChartConfigChartPaneElement, 'period'>
  ) {
    return 0
  }

  isCreator = false
  isCreatorDialogOpen = false
  attrs: Attrs = {} as Attrs
  declare static config: ElementBaseConfig

  model: any
  _thumbs: Thumb[]

  getDefaults?(): Partial<Attrs>
  renderCross?(ctx: RenderingContext2DType): void
  renderCrossText?(
    ctx: RenderingContext2DType,
    crossIndex: number,
    getDataByCrossIndex?: (key: keyof Quote, shouldReturnRoundedString?: boolean) => string | undefined
  ): void

  renderContent?(ctx: RenderingContext2DType): void
  renderGrid?(ctx: RenderingContext2DType): void
  renderLabels?(ctx: RenderingContext2DType): void
  declare defaults?: Partial<Attrs>
  getModalConfigBase?(): TodoModalConfig
  declare modalConfig?: TodoModalConfig
  getMinMax?(): { min: number; max: number }
  setupAxis?(fx: ScaleAxis): void | { min: number; max: number }

  activeThumb: Thumb | null = null
  isMouseDown = false
  isSelected = false
  isHovered = false
  isEditInProgress = false
  edgeXYValues: EdgeValues | null = null
  declare scaled?: ObjectHash<number> | Partial<Attrs>

  declare toolsCallback: () => void

  constructor(values: Partial<Attrs>, model: Model) {
    super(values, model)
    this.model = model
    if (this.getDefaults) {
      this.set(this.getDefaults() || {})
    } else {
      this.set(this.defaults || {})
    }
    this.set(values)
    this._thumbs = []

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.bind('mousedown', this.onMouseDown)
    this.bind('mousemove', this.onMouseMove)
    this.bind('mouseup', this.onMouseUp)
    this.bind('change', () => {
      // elements is optional because this.model is not Pane in all usecases, e.g. base_chart extends this element and sets Chart as model
      const element = this.getIsChartEvent()
        ? this.model.chartEvents?.()?.findByAttribute('instance', this)
        : this.model.elements?.()?.findByAttribute('instance', this)

      element?.trigger('change', element)
    })
  }

  get type() {
    return (this.constructor as typeof Element).type
  }

  getThumbs() {
    return this._thumbs
  }

  moveBy(_: number, __: number) {}

  setIsEditInProgress(isEditInProgress: boolean, shouldTriggerChange = true) {
    this.isEditInProgress = isEditInProgress
    if (shouldTriggerChange) {
      this.trigger('change', this)
    }
  }

  setIsSelected(isSelected: boolean) {
    this.isSelected = isSelected
    this.trigger('change')
  }

  setIsHovered(isHovered: boolean) {
    this.isHovered = isHovered
    this.trigger('change')
  }

  getShouldRenderThumbs() {
    return this.isSelected || this.isHovered
  }

  getIsPaneSelection(): boolean {
    const paneModel = this.model.elements().findByAttribute('instance', this)?.pane() as PaneModel | undefined
    return paneModel?.selection === this
  }

  set(obj: Partial<Attrs>) {
    const attrsSubset = Object.keys(obj).reduce(
      (acc, key) => ({ ...acc, [key]: this.attrs[key] }),
      {} as Partial<Attrs>
    )
    if (JSON.stringify(obj) !== JSON.stringify(attrsSubset)) {
      const customizer = (objValue: Partial<Attrs>, srcValue: Partial<Attrs>, key: keyof Attrs) => {
        if (this.getIsDrawing() && key === 'visibility') {
          return srcValue ?? {}
        }
      }
      mergewith(this.attrs, obj, customizer)
      if (this.getIsPaneSelection() && !this.getIsCreator()) {
        this.cachePointPositionTimestamp()
      }
      this.trigger('change', this)
    }
    this.edgeXYValues = null
    return this
  }

  get<T extends keyof Attrs>(key: T) {
    return this.attrs[key]
  }

  getBoundingPointKeys = (): { x: string[]; y: string[] } | void => {}

  fx = (x: number) => this.model.scale.x(x)

  fy = (y: number) => this.model.scale.y(y)

  scale({ x, y }: { x: string[]; y: string[] }) {
    this.scaled = {}
    x.forEach((el) => {
      Object.defineProperty(this.scaled, el, {
        get: () => this.fx(this.attrs[el]),
      })
    })

    y.forEach((el) => {
      Object.defineProperty(this.scaled, el, {
        get: () => this.fy(this.attrs[el]),
      })
    })
  }

  render(_: RenderingContext2DType) {}

  renderThumbs(context: CanvasRenderingContext2D) {
    const isHoveredThumbStyle = this.isHovered && !this.isSelected
    if (isHoveredThumbStyle) {
      context.globalAlpha = 0.5
    }
    this.getThumbs().map((thumb) => thumb.render(context))
    context.globalAlpha = 1
  }

  onMouseDown(area: PaneArea) {
    this.isMouseDown = true
    this.setIsEditInProgress(true, false)
    for (const thumb of this.getThumbs()) {
      if (thumb.isInArea(area)) {
        this.activeThumb = thumb
        this.activeThumb.startEditing(area)
        return
      }
    }
  }

  onMouseMove(area: PaneArea) {
    if (!area.mouseDown) {
      return
    }
    if (this.activeThumb != null) {
      this.activeThumb.moveTo(area)
      this.trigger('change')
    }
  }

  onMouseUp(_?: PaneArea) {
    this.isMouseDown = false
    this.setIsEditInProgress(false, false)
    this.activeThumb = null
    if (!this.getIsCreator() && !this.getIsChartEvent()) {
      this.cachePointPositionTimestamp()
    }
    this.trigger('change', this)
  }

  cachePointPositionTimestamp = () => {
    const quote = this.model?.chart().quote()
    if (quote) {
      const { x: xPointKeys } = this.getBoundingPointKeys()!
      const positionTimestamps = xPointKeys.reduce((acc, key) => {
        const positionX = this.attrs[key]
        const timeStamp = positionX && quote.getTimestampFomPositionX(positionX)
        return {
          ...acc,
          [key]: timeStamp,
        }
      }, {}) as TodoPositionTimestamps
      this.set({ positionTimestamps } as Partial<Attrs>)
    }
  }

  updateScales() {
    const quote = this.model.chart().quote()
    const { positionTimestamps } = this.attrs
    if (!quote || !positionTimestamps) {
      // positionTimestamps check is temporary to prevent app from crashing
      // caused by corrupted drawings - https://github.com/finvizhq/charts/pull/1386/files
      return
    }
    const pointPoitionsFromTimestamp = Object.entries(positionTimestamps).reduce(
      (acc, [key, timestamp]) => ({
        ...acc,
        [key]: timestamp && quote.getPositionXFromTimestamp(timestamp),
      }),
      {}
    )
    this.set(pointPoitionsFromTimestamp)
  }

  thumbsAreInArea(area: PaneArea) {
    for (const thumb of this.getThumbs()) {
      if (thumb.isInArea(area)) {
        return true
      }
    }
    return false
  }

  isInArea(area: PaneArea) {
    return this.thumbsAreInArea(area)
  }

  isDrawingElementLockedOrInvisible() {
    return this.model.chart().chart_layout().isLockDrawingsActive || !this.getIsVisible()
  }

  getModalConfig() {
    let config
    if (this.getModalConfigBase) {
      config = merge({}, this.getModalConfigBase())
    } else {
      config = merge({}, this.modalConfig)
    }
    if (this.getIsDrawing()) {
      config.inputs.push({ type: 'visibility', name: 'visibility', label: 'Visibility' })
    }
    if (config.title == null) {
      config.title = this.name
    }
    for (const input of config.inputs) {
      if (input.default != null) {
        input.value = input.default
      }
      if (this.attrs[input.name] != null) {
        input.value = this.attrs[input.name]
      }
    }
    return config
  }

  toObject() {
    return merge({}, this.attrs, { type: this.type })
  }

  toConfig<T extends Element>(): T['attrs'] & { type: T['type'] } {
    return this.toObject()
  }

  getAutosaveOmittedProperties() {
    return this.attrs.positionTimestamps ? Object.keys(this.attrs.positionTimestamps) : []
  }

  toAutosaveConfig() {
    // We don't want to save attrs which are stored in "position timestamps" because they are relative to timeframe for example
    // so in order to keep drawings in sync across different timeframes we can't rely on its position but we have to rely on "position timestamps" and then call updateScales to calculate correct positions for given timeframe
    return omit(this.toConfig(), this.getAutosaveOmittedProperties()) as Partial<Attrs>
  }

  replaceMyself(element: Element<Attrs, Model>) {
    setTimeout(() => {
      element.cachePointPositionTimestamp()
      const el = this.model.elements().findByAttribute('instance', this)
      if (!el) {
        // Temporary code below
        // additional info gaining for https://finvizcom.sentry.io/issues/3990343438/events/e87ce8b4108341cf81f15ec78707c65e/?project=33153&query=is%3Aunresolved+Cannot+read+properties+of+undefined&referrer=previous-event&statsPeriod=14d&stream_index=0
        window.Sentry?.captureMessage('element.ts replaceMyself', {
          extra: {
            oldElement: {
              type: this?.type,
              isCreator: this?.isCreator,
              isMouseDown: this?.isMouseDown,
              isSelected: this?.isSelected,
              isHovered: this?.isHovered,
              isEditInProgress: this?.isEditInProgress,
            },
            newElement: {
              type: element?.type,
              isCreator: element?.isCreator,
              isMouseDown: element?.isMouseDown,
              isSelected: element?.isSelected,
              isHovered: element?.isHovered,
              isEditInProgress: element?.isEditInProgress,
            },
          },
        })
      } else {
        el.replace(element)
      }
    })
  }

  toString() {
    return this.name
  }

  getIsCreator() {
    return this.isCreator
  }

  getIsCreatorDialogOpen() {
    return this.isCreatorDialogOpen
  }

  release() {
    this.cachePointPositionTimestamp()
    this.unbind()
  }

  getChartLayoutSettings() {
    return getChartLayoutSettings(this.model.chart().chart_layout())
  }

  getIsDrawing() {
    return !!this.type.startsWith('canvas/')
  }

  getIsChartEvent() {
    return !!this?.type.startsWith('chartEvent/')
  }

  getIsVisible() {
    const { micro, macro } = MicroMacroTimeframe[this.model.chart().quote().timeframe as TIMEFRAME]
    const visibility = this.attrs.visibility?.[macro]

    // this.attrs.visibility.minutes === undefined: drawing visible on any minutes timeframe
    if (visibility === undefined) return true
    // this.attrs.visibility.minutes === {}: drawing invisible on any minutes timeframe
    if (Object.keys(visibility).length === 0) return false
    // this.attrs.visibility.minutes === {from: 5, to: 15}: drawing visible on minutes TF between 5 & 15 interval
    return isInRange({ value: micro, min: visibility.from, max: visibility.to })
  }

  setVisibilityTo(setTo: SetVisibilityTo) {
    const { macro, micro } = MicroMacroTimeframe[this.model.chart().quote().timeframe as TIMEFRAME]
    const currentIndex = Object.keys(MacroTimeframe).indexOf(macro)

    let timeframesToSet: string[] = []
    let attrsToSet: { from?: number; to?: number } = {}

    switch (setTo) {
      case SetVisibilityTo.currentAndAbove: {
        timeframesToSet = Object.keys(MacroTimeframe).filter((key, index) => index < currentIndex)
        attrsToSet = { from: micro }
        break
      }
      case SetVisibilityTo.currentAndBelow: {
        timeframesToSet = Object.keys(MacroTimeframe).filter((key, index) => index > currentIndex)
        attrsToSet = { to: micro }
        break
      }
      case SetVisibilityTo.currentOnly: {
        timeframesToSet = Object.keys(MacroTimeframe).filter((key, index) => index !== currentIndex)
        attrsToSet = { from: micro, to: micro }
        break
      }
      case SetVisibilityTo.all:
      default: {
        this.set({ visibility: {} } as Partial<Attrs>)
        return
      }
    }

    this.set({
      visibility: Object.assign({}, ...timeframesToSet.map((item) => ({ [item]: {} })), { [macro]: attrsToSet }),
    } as Partial<Attrs>)
  }

  getEdgeXYValues() {
    const { x: xPoints, y: yPoints } = this.getBoundingPointKeys() ?? {}
    if (this.edgeXYValues === null) {
      const edgeXYValues = {} as EdgeValues
      xPoints?.forEach((key) => {
        const positionX = this.attrs[key]
        if (positionX !== undefined) {
          edgeXYValues.minX = Math.min(positionX, edgeXYValues.minX ?? positionX)
          edgeXYValues.maxX = Math.max(positionX, edgeXYValues.maxX ?? positionX)
        }
      })
      yPoints?.forEach((key) => {
        const positionY = this.attrs[key]
        if (positionY !== undefined) {
          edgeXYValues.minY = Math.min(positionY, edgeXYValues.minY ?? positionY)
          edgeXYValues.maxY = Math.max(positionY, edgeXYValues.maxY ?? positionY)
        }
      })

      this.edgeXYValues = edgeXYValues
    }

    return this.edgeXYValues
  }

  getIsInChartView(chart: Chart, options?: { omitX?: boolean; omitY?: boolean }) {
    const { minX, maxX, minY, maxY } = this.getEdgeXYValues()

    if (this.getIsCreator()) {
      return true
    }

    if (!options?.omitX) {
      const isMaxXInvalid = maxX === undefined || isNaN(maxX)
      const isMinXInvalid = minX === undefined || isNaN(minX)
      if (
        !isMaxXInvalid &&
        !isMinXInvalid &&
        !(this.fx(maxX) >= -chart.leftOffset && this.fx(minX) <= -chart.leftOffset + chart.width)
      ) {
        return false
      }
    }

    if (!options?.omitY) {
      if (minY === undefined || maxY === undefined) {
        return true
      }

      const topBound = this.model.scale.y.invert(0)
      const bottomBound = this.model.scale.y.invert(this.model.height)
      return topBound >= minY && bottomBound <= maxY
    }

    // If all checks fall through, render element
    return true
  }
}

export default Element
