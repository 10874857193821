import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import { ICCalculation } from '../indicator-calculation/ic'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, ICConfig } from './configs/ic'
import Overlay from './overlay'

const COLORS = {
  SpanA: {
    Stroke: '#009600',
    Fill: '#00960022',
  },
  SpanB: {
    Stroke: '#C80000',
    Fill: '#C8000022',
  },
  ConversionLine: '#69C1EA',
  BaseLine: '#E54040',
  LaggingSpan: '#FFA75F',
}

const DEFAULT_PARAMETERS = {
  Conversion: 9,
  Base: 26,
  SpanB: 52,
}

function parsePeriod(period: string) {
  const [conversion = DEFAULT_PARAMETERS.Conversion, base = DEFAULT_PARAMETERS.Base, spanB = DEFAULT_PARAMETERS.SpanB] =
    getParsedIntegersFromPeriodString(period)
  return [conversion, base, spanB]
}

class IchimokuCloud extends Overlay<Attrs> {
  static config = ICConfig
  static renderFull = false
  declare icCalculation: ICCalculation

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    return Math.max(...parsePeriod(period))
  }

  get renderFull() {
    return (this.constructor as typeof IchimokuCloud).renderFull
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    if (
      typeof this.attrs.period === 'string' &&
      (this.attrs.conversion === undefined || this.attrs.base === undefined || this.attrs.spanB === undefined)
    ) {
      const [conversion, base, spanB] = parsePeriod(this.attrs.period)
      this.attrs.conversion = conversion
      this.attrs.base = base
      this.attrs.spanB = spanB
    }
    this.trigger('change')
    return this
  }

  fx = (x: number) => {
    const lastIndex = this.data.close.length - 1
    const outsideBar = this.data.barIndex[lastIndex] + x - lastIndex

    return this.model.scale.x(this.data.barIndex[x] ?? outsideBar)
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()

    const {
      conversion,
      base,
      spanB,
      spanAFillColor,
      spanAStrokeColor,
      spanBFillColor,
      spanBStrokeColor,
      baseLineColor,
      conversionLineColor,
      laggingSpanColor,
    } = this.attrs

    if (this.data.close.length === 0) return

    // Calculation
    if (this.isComputeNecessary()) {
      this.icCalculation = new ICCalculation({
        quote: this.data,
        options: {
          conversion,
          base,
          spanB,
        },
      })
      this.icCalculation.calculate()
    }

    let lastSpanAX = 0
    let lastSpanBX = 0
    const cloudStartingX = this.fx(
      this.icCalculation.calculationSupportValues.spanBIndex +
        this.icCalculation.calculationSupportValues.baseStartIndex
    )

    const drawInVisibleAreaProps = {
      toIndexOffset: this.icCalculation.calculationSupportValues.baseStartIndex,
      leftOffset,
      paneModel: this.model,
      quote: this.data,
      width,
      fxOverride: this.fx,
    }

    context.set('lineWidth', 1)
    context.translate(0.5, 0.5)
    context.save()
    // 1. Senkou Span A
    // 1.1 Stroke
    context.set('strokeStyle', spanAStrokeColor)
    context.set('fillStyle', spanAFillColor)
    context.beginPath()

    let firstBarToRenderIndex: number | undefined
    let lastBarToRenderIndex = 0
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        firstBarToRenderIndex ??= i
        context.lineTo(x, Math.round(this.fy(this.icCalculation.calculatedValues.senkouSpanA[i])))
        lastSpanAX = x
        lastBarToRenderIndex = i
      },
    })
    context.stroke()

    // 1.2 Clipping area
    context.lineTo(lastSpanAX, context.canvas.height)
    context.lineTo(cloudStartingX, context.canvas.height)
    context.clip()

    // 1.3 Fill
    if (firstBarToRenderIndex === undefined) return
    context.beginPath()
    for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
      if (i >= this.icCalculation.calculatedValues.senkouSpanA.length) continue
      const x = this.fx(i)
      context.lineTo(x, Math.round(this.fy(this.icCalculation.calculatedValues.senkouSpanB[i])))
      lastSpanBX = x
    }
    context.lineTo(lastSpanBX, 0)
    context.lineTo(cloudStartingX, 0)
    context.fill()
    context.restore()

    // 2. Senkou Span B
    // 2.1 Stroke
    context.save()
    context.set('strokeStyle', spanBStrokeColor)
    context.set('fillStyle', spanBFillColor)
    context.beginPath()
    for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
      if (i >= this.icCalculation.calculatedValues.senkouSpanA.length) continue
      const x = this.fx(i)
      context.lineTo(x, Math.round(this.fy(this.icCalculation.calculatedValues.senkouSpanB[i])))
    }
    context.stroke()

    // 2.2 Clipping area
    context.lineTo(lastSpanBX, context.canvas.height)
    context.lineTo(cloudStartingX, context.canvas.height)
    context.clip()

    // 2.3 Fill
    context.beginPath()
    for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
      context.lineTo(this.fx(i), Math.round(this.fy(this.icCalculation.calculatedValues.senkouSpanA[i])))
    }
    context.lineTo(lastSpanBX, 0)
    context.lineTo(cloudStartingX, 0)
    context.fill()
    context.restore()
    context.translate(-0.5, -0.5)

    if (this.renderFull) {
      // conversion line
      context.set('strokeStyle', conversionLineColor)
      context.translate(0.5, 0.5)
      context.beginPath()
      for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
        context.lineTo(this.fx(i), Math.round(this.fy(this.icCalculation.calculatedValues.conversionLine[i])))
      }
      context.stroke()

      // base line
      context.set('strokeStyle', baseLineColor)
      context.beginPath()
      for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
        context.lineTo(this.fx(i), Math.round(this.fy(this.icCalculation.calculatedValues.baseLine[i])))
      }
      context.stroke()

      // lagging span
      context.set('strokeStyle', laggingSpanColor)
      context.beginPath()
      for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
        context.lineTo(this.fx(i - (base - 1)), Math.round(this.fy(this.data.close[i])))
      }
      context.stroke()
      context.translate(-0.5, -0.5)
    }
  }

  getModalConfig() {
    const options = {
      conversion: {
        type: 'number',
        label: 'Conversion Line',
        name: 'conversion',
        value: this.attrs.conversion ?? DEFAULT_PARAMETERS.Conversion,
        required: true,
        min: 1,
        max: 999999,
      },
      base: {
        type: 'number',
        label: 'Base Line Length',
        name: 'base',
        value: this.attrs.base ?? DEFAULT_PARAMETERS.Base,
        required: true,
        min: 1,
        max: 999999,
      },
      spanB: {
        type: 'number',
        label: 'Leading Span B Length',
        name: 'spanB',
        value: this.attrs.spanB ?? DEFAULT_PARAMETERS.SpanB,
        required: true,
        min: 1,
        max: 999999,
      },

      spanAStrokeColor: {
        type: 'color',
        label: 'Span A Stroke Color',
        name: 'spanAStrokeColor',
        value: this.attrs.spanAStrokeColor ?? this.getFreeColor(),
      },
      spanAFillColor: {
        type: 'color',
        label: 'Span A Fill Color',
        name: 'spanAFillColor',
        value: this.attrs.spanAFillColor ?? this.getFreeColor(),
      },
      spanBStrokeColor: {
        type: 'color',
        label: 'Span B Stroke Color',
        name: 'spanBStrokeColor',
        value: this.attrs.spanBStrokeColor ?? this.getFreeColor(),
      },
      spanBFillColor: {
        type: 'color',
        label: 'Span B Fill Color',
        name: 'spanBFillColor',
        value: this.attrs.spanBFillColor ?? this.getFreeColor(),
      },
      conversionLineColor: {
        type: 'color',
        label: 'Conversion Line Color',
        name: 'conversionLineColor',
        value: this.attrs.conversionLineColor ?? this.getFreeColor(),
      },
      baseLineColor: {
        type: 'color',
        label: 'Base Line Color',
        name: 'baseLineColor',
        value: this.attrs.baseLineColor ?? this.getFreeColor(),
      },
      laggingSpanColor: {
        type: 'color',
        label: 'Lagging Span Color',
        name: 'laggingSpanColor',
        value: this.attrs.laggingSpanColor ?? this.getFreeColor(),
      },
    }

    return {
      title: this.config.label,
      inputs: this.config.inputsOrder.map((item) => options[item as keyof Omit<Attrs, 'period'>]),
      inputsErrorMessages: {
        conversion: `${options.conversion.label} must be a whole number between ${options.conversion.min} and ${options.conversion.max}`,
        base: `${options.base.label} must be a whole number between ${options.base.min} and ${options.base.max}`,
        spanB: `${options.spanB.label} must be a whole number between ${options.spanB.min} and ${options.spanB.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'conversion':
      case 'base':
      case 'spanB':
      case 'lagging':
        return this.getIsNumberInputValid({ key })
      case 'spanAStrokeColor':
      case 'spanAFillColor':
      case 'spanBStrokeColor':
      case 'spanBFillColor':
      case 'conversionLineColor':
      case 'baseLineColor':
      case 'laggingSpanColor':
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.spanAStrokeColor
  }
}

IchimokuCloud.prototype.defaults = {
  spanAStrokeColor: COLORS.SpanA.Stroke,
  spanAFillColor: COLORS.SpanA.Fill,
  spanBStrokeColor: COLORS.SpanB.Stroke,
  spanBFillColor: COLORS.SpanB.Fill,
  conversionLineColor: COLORS.ConversionLine,
  baseLineColor: COLORS.BaseLine,
  laggingSpanColor: COLORS.LaggingSpan,
}

export default IchimokuCloud
