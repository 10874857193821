import { Attrs, FIConfig } from '../indicators/configs/fi'
import { ExponentialMovingAverage } from './ema'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  fi: number[]
}

export class FICalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = FIConfig

  calculate() {
    const { period } = this.options
    const { close, volume } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return

    const ema = new ExponentialMovingAverage(period)

    for (let index = 1; index < close.length; index++) {
      const value = ((close[index] - close[index - 1]) * volume[index]) / 1000000
      ema.add(value)

      if (index < period) continue

      this._calculatedValues.fi[index] = ema.value
    }
  }
}
