export * from 'finviz-charts/app/lib-export'

export enum SettingsTab {
  Chart = 'chart',
  Colors = 'colors',
}

export enum SidebarDirection {
  vertical,
  horizontal,
}
