import Spine, { Collection } from '@finviz/spine'
import omit from 'lodash.omit'

import { IIdea, RootChartConfigObject, Theme, TodoObjectHashAnyType } from '../../types/shared'
import { ChartEditorEnum, IndicatorType, LayoutType, SpecificChartFunctionality } from '../constants/common'
import { DrawingSetting } from '../controllers/toolbar/interfaces'
import Indicator from '../indicators/indicator'
import { getIsSSr } from '../utils/helpers'
import Chart from './chart'
import ChartEventElement from './chart-event-element'
import { ChartSyncablePart } from './chart/contstants'
import { ISettings } from './chart_settings/interfaces'
import DrawingsInternalStore from './drawings-internal-store'
import Pane from './pane'
import { QuoteFetchType } from './quote/constants'

class ChartLayout extends Spine.Model {
  static initClass(chartModel: typeof Chart, drawingsInternalStoreModel: typeof DrawingsInternalStore) {
    this.configure(
      'ChartLayout',
      'layout',
      'activeChart',
      'activePane',
      'activeChartEvent',
      'width',
      'height',
      'editable',
      'canChangeTicker',
      'scrollable',
      'initialScrollable',
      'cross',
      'editors',
      'theme',
      'settings',
      'isWheelZoomEnabled',
      'specificChartFunctionality',
      'isLargeChart',
      'isHideDrawingsActive',
      'isPreserveDrawingsActive',
      'isLockDrawingsActive',
      'isDrawingModeContinuousActive',
      'uuid',
      'isTouchCrossActive',
      'defaultZoomFactor',
      'syncChartParts',
      'isInit',
      'willDestroy',
      'idea'
    )
    this.hasMany('charts', chartModel)
    this.belongsTo('drawingsInternalStore', drawingsInternalStoreModel)
  }

  declare layout: LayoutType
  declare activeChart: Chart
  declare activePane?: Pane
  declare activeChartEvent?: ChartEventElement
  declare width: number
  declare height: number
  declare editable: boolean
  declare canChangeTicker: boolean
  declare scrollable: boolean
  declare readonly initialScrollable: boolean
  declare cross: boolean
  declare editors: ChartEditorEnum[]
  declare theme: Theme
  declare settings: ISettings
  declare isWheelZoomEnabled: boolean
  declare specificChartFunctionality: SpecificChartFunctionality
  declare isLargeChart?: boolean
  declare charts: () => Collection<Chart>
  declare isHideDrawingsActive: boolean
  declare isPreserveDrawingsActive: boolean
  declare isLockDrawingsActive: boolean
  declare isDrawingModeContinuousActive: boolean
  declare uuid: string
  declare isTouchCrossActive?: boolean
  declare defaultZoomFactor: number
  declare syncChartParts: ChartSyncablePart[]
  declare idea?: IIdea
  declare isInit?: boolean
  declare willDestroy?: boolean
  declare drawingsInternalStore: () => DrawingsInternalStore

  constructor(...args: any[]) {
    super(...args)

    if (!getIsSSr()) {
      this.bind('update', (model: ChartLayout) => {
        if (!window.FinvizSettings.toolsState) {
          window.FinvizSettings.toolsState = {}
        }
        window.FinvizSettings.toolsState[DrawingSetting.PreserveDrawings] = model.isPreserveDrawingsActive
      })
    }
  }

  getPaneWithSelection() {
    for (const pane of this.getAllPanes()) {
      if (pane.selection) {
        return pane
      }
    }
  }

  toObject() {
    const charts = this.getAllCharts().map((chart) => chart.toObject())
    return {
      layout: this.layout,
      width: this.width,
      height: this.height,
      charts,
      isHideDrawingsActive: this.isHideDrawingsActive,
      isPreserveDrawingsActive: this.isPreserveDrawingsActive,
    }
  }

  toConfig<T extends Partial<RootChartConfigObject> = RootChartConfigObject>(omitKeys = [] as string[]): T {
    const charts = this.getAllCharts().map((chart) => chart.toConfig(omitKeys))
    return JSON.parse(
      JSON.stringify(
        omit(
          {
            ...this.settings.ChartSettings.center,
            layout: this.layout,
            width: this.width,
            height: this.height,
            charts,
            isHideDrawingsActive: this.isHideDrawingsActive,
            isPreserveDrawingsActive: this.isPreserveDrawingsActive,
            colors: this.settings.ChartSettings.general.Colors,
            specificChartFunctionality: this.specificChartFunctionality,
            editors: this.editors,
            editable: this.editable,
            scrollable: this.scrollable,
            theme: this.theme,
            newCharts: /\bcharts\b/.test(window.location.pathname),
          },
          omitKeys
        )
      )
    )
  }

  destroy(options?: TodoObjectHashAnyType) {
    this.updateAttribute('willDestroy', true)
    return super.destroy(options)
  }

  destroyCascade(options?: TodoObjectHashAnyType) {
    this.updateAttribute('willDestroy', true)
    this.getAllCharts().forEach((chart) => {
      chart.destroyCascade()
    })
    return this.destroy(options)
  }

  getAllCharts() {
    return this.charts().all()
  }

  getAllPanes() {
    return this.getAllCharts().flatMap((chart) => chart.getAllPanes())
  }

  getAllElements() {
    return this.getAllCharts().flatMap((chart) => chart.getAllElements())
  }

  setSyncChartParts(chartParts: ChartSyncablePart | ChartSyncablePart[], isInSync: boolean) {
    const chartPartsToSync = Array.isArray(chartParts) ? chartParts : [chartParts]

    const newSyncedChartParts = this.syncChartParts?.filter((chartPart) => !chartPartsToSync.includes(chartPart)) ?? []

    if (isInSync) {
      newSyncedChartParts.push(...chartPartsToSync)
    }

    this.updateAttributes({ syncChartParts: newSyncedChartParts })
  }

  getIsChartPartInSync(chartPart: ChartSyncablePart) {
    return !!this.syncChartParts?.includes(chartPart)
  }

  getIsIndicatorPresent(type: IndicatorType) {
    return !!this.charts()
      .first()
      ?.getAllElements()
      .some((element) => element.isIndicator() && (element.instance as unknown as Indicator).type === type)
  }

  getHTMLElementId() {
    return `chart-layout-${this.id}`
  }

  exitIdea() {
    this.updateAttribute('idea', undefined)
    this.getAllCharts().forEach((chart) => {
      // Change all idea quotes to non idea quotes and refetch because we don't need them anymore, but they may cause inconsistencies if they are in cache
      chart.getAllQuotes().forEach((quote) => {
        if (quote.ideaID) {
          quote.updateAttributes({ ideaID: undefined })
          quote.fetchData({ fetchType: QuoteFetchType.Refetch })
        }
      })

      chart.updateAttributes({ refreshData: true })
    })
  }

  isIdeaId(id: number | null = null) {
    return (this.idea?.id ?? null) === id
  }
}

export default ChartLayout
