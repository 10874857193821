import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = { period: number }

export interface Attrs extends Options {
  color: string
}

export class VWAPConfig extends BaseConfig {
  static type = OverlayType.Vwap
  static abbreviation = 'vwap'
  static label = 'VWAP (intraday charts)'
  static shortLabel = 'VWAP'
  static description =
    'The VWAP measures the average price of a security, weighted by volume throughout the trading day. It helps determine the true average price, with prices above the VWAP indicating buying pressure and prices below the VWAP indicating selling pressure.'

  static inputsOrder = ['color'] as Array<keyof Omit<Attrs, 'period'>>
  static inputsLabels = { color: 'Color' }
  static calculatedValuesOrder = ['vwap'] as const
  static calculatedValuesLabels = { vwap: 'VWAP' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
