import { DefaultErrorBoundary } from '@finviz/website'
import * as React from 'react'
import { FallbackProps } from 'react-error-boundary'

export function ModuleLoadFailed(props: Partial<FallbackProps>) {
  return (
    <DefaultErrorBoundary
      {...props}
      title="Loading Failed"
      message="We’re sorry we can’t load this module right now. Please refresh the page and try again."
    />
  )
}
