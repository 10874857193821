import { ChartConfigChartPane, Position, RootChartConfigObject } from '../../types/shared'
import { ChartElementType, IndicatorType, OverlayType, PANE_HEIGHT_DEFAULT, ScaleType } from '../constants/common'

function hasPaneWithCOTCode(panes: ChartConfigChartPane[], code: string) {
  return panes.some(({ elements }) =>
    elements.some((element) => element.type === IndicatorType.Cot && element.code === code)
  )
}
function pushIndicators(
  panes: ChartConfigChartPane[],
  indicator: { name: string; parameters: string },
  options?: { COTs?: string[] }
) {
  if (indicator.name === 'cot') {
    const cotKeys = options?.COTs ?? []
    if (cotKeys.length === 0) {
      cotKeys.push('')
    }
    cotKeys.forEach(function (code: string) {
      if (!hasPaneWithCOTCode(panes, code)) {
        panes.push({
          height: PANE_HEIGHT_DEFAULT.indicator,
          elements: [
            {
              type: IndicatorType.Cot,
              code,
            },
          ],
        })
      }
    })
  } else {
    panes.push({
      height: PANE_HEIGHT_DEFAULT.indicator,
      elements: [
        {
          type: ('indicators/' + indicator.name) as IndicatorType,
          period: indicator.parameters,
        },
      ],
    })
  }
}

function getChartTypeByTaConfigStyle(style: string) {
  const chartTypeMap = {
    candlestick: ChartElementType.CandleStick,
    heikinashi: ChartElementType.HeikinAshi,
    hollowcandlestick: ChartElementType.HollowCandleStick,
    line: ChartElementType.LineChart,
    ohlc: ChartElementType.OhlcChart,
  }
  return chartTypeMap[style as keyof typeof chartTypeMap]
}

function getChartScaleByTaConfigScale(scale: string | undefined) {
  const chartScaleMap = {
    linear: ScaleType.Linear,
    percentage: ScaleType.Percentage,
    logarithmic: ScaleType.Logarithmic,
  }
  return chartScaleMap[scale as keyof typeof chartScaleMap] ?? ScaleType.Linear
}

/*
 * Merges chartConfig (in "chart" format) with taConfig (in format that TA is preserved in DB)
 */
export const mergeChartAndTAConfig = function convertTA(
  chartConfig: RootChartConfigObject,
  taConfig: TaConfigObject,
  options?: { COTs: string[] }
) {
  const panes = chartConfig.charts[0].panes
  const chart = chartConfig.charts[0]

  chart.scale = getChartScaleByTaConfigScale(taConfig.scale)

  taConfig.indicators
    .filter((indicator) => indicator.position === Position.Above)
    .forEach((indicator) => {
      pushIndicators(panes, indicator, options)
    })

  const overlays = taConfig.overlays.map(function (overlay) {
    return {
      type: ('overlays/' + overlay.name) as OverlayType,
      period: overlay.parameters ?? '',
      color: overlay.color ?? '',
    }
  })
  panes.push({
    height: PANE_HEIGHT_DEFAULT.chart,
    elements: [
      {
        type: getChartTypeByTaConfigStyle(taConfig.style),
        overlays: overlays,
      },
    ],
  })

  taConfig.indicators
    .filter((indicator) => indicator.position === Position.Below)
    .forEach((indicator) => {
      pushIndicators(panes, indicator, options)
    })

  chartConfig.height = chart.height = PANE_HEIGHT_DEFAULT.chart + (panes.length - 1) * PANE_HEIGHT_DEFAULT.indicator
}

export const getPanes = function getPanes(taConfig: TaConfigObject) {
  const options = {}
  const panes: ChartConfigChartPane[] = []

  taConfig.indicators
    .filter((indicator) => indicator.position === Position.Above)
    .forEach((indicator) => {
      pushIndicators(panes, indicator, options)
    })

  const overlays = taConfig.overlays.map(function (overlay) {
    return {
      type: ('overlays/' + overlay.name) as OverlayType,
      period: overlay.parameters ?? '',
      color: overlay.color ?? '',
    }
  })
  panes.push({
    height: PANE_HEIGHT_DEFAULT.chart,
    elements: [
      {
        type: getChartTypeByTaConfigStyle(taConfig.style),
        overlays: overlays,
      },
    ],
  })

  taConfig.indicators
    .filter((indicator) => indicator.position === Position.Below)
    .forEach((indicator) => {
      pushIndicators(panes, indicator, options)
    })

  return panes
}
