import { AroonCalculation } from '../indicator-calculation/aroon'
import { ArooscConfig, Attrs } from './configs/aroosc'
import Indicator from './indicator'

class AroonOscillator extends Indicator<Attrs> {
  static config = ArooscConfig
  aroonCalculation: AroonCalculation | null = null
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (!this.isComputeNecessary() && this.aroonCalculation !== null) return

    this.aroonCalculation = new AroonCalculation({
      quote: this.data,
      options: { period: this.period },
    })

    this.aroonCalculation.calculate()

    this.lastValue = this.aroonCalculation.calculatedValues.oscillator.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.aroonCalculation?.calculatedValues.oscillator ?? [])
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(
      context,
      this.aroonCalculation?.calculatedValues.oscillator ?? [],
      this.period,
      0,
      0,
      0,
      false
    )
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 25,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: ArooscConfig.label,
      inputs: ArooscConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default AroonOscillator
