import Pane from '../models/pane'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, Options, StofaConfig } from './configs/stofa'
import Stochastics from './stofu'

class StochasticsFast extends Stochastics<Attrs> {
  static config = StofaConfig

  declare kPeriod: number
  declare period: number
  declare dPeriod: number

  constructor(attrs: Attrs, model: Pane) {
    super(attrs, model)
    this.kPeriod = 1
  }

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [period = 0, dPeriod = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period, dPeriod, kPeriod: 1 })
    } else {
      super.set(values)
    }
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
      dPeriod: {
        type: 'number',
        label: 'D Period',
        name: 'dPeriod',
        value: this.dPeriod ?? 3,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: StofaConfig.label,
      inputs: StofaConfig.inputsOrder.map((item) => options[item as keyof Options]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        dPeriod: `${options.dPeriod.label} must be a whole number between ${options.dPeriod.min} and ${options.dPeriod.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
      case 'dPeriod':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default StochasticsFast
