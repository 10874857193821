import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class CCIConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Cci
  static abbreviation = 'cci'
  static label = 'Commodity Channel Index'
  static shortLabel = 'CCI'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['cci'] as const
  static calculatedValuesLabels = {
    cci: 'CCI',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
