import Utils from '../utils'
import { getContextWithCache } from '../utils/canvas'
import { captureException, getIsSSr } from '../utils/helpers'

class Cache {
  constructor() {
    this.canvas = getIsSSr() ? null : document.createElement('canvas')
  }

  set(callback, width, height) {
    this.callback = callback
    this.width = width
    this.height = height
    this.rendered = false
  }

  render(context, x, y) {
    if (x == null) {
      x = 0
    }
    if (y == null) {
      y = 0
    }
    if (!this.rendered) {
      this.refresh()
    }
    if (this.width < 1 || this.height < 1) {
      return
    }

    try {
      if (this.ratio >= 1 && this.canvas) {
        context.drawImage(
          this.canvas,
          0,
          0,
          ~~(this.width * this.ratio),
          ~~(this.height * this.ratio),
          x,
          y,
          this.width,
          this.height
        )
      } else {
        context.save()
        context.translate(x, y)
        this.callback(context)
        context.restore()
      }
    } catch (e) {
      captureException(
        e,
        this.canvas
          ? {
              extra: {
                width: this.width,
                height: this.height,
                ratio: this.ratio,
                cWidth: this.canvas.width,
                cHeight: this.canvas.height,
                sWidth: window.screen.width,
                sHeight: window.screen.height,
              },
            }
          : {}
      )
    }
  }

  refresh() {
    if (getIsSSr()) {
      return
    }
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.context = getContextWithCache(this.canvas)
    this.ratio = Utils.getScaleRatio()
    Utils.setSizeOnCanvasElement({ canvasElement: this.canvas, width: this.width, height: this.height })
    this.context.scale(this.ratio, this.ratio)

    this.callback(this.context)
    this.rendered = true
  }
}

export default Cache
