import tailwindColors from '../constants/colors'
import { getHEXWithSpecificAplha } from '../utils/colors'

export interface IChartThemeColors {
  aftermarketFade: string
  background: string
  borderDown: string
  borderUp: string
  canvasFill: string
  cross: string
  crossText: string
  crossTextBackground: string
  currentBackground: string
  currentText: string
  grid: string
  gridSecondary: string
  indicatorCurrentBackground: string
  indicatorCurrentText: string
  line: string
  marketDown: string
  marketUp: string
  negativeChange: string
  ohlcTrendDown: string
  ohlcTrendUp: string
  overlayTransparentBackground: string
  percentageZeroLineText: string
  performanceIndicatorLabel: string
  positiveChange: string
  premarketFade: string
  prevClose: string
  text: string
  textSecondary: string
  trendDown: string
  trendUp: string
  volumeTrendDown: string
  volumeTrendUp: string
  wickDown: string
  wickUp: string
  zeroChange: string
}

export interface IIndicatorThemeColors {
  line: string
  signalLine: string
}

export interface IElementThemeColors {
  line: string
  border: string
  textWithoutBackground: string
}

interface IDarkerWickColors {
  borderUp: string
  wickUp: string
  borderDown: string
  wickDown: string
}

export const chartLightTheme: IChartThemeColors = {
  aftermarketFade: '#FFFFFF99',
  background: '#008CFF0E',
  borderDown: '#000000FF',
  borderUp: '#000000FF',
  canvasFill: '#FFFFFFFF',
  cross: '#0000004C',
  crossText: '#fff',
  crossTextBackground: '#111',
  currentBackground: tailwindColors.brightYellow[100],
  currentText: tailwindColors.gray[800],
  grid: '#D2D2D2FF',
  gridSecondary: '#ECECEC',
  indicatorCurrentBackground: tailwindColors.gray[600],
  indicatorCurrentText: '#fff',
  line: '#1E6DC0FF',
  marketDown: tailwindColors.red[500],
  marketUp: tailwindColors.green[500],
  negativeChange: '#C80000FF',
  ohlcTrendDown: '#E54040FF',
  ohlcTrendUp: '#40A140FF',
  overlayTransparentBackground: getHEXWithSpecificAplha('#C9CCD6', 0.25),
  percentageZeroLineText: '#111',
  performanceIndicatorLabel: tailwindColors.gray[600],
  positiveChange: '#009600FF',
  premarketFade: '#FFFFFF99',
  prevClose: '#C80000FF',
  text: '#828282',
  textSecondary: '#dedede',
  trendDown: '#FF0000FF',
  trendUp: '#00FF00FF',
  volumeTrendDown: '#ffc0c0',
  volumeTrendUp: '#a8e0a8',
  wickDown: '#000000FF',
  wickUp: '#000000FF',
  zeroChange: '#000',
}

export const chartDarkTheme: IChartThemeColors = {
  aftermarketFade: '#4C526133',
  background: '#1E6DC019',
  borderDown: '#F63538FF',
  borderUp: '#30CC5AFF',
  canvasFill: tailwindColors.gray[800],
  cross: '#404553',
  crossText: '#fff',
  crossTextBackground: '#111',
  currentBackground: tailwindColors.yellow[200],
  currentText: tailwindColors.gray[700],
  grid: '#363A46FF',
  gridSecondary: '#2C303A',
  indicatorCurrentBackground: tailwindColors.gray[500],
  indicatorCurrentText: '#fff',
  line: '#1E6DC0FF',
  marketDown: tailwindColors.red[300],
  marketUp: tailwindColors.green[300],
  negativeChange: tailwindColors.red[400],
  ohlcTrendDown: '#E54040FF',
  ohlcTrendUp: '#40A140FF',
  overlayTransparentBackground: getHEXWithSpecificAplha('#0C0E13', 0.35),
  percentageZeroLineText: '#fff',
  performanceIndicatorLabel: tailwindColors.gray[300],
  positiveChange: tailwindColors.green[400],
  premarketFade: '#4C526133',
  prevClose: '#C80000FF',
  text: '#929cb3',
  textSecondary: '#929CB399',
  trendDown: '#F63538FF',
  trendUp: '#30CC5AFF',
  volumeTrendDown: '#F635387F',
  volumeTrendUp: '#30CC5A7F',
  wickDown: '#F63538FF',
  wickUp: '#30CC5AFF',
  zeroChange: '#929cb3',
}

export const darkerWickColors: IDarkerWickColors = {
  borderUp: '#006400FF',
  wickUp: '#006400',
  borderDown: '#6E0000FF',
  wickDown: '#6E0000FF',
}

export const indicatorLightTheme: IIndicatorThemeColors = {
  line: '#000000',
  signalLine: tailwindColors.red[400],
}

export const indicatorDarkTheme: IIndicatorThemeColors = {
  line: '#D07933',
  signalLine: tailwindColors.red[400],
}

export const elementLightTheme: IElementThemeColors = {
  line: '#000000',
  border: '#000000',
  textWithoutBackground: '#000000',
}

export const elementDarkTheme: IElementThemeColors = {
  line: '#5faaf4',
  border: '#5192D1',
  textWithoutBackground: '#929cb3',
}
export const generalColors: Array<string> = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
]

// node chart with thin bars color override
export const thinBarColorsOverride = {
  trendUp: '#00b400',
  trendDown: '#b40000',
}

export enum AutoSaveState {
  Off,
  Saved,
  Unsaved,
  Saving,
}

export const CHART_EVENT_BADGE_SIZE = 24
