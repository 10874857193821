import { NotificationWrapper, withSuspense } from '@finviz/website'
import React from 'react'

import { RootChartConfigObject } from '../../../types/shared'
import { ElementStyleDialogWrapper } from '../../modals/element_style'
import { useChartLayoutGlobalModelAsync } from '../../utils/useChartLayoutGlobalModelAsync'
import { ChartLayoutComponent } from './chart_layout_component'

interface ChartLayoutProps {
  config: RootChartConfigObject
  shouldResize?: boolean
  parentElement?: React.RefObject<HTMLElement> | HTMLElement
  isWithNotifications?: boolean
}

export function ChartLayout({ config, shouldResize, isWithNotifications = true, parentElement }: ChartLayoutProps) {
  const Wrapper = isWithNotifications ? NotificationWrapper : React.Fragment
  return (
    <Wrapper>
      <ElementStyleDialogWrapper>
        <ChartLayoutComponent config={config} shouldResize={shouldResize} parentElement={parentElement} />
      </ElementStyleDialogWrapper>
    </Wrapper>
  )
}

export function ChartLayoutWithGlobalStateRaw({
  config,
  shouldResize,
  parentElement,
}: Omit<ChartLayoutProps, 'isWithNotifications'>) {
  const { setChartLayoutModel, getChartLayoutModelConfig, lastGlobalRerender } = useChartLayoutGlobalModelAsync()

  return (
    <ElementStyleDialogWrapper>
      {config && (
        <ChartLayoutComponent
          key={lastGlobalRerender}
          config={getChartLayoutModelConfig({ fallback: config })}
          shouldResize={shouldResize}
          onRegisterChartLayoutModel={setChartLayoutModel}
          parentElement={parentElement}
        />
      )}
    </ElementStyleDialogWrapper>
  )
}

export const ChartLayoutWithGlobalState = withSuspense(ChartLayoutWithGlobalStateRaw)
