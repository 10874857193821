import { OBVConfig } from '../indicators/configs/obv'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  obv: number[]
}

export class OBVCalculation extends MainCalculation<undefined, DefaultCalculatedValuesType> {
  static config = OBVConfig

  calculate() {
    const { close, volume } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let i = 1; i < close.length; i++) {
      let currentObv = this._calculatedValues.obv[i - 1] ?? 0
      if (close[i] > close[i - 1]) {
        currentObv += volume[i]
      } else if (close[i] < close[i - 1]) {
        currentObv -= volume[i]
      }

      this._calculatedValues.obv[i] = currentObv
    }
  }
}
