import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import PaneModel from '../../models/pane'

export function withPaneModel<P extends { model: PaneModel }>(Component: React.ComponentType<P>) {
  return function WrappedComponent(props: P) {
    const model = useModelState(props.model, { watchProperties: ['selection'] })

    return <Component {...props} model={model} />
  }
}
