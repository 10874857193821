import Text from '../canvas/text'
import { ChartElementType, TextAlign, TextBaseline } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import Pane from '../models/pane'
import { isRedesignEnabled } from '../utils'
import Chart, { BaseChartAttrs } from './base_chart'

const interval = 3
const domainMin = -1
const domainMax = 1
const red = 'rgba(200, 0, 0, 0.392)'
const redLine = 'rgb(120, 0, 0)'
const green = 'rgba(0, 180, 0, 0.392)'
const greenLine = 'rgb(0, 100, 0)'

class MarketSentiment extends Chart {
  static type = ChartElementType.MarketSentiment

  static initClass() {
    Object.defineProperty(this.prototype, 'width', {
      get() {
        const { MarketSentimentSettings } = this.getChartLayoutSettings()
        return this.model.width - MarketSentimentSettings.left.width - MarketSentimentSettings.right.width
      },
    })

    Object.defineProperty(this.prototype, 'height', {
      get() {
        const { MarketSentimentSettings } = this.getChartLayoutSettings()
        return this.paneModel.height - MarketSentimentSettings.top.height - MarketSentimentSettings.bottom.height
      },
    })
  }

  declare _data: number[]
  declare _dataLastIndex: number | null

  constructor(values: Partial<BaseChartAttrs>, model: Pane) {
    super(values, model)
    this.renderText = this.renderText.bind(this)
    this.renderOverlaysLabels = this.renderOverlaysLabels.bind(this)
    this.renderYAxis = this.renderYAxis.bind(this)
    this.setupCache = this.setupCache.bind(this)
  }

  setupCache() {
    super.setupCache()
    if (!this.data) {
      return
    }
    const arr: number[] = []
    this.data.close.forEach((sum, i) => {
      arr[i] = sum / this.data.volume[i]
    })
    this._data = arr

    let lastIndex = null
    for (let i = this.data.volume.length - 1; i >= 0; i--) {
      if (this.data.volume[i] !== 0) {
        lastIndex = i
        break
      }
    }
    this._dataLastIndex =
      lastIndex === null
        ? null
        : Math.min(lastIndex, Math.round((this.data.drawMinutesPerDay - this.data.marketStartMinutes) / interval))
  }

  renderChart() {
    const { MarketSentimentSettings } = this.getChartLayoutSettings()
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + MarketSentimentSettings.left.width + 0.5,
      yOffset: MarketSentimentSettings.top.height + 0.5,
    })
    translate.do()
    this.renderLineBullBear(this.context, this._data, 0, 0, 0)
    translate.undo()
  }

  renderLineBullBear(
    context: CanvasRenderingContext2D,
    array: number[],
    from: number,
    oversold: number,
    overbought: number
  ) {
    if (this._dataLastIndex === null) {
      return
    }
    const isRedesign = isRedesignEnabled()
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general

    const domain = [domainMax, domainMin]

    context.save()
    context.beginPath()
    context.rect(
      -this.leftOffset,
      this.fy(domain[1]),
      this.fx(this._dataLastIndex),
      Math.round(this.fy(oversold) - this.fy(domain[1])) - 0.5
    )
    context.clip()
    context.set('fillStyle', red)
    context.set('strokeStyle', isRedesign ? Colors.marketDown : redLine)
    context.beginPath()
    context.moveTo(this.fx(0), Math.round(this.fy(domain[0])))
    for (let i = from; i < this.data.close.length; i++) {
      context.lineTo(this.fx(i), Math.round(this.fy(array[i])))
    }
    context.lineTo(this.fx(this.data.close.length - 1), Math.round(this.fy(domain[0])))
    context.fill()
    context.stroke()
    context.restore()

    context.save()
    context.beginPath()
    context.rect(
      -this.leftOffset,
      this.fy(domain[0]),
      this.fx(this._dataLastIndex),
      Math.round(this.fy(overbought)) - 0.5
    )
    context.clip()
    context.set('fillStyle', green)
    context.set('strokeStyle', isRedesign ? Colors.marketUp : greenLine)
    context.beginPath()
    context.moveTo(this.fx(0), Math.round(this.fy(domain[1])))
    for (let i = from; i < this.data.close.length; i++) {
      context.lineTo(this.fx(i), Math.round(this.fy(array[i])))
    }
    context.lineTo(this.fx(this.data.close.length - 1), Math.round(this.fy(domain[1])))
    context.fill()
    context.stroke()
    context.restore()
  }

  renderXAxis() {
    const { MarketSentimentSettings } = this.getChartLayoutSettings()
    const { Colors } = MarketSentimentSettings.general
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + MarketSentimentSettings.left.width,
      yOffset: MarketSentimentSettings.top.height,
    })
    translate.do()

    const xLine = this.getXLine()
    const text = new Text(
      {
        y: this.height + 12,
        font: { size: 7 },
        fillStyle: Colors.text,
        textBaseline: TextBaseline.bottom,
        textAlign: TextAlign.center,
      },
      this.paneModel
    )
    let x

    x = this.fx(0)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10) + 2 /* remove overflow */
    text.set({ text: '10AM', x }).render(this.context)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10 + 20)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10 + 40)
    text.set({ text: '12PM', x }).render(this.context)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10 + 60)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10 + 80)
    text.set({ text: '2PM', x }).render(this.context)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10 + 100)
    xLine.set({ x1: x, x2: x }).render(this.context)

    x = this.fx(10 + 120 - 1)
    text.set({ text: '4PM', x }).render(this.context)
    xLine.set({ x1: x, x2: x }).render(this.context)

    translate.undo()
  }

  renderText() {}

  renderCrossText() {}

  renderOverlaysLabels() {}

  _compute() {
    return { min: domainMin, max: domainMax }
  }

  computeVisibleMinMax() {
    return this._compute()
  }

  renderYAxis(context: CanvasRenderingContext2D) {
    const { MarketSentimentSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const isRedesign = isRedesignEnabled()
    const translate = getTranslate({
      context: this.context,
      xOffset: MarketSentimentSettings.left.width,
      yOffset: MarketSentimentSettings.top.height,
    })
    translate.do()

    const yLine = this.getYLine()
    for (const tick of [domainMin, 0, domainMax]) {
      const y = Math.round(this.fy(tick))
      yLine.set({ y1: y, y2: y }).render(context)
    }

    const text = new Text(
      {
        x: this.width + 27,
        font: { size: 8 },
        textBaseline: TextBaseline.middle,
        textAlign: TextAlign.right,
      },
      this.paneModel
    )

    const fillUp = isRedesign ? Colors.marketUp : greenLine
    const fillDown = isRedesign ? Colors.marketDown : redLine
    const last = this._data[this._dataLastIndex!]
    const percent = this._dataLastIndex !== null ? Math.floor(last * 50 + 50) : 50
    text.set({ y: this.fy(0) - 5, fillStyle: fillUp, text: `${percent}%` }).render(context)
    text.set({ y: this.fy(0) + 7, fillStyle: fillDown, text: `${100 - percent}%` }).render(context)

    translate.undo()
  }

  clip() {
    const { MarketSentimentSettings } = this.getChartLayoutSettings()
    this.context.beginPath()
    this.context.rect(MarketSentimentSettings.left.width, MarketSentimentSettings.top.height, this.width, this.height)
    this.context.clip()
  }

  getMinMax() {
    return this._compute()
  }

  renderVolumeAxis() {}

  renderVolume() {}

  toString() {
    return `Market sentiment chart`
  }
}
MarketSentiment.initClass()

export default MarketSentiment
