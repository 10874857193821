import { useChartLayoutGlobalModelAsyncModule } from '../controllers/chart-settings/async-modules'
import { useAsyncModule } from './async-modules-utils'

export function useChartLayoutGlobalModelAsync() {
  const [useChartLayoutGlobalModelModule] = useAsyncModule({
    ...useChartLayoutGlobalModelAsyncModule,
    isSuspenseMode: true,
  })
  return useChartLayoutGlobalModelModule.useChartLayoutGlobalModel()
}
