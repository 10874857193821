import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

export interface IEllipseAttrs {
  x1: number
  x2: number
  x3: number
  cp1x: number
  cp2x: number
  y1: number
  y2: number
  y3: number
  cp1y: number
  cp2y: number
  width: number
  fill: string
  border: DrawingBorder
}

interface ICoordinates {
  topCenterX: number
  topCenterY: number
  topRightX: number
  topRightY: number
  topLeftX: number
  topLeftY: number
  centerLeftX: number
  centerLeftY: number
  centerRightX: number
  centerRightY: number
  bottomCenterX: number
  bottomCenterY: number
  bottomLeftX: number
  bottomLeftY: number
  bottomRightX: number
  bottomRightY: number
}

// OLD Ellipse "v0" kept for backward-compatibiluty for old saved ideas
// current Ellipse tool located in ellipse folder
class Ellipse<Attrs extends IEllipseAttrs = IEllipseAttrs> extends Element<Attrs> {
  static type = CanvasElementType.ellipse

  name = 'Ellipse'

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'x3' | 'y1' | 'y2' | 'y3' | 'width'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'x1',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'x2',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'lw',
        () => this.model.scale.x.invert(this.getCoordinates().centerLeftX),
        () => this.model.scale.y.invert(this.getCoordinates().centerLeftY),
        this.resize,
        this.model
      ),
      new Thumb(
        'rw',
        () => this.model.scale.x.invert(this.getCoordinates().centerRightX),
        () => this.model.scale.y.invert(this.getCoordinates().centerRightY),
        this.resize,
        this.model
      ),
    ]
    const boundingPointKeys = this.getBoundingPointKeys()
    this.scale({ x: [...boundingPointKeys.x, 'width'], y: boundingPointKeys.y })
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.line,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  renderContent(context: CanvasRenderingContext2D) {
    const c = this.getCoordinates()

    context.set('fillStyle', this.attrs.fill)

    context.beginPath()
    context.moveTo(c.bottomCenterX, c.bottomCenterY)
    context.bezierCurveTo(c.bottomRightX, c.bottomRightY, c.topRightX, c.topRightY, c.topCenterX, c.topCenterY)
    context.bezierCurveTo(c.topLeftX, c.topLeftY, c.bottomLeftX, c.bottomLeftY, c.bottomCenterX, c.bottomCenterY)
    context.closePath()
    context.fill()

    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    if ((this.attrs.border != null ? this.attrs.border.width : undefined) !== 0) {
      context.stroke()
    }

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.y1 += y
    this.attrs.x2 += x
    this.attrs.y2 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'x1':
        this.attrs.x1 += difX
        this.attrs.y1 += difY
        break
      case 'x2':
        this.attrs.x2 += difX
        this.attrs.y2 += difY
        break
      case 'lw':
        this.attrs.width += difX
        break
      case 'rw':
        this.attrs.width -= difX
        break
      default:
        break
    }
  }

  getCoordinates(): ICoordinates {
    // TODO cache?
    // http://stackoverflow.com/questions/14169234/the-relation-of-the-bezier-curve-and-ellipse
    const rotationAngle =
      Math.PI + Math.atan(-1 / ((this.scaled.y2 - this.scaled.y1) / (this.scaled.x2 - this.scaled.x1)))

    const sign = this.scaled.y1 > this.scaled.y2 ? 1 : -1
    const radiusX = this.scaled.width / 2
    let radiusY = (sign * Math.abs(this.scaled.y1 - this.scaled.y2)) / Math.cos(rotationAngle) / 2
    if (this.scaled.y1 === this.scaled.y2) {
      radiusY = Math.abs(this.scaled.x1 - this.scaled.x2) / 2
    }

    const width_two_thirds = (radiusX * 4) / 3

    const dx1 = Math.sin(rotationAngle) * radiusY
    const dy1 = Math.cos(rotationAngle) * radiusY
    const dx2 = Math.cos(rotationAngle) * width_two_thirds
    const dy2 = Math.sin(rotationAngle) * width_two_thirds

    const x = this.scaled.x1 + dx1
    const y = this.scaled.y1 - dy1

    const topCenterX = x - dx1
    const topCenterY = y + dy1
    const topRightX = topCenterX + dx2
    const topRightY = topCenterY + dy2
    const topLeftX = topCenterX - dx2
    const topLeftY = topCenterY - dy2

    const bottomCenterX = x + dx1
    const bottomCenterY = y - dy1
    const bottomRightX = bottomCenterX + dx2
    const bottomRightY = bottomCenterY + dy2
    const bottomLeftX = bottomCenterX - dx2
    const bottomLeftY = bottomCenterY - dy2

    const centerRightX = x + dx2
    const centerRightY = y + dy2
    const centerLeftX = x - dx2
    const centerLeftY = y - dy2

    return {
      topCenterX,
      topCenterY,
      topRightX,
      topRightY,
      topLeftX,
      topLeftY,
      centerLeftX,
      centerLeftY,
      centerRightX,
      centerRightY,
      bottomCenterX,
      bottomCenterY,
      bottomLeftX,
      bottomLeftY,
      bottomRightX,
      bottomRightY,
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const c = this.getCoordinates()
    const left = Math.min(this.scaled.x1, this.scaled.x2, c.centerRightX)
    const right = Math.max(this.scaled.x1, this.scaled.x2, c.centerLeftX)
    const top = Math.min(this.scaled.y1, this.scaled.y2, c.centerRightY)
    const bottom = Math.max(this.scaled.y1, this.scaled.y2, c.centerLeftY)
    if (left < area.scaled.x && area.scaled.x < right && top < area.scaled.y && area.scaled.y < bottom) {
      return true
    }
    return super.isInArea(area)
  }

  getIsInChartView() {
    return true
  }
}

Ellipse.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, default: {} },
  ],
}

export default Ellipse
