import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class RWIConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Rwi
  static abbreviation = 'rwi'
  static label = 'Random Walk Index'
  static shortLabel = 'RWI'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
