import { Textarea } from '@finviz/website'
import React from 'react'

import { ObjectHash } from '../../../../types/shared'
import { useModelState } from '../../../model-hooks/use-model-state'
import Chart from '../../../models/chart'
import ChartLayout from '../../../models/chart_layout'
import { useDrawingAutoSaveControls } from '../../autosave/use-drawing-auto-save-controls'
import { getElementNoteText, getTickersAndContainerTypesInLayoutModel } from '../../autosave/utils'

interface ChartAutoSaveNoteProps {
  ticker: string
  note: string
  onNoteChange: (note: string, ticker: string) => void
}

function ChartAutoSaveNote({ ticker, note, onNoteChange }: ChartAutoSaveNoteProps) {
  return (
    <Textarea
      data-testid={`autosave-note-textarea-${ticker}`}
      label={ticker}
      inputClass="w-full"
      rows={4}
      value={note}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onNoteChange(e.target.value, ticker)
      }}
    />
  )
}

interface Props {
  chartLayoutModel: ChartLayout
}

export function ChartAutoSaveNotes({ chartLayoutModel }: Props) {
  const { handleAutoSaveNoteChange } = useDrawingAutoSaveControls(chartLayoutModel)
  const [tickers, setTickers] = React.useState<string[]>([])
  const unwatchedInternalStore = React.useMemo(() => chartLayoutModel.drawingsInternalStore(), [chartLayoutModel])
  const drawingsInternalStore = useModelState(unwatchedInternalStore, { watchProperties: ['elements'] })

  React.useEffect(() => {
    // There was no better way how to listen on ticker changes which is necessary for displaying correct text areas
    const handleChartChange = () => {
      const { tickers } = getTickersAndContainerTypesInLayoutModel(chartLayoutModel)
      setTickers(tickers)
    }

    Chart.bind('change', handleChartChange)
    handleChartChange()
    return () => {
      Chart.unbind('change', handleChartChange)
    }
  }, [chartLayoutModel])

  const tickerNoteMap = React.useMemo(() => {
    const map: ObjectHash = {}
    drawingsInternalStore.elements.forEach((element) => {
      if (element.containerType === 'note' && tickers.includes(element.ticker)) {
        map[element.ticker] = getElementNoteText(element)
      }
    })

    return map
  }, [drawingsInternalStore.elements, tickers])

  return (
    <div className="space-y-4">
      {tickers.map((ticker) => (
        <ChartAutoSaveNote
          key={ticker}
          ticker={ticker}
          note={tickerNoteMap[ticker] ?? ''}
          onNoteChange={handleAutoSaveNoteChange}
        />
      ))}
    </div>
  )
}
