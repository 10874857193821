import { KCHConfig, Options } from '../overlays/configs/kch'
import { AverageTrueRange } from './atr'
import { ExponentialMovingAverage } from './ema'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  upperBand: number[]
  middleLine: number[]
  lowerBand: number[]
}

export class KCHCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = KCHConfig
  declare options: Options

  calculate() {
    const { emaPeriod, multiplier, atrPeriod } = this.options
    const { close, high, low } = this.quote
    const start = Math.max(emaPeriod, atrPeriod)

    this._calculatedValues = this.getDefaultCalculatedValues()
    const ema = new ExponentialMovingAverage(emaPeriod)
    const atr = new AverageTrueRange(atrPeriod)

    for (let i = 0; i < close.length; i++) {
      ema.add(close[i])
      atr.add(high[i], low[i], close[i - 1])
      if (i < start - 1) {
        continue
      }
      this._calculatedValues.middleLine[i] = ema.value
      this._calculatedValues.upperBand[i] = ema.value + multiplier * atr.value
      this._calculatedValues.lowerBand[i] = ema.value - multiplier * atr.value
    }
  }
}
