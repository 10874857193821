export type HLC = {
  ts: number
  count: number
  uuid: string
}

export const serializeHlc = ({ ts, count, uuid }: HLC) =>
  ts.toString().padStart(15, '0') + ':' + count.toString(36).padStart(5, '0') + ':' + uuid

export const parseHlc = (serialized: string) => {
  const [ts, count, uuid] = serialized.split(':')
  return {
    ts: Number.parseInt(ts),
    count: Number.parseInt(count, 36),
    uuid,
  }
}

export const initHlc = (uuid: string, now: number): HLC => ({
  ts: now,
  count: 0,
  uuid,
})

export const compareHlc = (one: HLC, two: HLC) => {
  if (one.ts === two.ts) {
    if (one.count === two.count) {
      if (one.uuid === two.uuid) {
        return 0
      }
      return one.uuid < two.uuid ? -1 : 1
    }
    return one.count - two.count
  }
  return one.ts - two.ts
}

export const incrementHlc = (local: HLC, now: number): HLC => {
  if (now > local.ts) {
    return { ts: now, count: 0, uuid: local.uuid }
  }

  return { ...local, count: local.count + 1 }
}

export const receiveHlc = (local: HLC, remote: HLC): HLC => {
  if (compareHlc(local, remote) >= 0) {
    return local
  }
  return { ...local, ts: remote.ts, count: remote.count }
}
