import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import { getRoundedObject, getXYOffsetFromLine } from '../controllers/renderUtils'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

export interface IArrowAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  border: DrawingBorder
}

class Arrow<Attrs extends IArrowAttrs> extends Element<Attrs> {
  static type = CanvasElementType.arrow

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2'>
  name = 'Arrow'

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.renderContent = this.renderContent.bind(this)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'l',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'r',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    return {
      border: {
        width: 3,
        color: this.getChartLayoutSettings().ElementSettings.Colors.line,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, y1, y2 } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, y1, y2 })
    const translateXY = getXYOffsetFromLine({ lineWidth: this.attrs.border.width, ...roundedXY })

    context.translate(translateXY.x, translateXY.y)
    context.beginPath()
    context.set('lineCap', 'round')
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)

    const angle = Math.PI / 8
    const d = 20

    context.beginPath()
    context.moveTo(roundedXY.x1, roundedXY.y1)
    context.lineTo(roundedXY.x2, roundedXY.y2)
    context.stroke()

    const lineAngle = Math.atan2(roundedXY.y2 - roundedXY.y1, roundedXY.x2 - roundedXY.x1)
    const h = Math.abs(d / Math.cos(angle))

    const angle1 = lineAngle + angle
    const topx = roundedXY.x1 + Math.cos(angle1) * h
    const topy = roundedXY.y1 + Math.sin(angle1) * h
    const angle2 = lineAngle - angle
    const botx = roundedXY.x1 + Math.cos(angle2) * h
    const boty = roundedXY.y1 + Math.sin(angle2) * h

    context.beginPath()
    context.moveTo(topx, topy)
    context.lineTo(roundedXY.x1, roundedXY.y1)
    context.lineTo(botx, boty)
    context.stroke()
    context.translate(translateXY.x * -1, translateXY.y * -1)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.x2 += x
    this.attrs.y1 += y
    this.attrs.y2 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    if (type === 'l') {
      this.attrs.x1 += difX
      this.attrs.y1 += difY
    } else {
      this.attrs.x2 += difX
      this.attrs.y2 += difY
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    if (math.distanceToSegment(area.scaled, this.scaled) <= 10) {
      return true
    }
    return super.isInArea(area)
  }
}

Arrow.prototype.modalConfig = {
  inputs: [{ type: 'line', name: 'border' }],
}

export default Arrow
