export default class ElementBaseConfig {
  static type: string
  static abbreviation: string
  static label: string
  static shortLabel: string
  static description: string
  static optionsOrder: readonly string[]
  static optionsLabels: { [key: string]: string }
  static inputsOrder: readonly string[]
  static inputsLabels: { [key: string]: string }
  static calculatedValuesOrder: readonly string[]
  static calculatedValuesLabels: { [key: string]: string }
  static defaultCalculatedValueOption?: string

  static getShortLabelWithAttrs: (attrs: ObjectHash) => string
  static getDefaultCalculatedValues: () => { [key: string]: number[] }

  get type() {
    return (this.constructor as typeof ElementBaseConfig).type
  }

  get abbreviation() {
    return (this.constructor as typeof ElementBaseConfig).abbreviation
  }

  get label() {
    return (this.constructor as typeof ElementBaseConfig).label
  }

  get shortLabel() {
    return (this.constructor as typeof ElementBaseConfig).shortLabel
  }

  get description() {
    return (this.constructor as typeof ElementBaseConfig).description
  }

  get optionsOrder() {
    return (this.constructor as typeof ElementBaseConfig).optionsOrder
  }

  get optionsLabels() {
    return (this.constructor as typeof ElementBaseConfig).optionsLabels
  }

  get inputsOrder() {
    return (this.constructor as typeof ElementBaseConfig).inputsOrder
  }

  get inputsLabels() {
    return (this.constructor as typeof ElementBaseConfig).inputsLabels
  }

  get calculatedValuesOrder() {
    return (this.constructor as typeof ElementBaseConfig).calculatedValuesOrder
  }

  get calculatedValuesLabels() {
    return (this.constructor as typeof ElementBaseConfig).calculatedValuesLabels
  }

  get defaultCalculatedValueOption() {
    return (this.constructor as typeof ElementBaseConfig).defaultCalculatedValueOption
  }

  get getShortLabelWithAttrs() {
    return (this.constructor as typeof ElementBaseConfig).getShortLabelWithAttrs
  }

  get getDefaultCalculatedValues() {
    return (this.constructor as typeof ElementBaseConfig).getDefaultCalculatedValues
  }
}
