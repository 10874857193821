import { Button, ButtonComponentProps, Delayed, Icon, Spinner, useDialogState } from '@finviz/website'
import classnames from 'classnames'
import * as React from 'react'

import { publishModalAsyncModule } from '../../modals/publish/async-modules'
import ChartLayout from '../../models/chart_layout'
import { useAsyncModule } from '../../utils/async-modules-utils'

interface PublishProps extends Pick<ButtonComponentProps, 'theme' | 'size' | 'rounding'> {
  model: ChartLayout
}

export function Publish({ model, ...props }: React.PropsWithChildren<PublishProps>) {
  const dialog = useDialogState()
  const isDialogOpen = dialog.useState('open')
  const [publishModalModule, { isLoading }] = useAsyncModule({
    ...publishModalAsyncModule,
    shouldLoadModule: isDialogOpen,
    errorSeverity: 'medium',
    onError: () => dialog.hide(),
  })
  const PublishDialog = publishModalModule?.default

  return (
    <>
      <Button
        {...props}
        contentClass="hidden lg:flex"
        active={isDialogOpen}
        appearance={props.children ? undefined : 'square'}
        title={props.children ? undefined : 'Share chart'}
        onClick={() => dialog.show()}
        leftContent={
          isLoading ? (
            <Delayed
              delayComponent={
                <Icon name="share" className={classnames({ 'text-white': props.theme === 'headerItem' })} />
              }
            >
              <Spinner width={16} />
            </Delayed>
          ) : (
            'share'
          )
        }
      />
      {PublishDialog && <PublishDialog state={dialog} layoutModel={model} />}
    </>
  )
}
