import { Attrs, WRConfig } from './configs/wr'
import Indicator from './indicator'

class WilliamsR extends Indicator<Attrs> {
  static config = WRConfig

  declare period: number
  r: number[] = []

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.r = []
      for (let i = this.period; i < this.data.close.length; i++) {
        let highestHigh = this.data.high[i]
        let lowestLow = this.data.low[i]
        for (let j = i - this.period + 1; j <= i; j++) {
          if (this.data.high[j] > highestHigh) {
            highestHigh = this.data.high[j]
          }
          if (this.data.low[j] < lowestLow) {
            lowestLow = this.data.low[j]
          }
        }

        this.r[i] = 0
        if (highestHigh - lowestLow > 0) {
          this.r[i] = ((highestHigh - this.data.close[i]) / (highestHigh - lowestLow)) * -100
        }
      }

      this.lastValue = this.r.last() ?? null
    }
    const { min, max } = this.r.length > 0 ? this.computeVisibleMinMax(this.r) : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.r)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.r, this.period, -80, -50, -20)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: WRConfig.label,
      inputs: WRConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default WilliamsR
