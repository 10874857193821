import Spine from '@finviz/spine'
import { ContextMenuItem } from '@finviz/website'

export type CallbacksType = {
  onFullyOpen: () => void
  onFullyClosed: () => void
  onBeforeItemClick: () => void
}

class ContextMenu extends Spine.Model {
  static initClass() {
    this.configure('ContextMenu', 'shown', 'config', 'anchorRect', 'callbacks')
  }

  declare shown: boolean
  declare config: ContextMenuItem[]
  declare anchorRect: { x: number; y: number }
  declare callbacks: CallbacksType | undefined
}

ContextMenu.initClass()

const contextMenu = ContextMenu.create() as ContextMenu
contextMenu.save()

export default contextMenu
