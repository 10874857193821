import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType, TextAlign, TextBaseline } from '../constants/common'
import { getOffsetFromLineWidth, getRoundedObject } from '../controllers/renderUtils'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Element from './element'
import Text, { ITextAttrs } from './text'
import Thumb from './thumb'

export interface IXABCDPositionAttrs {
  xx: number
  xy: number
  ax: number
  ay: number
  bx: number
  by: number
  cx: number
  cy: number
  dx: number
  dy: number
}

type xPoints = 'xx' | 'ax' | 'bx' | 'cx' | 'dx'
type yPoints = 'xy' | 'ay' | 'by' | 'cy' | 'dy'

export interface IXABCDAttrs extends IXABCDPositionAttrs {
  fill: string
  border: DrawingBorder
  lineWidth: number
}

class XABCD<Attrs extends IXABCDAttrs = IXABCDAttrs> extends Element<Attrs> {
  static type = CanvasElementType.xabcd

  name = 'XABCD pattern'

  declare scaled: Pick<Attrs, xPoints | yPoints>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.resize = this.resize.bind(this)

    this._thumbs = [
      new Thumb(
        'x',
        () => this.attrs.xx,
        () => this.attrs.xy,
        this.resize,
        this.model
      ),
      new Thumb(
        'a',
        () => this.attrs.ax,
        () => this.attrs.ay,
        this.resize,
        this.model
      ),
      new Thumb(
        'b',
        () => this.attrs.bx,
        () => this.attrs.by,
        this.resize,
        this.model
      ),
      new Thumb(
        'c',
        () => this.attrs.cx,
        () => this.attrs.cy,
        this.resize,
        this.model
      ),
      new Thumb(
        'd',
        () => this.attrs.dx,
        () => this.attrs.dy,
        this.resize,
        this.model
      ),
    ]
    if (this.attrs.lineWidth == null) {
      this.attrs.lineWidth = 1
    }
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({
    x: ['xx', 'ax', 'bx', 'cx', 'dx'],
    y: ['xy', 'ay', 'by', 'cy', 'dy'],
  })

  renderContent(context: CanvasRenderingContext2D) {
    const { xx, xy, ax, ay, bx, by, cx, cy, dx, dy } = this.scaled
    const roundedXY = getRoundedObject({ xx, xy, ax, ay, bx, by, cx, cy, dx, dy })

    context.set('fillStyle', this.attrs.fill)
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)

    const offset = getOffsetFromLineWidth(this.attrs.border.width)
    context.translate(offset, offset)

    context.beginPath()
    context.moveTo(roundedXY.xx, roundedXY.xy)
    context.lineTo(roundedXY.ax, roundedXY.ay)
    context.lineTo(roundedXY.bx, roundedXY.by)
    context.lineTo(roundedXY.xx, roundedXY.xy)
    context.fill()
    if ((this.attrs.border != null ? this.attrs.border.width : undefined) !== 0) {
      context.stroke()
    }

    context.beginPath()
    context.moveTo(roundedXY.bx, roundedXY.by)
    context.lineTo(roundedXY.cx, roundedXY.cy)
    context.lineTo(roundedXY.dx, roundedXY.dy)
    context.lineTo(roundedXY.bx, roundedXY.by)
    context.fill()
    if ((this.attrs.border != null ? this.attrs.border.width : undefined) !== 0) {
      context.stroke()
    }

    context.beginPath()
    context.moveTo(roundedXY.ax, roundedXY.ay)
    context.lineTo(roundedXY.cx, roundedXY.cy)
    context.moveTo(roundedXY.xx, roundedXY.xy)
    context.lineTo(roundedXY.dx, roundedXY.dy)
    context.stroke()

    const text = new Text(
      {
        font: { size: 8, style: 'bold' },
        lineHeight: 8,
        padding: {
          top: 2,
          right: 2,
          bottom: 2,
          left: 2,
        },
        textBaseline: TextBaseline.middle,
        textAlign: TextAlign.start,
        fillStyle: '#ffffff',
        background: this.attrs.fill || '#000000',
      },
      this.model
    )

    for (const t of Array.from('xabcd')) {
      text
        .set({
          text: t.toUpperCase(),
          x: roundedXY[(t + 'x') as xPoints] - 6,
          y: roundedXY[(t + 'y') as yPoints] + 12,
        } as Partial<ITextAttrs>)
        .render(context)
    }

    const retLabel = (y1: string, y2: string, y3: string) => {
      const len = Math.abs(this.attrs[(y1 + 'y') as yPoints] - this.attrs[(y2 + 'y') as yPoints])
      const ret = Math.abs(this.attrs[(y3 + 'y') as yPoints] - this.attrs[(y2 + 'y') as yPoints]) / len
      const x = (roundedXY[(y1 + 'x') as xPoints] + roundedXY[(y3 + 'x') as xPoints]) / 2
      const y = (roundedXY[(y1 + 'y') as yPoints] + roundedXY[(y3 + 'y') as yPoints]) / 2
      return text
        .set({
          text: ret.toFixed(3),
          x,
          y,
        })
        .render(context)
    }

    retLabel('x', 'a', 'b')
    retLabel('a', 'b', 'c')
    retLabel('b', 'c', 'd')
    retLabel('x', 'a', 'd')

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
    context.translate(offset * -1, offset * -1)
  }

  moveBy(x: number, y: number) {
    for (const t of Array.from('xabcd')) {
      this.attrs[(t + 'x') as xPoints] += x
      this.attrs[(t + 'y') as yPoints] += y
    }
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    this.attrs[(type + 'x') as xPoints] += difX
    return (this.attrs[(type + 'y') as yPoints] += difY)
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    let t
    const xab = (() => {
      const result = []
      for (t of Array.from('xab')) {
        result.push({ x: this.scaled[(t + 'x') as xPoints], y: this.scaled[(t + 'y') as yPoints] })
      }
      return result
    })()
    const bcd = (() => {
      const result1 = []
      for (t of Array.from('bcd')) {
        result1.push({ x: this.scaled[(t + 'x') as xPoints], y: this.scaled[(t + 'y') as yPoints] })
      }
      return result1
    })()
    if (math.pointInPolygon(area.scaled, xab) || math.pointInPolygon(area.scaled, bcd)) {
      return true
    }
    return super.isInArea(area)
  }
}

XABCD.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1 },
  ],
}

export default XABCD
