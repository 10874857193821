import * as websiteShared from '@finviz/website'

import { captureException } from './helpers'

/**
 * Hook used to asynchronously load a module
 *
 * Usage: [wiki](https://github.com/finvizhq/Finviz-Website/wiki/Loading-modules-asynchronously#useasyncmodule)
 */
export const useAsyncModule = <T, SuspenseMode extends boolean>(
  props: {
    importFn: () => Promise<T>
    onError?: () => void
    cacheKey: string
    captureException?: (exception: Error, captureContext?: { extra: Record<string, unknown> }) => void
    errorSeverity?: 'medium' | 'high'
  } & (websiteShared.ControlledModuleConfig | websiteShared.SuspenseModuleConfig<SuspenseMode>)
) =>
  websiteShared.useAsyncModule({ captureException, ...props }) as [
    SuspenseMode extends true ? T : T | null,
    { isLoading: boolean; loadModule: () => Promise<T>; isError: boolean },
  ]
