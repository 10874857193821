import { ObjectHash, RequireByKey } from '../../types/shared'

interface HeikinAshiData {
  HAOpen: number[]
  HAClose: number[]
}

interface QuoteValues {
  cachedAt: null | number
  dayToOffset: ObjectHash<number, number>[]
  dateToIndex: ObjectHash<number, number>[]
  heikinAshiData?: HeikinAshiData
  daysInQuote: Date[]
}

interface QuoteCacheFunctionProps {
  cid: string
  key: keyof QuoteValues
  fetchedAt: number
  value: any
}

const quoteCache = {} as ObjectHash<QuoteValues>

export function getCachedQuote({
  cid,
  key,
  fetchedAt,
}: RequireByKey<Partial<Pick<QuoteCacheFunctionProps, 'cid' | 'key' | 'fetchedAt'>>, 'cid'>) {
  const quote = quoteCache[cid]
  if (quote?.cachedAt === fetchedAt && key) {
    return quote[key]
  } else if (!key) {
    return quote
  }
  return null
}

export function setCachedQuote({
  cid,
  key,
  value,
  fetchedAt,
}: Pick<QuoteCacheFunctionProps, 'cid' | 'key' | 'value' | 'fetchedAt'>) {
  quoteCache[cid] = {
    ...(quoteCache[cid]?.cachedAt === fetchedAt ? quoteCache[cid] : {}),
    [key]: value,
    cachedAt: fetchedAt,
  } as QuoteValues
}

export function deleteCachedQuote({ cid }: Pick<QuoteCacheFunctionProps, 'cid'>) {
  delete quoteCache[cid]
}
