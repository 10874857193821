import { Instrument, Point } from '../../types/shared'

interface LinePoints {
  x1: number
  x2: number
  y1: number
  y2: number
}

export enum BigNumber {
  Thousand,
  Million,
  Billion,
  Trillion,
}

export const BIG_NUMBER_VALUES = {
  [BigNumber.Thousand]: 1e3,
  [BigNumber.Million]: 1e6,
  [BigNumber.Billion]: 1e9,
  [BigNumber.Trillion]: 1e12,
}

const BIG_NUMBER_ZEROES = {
  [BigNumber.Thousand]: 3,
  [BigNumber.Million]: 6,
  [BigNumber.Billion]: 9,
  [BigNumber.Trillion]: 12,
}

const BIG_NUMBER_SUFFIX = {
  [BigNumber.Thousand]: 'K',
  [BigNumber.Million]: 'M',
  [BigNumber.Billion]: 'B',
  [BigNumber.Trillion]: 'T',
}

const math = {
  dotProduct(p1: Point, p2: Point) {
    return p1.x * p2.x + p1.y * p2.y
  },

  crossProduct(p1: Point, p2: Point) {
    return p1.x * p2.y - p1.y * p2.x
  },

  distance(p1: Point, p2: Point) {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
  },

  lineEquation(line: LinePoints) {
    // ax + by + c = 0
    if (line.x1 === line.x2) {
      return {
        a: 1,
        b: 0,
        c: -line.x1,
      }
    }
    const k = (line.y2 - line.y1) / (line.x2 - line.x1)
    return {
      a: -k,
      b: 1,
      c: k * line.x1 - line.y1,
      k,
    }
  },

  pointInPolygon(point: Point, polygon: Point[]) {
    // http://alienryderflex.com/polygon/
    let j = polygon.length - 1
    let oddNodes = false
    for (let i = 0; i < polygon.length; i++) {
      const p = polygon[i]
      if (
        ((p.y < point.y && polygon[j].y >= point.y) || (polygon[j].y < point.y && p.y >= point.y)) &&
        (p.x <= point.x || polygon[j].x <= point.x)
      ) {
        if (p.x + ((point.y - p.y) / (polygon[j].y - p.y)) * (polygon[j].x - p.x) < point.x) {
          oddNodes = !oddNodes
        }
      }
      j = i
    }
    return oddNodes
  },

  distanceToLine(point: Point, line: LinePoints) {
    const { a, b, c } = this.lineEquation(line)
    const dist = Math.abs(a * point.x + b * point.y + c) / Math.sqrt(a * a + b * b)
    return dist
  },

  distanceToSegment(point: Point, line: LinePoints) {
    const l2 = Math.pow(line.x1 - line.x2, 2) + Math.pow(line.y1 - line.y2, 2)
    if (l2 === 0) {
      return Math.sqrt(Math.pow(point.x - line.x1, 2) + Math.pow(point.y - line.y1, 2))
    }
    const t = ((point.x - line.x1) * (line.x2 - line.x1) + (point.y - line.y1) * (line.y2 - line.y1)) / l2
    if (t < 0) {
      return Math.sqrt(Math.pow(point.x - line.x1, 2) + Math.pow(point.y - line.y1, 2))
    }
    if (t > 1) {
      return Math.sqrt(Math.pow(point.x - line.x2, 2) + Math.pow(point.y - line.y2, 2))
    }
    const x = line.x1 + t * (line.x2 - line.x1)
    const y = line.y1 + t * (line.y2 - line.y1)
    return Math.sqrt(Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2))
  },

  getInstrumentDecimalPlaces({ value, instrument }: { value: number; instrument?: Instrument }) {
    if (instrument === Instrument.Crypto && value <= 0.001) {
      return 8
    }

    if ((instrument !== Instrument.Stock && value <= 6) || (instrument === Instrument.Stock && value < 1)) {
      return 4
    }

    return 2
  },

  formatBigNumber(number: number, toFixed?: number, minNumber?: BigNumber) {
    const absNum = Math.abs(number)
    const getString = ({ num, suffix, zeroes }: { num: number; suffix: string; zeroes: number }) => {
      const str = num.toString()
      if (str.includes('e')) {
        const splitString = str.split('e')
        const addZeroes = Number(splitString[1]) - zeroes
        return Math.round(Number(splitString[0])).toString().padEnd(Math.max(0, addZeroes), '0') + suffix
      }
      const sign = Math.sign(num)
      const numberByZeroesCount = Math.pow(10, zeroes)
      const result = (absNum / numberByZeroesCount) * sign
      return (Number.isInteger(result) ? result : result.toFixed(toFixed !== undefined ? toFixed : 1)) + suffix
    }

    if (absNum >= BIG_NUMBER_VALUES[BigNumber.Trillion] || minNumber === BigNumber.Trillion) {
      return getString({
        num: number,
        suffix: BIG_NUMBER_SUFFIX[BigNumber.Trillion],
        zeroes: BIG_NUMBER_ZEROES[BigNumber.Trillion],
      })
    } else if (absNum >= BIG_NUMBER_VALUES[BigNumber.Billion] || minNumber === BigNumber.Billion) {
      return getString({
        num: number,
        suffix: BIG_NUMBER_SUFFIX[BigNumber.Billion],
        zeroes: BIG_NUMBER_ZEROES[BigNumber.Billion],
      })
    } else if (absNum >= BIG_NUMBER_VALUES[BigNumber.Million] || minNumber === BigNumber.Million) {
      return getString({
        num: number,
        suffix: BIG_NUMBER_SUFFIX[BigNumber.Million],
        zeroes: BIG_NUMBER_ZEROES[BigNumber.Million],
      })
    } else if (absNum >= BIG_NUMBER_VALUES[BigNumber.Thousand] || minNumber === BigNumber.Thousand) {
      return getString({
        num: number,
        suffix: BIG_NUMBER_SUFFIX[BigNumber.Thousand],
        zeroes: BIG_NUMBER_ZEROES[BigNumber.Thousand],
      })
    } else {
      return toFixed !== undefined ? number.toFixed(toFixed) : number.toString()
    }
  },

  round({
    value,
    lastClose = 0,
    instrument,
    overridePlaces,
  }: {
    value: number
    lastClose?: number
    instrument?: Instrument
    overridePlaces?: number
  }) {
    if (Math.abs(value) >= BIG_NUMBER_VALUES[BigNumber.Million]) {
      return this.formatBigNumber(value, overridePlaces)
    }

    const places =
      typeof overridePlaces === 'number'
        ? overridePlaces
        : this.getInstrumentDecimalPlaces({ value: lastClose, instrument })

    if (value < 0.000001) {
      // There was an issue with getting NaN when rounding small numbers with latter method
      return value.toFixed(places)
    }
    return Number(`${Math.round(Number(`${value}e+${places}`))}e-${places}`).toFixed(places)
  },

  perpendicularPointToLine({ line, distance }: { line: LinePoints; distance: number }) {
    if (distance === 0) {
      return { x: line.x2, y: line.y2 }
    }

    let yAxisCoeficient
    let xAxisCoeficient

    if (line.x1 <= line.x2 && line.y1 >= line.y2) {
      xAxisCoeficient = -1
      yAxisCoeficient = -1
    } else if (line.x1 <= line.x2 && line.y1 <= line.y2) {
      xAxisCoeficient = 1
      yAxisCoeficient = -1
    } else if (line.x1 >= line.x2 && line.y1 >= line.y2) {
      xAxisCoeficient = -1
      yAxisCoeficient = 1
    } else {
      // conditions falling to else: (line.x1 >= line.x2 && line.y1 <= line.y2)
      xAxisCoeficient = 1
      yAxisCoeficient = 1
    }

    const radAngle = Math.atan2(Math.abs(line.x2 - line.x1), Math.abs(line.y2 - line.y1))
    const x = line.x2 + xAxisCoeficient * (distance * Math.cos(radAngle))
    const y = line.y2 + yAxisCoeficient * (distance * Math.sin(radAngle))
    return { x, y }
  },

  rotatedPointCoordinates({
    rotationAxisPoint,
    angle,
    pointToRotate,
  }: {
    rotationAxisPoint: Point
    angle: number
    pointToRotate: Point
  }) {
    // return newCoordinates for pointToRotate after rotation defined by
    // angle around rotationAxisPoint
    const sin = Math.sin(angle)
    const cos = Math.cos(angle)
    const rotatedX =
      cos * (pointToRotate.x - rotationAxisPoint.x) +
      sin * (pointToRotate.y - rotationAxisPoint.y) +
      rotationAxisPoint.x
    const rotatedY =
      cos * (pointToRotate.y - rotationAxisPoint.y) -
      sin * (pointToRotate.x - rotationAxisPoint.x) +
      rotationAxisPoint.y
    return { x: rotatedX, y: rotatedY }
  },
  sharedPointLinesAngle({
    sharedPoint,
    oldLinePoint,
    newLinePoint,
  }: {
    sharedPoint: Point
    oldLinePoint: Point
    newLinePoint: Point
  }) {
    // return angle between line from sharedPoint to oldLinePoint and
    // line from sharedPoint to newLinePoint
    const numerator =
      oldLinePoint.y * (sharedPoint.x - newLinePoint.x) +
      sharedPoint.y * (newLinePoint.x - oldLinePoint.x) +
      newLinePoint.y * (oldLinePoint.x - sharedPoint.x)
    const denominator =
      (oldLinePoint.x - sharedPoint.x) * (sharedPoint.x - newLinePoint.x) +
      (oldLinePoint.y - sharedPoint.y) * (sharedPoint.y - newLinePoint.y)
    const ratio = numerator / denominator

    return Math.atan(ratio)
  },

  twoSegmentLinesIntersection(p1: Point, p2: Point, p3: Point, p4: Point) {
    // line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
    // Determine the intersection point of two line segments
    // Return FALSE if the lines don't intersect

    // Check if none of the lines are of length 0
    if ((p1.x === p2.x && p1.y === p2.y) || (p3.x === p4.x && p3.y === p4.y)) {
      return false
    }

    const denominator = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y)

    // Lines are parallel
    if (denominator === 0) {
      return false
    }

    const ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denominator
    const ub = ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
      return false
    }

    // Return a object with the x and y coordinates of the intersection
    const x = p1.x + ua * (p2.x - p1.x)
    const y = p1.y + ua * (p2.y - p1.y)

    return { x, y }
  },

  // https://stackoverflow.com/a/60368757
  checkLineIntersection(line1_p1: Point, line1_p2: Point, line2_p1: Point, line2_p2: Point) {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    let a, b
    const result: { x: number; y: number; onLine1: boolean; onLine2: boolean } = {
      x: 0,
      y: 0,
      onLine1: false,
      onLine2: false,
    }
    const denominator =
      (line2_p2.y - line2_p1.y) * (line1_p2.x - line1_p1.x) - (line2_p2.x - line2_p1.x) * (line1_p2.y - line1_p1.y)
    if (denominator === 0) {
      return false
    }

    a = line1_p1.y - line2_p1.y
    b = line1_p1.x - line2_p1.x
    const numerator1 = (line2_p2.x - line2_p1.x) * a - (line2_p2.y - line2_p1.y) * b
    const numerator2 = (line1_p2.x - line1_p1.x) * a - (line1_p2.y - line1_p1.y) * b
    a = numerator1 / denominator
    b = numerator2 / denominator

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = line1_p1.x + a * (line1_p2.x - line1_p1.x)
    result.y = line1_p1.y + a * (line1_p2.y - line1_p1.y)

    // if line1 is a segment and line2 is infinite, they intersect if:
    if (a > 0 && a < 1) {
      result.onLine1 = true
    }
    // if line2 is a segment and line1 is infinite, they intersect if:
    if (b > 0 && b < 1) {
      result.onLine2 = true
    }
    // if line1 and line2 are segments, they intersect if both of the above are true
    return result
  },

  // ray is considered line1, segment is considered line2
  getRayToLineSegmentIntersection(rayOrigin: Point, rayEnd: Point, segmentStart: Point, segmentEnd: Point) {
    const lineIntersection = this.checkLineIntersection(rayOrigin, rayEnd, segmentStart, segmentEnd)

    if (
      !lineIntersection ||
      lineIntersection.onLine1 ||
      this.dotProduct(
        this.subtractPoints(rayOrigin, lineIntersection as Point),
        this.subtractPoints(rayOrigin, rayEnd)
      ) <= 0
    )
      return false
    return { ...lineIntersection, x: Math.round(lineIntersection.x), y: Math.round(lineIntersection.y) } // sometimes edge value can have crazy precision instead of `0` something like `5.684341886080802e-14` which is basically 0.00000000000005684341886080802 we should round it
  },

  getMiddlePointOnLineSegment(P1: Point, P2: Point) {
    const x = (P1.x - P2.x) / 2 + P2.x
    const y = (P1.y - P2.y) / 2 + P2.y
    return { x, y }
  },

  normalizeVector(v: Point) {
    const length = Math.sqrt(v.x * v.x + v.y * v.y)
    return { x: v.x / length, y: v.y / length }
  },

  multiplyVectorByScalar(v: Point, scalar: number) {
    return { x: v.x * scalar, y: v.y * scalar }
  },

  addPoints(P1: Point, P2: Point) {
    return { x: P1.x + P2.x, y: P1.y + P2.y }
  },

  subtractPoints(P1: Point, P2: Point): Point {
    return { x: P1.x - P2.x, y: P1.y - P2.y }
  },

  isSamePoint(P1: Point, P2: Point, margin = { x: 0, y: 0 }) {
    return P1.x - margin.x <= P2.x && P2.x <= P1.x + margin.x && P1.y - margin.y <= P2.y && P2.y <= P1.y + margin.y
  },
}

export default math
