import { VWapCalculation } from '../indicator-calculation/vwap'
import PaneModel from '../models/pane'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, VWAPConfig } from './configs/vwap'
import Overlay from './overlay'

class VWAP extends Overlay<Attrs> {
  static config = VWAPConfig
  declare vwapCalculation: VWapCalculation

  static getNumOfBarsBuffer() {
    return 1
  }

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.attrs.period = 5
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    if (!this.isRendered()) {
      return
    }

    const { leftOffset, width } = this.model.chart()
    context.translate(0.5, 0.5)
    context.beginPath()
    context.set('lineWidth', 1)
    context.set('strokeStyle', this.attrs.color)

    if (this.isComputeNecessary()) {
      this.vwapCalculation = new VWapCalculation({
        quote: this.data,
        options: { period: this.attrs.period },
      })

      this.vwapCalculation.calculate()
    }

    drawInVisibleArea({
      leftOffset,
      width,
      quote: this.data,
      paneModel: this.model,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.vwapCalculation.calculatedValues.vwap[i])))
      },
    })

    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: VWAPConfig.label,
      inputs: VWAPConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {},
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'color':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  isRendered() {
    return this.model.chart().quote().isIntraday
  }

  isRenderedOverlaysLabel() {
    return this.isRendered()
  }

  toString() {
    return VWAPConfig.shortLabel
  }
}

VWAP.prototype.defaults = { color: '#9467bd' }

export default VWAP
