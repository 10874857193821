import Spine from '@finviz/spine'
import * as React from 'react'

export function useModelRef<Model extends Spine.Model>(model: Model | null) {
  const modelRef = React.useRef(model)

  React.useEffect(() => {
    modelRef.current = model
  }, [model])

  return modelRef
}
