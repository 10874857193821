import {
  Button,
  ButtonGroup,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogStateReturn,
  useDialogState,
} from '@finviz/website'
import debounce from 'lodash.debounce'
import * as React from 'react'

import { ObjectHash } from '../../types/shared'
import CanvasElement from '../canvas/element'
import Pane from '../models/pane'
import { BackgroundInput } from './background'
import { BorderInput } from './border'
import { CheckBox } from './checkbox'
import { FontInput } from './font'
import { LineInput } from './line'
import { MultilineStringInput } from './multiline_string'
import { NumberInput } from './number'
import { StringInput } from './string'
import { Trend } from './trend'
import { Visibility } from './visibility'

function getInputForType(type: string) {
  switch (type) {
    case 'number':
      return NumberInput
    case 'string':
      return StringInput
    case 'text':
      return StringInput
    case 'background':
      return BackgroundInput
    case 'border':
      return BorderInput
    case 'line':
      return LineInput
    case 'multiline_string':
      return MultilineStringInput
    case 'font':
      return FontInput
    case 'checkbox':
      return CheckBox
    case 'trend':
      return Trend
    case 'visibility':
      return Visibility
    default:
      return () => <div>ERROR: Unknown input type</div>
  }
}

const TAB_BUTTON_COMMON_PROPS = { type: 'button' as const, className: 'flex-1', contentClass: 'text-center' }

type OnSubmitType = ((value: ObjectHash) => void) | null
type OnDismissType = (() => void) | null

export interface ElementStyleDialogProps<ElementType extends CanvasElement = CanvasElement> {
  state: DialogStateReturn
  element: ElementType
  onSubmit: ((values: ElementType['attrs']) => void) | null
  onDismiss: (() => void) | null
  onHide: () => void
}

export type StyleDialogOpener<ElementType extends CanvasElement> = (
  element: ElementType,
  onSubmit: ElementStyleDialogProps<ElementType>['onSubmit'],
  onDismiss: ElementStyleDialogProps<ElementType>['onDismiss']
) => void

function getValuesFromFormState(formState: ObjectHash) {
  return formState.reduce((acc: ObjectHash, cur: ObjectHash) => {
    acc[cur.name] = cur.value
    return acc
  }, {} as ObjectHash)
}

enum DialogTabs {
  Style,
  Visibility,
}

export function ElementStyleDialog(props: ElementStyleDialogProps) {
  const config = props.element.getModalConfig()
  const initialFormStateRef = React.useRef<ObjectHash<string>[]>(JSON.parse(JSON.stringify(config.inputs)))
  const [formState, setFormState] = React.useState<ObjectHash<string>[]>(config.inputs)
  const [selectedTab, setSelectedTab] = React.useState(DialogTabs.Style)
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<string | false>(false) // If string, the value is used as title for disabled button.

  const isDrawingElement = props.element.getIsDrawing()

  const handleFullyOpen = React.useCallback(() => {
    if (props.element instanceof CanvasElement && props.element.model instanceof Pane) {
      props.element.setIsSelected(true)
      props.element.model.updateAttribute('selection', props.element)
      props.element.setIsEditInProgress(true)
    }
  }, [props.element])

  const handleFullyClosed = React.useCallback(() => {
    if (props.element instanceof CanvasElement) {
      props.element.setIsEditInProgress(false)
    }
    props.onHide()
  }, [props])

  const handleValuesChange = React.useCallback(
    ({
      values,
      shouldSubmit = false,
      shouldDismiss = false,
    }: {
      values: ObjectHash
      shouldSubmit?: boolean
      shouldDismiss?: boolean
    }) => {
      const hasOnSubmit = typeof props.onSubmit === 'function'
      const hasOnDismiss = typeof props.onDismiss === 'function'

      props.element.set(values)
      if (shouldSubmit && hasOnSubmit) {
        props.onSubmit!(values)
      } else if (shouldDismiss && hasOnDismiss) {
        props.onDismiss!()
      }

      if (shouldSubmit || shouldDismiss) {
        props.state.hide()
      }
    },
    [props]
  )

  const handleValuesChangeDebounceRef = React.useRef(
    debounce(
      (newFormState) => {
        const values = getValuesFromFormState(newFormState)
        handleValuesChange({ values })
      },
      50,
      { maxWait: 100 }
    )
  )

  React.useEffect(
    () => () => {
      handleValuesChangeDebounceRef.current.cancel()
    },
    []
  )

  const onSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault()
      if (ev.currentTarget.checkValidity()) {
        const values = getValuesFromFormState(formState)
        handleValuesChange({ values, shouldSubmit: true })
      }
    },
    [formState, handleValuesChange]
  )

  const handleCloseDialog = React.useCallback(() => {
    const values = getValuesFromFormState(initialFormStateRef.current)
    handleValuesChange({ values, shouldDismiss: true })

    // Ariakit hideOnEscape expects a bool as return value
    return true
  }, [handleValuesChange])

  const getShouldRenderInputForTab = (inputType: string) => {
    if (selectedTab === DialogTabs.Visibility && inputType === 'visibility') return true
    if (selectedTab !== DialogTabs.Visibility && inputType !== 'visibility') return true
    return false
  }

  return (
    <Dialog
      isDraggable
      modal={false}
      className="w-96"
      data-testid="charts-dialog"
      state={props.state}
      aria-label={config.title ?? 'Edit style'}
      backdrop={<div onMouseDown={handleCloseDialog} />}
      onFullyOpen={handleFullyOpen}
      onFullyClosed={handleFullyClosed}
      hideOnEsc={handleCloseDialog}
    >
      <DialogHeader onCloseClick={handleCloseDialog}>{config.title}</DialogHeader>
      {formState.length > 0 ? (
        <form onSubmit={onSubmit} className="flex flex-col overflow-hidden">
          <DialogBody className="space-y-4">
            {isDrawingElement && (
              <ButtonGroup hasDivider={false} className="w-full">
                <Button
                  {...TAB_BUTTON_COMMON_PROPS}
                  active={selectedTab === DialogTabs.Style}
                  onClick={() => setSelectedTab(DialogTabs.Style)}
                  data-testid="dialog-tab-button-style"
                >
                  Style
                </Button>
                <Button
                  {...TAB_BUTTON_COMMON_PROPS}
                  active={selectedTab === DialogTabs.Visibility}
                  onClick={() => setSelectedTab(DialogTabs.Visibility)}
                  data-testid="dialog-tab-button-visibility"
                >
                  Visibility
                </Button>
              </ButtonGroup>
            )}

            {formState.map((input) => {
              const InputComponent = getInputForType(input.type) as React.FC<{
                onChange: (value: string) => void
                handleDisableSubmit?: typeof setIsSubmitDisabled
              }>
              return (
                getShouldRenderInputForTab(input.type) && (
                  <InputComponent
                    key={input.name}
                    {...input}
                    onChange={(value) => {
                      setFormState((prevState) => {
                        const newFormState = prevState.map((stateInput) => {
                          if (stateInput.name === input.name) {
                            return { ...stateInput, value }
                          }
                          return stateInput
                        })
                        handleValuesChangeDebounceRef.current(newFormState)
                        return newFormState
                      })
                    }}
                    handleDisableSubmit={setIsSubmitDisabled}
                  />
                )
              )
            })}
          </DialogBody>
          <DialogFooter>
            <Button type="button" onClick={handleCloseDialog} data-testid="dialog-button-cancel">
              Close
            </Button>
            <Button
              theme="blue"
              type="submit"
              data-testid="dialog-button-confirm"
              disabled={!!isSubmitDisabled}
              title={isSubmitDisabled || 'Submit'}
            >
              Save
            </Button>
          </DialogFooter>
        </form>
      ) : (
        <>
          <DialogBody className="text-sm">This indicator doesn't offer any configuration options.</DialogBody>
          <DialogFooter>
            <Button type="button" onClick={handleCloseDialog} data-testid="dialog-button-cancel">
              Close
            </Button>
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}

export const ElementStyleDialogContext = React.createContext<StyleDialogOpener<any>>(() => {})

export function withElementStyleDialogState<P>(Component: React.ComponentType<P>) {
  return function WrappedComponent(props: P) {
    const context = React.useContext(ElementStyleDialogContext)

    return <Component {...props} openElementStyleDialog={context} />
  }
}

export function ElementStyleDialogWrapper(props: { children: React.ReactNode }) {
  const [element, setElement] = React.useState<CanvasElement | null>(null)
  const [onSubmit, setOnSubmit] = React.useState<OnSubmitType>(null)
  const [onDismiss, setOnDismiss] = React.useState<OnDismissType>(null)
  const dialog = useDialogState()

  const openDialog = (element: CanvasElement, onSubmit?: OnSubmitType, onDismiss?: OnDismissType) => {
    if (onSubmit) {
      setOnSubmit(() => onSubmit)
    }
    if (onDismiss) {
      setOnDismiss(() => onDismiss)
    }

    setElement(element)
    dialog.show()
  }

  const onHide = () => {
    setElement(null)
    setOnSubmit(null)
    setOnDismiss(null)
  }

  return (
    <ElementStyleDialogContext.Provider value={openDialog}>
      {props.children}
      {element && (
        <ElementStyleDialog
          state={dialog}
          element={element}
          onSubmit={onSubmit}
          onDismiss={onDismiss}
          onHide={onHide}
        />
      )}
    </ElementStyleDialogContext.Provider>
  )
}
