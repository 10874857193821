import { SARCalculation } from '../indicator-calculation/sar'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, SARConfig } from './configs/sar'
import Overlay from './overlay'

const RECTANGLE_WIDTH = 3
const RECTANGLE_HALF_WIDTH = 3 / 2

class SAR extends Overlay<Attrs> {
  static config = SARConfig
  declare sarCalculation: SARCalculation

  static getNumOfBarsBuffer() {
    return 0
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    if (typeof this.attrs.period === 'string' && (this.attrs.step === undefined || this.attrs.maxStep === undefined)) {
      const [step = 0, maxStep = 0] = this.attrs.period.split(',').map((x) => parseFloat(x))
      this.attrs.step = step
      this.attrs.maxStep = maxStep
    }
    this.trigger('change')
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()
    const { step, maxStep } = this.attrs

    if (this.isComputeNecessary()) {
      this.sarCalculation = new SARCalculation({
        quote: this.data,
        options: { step, maxStep },
      })

      this.sarCalculation.calculate()
    }

    drawInVisibleArea({
      leftOffset,
      width,
      quote: this.data,
      paneModel: this.model,
      drawBarCallback: (i, x) => {
        const rectX = Math.round(x - RECTANGLE_HALF_WIDTH)
        const rectY = Math.round(this.fy(this.sarCalculation.calculatedValues.sar[i]) - RECTANGLE_HALF_WIDTH)
        context.set(
          'fillStyle',
          this.sarCalculation.calculationSupportValues.isLong[i] ? this.attrs.risingColor : this.attrs.fallingColor
        )
        context.fillRect(rectX, rectY, RECTANGLE_WIDTH, RECTANGLE_WIDTH)
      },
    })
  }

  getModalConfig() {
    const options = {
      step: {
        type: 'number',
        step: 0.01,
        min: 0.01,
        max: 999,
        label: 'Step',
        name: 'step',
        value: this.attrs.step ?? 0.02,
        required: true,
      },
      maxStep: {
        type: 'number',
        step: 0.01,
        min: 0.01,
        max: 999,
        label: 'Maximum Step',
        name: 'maxStep',
        value: this.attrs.maxStep ?? 0.2,
        required: true,
      },
      risingColor: {
        type: 'color',
        label: 'Rising Color',
        name: 'risingColor',
        value: this.attrs.risingColor ?? this.getFreeColor(),
        customizable: false,
      },
      fallingColor: {
        type: 'color',
        label: 'Falling Color',
        name: 'fallingColor',
        value: this.attrs.fallingColor ?? this.getFreeColor(),
        customizable: false,
      },
    }

    return {
      title: SARConfig.label,
      inputs: SARConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        step: `${options.step.label} must be a number between ${options.step.min} and ${options.step.max}`,
        maxStep: `${options.maxStep.label} must be a number between ${options.maxStep.min} and ${options.maxStep.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'step':
      case 'maxStep': {
        return this.getIsNumberInputValid({ key, integerOnly: false })
      }
      case 'risingColor':
      case 'fallingColor':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.risingColor
  }
}

SAR.prototype.defaults = {
  risingColor: '#69c1ea',
  fallingColor: '#d386df',
}

export default SAR
