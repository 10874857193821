import { CanvasElementType } from '../constants/common'
import arrow from './arrow'
import brush_v1 from './brush/brush_v1'
import callout_v1 from './callout/callout_v1'
import curve from './curve'
import element from './element'
import elliottWaves from './elliott_waves/elliott_waves'
import ellipse from './ellipse'
import ellipse_v1 from './ellipse/ellipse_v1'
import fibonacci from './fibonacci_retracements'
import horizontalLine from './horizontal_line'
import line from './line'
import measure from './measure'
import pitchfork_v1 from './pitchfork/pitchfork_v1'
import polygon from './polygon'
import position from './position'
import rectangle from './rectangle'
import rotatedRectangle from './rotated_rectangle'
import rotatedRectangle_v1 from './rotated_rectangle/rotated_rectangle_v1'
import scaledLine from './scaled_line'
import scaledText from './scaled_text'
import symbolElement from './symbol'
import text from './text'
import triangle from './triangle'
import verticalLine from './vertical_line'
import xabcd from './xabcd'

export const drawingToolsByType = Object.freeze({
  [CanvasElementType.element]: element,
  [CanvasElementType.arrow]: arrow,
  [CanvasElementType.brushV1]: brush_v1,
  [CanvasElementType.calloutV1]: callout_v1,
  [CanvasElementType.curve]: curve,
  [CanvasElementType.elliottWaves]: elliottWaves,
  [CanvasElementType.ellipse]: ellipse,
  [CanvasElementType.ellipseV1]: ellipse_v1,
  [CanvasElementType.fibonacciRetracements]: fibonacci,
  [CanvasElementType.horizontalLine]: horizontalLine,
  [CanvasElementType.line]: line,
  [CanvasElementType.measure]: measure,
  [CanvasElementType.pitchforkV1]: pitchfork_v1,
  [CanvasElementType.polygon]: polygon,
  [CanvasElementType.position]: position,
  [CanvasElementType.rectangle]: rectangle,
  [CanvasElementType.rotatedRectangle]: rotatedRectangle,
  [CanvasElementType.rotatedRectangleV1]: rotatedRectangle_v1,
  [CanvasElementType.scaledLine]: scaledLine,
  [CanvasElementType.scaledText]: scaledText,
  [CanvasElementType.symbol]: symbolElement,
  [CanvasElementType.text]: text,
  [CanvasElementType.triangle]: triangle,
  [CanvasElementType.verticalLine]: verticalLine,
  [CanvasElementType.xabcd]: xabcd,
})
