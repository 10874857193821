import { EmptyObject, ObjectHash } from '../../types/shared'

export function getRoundedString({ number, numOfDecimalPlaces }: { number: number; numOfDecimalPlaces: number }) {
  const precision = Math.pow(10, numOfDecimalPlaces)
  return String(Math.round(number * precision) / precision)
}

export function getIsSSr() {
  return typeof process !== 'undefined' && !!process?.env.IS_OFFSCREEN_RENDERER
}

export function isValidHexColor(color: string) {
  const regex = /^#([\da-f]{3}){1,2}$|^#([\da-f]{4}){1,2}$/i
  return !!color && regex.test(color)
}

export function getParsedIntegersFromPeriodString(periodString: string) {
  const periodParts = periodString.split(',')
  return periodParts.map((part) => Number.parseInt(part, 10))
}

export function captureException(exception: Error, captureContext?: { extra: ObjectHash }) {
  if (process.env.NODE_ENV === 'development' || process.env.IS_E2E_TESTING) {
    console.error(exception)
  } else if (process.env.IS_OFFSCREEN_RENDERER) {
    import('@sentry/node').then((client) => {
      client.captureException(exception, captureContext)
    })
  } else {
    window.Sentry.captureException(exception, captureContext)
  }
}

export function isPositiveFloat(value: string | number) {
  const parsedNumber = Number.parseFloat(`${value}`)
  return !Number.isNaN(parsedNumber) && isFinite(parsedNumber) && parsedNumber > 0
}

export function isPositiveInteger(value: string | number) {
  const string = `${value}`
  return isPositiveFloat(value) && Number.parseInt(string, 10) === Number.parseFloat(string)
}

export function isInRange({
  value,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
}: {
  value: string | number
  min?: number
  max?: number
}) {
  const parsedNumber = Number.parseFloat(`${value}`)
  return !Number.isNaN(parsedNumber) && parsedNumber >= min && parsedNumber <= max
}

export function tryJsonParse<Result extends ObjectHash = EmptyObject>(json: string | null = '') {
  try {
    return JSON.parse(json ?? '') as Result
  } catch {
    return null
  }
}

export function getValueInRange({ value, min, max }: { value: number; min: number; max: number }) {
  return Math.min(Math.max(value, min), max)
}

export function isPrimaryClick(e: React.Touch | React.TouchEvent | React.MouseEvent) {
  // cypress trigger mouseevent has button: undefined if button option isn't explicitly specified
  // as cy.get('.target').trigger('mousedown', { button: 0 })
  if (e.hasOwnProperty('button') && (e as unknown as MouseEvent).button !== undefined) {
    return (e as unknown as MouseEvent).button === 0
  } else {
    return true
  }
}

// this function is used to fix the issue with circular reference when stringifying an object using JSON.stringify
export function getJSONReplacerFunc() {
  const visited = new WeakSet()
  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (visited.has(value)) {
        return
      }
      visited.add(value)
    }
    return value
  }
}

// This is a duplicate from Website repo which is accessible on window.FLibs but this doesn't work on SSR charts
export const formatDateToStringUS = (date: Date, options?: Intl.DateTimeFormatOptions | undefined) =>
  date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    ...options,
  })

// randomUUID source: https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid/2117523#2117523
export function randomUUID() {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (substring) => {
    const c = Number(substring)
    return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  })
}

export function getUuid() {
  return window.crypto?.randomUUID?.() ?? randomUUID()
}

// duplicate from Website/js/app/header/utils.ts , will be refactored in next PR
export function getEarningsDateSuffix(date: Date) {
  const hours = date.getHours()

  if (hours <= 9) {
    return 'BMO'
  } else if (hours >= 16) {
    return 'AMC'
  }

  return ''
}

export function isValidTickerString(ticker: string) {
  const regex = /^@?[a-z0-9](?:[a-z0-9]*[-_]?[a-z0-9]+)?$/
  return regex.test(ticker.toLowerCase())
}
