import { convertColorToHEX } from '@finviz/website'
import * as React from 'react'

import { ColorRangeRowInput } from './color_range_row'

interface Value {
  color: string
  width: string
}

interface BorderInputProps {
  label: string
  value: Value
  min: number
  max: number
  onChange: (value: Value) => void
}

export function BorderInput({ label = 'Border', value, onChange, min, max }: BorderInputProps) {
  return (
    <ColorRangeRowInput
      label={label}
      colorValue={convertColorToHEX(value.color)}
      colorTestId="chart-modal-border-color"
      rangeValue={value.width}
      rangeProps={{ min: min ?? 1, max: max ?? 5, step: 1 }}
      rangeLabel="Width"
      rangeTestId="chart-modal-border-range"
      onChange={(color: string, width: string) => onChange({ color, width })}
    />
  )
}
