import { format } from 'date-fns'

import { ObjectHash } from '../../types/shared'
import { TIMEFRAME } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import { dateFromUnixTimestamp } from '../utils'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import Indicator from './indicator'

type ValuesType = Array<{
  isBeforeData: boolean
  timestamp: number
  value: number | null
  sourceIndex: number
}>

class FinancialIndicator<Attrs extends ObjectHash = ObjectHash> extends Indicator<Attrs> {
  values: ValuesType[] = []
  applyMinMaxPadding = true

  getComputedValuesAtDateIndex(_: number): ValuesType {
    throw Error('Implement getComputedValuesAtDateIndex method')
  }

  compute() {
    if (!isNaN(this.contentWidth) && this.isComputeNecessary()) {
      this.values = []

      if (this.data.financialAttachmentsData) {
        for (let i = 0; i < this.data.date.length; i++) {
          const computedValues = this.getComputedValuesAtDateIndex(i)
          this.values[i] = computedValues.length > 0 ? computedValues : (this.values[i - 1] ?? [])
        }
      }
    }

    this.lastValue = this.getLastValue()
    const { min, max } = this.computeVisibleMinMax()
    this.min = min
    this.max = max
  }

  getLastValue() {
    return this.getValueAtDataIndex(this.values.length - 1)
  }

  computeVisibleMinMax() {
    return super.computeVisibleMinMax(this.values.map((values) => values[values.length - 1]?.value ?? Number.NaN))
  }

  getValueAtDataIndex(dataIndex: number) {
    const vals = this.values[dataIndex]
    const val = vals?.[(vals?.length ?? 0) - 1] ?? null
    return val?.value
  }

  getValueLabelsAtIndex(index: number) {
    const shouldShowDate = [TIMEFRAME.i1, TIMEFRAME.i2, TIMEFRAME.i3].includes(this.data.timeframe)
    const dataIndex = this.data.barToDataIndex[index]
    const values = this.values[dataIndex]
    if (!values) {
      return []
    }

    return values.map(({ timestamp, value, isBeforeData }) => {
      const valueLabel = this.getValueLabel(value)

      return {
        color: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!,
        text:
          valueLabel !== null
            ? `${valueLabel} ${
                values.length >= 2 || isBeforeData || shouldShowDate
                  ? `(${format(dateFromUnixTimestamp(timestamp), 'MMM dd')})`
                  : ''
              }`
            : null,
      }
    })
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    const translate = getTranslate({ context, xOffset: 0.5, yOffset: 0.5 })
    translate.do()
    context.set('strokeStyle', this.getChartLayoutSettings().IndicatorSettings.general.Colors.line)
    context.beginPath()
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        const val = this.getValueAtDataIndex(i)
        context.lineTo(x, Math.round(this.fy(val!)))
      },
    })
    context.stroke()
    translate.undo()
  }

  formatAxis(value: number) {
    return this.getValueLabel(value) ?? value.toString()
  }
}

export default FinancialIndicator
