import { ADXCalculation } from '../indicator-calculation/adx'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { ADXConfig, Attrs } from './configs/adx'
import Indicator from './indicator'

class ADX extends Indicator<Attrs> {
  static config = ADXConfig
  declare adxCalculation: ADXCalculation

  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.adxCalculation = new ADXCalculation({
        quote: this.data,
        options: { period: this.period },
      })

      this.adxCalculation.calculate()
      this.lastValue = this.adxCalculation.calculatedValues.adx.last() ?? null
    }
    const { min, max } =
      this.adxCalculation.calculatedValues.pdi.length > 0 ||
      this.adxCalculation.calculatedValues.mdi.length > 0 ||
      this.adxCalculation.calculatedValues.adx.length > 0
        ? this.computeVisibleMinMax(
            this.adxCalculation.calculatedValues.pdi,
            this.adxCalculation.calculatedValues.mdi,
            this.adxCalculation.calculatedValues.adx
          )
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      pdi: '#2eb12e',
      mdi: '#d43737',
      adx: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line,
    }
  }

  getValueLabelsAtIndex(index: number) {
    const lineColors = this.getLineColors()
    const dataIndex = this.data.barToDataIndex[index]
    return [
      { color: lineColors.pdi, text: this.getValueLabel(this.adxCalculation.calculatedValues.pdi[dataIndex]) },
      { color: lineColors.mdi, text: this.getValueLabel(this.adxCalculation.calculatedValues.mdi[dataIndex]) },
      { color: lineColors.adx, text: this.getValueLabel(this.adxCalculation.calculatedValues.adx[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0) return

    const lineColors = this.getLineColors()

    context.translate(0.5, 0.5)
    context.set('strokeStyle', lineColors.pdi)
    context.beginPath()

    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period,
    }
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.adxCalculation.calculatedValues.pdi[i])))
      },
    })

    context.stroke()

    context.set('strokeStyle', lineColors.mdi)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.adxCalculation.calculatedValues.mdi[i])))
      },
    })
    context.stroke()
    context.set('strokeStyle', lineColors.adx)
    context.beginPath()

    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.adxCalculation.calculatedValues.adx[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: ADXConfig.label,
      inputs: ADXConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default ADX
