import { Instrument } from '../../types/shared'
import ChartLayoutModel from './chart_layout'
import Element from './element'

export const handleRemoveIndicatorPane = ({
  paneIndex,
  chartLayoutModel,
}: {
  paneIndex: number
  chartLayoutModel: ChartLayoutModel
}) => {
  const charts = chartLayoutModel.getAllCharts()
  charts.forEach((chart) => chart.getAllPanes()[paneIndex].destroyCascade())
}

export function getNormalizedZIndex({ index, isBelowZero }: { index: number; isBelowZero: boolean }) {
  return (index + 1) * (isBelowZero ? -1 : 1)
}

export function rebuildElementZIndexes({
  elementsArray,
  isBelowZero,
}: {
  elementsArray: Element[]
  isBelowZero: boolean
}) {
  const elements = isBelowZero ? [...elementsArray].reverse() : elementsArray
  elements.forEach((element, index) => {
    const newZIndex = getNormalizedZIndex({ index, isBelowZero })
    if (newZIndex !== element.zIndex) {
      element.updateAttributes({ zIndex: newZIndex })
    }
  })
}

export function changeElementZIndexes({
  elementsArray,
  addElement,
  oldZIndex,
  newZIndex,
  isBelowZero = false,
}: {
  elementsArray: Element[]
  addElement: Element | undefined
  oldZIndex: number
  newZIndex: number
  isBelowZero?: boolean
}) {
  const array = isBelowZero ? [...elementsArray].reverse() : elementsArray
  const elements =
    elementsArray.length === 0 && addElement
      ? [...array, addElement]
      : array
          .filter((_, index) => getNormalizedZIndex({ index, isBelowZero }) !== oldZIndex)
          .flatMap((el, index) =>
            getNormalizedZIndex({ index, isBelowZero }) === newZIndex && addElement ? [addElement, el] : el
          )

  // If there are only 2 drawings and we move a drawing from index 1 to index 2 to have to push it to the array because it's the last element and it didn't get into the array in the flatMap above
  if (elements.length < Math.abs(newZIndex) && addElement) {
    elements.push(addElement)
  }

  rebuildElementZIndexes({ elementsArray: isBelowZero ? [...elements].reverse() : elements, isBelowZero })
}

export function getRawTickerForInstrument(instrument: Instrument, ticker: string) {
  return `${
    [Instrument.Crypto, Instrument.Forex, Instrument.Futures].includes(instrument) && !ticker.includes('@') ? '@' : ''
  }${ticker}`
}
