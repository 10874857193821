import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartLayout from '../../models/chart_layout'
import { AutoSaveState } from '../../models/constants'

interface LoadLatestDataButtonProps {
  chartLayoutModel: ChartLayout
}

export function AutoSaveStateIndicator({ chartLayoutModel }: LoadLatestDataButtonProps) {
  const drawingsInternalStoreUnwatched = React.useMemo(
    () => chartLayoutModel.drawingsInternalStore(),
    [chartLayoutModel]
  )
  const { autoSaveState } = useModelState(drawingsInternalStoreUnwatched, { watchProperties: ['autoSaveState'] })!
  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    if (autoSaveState === AutoSaveState.Off) {
      return
    }

    let setVisibilityTimeout: number | null = null
    const newIsVisible = autoSaveState !== AutoSaveState.Saved

    setVisibilityTimeout = window.setTimeout(
      () => {
        setIsVisible(newIsVisible)
      },
      newIsVisible ? 0 : 3000
    )

    const checkIfNeededPreventLeaving = (event: BeforeUnloadEvent) => {
      if (autoSaveState === AutoSaveState.Saved) {
        return undefined
      }

      const message = `Your latest changes haven't been saved yet. If you leave now, your changes might be lost.`
      event.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', checkIfNeededPreventLeaving)

    return () => {
      window.removeEventListener('beforeunload', checkIfNeededPreventLeaving)
      if (setVisibilityTimeout) {
        clearTimeout(setVisibilityTimeout)
      }
    }
  }, [autoSaveState])

  if (!isVisible) {
    return null
  }

  // @todo - add proper indicator component here
  return null
}
