import Spine from '@finviz/spine'

import Chart from './chart'

class Overlay extends Spine.Model {
  static initClass(chartModel: typeof Chart) {
    this.configure('Overlay', 'overlay')
    this.belongsTo('chart', chartModel)
  }

  declare overlay: any
  declare chart: () => Chart
}

export default Overlay
