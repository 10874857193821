import { Dialog, DialogBody, DialogHeader, DialogStateReturn } from '@finviz/website'

import IdeasTabs from './IdeasTabs'

type IdeasDialogProps = {
  state: DialogStateReturn
}

export default function IdeasDialog({ state }: IdeasDialogProps) {
  return (
    <Dialog state={state} aria-label="Your ideas" data-testid="charts-ideas-modal">
      <DialogHeader>Your Ideas</DialogHeader>
      <DialogBody className="min-w-96 max-w-145 space-y-1 p-2" hasPadding={false}>
        <IdeasTabs />
      </DialogBody>
    </Dialog>
  )
}
