import { RendererChartBarProps } from '../../types/shared'
import { ChartElementType } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import utils from '../utils'
import { getBarWidthWithMarginByParts } from '../utils/chart'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { getIsSSr } from '../utils/helpers'
import Chart from './base_chart'

const WICK_WIDTH = 1

class CandleStick extends Chart {
  static type = ChartElementType.CandleStick
  static label = 'Candle'
  static iconName = 'candleBarBasic'

  getRenderChartBarProps({ index, chartSettingsColors, hasFillAndBorder }: RendererChartBarProps) {
    const { trendDown, trendUp, borderDown, borderUp, wickDown, wickUp } = chartSettingsColors
    const open = this.data.open[index]
    const close = this.data.close[index]
    const trendColor = close < open ? trendDown : trendUp
    const wickColor = close < open ? wickDown : wickUp
    return {
      open,
      close,
      high: this.data.high[index],
      low: this.data.low[index],
      barFillColor: trendColor,
      borderColor: close < open ? borderDown : borderUp,
      wickColor: hasFillAndBorder ? wickColor : trendColor,
    }
  }

  renderChart() {
    if (this.setupAxisTimeFrame !== this.data.timeframe) {
      return
    }
    const { ChartSettings } = this.getChartLayoutSettings()
    const Colors = this.getCandleLikeChartsRenderingColors()
    const { barFillWidth, barBorderWidth } = getBarWidthWithMarginByParts({
      chartLayout: this.model.chart_layout(),
      zoomFactor: this.model.zoomFactor,
    })
    const shouldRenderFillAndBorder =
      barFillWidth + barBorderWidth * 2 >= 2 &&
      !(this.type === ChartElementType.HollowCandleStick && barBorderWidth === 0)
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width,
      yOffset: ChartSettings.top.height,
    })
    const translateForWick = getTranslate({
      context: this.context,
      xOffset: WICK_WIDTH / 2,
      yOffset: 0,
    })

    translate.do()
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.paneModel,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, center: number) => {
        const { open, close, high, low, wickColor, borderColor, barFillColor } = this.getRenderChartBarProps({
          index: i,
          chartSettingsColors: Colors,
          hasFillAndBorder: shouldRenderFillAndBorder,
        })

        const candleBodyHigh = Math.round(this.fy(utils.max(open, close)))
        const candleBodyLow = Math.round(this.fy(utils.min(open, close)))
        const candleWickHigh = Math.round(this.fy(high))
        const candleWickLow = Math.round(this.fy(low))

        const candleBodyHeight = candleBodyLow - candleBodyHigh
        const candleBodyLeftX = center - Math.floor(barBorderWidth + barFillWidth / 2)
        const halfBorderWidth = barBorderWidth / 2

        // TODO: revisit for better approach, this solve issue with candlesticks
        // with even width are shifted 1px to the left and thus first candle is vertically sliced
        if (getIsSSr() && candleBodyLeftX + this.leftOffset < 0) {
          return
        }

        const isBodyHeightGreaterOrEqualBorders = candleBodyHeight >= barBorderWidth * 2
        const isWickLineCandleLessThanOnePixel = !shouldRenderFillAndBorder && candleWickLow - candleWickHigh < 1

        translateForWick.do()
        this.context.set('strokeStyle', wickColor)
        this.context.beginPath()
        this.context.moveTo(center, candleWickHigh)
        if (shouldRenderFillAndBorder) {
          this.context.lineTo(center, candleBodyHigh)
          this.context.moveTo(
            center,
            candleBodyHigh + (isBodyHeightGreaterOrEqualBorders ? candleBodyHeight : WICK_WIDTH)
          )
        }
        this.context.lineTo(center, isWickLineCandleLessThanOnePixel ? candleWickHigh + 1 : candleWickLow)
        this.context.stroke()
        translateForWick.undo()

        if (shouldRenderFillAndBorder) {
          const hasBorder = barBorderWidth > 0
          if (hasBorder) {
            this.context.set('strokeStyle', borderColor)
            if (isBodyHeightGreaterOrEqualBorders) {
              this.context.set('lineWidth', barBorderWidth)
              this.context.strokeRect(
                candleBodyLeftX + halfBorderWidth,
                candleBodyHigh + halfBorderWidth,
                barFillWidth + 2 * halfBorderWidth,
                candleBodyHeight - 2 * halfBorderWidth
              )
              this.context.set('lineWidth', WICK_WIDTH)
            } else {
              this.context.beginPath()
              this.context.moveTo(candleBodyLeftX, candleBodyHigh + WICK_WIDTH / 2)
              this.context.lineTo(candleBodyLeftX + barFillWidth + 2 * barBorderWidth, candleBodyHigh + WICK_WIDTH / 2)
              this.context.stroke()
            }
          }

          const fillHeight = candleBodyHeight - 2 * barBorderWidth
          const hasFill = fillHeight > 0
          if (hasFill || !hasBorder) {
            this.context.set('fillStyle', hasFill ? barFillColor : borderColor)
            this.context.fillRect(
              candleBodyLeftX + barBorderWidth,
              candleBodyHigh + barBorderWidth,
              barFillWidth,
              hasFill ? fillHeight : WICK_WIDTH
            )
          }
        }
      },
    })
    translate.undo()
  }

  toString() {
    return `Candle stick ${this.data.ticker}`
  }
}

export default CandleStick
