import { EMA2Config } from './configs/ema2'
import EMA from './ema'

class EMA2 extends EMA {
  static config = EMA2Config
}

EMA2.prototype.defaults = { color: '#ff6400' }

export default EMA2
