import Spine from '@finviz/spine'
import * as React from 'react'

import { DefaultSpineEvents } from '../../types/shared'
import ElementModule from '../canvas/element'
import IndicatorModule from '../indicators/indicator'
import { useForceUpdate } from '../utils/force-update'
import { getJSONReplacerFunc } from '../utils/helpers'

interface UseModelStateOptions<T> {
  watchProperties?: T extends null ? never[] : Array<keyof T>
  listenOnEvents?: string[]
}

const getIsSpineObject = (value: any) =>
  [Spine.Model, Spine.Module, Spine.Collection].some((spineObject) => value instanceof spineObject)

const getWatchedPropValue = <Model extends Spine.Model>(
  watchedProp: Model[keyof Model] | ElementModule | IndicatorModule | undefined,
  model: Model | null
) => {
  if (typeof watchedProp === 'function') {
    const value = watchedProp.call(model)

    if (value && !getIsSpineObject(value)) {
      throw Error('Watched prop should have a primitive value or a spine object')
    }

    if (typeof value?.all === 'function') {
      return value.all()
    }

    return value
  }

  if (typeof watchedProp === 'object') {
    const hasToObject = !!watchedProp && 'toObject' in watchedProp
    return JSON.stringify(hasToObject ? watchedProp.toObject() : watchedProp, getJSONReplacerFunc())
  }

  return watchedProp
}

const getWatchedProps = <Model extends Spine.Model>(model: Model | null, props?: Array<keyof Model>) =>
  props?.reduce(
    (acc, propKey) => ({
      ...acc,
      [propKey]: getWatchedPropValue(model?.[propKey], model),
    }),
    {} as Partial<Model>
  )

const getAreWatchedPropsEqual = <Value extends Spine.Model | string | undefined>(
  currentValue: Value,
  previousValue: Value
) => {
  if (currentValue instanceof Spine.Model) {
    return currentValue.eql(previousValue)
  }

  return currentValue === previousValue
}

const DEFAULT_SPINE_EVENTS = Object.values(DefaultSpineEvents)

export function useModelState<Model extends Spine.Model | null>(
  model: Model,
  options: UseModelStateOptions<Model> = {}
) {
  const { watchProperties = [], listenOnEvents = DEFAULT_SPINE_EVENTS } = options
  const stringifiedOptions = JSON.stringify(options)
  const { forceUpdate } = useForceUpdate()
  const watchedPropertiesRef = React.useRef(getWatchedProps(model, watchProperties))

  React.useEffect(() => {
    const handleModelChange = () => {
      const hasChange = watchProperties.some((propKey) => {
        const currentValue = getWatchedPropValue(model?.[propKey], model)
        const previousValue = watchedPropertiesRef.current?.[propKey]

        if (Array.isArray(currentValue)) {
          return (
            !Array.isArray(previousValue) ||
            currentValue.length !== previousValue.length ||
            currentValue.some((record, index) => !getAreWatchedPropsEqual(record, previousValue[index]))
          )
        }

        return !getAreWatchedPropsEqual(currentValue, previousValue)
      })

      if (hasChange) {
        watchedPropertiesRef.current = getWatchedProps(model, watchProperties)
        forceUpdate()
      }
    }

    const spineEvents = listenOnEvents.join(' ')

    model?.bind(spineEvents, handleModelChange)

    forceUpdate()

    return () => {
      model?.unbind(spineEvents, handleModelChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model?.cid, stringifiedOptions, forceUpdate])

  return model
}
