import * as dateFns from 'date-fns'

import { Instrument } from '../../types/shared'
import Text from '../canvas/text'
import { TIMEFRAME, TextBaseline } from '../constants/common'
import Chart from '../models/chart'
import Utils from '../utils'
import { getBarWidthWithMargin, round } from '../utils/chart'
import { getAreNoBarsVisible, getVisibleBarToRenderIndex } from '../utils/draw_in_visible_area'
import { Attrs, Calculation, CalculationType, PPConfig } from './configs/pp'
import Overlay from './overlay'

enum PivotPeriod {
  day,
  week,
  month,
  year,
}

type PivotPointType = {
  pivot: number
  res1: number
  res2: number
  res3: number
  sup1: number
  sup2: number
  sup3: number
}

const DEFAULT_PARAMETERS = {
  CalculationType: 'standard' as CalculationType,
  Color: '#FFA75F',
}
const FIB_LVL_1 = 0.382
const FIB_LVL_2 = 0.618
const DAY_SECONDS = 86400
const MINUTES_PER_DAY = 1440

class PivotPoints extends Overlay<Attrs> {
  static config = PPConfig

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (period) {
      this.attrs.calculationType = period as CalculationType
      this.trigger('change')
    }
    return this
  }

  getPeriodType() {
    switch (this.data.timeframe) {
      case TIMEFRAME.i30:
      case TIMEFRAME.h:
      case TIMEFRAME.h2:
        return PivotPeriod.week
      case TIMEFRAME.h4:
      case TIMEFRAME.d:
        return PivotPeriod.month
      case TIMEFRAME.w:
      case TIMEFRAME.m:
        return PivotPeriod.year
      default:
        return PivotPeriod.day
    }
  }

  getIsEnoughData(startIndex: number, endIndex: number, periodType: PivotPeriod) {
    let dayGap = 7 // one week for week period
    if (periodType === PivotPeriod.month) dayGap = 28 // shortest month
    if (periodType === PivotPeriod.year) dayGap = 365

    if (startIndex !== endIndex) {
      if (startIndex === 0) {
        const daysDiff = (this.data.date[endIndex] - this.data.date[startIndex]) / DAY_SECONDS
        // we need to somehow determine if we have data if it starts from index 0
        // and we can't easily check if day before is different (different month/week/year)
        // so we are at least checking if it is [dayGap] days between start and end date
        return daysDiff >= dayGap
      } else {
        const startDay = Utils.dateFromUnixTimestamp(this.data.date[startIndex])
        const beforeDay = Utils.dateFromUnixTimestamp(this.data.date[startIndex - 1])
        if (periodType === PivotPeriod.week) {
          return beforeDay.getDate() !== startDay.getDate()
        } else {
          return beforeDay.getMonth() !== startDay.getMonth()
        }
      }
    }
    return false
  }

  fx = (x: number) => {
    const lastIndex = this.data.close.length - 1
    const outsideBar = this.data.barIndex[lastIndex] + x - lastIndex

    return this.model.scale.x(this.data.barIndex[x] ?? outsideBar)
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    if (this.data.close.length === 0) return

    const chartModel = this.model.chart() as Chart
    const { leftOffset, width, zoomFactor } = chartModel
    const { left, right } = chartModel.getChartLayoutSettings().ChartSettings
    const chartWidth = width - left.width - right.width
    const barWidth = getBarWidthWithMargin({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })

    const firstBarToRender = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
    })
    const lastBarToRender = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
      chartWidth,
    })

    const areNoBarsVisible = getAreNoBarsVisible(firstBarToRender, lastBarToRender)
    if (areNoBarsVisible) return

    const text = (label: string, price: number, calculatedX: number) => {
      new Text(
        {
          x: calculatedX,
          y: this.fy(price) - 13,
          font: { size: 8, weight: '900' },
          textBaseline: TextBaseline.top,
          fillStyle: this.attrs.color,
          text: `${label} (${round({ data: this.data, num: price })})`,
        },
        this.model
      ).render(context)
    }

    const line = (fromX: number, toX: number, y: number) => {
      context.moveTo(fromX, this.fy(y))
      context.lineTo(toX, this.fy(y))
    }

    let startIndex = -1
    let endIndex = -1
    const isStock = this.data.instrument === Instrument.Stock
    const periodType = this.getPeriodType()
    let currentDate = new Date()
    let lastDate: Date | undefined

    context.translate(0.5, 0.5)
    context.set('strokeStyle', this.attrs.color)

    for (let index = firstBarToRender.index; index <= lastBarToRender.index; index++) {
      currentDate = Utils.dateFromUnixTimestamp(this.data.date[index])

      // check if new calculation/render is necessary
      switch (periodType) {
        case PivotPeriod.day: // only if it is a new day
          if (currentDate.getDate() === lastDate?.getDate()) {
            continue
          }
          break
        case PivotPeriod.week: // only if it is a new week
          if (lastDate && dateFns.isSameWeek(lastDate, currentDate, { weekStartsOn: 1 })) {
            continue
          }
          break
        case PivotPeriod.month: // only if it is a new month
          if (currentDate.getMonth() === lastDate?.getMonth()) {
            continue
          }
          break
        case PivotPeriod.year: // only if it is a new year
          if (currentDate.getFullYear() === lastDate?.getFullYear()) {
            continue
          }
          break
      }
      lastDate = currentDate

      // Find start/end indexes for the entire previous period
      switch (periodType) {
        case PivotPeriod.day:
          if (index > 0) {
            let previousTradingDay = new Date()
            for (let i = index - 1; i >= 0; i--) {
              previousTradingDay = Utils.dateFromUnixTimestamp(this.data.date[i])
              if (previousTradingDay.getDate() !== currentDate.getDate()) break
            }
            previousTradingDay.setHours(0, 0, 0, 0)
            const previousNYMidnightTimestamp = Utils.convertLocalToNyTime(previousTradingDay, false).getTime() / 1000
            const currentMidnight = new Date(currentDate)
            currentMidnight.setHours(0, 0, 0, 0)
            const currentNYMidnightTimestamp = Utils.convertLocalToNyTime(currentMidnight, false).getTime() / 1000

            startIndex = this.data.date.findIndex((ts) => ts >= previousNYMidnightTimestamp)
            endIndex = this.data.date.findIndex((ts) => ts > currentNYMidnightTimestamp)

            // check if we have data for entire day
            if (startIndex !== endIndex) {
              const diff = this.data.date[endIndex] - this.data.date[startIndex]
              const isNonStockFullDay = !isStock && diff >= DAY_SECONDS
              const isStockFullDay = isStock && previousTradingDay.getDate() !== currentDate.getDate()
              if (!isNonStockFullDay && !isStockFullDay) {
                startIndex = endIndex = Number.MIN_SAFE_INTEGER
              }
            }
          }
          break
        case PivotPeriod.week:
          const firstDayCurrentWeek = dateFns.startOfWeek(currentDate, { weekStartsOn: 1 })
          const firstDayCurrentWeekTimestamp = Utils.convertLocalToNyTime(firstDayCurrentWeek, false).getTime() / 1000
          const firstDayPreviousWeek = dateFns.sub(firstDayCurrentWeek, { weeks: 1 })
          const firstDayPreviousWeekTimestamp = Utils.convertLocalToNyTime(firstDayPreviousWeek, false).getTime() / 1000

          startIndex = this.data.date.findIndex((ts) => ts >= firstDayPreviousWeekTimestamp)
          endIndex = this.data.date.findIndex((ts) => ts > firstDayCurrentWeekTimestamp)

          if (!this.getIsEnoughData(startIndex, endIndex, periodType)) {
            startIndex = endIndex = Number.MIN_SAFE_INTEGER
          }
          break
        case PivotPeriod.month:
          const firstDayCurrentMonth = dateFns.startOfMonth(currentDate)
          const firstDayCurrentMonthTimestamp = Utils.convertLocalToNyTime(firstDayCurrentMonth, false).getTime() / 1000
          const firstDayPreviousMonth = dateFns.sub(firstDayCurrentMonth, { months: 1 })
          const firstDayPreviousMonthTimestamp =
            Utils.convertLocalToNyTime(firstDayPreviousMonth, false).getTime() / 1000

          startIndex = this.data.date.findIndex((ts) => ts >= firstDayPreviousMonthTimestamp)
          endIndex = this.data.date.findIndex((ts) => ts > firstDayCurrentMonthTimestamp)

          if (!this.getIsEnoughData(startIndex, endIndex, periodType)) {
            startIndex = endIndex = Number.MIN_SAFE_INTEGER
          }
          break
        case PivotPeriod.year:
          const firstDayCurrentYear = dateFns.startOfYear(currentDate)
          const firstDayCurrentYearTimestamp = Utils.convertLocalToNyTime(firstDayCurrentYear, false).getTime() / 1000
          const firstDayPreviousYear = dateFns.sub(firstDayCurrentYear, { years: 1 })
          const firstDayPreviousYearTimestamp = Utils.convertLocalToNyTime(firstDayPreviousYear, false).getTime() / 1000

          startIndex = this.data.date.findIndex((ts) => ts >= firstDayPreviousYearTimestamp)
          endIndex = this.data.date.findIndex((ts) => ts > firstDayCurrentYearTimestamp)

          if (!this.getIsEnoughData(startIndex, endIndex, periodType)) {
            startIndex = endIndex = Number.MIN_SAFE_INTEGER
          }
          break
      }

      // calculate pivot point if it was found
      if (!(startIndex !== -1 && endIndex !== -1 && startIndex < endIndex)) continue

      let pivot, sup1, sup2, sup3, res1, res2, res3
      const previousHigh = Math.max(...this.data.high.slice(startIndex, endIndex))
      const previousLow = Math.min(...this.data.low.slice(startIndex, endIndex))
      const previousClose = this.data.close[endIndex - 1] // endIndex is the beginning of the next period

      if (this.attrs.calculationType === 'standard') {
        pivot = (previousHigh + previousLow + previousClose) / 3
        sup1 = 2 * pivot - previousHigh
        sup2 = pivot - (previousHigh - previousLow)
        sup3 = previousLow - 2 * (previousHigh - pivot)
        res1 = 2 * pivot - previousLow
        res2 = pivot + (previousHigh - previousLow)
        res3 = previousHigh + 2 * (pivot - previousLow)
      } else {
        pivot = (previousHigh + previousLow + previousClose) / 3
        sup1 = pivot - (previousHigh - previousLow) * FIB_LVL_1
        sup2 = pivot - (previousHigh - previousLow) * FIB_LVL_2
        sup3 = pivot - (previousHigh - previousLow)
        res1 = pivot + (previousHigh - previousLow) * FIB_LVL_1
        res2 = pivot + (previousHigh - previousLow) * FIB_LVL_2
        res3 = pivot + (previousHigh - previousLow)
      }

      const pivotPoint: PivotPointType = { pivot, sup1, sup2, sup3, res1, res2, res3 }

      // prepare x coordinates for lines rendering
      let fromX = this.fx(index)
      let toX = this.fx(index)
      switch (periodType) {
        case PivotPeriod.day:
          const interval = this.data.interval
          const currentX = fromX

          const toDate = new Date(currentDate)
          toDate.setHours(16, 0, 0, 0)
          if (this.data.aftermarket && interval <= 5) toDate.setHours(18, 30, 0, 0)
          if (interval <= 15 && !isStock) toDate.setHours(23, 59, 59, 99)
          const toTimestamp = toDate.getTime() / 1000
          const toDiffMinutes = (toTimestamp - currentDate.getTime() / 1000) / 60
          const toBars = toDiffMinutes / interval
          toX = Math.min(currentX + toBars * barWidth, -leftOffset + width)

          const fromDate = new Date(currentDate)
          fromDate.setHours(9, 30, 0, 0)
          if (this.data.premarket && interval <= 5) fromDate.setHours(7, 0, 0, 0)
          if (interval <= 15 && !isStock) fromDate.setHours(0, 0, 0, 0)
          const fromTimestamp = fromDate.getTime() / 1000
          const fromDiffMinutes = (fromTimestamp - currentDate.getTime() / 1000) / 60
          const fromBars = fromDiffMinutes / interval
          fromX = currentX + fromBars * barWidth
          break

        case PivotPeriod.week:
          for (let i = index + 1; i <= lastBarToRender.index; i++) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (!dateFns.isSameWeek(currentDate, nextDate, { weekStartsOn: 1 })) {
              toX = this.fx(i)
              break
            }
          }
          for (let i = index - 1; i >= firstBarToRender.index; i--) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (!dateFns.isSameWeek(currentDate, nextDate, { weekStartsOn: 1 })) {
              fromX = this.fx(i + 1) // because we need start from current week
              break
            }
          }

          if (fromX === toX) {
            // we need line to imaginary end of the week
            const barsPerDay = Math.ceil((this.data.drawMinutesPerDay ?? MINUTES_PER_DAY) / this.data.interval)
            toX += (isStock ? 5 : 7) * barsPerDay * barWidth
          }
          break

        case PivotPeriod.month:
          for (let i = index + 1; i <= lastBarToRender.index; i++) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getMonth() !== currentDate.getMonth()) {
              toX = this.fx(i)
              break
            }
          }
          for (let i = index - 1; i >= firstBarToRender.index; i--) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getMonth() !== currentDate.getMonth()) {
              fromX = this.fx(i + 1) // because we need start from current month
              break
            }
          }

          if (fromX === toX) {
            // we need line to imaginary end of the month
            const barsPerDay = this.data.isIntraday
              ? Math.ceil((this.data.drawMinutesPerDay ?? MINUTES_PER_DAY) / this.data.interval)
              : 1
            toX += (isStock ? 20 : 30) * barsPerDay * barWidth
          }
          break
        default:
          for (let i = index + 1; i <= lastBarToRender.index; i++) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getFullYear() !== currentDate.getFullYear()) {
              toX = this.fx(i)
              break
            }
          }
          for (let i = index - 1; i >= firstBarToRender.index; i--) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getFullYear() !== currentDate.getFullYear()) {
              fromX = this.fx(i + 1) // because we need start from current year
              break
            }
          }

          if (fromX === toX) {
            // we need line to imaginary end of the year
            toX += (this.data.timeframe === TIMEFRAME.w ? 49 : 12) * barWidth
          }
          break
      }
      fromX = Math.max(fromX, -leftOffset)

      // render pivot line
      context.beginPath()
      line(fromX, toX, pivotPoint.pivot)
      context.stroke()

      // render resistance and support lines
      context.save()
      context.setLineDash([3, 3])
      context.beginPath()
      line(fromX, toX, pivotPoint.res1)
      line(fromX, toX, pivotPoint.res2)
      line(fromX, toX, pivotPoint.res3)
      line(fromX, toX, pivotPoint.sup1)
      line(fromX, toX, pivotPoint.sup2)
      line(fromX, toX, pivotPoint.sup3)
      context.stroke()
      context.restore()

      // render labels if there is enough space
      if (toX - fromX >= 48) {
        text('P', pivotPoint.pivot, fromX)
        text('R1', pivotPoint.res1, fromX)
        text('R2', pivotPoint.res2, fromX)
        text('R3', pivotPoint.res3, fromX)
        text('S1', pivotPoint.sup1, fromX)
        text('S2', pivotPoint.sup2, fromX)
        text('S3', pivotPoint.sup3, fromX)
      }
    }

    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      calculationType: {
        type: 'select',
        label: 'Calculation Type',
        name: 'calculationType',
        value: this.attrs.calculationType ?? DEFAULT_PARAMETERS.CalculationType,
        required: true,
        items: [
          {
            value: 'standard',
            label: 'Standard',
          },
          {
            value: 'fibonacci',
            label: 'Fibonacci',
          },
        ],
      },
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: PPConfig.label,
      inputs: PPConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {},
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'calculationType':
      case 'color':
        return !!this.attrs[key]
      default:
        return false
    }
  }

  toString() {
    return `${PPConfig.shortLabel} (${Calculation[this.attrs.calculationType]})`
  }
}

PivotPoints.prototype.defaults = {
  calculationType: DEFAULT_PARAMETERS.CalculationType,
  color: DEFAULT_PARAMETERS.Color,
}

export default PivotPoints
