import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

interface Attrs {
  supportColor: string
  resistColor: string
}

export class PatternsConfig extends BaseConfig {
  static type = OverlayType.Patterns
  static abbreviation = 'patterns'
  static label = 'Patterns (daily charts)'
  static shortLabel = ''
  static inputsOrder = ['supportColor', 'resistColor'] as Array<keyof Attrs>
  static inputsLabels = { supportColor: 'Support Color', resistColor: 'Resist Color' }
}
