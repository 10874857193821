import Line from '../canvas/line'
import { TrixCalculation } from '../indicator-calculation/trix'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, TRIXConfig } from './configs/trix'
import Indicator from './indicator'

class Trix extends Indicator<Attrs> {
  static config = TRIXConfig

  trixCalculation: TrixCalculation | null = null
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.trixCalculation = new TrixCalculation({
        quote: this.data,
        options: {
          period: this.period,
        },
      })
      this.trixCalculation.calculate()
      this.lastValue = this.trixCalculation.calculatedValues.trix.last() ?? null
    }
    const trix = this.trixCalculation?.calculatedValues.trix ?? []
    const { min, max } = trix.length > 0 ? this.computeVisibleMinMax(trix) : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(0)),
        y2: Math.round(this.fy(0)),
        strokeStyle: '#ff8787',
        dashLength: 3,
      },
      this.model
    ).render(context)
    context.set('strokeStyle', this.getChartLayoutSettings().IndicatorSettings.general.Colors.line)
    context.translate(0.5, 0.5)
    context.beginPath()
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        const value = this.trixCalculation?.calculatedValues.trix[i]
        if (value) context.lineTo(x, ~~this.fy(value))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getValueLabelsAtIndex(index: number) {
    const dataIndex = this.data.barToDataIndex[index]
    return [
      {
        color: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!,
        text: this.getValueLabel(this.trixCalculation?.calculatedValues.trix[dataIndex]),
      },
    ]
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 9,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: TRIXConfig.label,
      inputs: TRIXConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default Trix
