import { PaneArea, ResizeByThumbFuncProps, ThumbType } from '../../types/shared'
import { getOffsetFromLineWidth } from '../controllers/renderUtils'
import PaneModel from '../models/pane'

type Area = PaneArea
type ResizeFunc = (props: ResizeByThumbFuncProps) => void
type ScaleFunc = () => number

class Thumb {
  type: ThumbType
  lastX = 0
  lastY = 0
  fx: ScaleFunc
  fy: ScaleFunc
  resize: ResizeFunc
  model: PaneModel

  constructor(type: ThumbType, fx: ScaleFunc, fy: ScaleFunc, resize: ResizeFunc, model: PaneModel) {
    this.type = type
    this.fx = fx
    this.fy = fy
    this.resize = resize
    this.model = model
  }

  render(context: CanvasRenderingContext2D) {
    const { ThumbSettings } = this.model.getChartLayoutSettings()
    context.set('fillStyle', ThumbSettings.color)
    context.set('strokeStyle', ThumbSettings.borderColor)
    context.set('lineWidth', ThumbSettings.borderWidth)
    const x = ~~this.x()
    const y = ~~this.y()
    const thumbWidth = this.getThumbWidth()
    const offset = getOffsetFromLineWidth(ThumbSettings.borderWidth)
    context.beginPath()
    context.rect(x + offset, y + offset, thumbWidth, thumbWidth)
    context.fill()
    context.stroke()
  }

  startEditing(area: Area) {
    this.lastX = area.x
    return (this.lastY = area.y)
  }

  moveTo(area: Area) {
    const difX = area.x - this.lastX
    const difY = area.y - this.lastY
    this.resize({ type: this.type, difX, difY, area })
    this.lastX = area.x
    this.lastY = area.y
  }

  isInArea(area: Area) {
    const width = this.model.scale.x.invert(this.getThumbWidth())
    const height = this.model.scale.y.invert(0) - this.model.scale.y.invert(this.getThumbWidth())
    // +- width/2 because we have coordinates of a center
    if (
      this.fx() + width / 2 >= area.x &&
      this.fx() - width / 2 <= area.x &&
      this.fy() + height / 2 > area.y &&
      this.fy() - height / 2 < area.y
    ) {
      return true
    }
    return false
  }

  getThumbWidth() {
    const { ThumbSettings } = this.model.getChartLayoutSettings()
    return ThumbSettings.size + ThumbSettings.borderWidth * 2
  }

  x() {
    const { ThumbSettings } = this.model.getChartLayoutSettings()
    return this.model.scale.x(this.fx()) - ThumbSettings.size / 2
  }

  y() {
    const { ThumbSettings } = this.model.getChartLayoutSettings()
    return this.model.scale.y(this.fy()) - ThumbSettings.size / 2
  }
}

export default Thumb
