import math from '../helpers/math'
import { getBarWidthWithMarginByParts } from '../utils/chart'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { VolConfig } from './configs/vol'
import Indicator from './indicator'

class Volume extends Indicator {
  static config = VolConfig

  compute() {
    const { min, max } =
      this.data.volume.length > 0 ? this.computeVisibleMinMax(this.data.volume) : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
    this.lastValue = this.data.volume.last() ?? null
  }

  getValueLabelsAtIndex(index: number) {
    const { Colors } = this.getChartLayoutSettings().ChartSettings.general
    const dataIndex = this.data.barToDataIndex[index]
    return [
      {
        color: this.data.close[dataIndex] < this.data.open[dataIndex] ? Colors.volumeTrendDown : Colors.volumeTrendUp,
        text: this.getValueLabel(this.data.volume[dataIndex]),
      },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const chartModel = this.model.chart()
    const { zoomFactor } = chartModel
    const { barFillWidth, barBorderWidth } = getBarWidthWithMarginByParts({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })
    const barWidthWithoutMargin = barFillWidth + barBorderWidth * 2
    const halfBarWidth = ~~Math.max(1, barWidthWithoutMargin / 2)

    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i, x) => {
        context.fillStyle = this.data.close[i] < this.data.open[i] ? Colors.volumeTrendDown : Colors.volumeTrendUp
        const y = Math.round(this.fy(this.data.volume[i]))
        context.fillRect(x - halfBarWidth, y, barWidthWithoutMargin, context.canvas.height - y)
      },
    })
  }

  formatAxis(value: number) {
    return math.formatBigNumber(value, 2)
  }

  getModalConfig() {
    return {
      title: VolConfig.label,
      inputs: [],
      inputsErrorMessages: {},
    }
  }
}

export default Volume
