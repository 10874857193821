import { Button, ButtonProps, Dropdown, Icon, useDropdownState } from '@finviz/website'
import React from 'react'
import ReactDOM from 'react-dom'
import { useSearchParams } from 'react-router-dom'

import { SIDEBAR_QUERY_PARAM } from '../../sidebar/sidebar'
import { MOBILE_TOOLBAR_DIV_ID } from '../../sidebar/sidebar-tabs'
import { drawingTools } from '../drawingTools'
import { DrawingActions, DrawingTool, IToolBarProps } from '../interfaces'
import Toolbar from '../toolbar'

type ToolbarMobileProps = Omit<IToolBarProps, 'isMobile'>

export default function ToolbarMobile({
  activeTool,
  onActiveToolChange,
  onDrawingActionClick,
  ...rest
}: ToolbarMobileProps) {
  const toolbarState = useDropdownState()
  const hideToolbar = toolbarState.hideAll
  const [urlSearchParams, setUrlSearchParams] = useSearchParams()

  // get toolbar mobile element on first render
  const [toolbarMobileElement, setToolbarMobileElement] = React.useState<HTMLElement | null>(null)
  React.useEffect(() => {
    setToolbarMobileElement(document.getElementById(MOBILE_TOOLBAR_DIV_ID))
  }, [])

  const drawButtonProps = React.useMemo<ButtonProps>(() => {
    if (urlSearchParams.has(SIDEBAR_QUERY_PARAM) || activeTool === DrawingTool.Mouse) {
      return {
        children: 'Draw',
        leftContent: 'draw',
        size: 'medium',
        onClick: () => {
          setUrlSearchParams((params) => {
            params.delete(SIDEBAR_QUERY_PARAM)
            return params
          })
        },
      }
    }

    return {
      appearance: 'square',
      active: true,
      size: 'medium',
      theme: 'chipTransparent',
      leftContent: <Icon name={drawingTools[activeTool].icon} width={32} className="shrink-0" />,
    }
  }, [activeTool, setUrlSearchParams, urlSearchParams])

  const handleActiveToolChange = React.useCallback(
    (drawingTool: DrawingTool, trigger?: 'button' | 'select' | 'group') => {
      onActiveToolChange(drawingTool, trigger)

      if (trigger !== 'group') return hideToolbar()
    },
    [hideToolbar, onActiveToolChange]
  )

  const handleDrawingActionClick = React.useCallback(
    (actionId: DrawingActions) => {
      onDrawingActionClick?.(actionId)
      hideToolbar()
    },
    [hideToolbar, onDrawingActionClick]
  )

  if (!toolbarMobileElement) return null

  return ReactDOM.createPortal(
    <Dropdown
      state={toolbarState}
      trigger={<Button {...drawButtonProps} />}
      // Move the dropdown to cover the button
      gutter={-32}
      modal={activeTool === DrawingTool.Mouse}
      backdrop={false}
      unmountOnHide={false}
      placement="top-end"
      className="-translate-x-1"
    >
      <Toolbar
        isMobileOverlay
        activeTool={activeTool}
        onActiveToolChange={handleActiveToolChange}
        onDrawingActionClick={handleDrawingActionClick}
        {...rest}
      />
    </Dropdown>,
    toolbarMobileElement
  )
}
