import {
  Button,
  Dialog,
  DialogBody,
  DialogDismiss,
  DialogFooter,
  DialogHeader,
  DialogStateReturn,
  Paragraph,
} from '@finviz/website'
import React from 'react'

export enum IdeaDrawingsConfirmationOptions {
  Merge,
  Override,
  Nothing,
  Cancel,
}

interface IIdeaLeaveDialog {
  state: DialogStateReturn
  actionCallback: (action: IdeaDrawingsConfirmationOptions) => void
}

export function IdeaLeaveDialog({ state, actionCallback }: IIdeaLeaveDialog) {
  return (
    <Dialog
      state={state}
      aria-label="You are leaving your idea, what would you like to do with the drawings from your currently open idea?"
      className="w-96"
      backdrop={<div onMouseDown={() => actionCallback(IdeaDrawingsConfirmationOptions.Cancel)} />}
    >
      <DialogHeader onCloseClick={() => actionCallback(IdeaDrawingsConfirmationOptions.Cancel)}>
        You’re About to Leave your Idea
      </DialogHeader>
      <DialogBody>
        <div className="space-y-4">
          <Paragraph>What would you like to do with the drawings from your current Idea?</Paragraph>
          <Paragraph>
            <strong>Replace Drawings</strong>
            <br />
            Your Autosaved Drawings for this ticker will be deleted, and the Idea Drawings will be copied to the live
            chart.
          </Paragraph>
          <Paragraph>
            <strong>Combine Drawings</strong>
            <br />
            The Idea Drawings will be copied to the live chart without changing the positions or settings of any
            Autosaved Drawings. This may cause duplicates if some drawings were also autosaved.
          </Paragraph>
          <Paragraph>
            <strong>Do Nothing</strong>
            <br />
            Close the idea without changing any Autosaved Drawings.
          </Paragraph>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          data-testid="confirmation-dialog-override"
          as={DialogDismiss}
          onClick={() => actionCallback(IdeaDrawingsConfirmationOptions.Override)}
        >
          Replace
        </Button>
        <Button
          data-testid="confirmation-dialog-merge"
          as={DialogDismiss}
          onClick={() => actionCallback(IdeaDrawingsConfirmationOptions.Merge)}
        >
          Combine
        </Button>
        <Button
          data-testid="confirmation-dialog-nothing"
          as={DialogDismiss}
          onClick={() => actionCallback(IdeaDrawingsConfirmationOptions.Nothing)}
        >
          Do Nothing
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
