import Spine from '@finviz/spine'

// eslint-disable-next-line no-extend-native
Array.prototype.last = function () {
  return this[this.length - 1]
}

// eslint-disable-next-line no-extend-native
String.prototype.padLeft = function (paddingValue) {
  return String(paddingValue + this).slice(-paddingValue.length)
}

// eslint-disable-next-line no-extend-native
String.prototype.startsWith = function (str) {
  return this.indexOf(str) === 0
}

interface CallbackCacheItem {
  newCb: (...args: any[]) => any
  origCb: (...args: any[]) => any
}

const _callbackCache: CallbackCacheItem[] = []
Spine.Collection.prototype.bind = function (ev, callback) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const that = this
  const cb = (record: Spine.Model, ...rest: any[]) => {
    if (record[that.fkey as keyof Spine.Model] === that.record.id) {
      return callback(record, ...rest)
    }
  }
  _callbackCache.push({ newCb: cb, origCb: callback })
  this.model.bind(ev, cb)
  return this
}

Spine.Collection.prototype.unbind = function (ev, callback) {
  let cb
  for (const item of _callbackCache) {
    if (item.origCb === callback) {
      cb = item.newCb
      break
    }
  }
  return this.model.unbind(ev, cb)
}

Spine.Model.findIndex = function (element) {
  const iterable = this.all()
  for (let index = 0; index < iterable.length; index++) {
    const el = iterable[index]
    if (element.eql(el)) {
      return index
    }
  }
}
