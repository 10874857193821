import { IconNameType } from '@finviz/website'
import React from 'react'

import { portfolioWatchlistAsyncModule } from './portfolio-watchlist/async-modules'
import { withModuleAsyncLoad } from './with-module-async-load'

export enum SidebarModule {
  portfolio = 'portfolio',
}

export type SidebarModuleType = {
  key: SidebarModule
  icon: IconNameType
  description: string
  content: React.ComponentType
  width?: {
    min?: number | string
    max?: number | string
    default?: { desktop?: number; mobile?: number }
  }
}

export const sidebarModules: Record<SidebarModule, SidebarModuleType> = Object.freeze({
  [SidebarModule.portfolio]: {
    key: SidebarModule.portfolio,
    icon: 'briefcase',
    description: 'Portfolio watchlist',
    content: withModuleAsyncLoad(portfolioWatchlistAsyncModule, true),
    width: { min: 200, default: { mobile: 275 } },
  },
})
