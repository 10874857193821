import React from 'react'

import { RootChartConfigObjectChart } from '../../../types/shared'
import ChartModel from '../../models/chart'
import ChartLayout from '../../models/chart_layout'
import { DrawingTool } from '../toolbar/interfaces'

export const CHART_CLASS_NAMES = 'chart flex-1 relative'
export const DEFAULT_WRAPPER_COMPONENT = 'div'

export interface ChartProps {
  chartIndex: number
  gridArea?: string
  WrapperComp?: React.ForwardRefRenderFunction<any>
  config: RootChartConfigObjectChart
  chartModel: ChartModel
  layoutModel: ChartLayout
  setActiveChartInteraction: (interaction: string | null) => void
  activeChartInteraction: string | null
  activeTool: DrawingTool
  touchEventsDisabled: boolean
  hasOutline?: boolean
  shouldResize?: boolean
  renderChartToolbar?: () => React.ReactNode
}
