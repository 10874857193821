import { ChartElementType } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import { getBarWithoutMarginWidth } from '../utils/chart'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import Chart from './base_chart'

class OHLCChart extends Chart {
  static type = ChartElementType.OhlcChart
  static label = 'OHLC'
  static iconName = 'ohlcChartType'

  renderChart() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width,
      yOffset: ChartSettings.top.height,
    })
    translate.do()

    const barWithoutMarginWidth = getBarWithoutMarginWidth(this.model)

    let lineWidth = 1
    if (barWithoutMarginWidth >= 40) {
      lineWidth = 4
    } else if (barWithoutMarginWidth >= 30) {
      lineWidth = 3
    } else if (barWithoutMarginWidth >= 15) {
      lineWidth = 2
    }
    const lineThickness = lineWidth / 2
    const handleLengthFromWick = Math.round(barWithoutMarginWidth / 2 - lineThickness)
    const lineOffset = lineWidth % 2 === 0 ? 0 : 0.5
    this.context.set('lineWidth', lineWidth)

    drawInVisibleArea({
      quote: this.data,
      paneModel: this.paneModel,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        const barX = ~~(x - 1) + lineOffset // move 1px to left so cursor don't cover bar of ohlc-chart & round for pixel perfect render on zoom

        this.context.beginPath()
        this.context.set(
          'strokeStyle',
          this.data.close[i] < this.data.open[i] ? Colors.ohlcTrendDown : Colors.ohlcTrendUp
        )

        this.context.moveTo(barX, Math.round(this.fy(this.data.high[i])))
        this.context.lineTo(barX, Math.round(this.fy(this.data.low[i])))

        if (handleLengthFromWick >= 1) {
          const open = Math.round(this.fy(this.data.open[i]))
          this.context.moveTo(barX + lineThickness, open + lineOffset) // move to right edge of wick
          this.context.lineTo(barX - lineThickness - handleLengthFromWick, open + lineOffset) // draw line to the left

          const close = Math.round(this.fy(this.data.close[i]))
          this.context.moveTo(barX - lineThickness, close + lineOffset) // move to left edge of wick
          this.context.lineTo(barX + lineThickness + handleLengthFromWick, close + lineOffset) // draw line to the right
        }

        this.context.stroke()
      },
    })

    translate.undo()
  }

  toString() {
    return `OHLC ${this.data.ticker}`
  }
}

export default OHLCChart
