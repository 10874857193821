import Spine from '@finviz/spine'
import { isSameDay, isSameMonth, isSameWeek } from 'date-fns'
import omit from 'lodash.omit'
import queryString from 'query-string'

import { Instrument, ObjectHash, RootChartConfigObject, TodoObjectHashAnyType } from '../../types/shared'
import {
  ChartEventDbType,
  ChartEventType,
  IntradayTimeframeInterval,
  QuoteFinancialAttachment,
  TIMEFRAME,
} from '../constants/common'
import type { ICOT } from '../indicators/cot'
import { convertLocalToNyTime, dateFromUnixTimestamp, dateStringFromDate } from '../utils'
import { getIsAbortError } from '../utils/abort_controller'
import { getPositionXFromTimestamp, getTimestampFromPositionX } from '../utils/chart'
import { IDividends, IEarnings, ISplit } from '../utils/chart-events-utils'
import fetchApi, { NotFoundError } from '../utils/fetch_api'
import { getIsSSr } from '../utils/helpers'
import { QuoteFetchType, QuoteFetchTypeUrlOption, QuoteUrlOptions } from './quote/constants'
import { FinancialAttachmentsData, ShortInterestData } from './quote/financialAttachmentsData'
import { deleteCachedQuote, getCachedQuote, setCachedQuote } from './quoteCache'
import { getRawTickerForInstrument } from './utils'

const quoteRefreshChecksBeforeFullRefresh = 15

type TodoPattern = TodoObjectHashAnyType

export interface TickerTimeframesProps {
  ticker: string
  instrument: string
  timeframe: string
}

interface QuoteFetchOptions {
  aftermarket?: boolean
  premarket?: boolean
  patterns?: boolean
  events?: boolean
  maxBars?: number
  financialAttachments?: string[]
}

interface QuoteInternalOptions {
  cachePredicate?: (quote: Quote) => boolean
  fetchNewDataOnCachedQuote?: boolean
}

interface UrlOptions extends Omit<QuoteFetchOptions, 'financialAttachments'> {
  ticker: string
  instrument: string
  timeframe: string
  financialAttachments?: string
}

type ChartEventDataRaw = Omit<IEarnings & IDividends & ISplit, 'eventType'> & { eventType: ChartEventDbType }

export interface DataResponse {
  COTs: COTData
  name: string
  ticker: string
  instrument: string
  interval: number
  timeframe: string
  open: number[]
  high: number[]
  low: number[]
  close: number[]
  date: number[]
  volume: number[]
  lastOpen: number
  lastHigh: number
  lastLow: number
  lastClose: number
  lastDate: number
  lastTime: string | null
  lastVolume: number
  dataId: string
  prevClose: number
  afterClose: number
  afterChange: number
  afterTime: string | null
  drawMinutesPerDay: number
  marketStartMinutes: number
  premarketLengthMinutes: number
  aftermarketLengthMinutes: number
  aftermarket: boolean
  premarket: boolean
  hasPatterns: boolean
  patterns: Array<TodoPattern>
  patternsMinRange: number
  patternsMaxRange: number
  relativeVolume: number
  isIntraday: boolean
  noNewData?: boolean
  chartEvents?: ChartEventDataRaw[]
  financialAttachmentsData?: FinancialAttachmentsData
}

interface COTData {
  [code: string]: ICOT
}

const getEmptyQuote = () => ({
  open: [],
  high: [],
  low: [],
  close: [],
  volume: [],
  date: [],
  fetchedAt: -1,
  numberOfRefreshChecks: 0,
  isFetching: false,
  is404: false,
  COTs: undefined,
  name: undefined,
  drawMinutesPerDay: undefined,
  marketStartMinutes: undefined,
  premarketLengthMinutes: undefined,
  aftermarketLengthMinutes: undefined,
  patterns: [],
  patternsMinRange: undefined,
  patternsMaxRange: undefined,
  relativeVolume: undefined,
  lastOpen: undefined,
  lastHigh: undefined,
  lastLow: undefined,
  lastClose: undefined,
  lastVolume: undefined,
  dataId: undefined,
  lastDate: undefined,
  prevClose: undefined,
  afterClose: undefined,
  afterChange: undefined,
  chartEvents: [],
  financialAttachments: [],
  financialAttachmentsData: null,
})

interface ICacheAvailabilityCheckFunctionsArguments {
  quote: Quote
  options?: QuoteFetchOptions & QuoteInternalOptions
}

const doesQuoteAndOptionsMatch = ({ quote, options }: ICacheAvailabilityCheckFunctionsArguments) =>
  // Only intraday quotes have afterhours
  (!quote.isIntraday || (!quote.premarket === !options?.premarket && !quote.aftermarket === !options?.aftermarket)) &&
  (!options?.patterns || !quote.hasPatterns === !options?.patterns) &&
  // if options.events is false we can reuse quote with events
  (!options?.events || !!quote.events) &&
  // Either has no financialAttachments or require all of them to match
  ((options?.financialAttachments?.length ?? 0) === 0 ||
    options!.financialAttachments!.every((financialAttachment) =>
      quote.financialAttachments?.includes(financialAttachment as QuoteFinancialAttachment)
    ))

const doesQuoteAndFetchParamsMatch = ({
  quote,
  ticker,
  instrument,
  timeframe,
}: {
  quote: Quote
  ticker: string
  instrument: Instrument
  timeframe: TIMEFRAME
}) => quote.ticker === ticker && quote.instrument === instrument && quote.timeframe === timeframe

// either we don't care about matching uuid at all, or they have to match
const isSameOrNoChartUuid = ({ quote, uuid }: { quote: Quote; uuid?: string }) => !uuid || quote.chartUuid === uuid

const isCacheAllowed = ({ quote, options }: ICacheAvailabilityCheckFunctionsArguments) =>
  !options?.cachePredicate || options.cachePredicate(quote)

const isNotEmptyQuote = (quote: Quote) => quote.fetchedAt !== -1 || quote.isFetching || !!quote.is404

export type QuoteGetParams = {
  ticker: string
  instrument: Instrument
  timeframe: TIMEFRAME
  options?: QuoteFetchOptions & QuoteInternalOptions
  abortController?: null | AbortController
  shouldUseCache?: boolean
  canBeEmptyQuote?: boolean
  chartUuid?: string
}

function getChartEventsWithId({ ticker, chartEvents }: { ticker: string; chartEvents?: ChartEventDataRaw[] }) {
  return (
    chartEvents?.map((item) => {
      let eventType: ChartEventType
      switch (item.eventType) {
        case ChartEventDbType.Dividends:
          eventType = ChartEventType.Dividends
          break
        case ChartEventDbType.Split:
          eventType = ChartEventType.Split
          break
        case ChartEventDbType.Earnings:
          eventType = ChartEventType.Earnings
          break
      }
      return {
        ...item,
        elementId: `${ticker.toLowerCase()}-${item.eventType}-${item.dateTimestamp}`,
        eventType,
      }
    }) ?? []
  )
}

class Quote extends Spine.Model {
  static computableKeys = [
    'isIntraday',
    'interval',
    'drawDays',
    'barIndex',
    'barToDataIndex',
    'alignedFinancialAttachmentsData',
    'timestampToFinancialsIndexesMap',
  ]

  static initClass() {
    this.configure(
      'Quote',
      'ticker',
      'name',
      'instrument',
      'timeframe',
      'open',
      'high',
      'low',
      'close',
      'date',
      'volume',
      'lastOpen',
      'lastHigh',
      'lastLow',
      'lastClose',
      'lastDate',
      'lastVolume',
      'lastTime',
      'dataId',
      'prevClose',
      'COTs',
      'chartEvents',
      'afterClose',
      'afterChange',
      'afterTime',
      'drawMinutesPerDay',
      'marketStartMinutes',
      'premarketLengthMinutes',
      'aftermarketLengthMinutes',
      'aftermarket',
      'premarket',
      'events',
      'financialAttachments',
      'financialAttachmentsData',
      'hasPatterns',
      'patterns',
      'patternsMinRange',
      'patternsMaxRange',
      'relativeVolume',
      'fetchedAt',
      'fetchingPromise',
      'isFetching',
      'wasFetchAborted',
      'chartUuid',
      'numberOfRefreshChecks',
      'maxBars',
      'ideaID',
      'is404',
      ...Quote.computableKeys
    )
  }

  static getFromCacheSync({
    ticker: tickerRaw,
    instrument,
    timeframe,
    options,
    chartUuid,
    canBeEmptyQuote,
  }: Omit<QuoteGetParams, 'abortController' | 'shouldUseCache'>): Quote | null {
    const ticker = tickerRaw.replace('@', '')
    return (
      Quote.select<Quote>(
        (q) =>
          doesQuoteAndFetchParamsMatch({ quote: q, ticker, instrument, timeframe }) &&
          isSameOrNoChartUuid({ quote: q, uuid: chartUuid }) &&
          (canBeEmptyQuote || isNotEmptyQuote(q)) &&
          isCacheAllowed({ quote: q, options }) &&
          (q.instrument !== Instrument.Stock ||
            Number.isInteger(q.ideaID) ||
            doesQuoteAndOptionsMatch({ quote: q, options }))
      )[0] ?? null
    )
  }

  static async get({
    ticker: tickerRaw,
    instrument,
    timeframe,
    options,
    abortController,
    shouldUseCache = true,
    canBeEmptyQuote = false,
    chartUuid,
  }: QuoteGetParams): Promise<Quote> {
    const ticker = tickerRaw.replace('@', '')
    const quote = shouldUseCache
      ? this.getFromCacheSync({ ticker: tickerRaw, instrument, timeframe, options, chartUuid, canBeEmptyQuote })
      : null

    if (quote) {
      if (quote.isFetching) {
        await quote.getResolvedFetchingPromise()
      } else if (options?.fetchNewDataOnCachedQuote) {
        await quote.fetchData({ fetchType: QuoteFetchType.NewerData, abortController })
      }
      return quote
    }

    const newQuote = Quote.create<Quote>({
      ...getEmptyQuote(),
      ticker,
      timeframe,
      instrument,
      chartUuid,
      maxBars: options?.maxBars,
      premarket: !!options?.premarket,
      aftermarket: !!options?.aftermarket,
      hasPatterns: !!options?.patterns,
      events: !!options?.events,
      financialAttachments: options?.financialAttachments ?? [],
    })

    await newQuote.fetchData({ fetchType: QuoteFetchType.Refetch, abortController })

    return newQuote
  }

  static async getAll(
    tickersAndTimeframes: Array<Pick<QuoteGetParams, 'ticker' | 'instrument' | 'timeframe' | 'options'>>
  ) {
    // TODO: fetch in 1 request
    if (tickersAndTimeframes.length === 0) {
      return {}
    }

    try {
      const quotes = await Promise.all(tickersAndTimeframes.map((props) => this.get(props)))
      const data = quotes.reduce(
        (accumulator, quote) => ({
          ...accumulator,
          [quote.ticker]: quote,
        }),
        {}
      )
      return data
    } catch {
      // TODO: handle somehow
    }
  }

  declare ticker: string
  declare name: string
  declare instrument: Instrument
  declare interval: number
  declare timeframe: TIMEFRAME
  declare open: number[]
  declare high: number[]
  declare low: number[]
  declare close: number[]
  declare date: number[]
  declare volume: number[]
  declare lastOpen: number
  declare lastHigh: number
  declare lastLow: number
  declare lastClose: number
  declare lastDate: number
  declare lastVolume: number
  declare dataId: string
  declare prevClose: number
  declare COTs: COTData
  declare afterClose: number
  declare afterChange: number
  declare drawMinutesPerDay: number
  declare marketStartMinutes: number
  declare premarketLengthMinutes: number
  declare aftermarketLengthMinutes: number
  declare hasPatterns: boolean
  declare patterns: Array<TodoPattern>
  declare patternsMinRange: number
  declare patternsMaxRange: number
  declare relativeVolume: number
  declare isIntraday: boolean
  declare drawDays?: number
  declare barIndex: number[]
  /**
   * Array of length the same as number of bars
   *
   * If there's a gap and a bar index has no corresponding data index,
   * the last data index is used (or 0 if there's no last data index)
   *
   * @type {number[]}
   */
  declare barToDataIndex: number[]
  declare chartUuid?: string
  declare aftermarket: boolean
  declare premarket: boolean
  declare fetchedAt: number
  declare numberOfRefreshChecks: number
  declare isFetching: boolean
  declare fetchingPromise?: Promise<void>
  declare wasFetchAborted: boolean
  declare maxBars?: number
  declare ideaID?: number
  declare is404?: boolean
  declare chartEvents: Array<IEarnings | IDividends | ISplit>
  declare events?: boolean
  declare financialAttachments?: QuoteFinancialAttachment[]
  declare financialAttachmentsData?: FinancialAttachmentsData
  declare alignedFinancialAttachmentsData: {
    [QuoteFinancialAttachment.shortInterest]?: Array<ShortInterestData & { matchingQuoteTimestamp?: number | null }>
  }

  declare timestampToFinancialsIndexesMap: {
    [QuoteFinancialAttachment.shortInterest]?: ObjectHash<number[]>
  }

  async fetchRequest({
    abortController,
    options = { type: QuoteFetchTypeUrlOption.New },
  }: {
    abortController?: null | AbortController
    options?: QuoteUrlOptions
  }) {
    let data: DataResponse | null = null
    let is404 = false
    let wasFetchAborted = false

    const fetchingPromise = fetchApi<DataResponse>({
      location: this.getUrl(options),
      throwOnStatusCodes: [404],
      abortController,
    })

    this.updateAttributes({
      isFetching: true,
      fetchingPromise,
    })

    try {
      data = await fetchingPromise
    } catch (err) {
      is404 = err instanceof NotFoundError
      if (getIsAbortError(err as Error)) {
        wasFetchAborted = true
      } else if (!is404 && process.env.IS_E2E_TESTING) {
        throw new Error('Quote fetch error', { cause: err })
      }
      // Ignore network/notfound errors
    }

    const shouldUseEmptyQuote = !data && !wasFetchAborted && options.type === QuoteFetchTypeUrlOption.New

    this.updateAttributes({
      ...(shouldUseEmptyQuote ? getEmptyQuote() : {}),
      is404,
      wasFetchAborted,
      isFetching: false,
    })

    return data
  }

  async isPossibleToFetchSequentialData({
    abortController,
  }: {
    abortController?: null | AbortController
  } = {}) {
    const data = await this.fetchRequest({ abortController })
    if (data && data.date.length > 0 && this.date.length > 0) {
      return this.date[this.date.length - 1] >= data.date[0]
    }
    return false
  }

  async fetchData({
    fetchType,
    abortController,
  }: {
    fetchType: QuoteFetchType
    abortController?: null | AbortController
  }) {
    let options: QuoteUrlOptions
    if (fetchType === QuoteFetchType.Refetch) {
      options = { type: QuoteFetchTypeUrlOption.New }
    } else if (fetchType === QuoteFetchType.NewerData) {
      options = { type: QuoteFetchTypeUrlOption.Newer }
      if ((this.numberOfRefreshChecks ?? 0) < quoteRefreshChecksBeforeFullRefresh) {
        options.dataId = this.dataId
      }
    } else {
      return
    }
    if (this.instrument === Instrument.Stock) {
      options.events = this.events == null ? true : this.events
    }

    const data = await this.fetchRequest({ abortController, options })

    if (!data || data.noNewData === true) {
      this.updateAttributes({
        numberOfRefreshChecks: (this.numberOfRefreshChecks ?? 0) + 1,
      })
      return
    }
    if (this.timeframe !== data.timeframe || this.ticker.toLowerCase() !== data.ticker?.toLowerCase()) {
      return
    }

    const newQuoteData = {
      COTs: data.COTs,
      name: data.name,
      open: data.open,
      high: data.high,
      low: data.low,
      close: data.close,
      volume: data.volume,
      date: data.date,
      drawMinutesPerDay: data.drawMinutesPerDay,
      marketStartMinutes: data.marketStartMinutes,
      premarketLengthMinutes: data.premarketLengthMinutes,
      aftermarketLengthMinutes: data.aftermarketLengthMinutes,
      patterns: data.patterns ?? [],
      patternsMinRange: data.patternsMinRange,
      patternsMaxRange: data.patternsMaxRange,
      relativeVolume: data.relativeVolume,
      lastOpen: data.lastOpen,
      lastHigh: data.lastHigh,
      lastLow: data.lastLow,
      lastClose: data.lastClose,
      lastVolume: data.lastVolume,
      dataId: data.dataId,
      lastDate: data.lastDate,
      lastTime: data.lastTime,
      prevClose: data.prevClose,
      afterClose: data.afterClose,
      afterChange: data.afterChange,
      afterTime: data.afterTime,
      numberOfRefreshChecks: 0,
      chartEvents: data.chartEvents ?? [],
      fetchedAt: Date.now(),
      financialAttachmentsData: data.financialAttachmentsData,
    }

    if (
      !(
        fetchType === QuoteFetchType.Refetch ||
        [Instrument.Futures, Instrument.Forex, Instrument.Crypto].includes(this.instrument)
      )
    ) {
      /*
       * Merges current and new data taking date as index
       */
      let AIndex = 0
      let BIndex = 0
      let mergeIndex = -1
      while (AIndex < this.date.length || BIndex < data.date.length) {
        const ADate = this.date[AIndex]
        const BDate = data.date[BIndex]
        if (BDate === undefined) {
          break
        } else if (ADate === BDate) {
          // Same date, update OHLCV
          this.open[AIndex] = data.open[BIndex]
          this.high[AIndex] = data.high[BIndex]
          this.low[AIndex] = data.low[BIndex]
          this.close[AIndex] = data.close[BIndex]
          this.volume[AIndex] = data.volume[BIndex]
          AIndex++
          BIndex++
        } else if (ADate > BDate || ADate === undefined) {
          // New bar for a date not yet in our dataset
          // If weekly or monthly timeframe & wasn't merged on AIndex
          if (mergeIndex === -1 && [TIMEFRAME.w, TIMEFRAME.m].includes(this.timeframe)) {
            const prevAIndex = AIndex - 1
            const prevDate = dateFromUnixTimestamp(this.date[prevAIndex])
            const newDate = dateFromUnixTimestamp(data.date[BIndex])
            // If merge bar for weekly is sameWeek or for monthly is sameMonth perform merge on AIndex
            if (
              (TIMEFRAME.w === this.timeframe && isSameWeek(prevDate, newDate, { weekStartsOn: 1 })) ||
              (TIMEFRAME.m === this.timeframe && isSameMonth(prevDate, newDate))
            ) {
              this.date[prevAIndex] = data.date[BIndex]
              this.open[prevAIndex] = data.open[BIndex]
              this.high[prevAIndex] = data.high[BIndex]
              this.low[prevAIndex] = data.low[BIndex]
              this.close[prevAIndex] = data.close[BIndex]
              this.volume[prevAIndex] = data.volume[BIndex]
              mergeIndex = prevAIndex
              // Incrementing BIndex only because we're mergin arrays on AIndex
              BIndex++
              continue
            }
          }
          this.date.splice(AIndex, 0, data.date[BIndex])
          this.open.splice(AIndex, 0, data.open[BIndex])
          this.high.splice(AIndex, 0, data.high[BIndex])
          this.low.splice(AIndex, 0, data.low[BIndex])
          this.close.splice(AIndex, 0, data.close[BIndex])
          this.volume.splice(AIndex, 0, data.volume[BIndex])
          // Incrementing AIndex because we're changing the array in-place
          AIndex++
          BIndex++
        } else if (ADate < BDate) {
          AIndex++
        } else {
          console.warn('fetchNewerData merge warning')
          window.Sentry?.captureMessage('quote.ts fetchNewerData merge warning', {
            extra: {
              thisDateLength: this.date.length,
              dataDateLength: data.date.length,
              AIndex,
              BIndex,
              ADate,
              BDate,
            },
          })
        }
      }

      newQuoteData.date = this.date
      newQuoteData.open = this.open
      newQuoteData.high = this.high
      newQuoteData.low = this.low
      newQuoteData.close = this.close
      newQuoteData.volume = this.volume
    }

    const hasUpdatedSuccessfully = this.updateAttributes(newQuoteData)
    // https://github.com/finvizhq/charts/issues/510
    if (!hasUpdatedSuccessfully) {
      // attempt to find if this.model.quote() => null is comming from this.save() validation fail
      window.Sentry?.captureMessage('quote.ts fetchNewerData save() validation failed', { extra: this })
    }
    if (!this) {
      // or this somehow became null / falsy
      window.Sentry?.captureMessage('quote.ts fetchNewerData this is falsy', { extra: this })
    }
    this.trigger(fetchType, this, fetchType)
  }

  async getResolvedFetchingPromise() {
    try {
      return await this.fetchingPromise
    } catch {
      // do nothing
    }
  }

  fetchOlderData() {
    return // not yet implemented
    // TODO cancel previous request
  }

  load(atts: ObjectHash) {
    super.load(atts)
    // This has to be here to prevent recalculations if atts.fetchedAt is not provided
    const fetchedAt = atts.fetchedAt ?? this.fetchedAt ?? 0
    const { cachedAt } = (getCachedQuote({ cid: this.cid }) as { cachedAt: number } | null) || {}
    if (this.timeframe && cachedAt !== fetchedAt) {
      setCachedQuote({ cid: this.cid, key: 'cachedAt', value: fetchedAt, fetchedAt })
      this.isIntraday = [
        TIMEFRAME.i1,
        TIMEFRAME.i2,
        TIMEFRAME.i3,
        TIMEFRAME.i5,
        TIMEFRAME.i10,
        TIMEFRAME.i15,
        TIMEFRAME.i30,
        TIMEFRAME.h,
        TIMEFRAME.h2,
        TIMEFRAME.h4,
      ].includes(this.timeframe)
      this.interval = this.isIntraday
        ? IntradayTimeframeInterval[this.timeframe as keyof typeof IntradayTimeframeInterval]
        : 0
      this.drawDays = this.getDrawDays()
      this.barIndex = this.getBarIndexes()
      this.patterns = this.patterns ?? []
      this.barToDataIndex = this.barIndex.flatMap((barIndex, index) => {
        const lastIndex = this.barIndex[index - 1] ?? -1
        return [...Array.from({ length: barIndex - lastIndex - 1 }).fill(Math.max(0, index - 1)), index] as number[]
      })
      this.alignAllFinancialAttachmentsDataWithQuoteDates()
    }
    if (this.timeframe && this.ticker) {
      this.chartEvents = getChartEventsWithId({
        ticker: this.ticker,
        chartEvents: this.chartEvents as unknown as ChartEventDataRaw[],
      })
    }
    return this
  }

  alignAllFinancialAttachmentsDataWithQuoteDates() {
    this.alignedFinancialAttachmentsData = {}
    this.timestampToFinancialsIndexesMap = {}
    this.financialAttachments?.forEach((quoteFinancialAttachment) => {
      this.alignFinancialAttachmentsDataWithQuoteDates(quoteFinancialAttachment)
    })
  }

  alignFinancialAttachmentsDataWithQuoteDates(quoteFinancialAttachment: QuoteFinancialAttachment) {
    const timestampToFinancialsIndexesMap: ObjectHash<number[]> = {}
    const financialAttachmentsData = this.financialAttachmentsData?.[quoteFinancialAttachment]

    if (financialAttachmentsData) {
      let lastDateIndex = 0
      const firstDate = this.date[0]
      this.alignedFinancialAttachmentsData[quoteFinancialAttachment] = financialAttachmentsData.map((data, index) => {
        let matchingQuoteTimestamp: number | null = null

        const parsedDate = dateFromUnixTimestamp(data.timestamp)
        const convertedDateToNyTimestamp = convertLocalToNyTime(parsedDate, false).getTime() / 1000

        if (firstDate < convertedDateToNyTimestamp) {
          for (let dateIndex = lastDateIndex; dateIndex < this.date.length; dateIndex += 1) {
            const timestamp = this.date[dateIndex]
            const dateDate = dateFromUnixTimestamp(timestamp)
            if (this.isIntraday || this.timeframe === TIMEFRAME.d) {
              if (isSameDay(parsedDate, dateDate)) {
                lastDateIndex = dateIndex
                matchingQuoteTimestamp = timestamp
                break
              }
            } else if (this.timeframe === TIMEFRAME.w) {
              if (isSameWeek(parsedDate, dateDate, { weekStartsOn: 1 })) {
                lastDateIndex = dateIndex
                matchingQuoteTimestamp = timestamp
                break
              }
            } else if (this.timeframe === TIMEFRAME.m) {
              if (isSameMonth(parsedDate, dateDate)) {
                lastDateIndex = dateIndex
                matchingQuoteTimestamp = timestamp
                break
              }
            }
          }
        }

        if (matchingQuoteTimestamp !== null) {
          const key = matchingQuoteTimestamp.toString()
          timestampToFinancialsIndexesMap[key] ??= []
          timestampToFinancialsIndexesMap[key].push(index)
        }

        return { ...data, matchingQuoteTimestamp }
      })

      this.timestampToFinancialsIndexesMap = {
        [quoteFinancialAttachment]: timestampToFinancialsIndexesMap,
      }
    }
  }

  clearCachedData() {
    deleteCachedQuote({ cid: this.cid })
  }

  destroy(options?: ObjectHash) {
    super.destroy(options)
    this.clearCachedData()
    return this
  }

  getDrawDays() {
    if (this.instrument !== Instrument.Stock) {
      return Infinity
    }
    switch (this.timeframe) {
      case TIMEFRAME.i1:
      case TIMEFRAME.i2:
      case TIMEFRAME.i3:
      case TIMEFRAME.i5:
      case TIMEFRAME.i10:
        return 10
      case TIMEFRAME.i15:
      case TIMEFRAME.i30:
      case TIMEFRAME.h:
      case TIMEFRAME.h2:
      case TIMEFRAME.h4:
        return 15
      default:
        break
    }
  }

  getHeikinAshiData() {
    const cachedHeikinAshiData = getCachedQuote({ cid: this.cid, key: 'heikinAshiData', fetchedAt: this.fetchedAt })
    if (cachedHeikinAshiData) {
      return cachedHeikinAshiData as unknown as { open: number[]; close: number[] }
    }

    const HAClose = this.open.map((_, i) => (this.open[i] + this.high[i] + this.close[i] + this.low[i]) / 4)
    const HAOpen = this.open.reduce(
      (acc, open, i) => [...acc, (i === 0 ? open + HAClose[0] : acc[i - 1] + HAClose[i - 1]) / 2],
      [] as number[]
    )

    const heikAshiData = { open: HAOpen, close: HAClose }

    setCachedQuote({ cid: this.cid, key: 'heikinAshiData', value: heikAshiData, fetchedAt: this.fetchedAt })

    return heikAshiData
  }

  getDayToOffset(): ObjectHash<number> {
    const cachedDayToOffset = getCachedQuote({ cid: this.cid, key: 'dayToOffset', fetchedAt: this.fetchedAt })
    if (cachedDayToOffset) {
      return cachedDayToOffset as unknown as ObjectHash<number>
    }
    const dayToOffset: ObjectHash = {}
    let day = 0
    let lastDay
    for (let i = 0, end = this.date.length, asc = end >= 0; asc ? i < end : i > end; asc ? i++ : i--) {
      const date = dateFromUnixTimestamp(this.date[i])
      const dateString = dateStringFromDate(date)
      if (dateString !== lastDay) {
        dayToOffset[dateString] = day
        day++
        lastDay = dateString
      }
    }
    // for ofscreen renderer we need to add lastDate to have correct dayToOffset because if
    // premarket=false in fetch query there isn't other way to distinguish that
    if (getIsSSr() && this.lastDate) {
      // YYYYMMDD / 20211118 => YYYY-MM-DD
      const [yyyy, mm, dd] = [~~(this.lastDate / 10000), ~~(this.lastDate / 100) % 100, this.lastDate % 100]
      const lastDate = `${yyyy}-${String(mm).padStart(2, '0')}-${String(dd).padStart(2, '0')}`

      if (lastDay !== lastDate) {
        dayToOffset[lastDate] = day
      }
    }

    setCachedQuote({ cid: this.cid, key: 'dayToOffset', value: dayToOffset, fetchedAt: this.fetchedAt })
    return dayToOffset
  }

  getDaysInQuote() {
    const cachedDayToOffset = getCachedQuote({ cid: this.cid, key: 'daysInQuote', fetchedAt: this.fetchedAt })
    if (cachedDayToOffset) {
      return cachedDayToOffset as unknown as Date[]
    }
    const dayToOffset = this.getDayToOffset()
    const daysInQuote = Object.keys(dayToOffset)
      .map((dateString) => {
        const [year, month, day] = dateString.split('-')
        return new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day))
      })
      .sort((a, b) => a.getTime() - b.getTime())

    setCachedQuote({ cid: this.cid, key: 'daysInQuote', value: daysInQuote, fetchedAt: this.fetchedAt })
    return daysInQuote
  }

  getBarIndexes() {
    if (!this.isIntraday || this.instrument !== Instrument.Stock) {
      return Array.from({ length: this.date.length }).map((_, index) => index)
    }

    const barIndex = []
    const dayToOffset = this.getDayToOffset()

    for (let i = 0; i < this.date.length; i += 1) {
      const date = dateFromUnixTimestamp(this.date[i])
      let x = Math.ceil((date.getHours() * 60 + date.getMinutes() - this.marketStartMinutes) / this.interval)
      x += dayToOffset[dateStringFromDate(date)] * this.getBarsInDay()! // add days
      barIndex[i] = x
    }

    return barIndex
  }

  getBarIndex(i: number) {
    if (!this.isIntraday || this.instrument !== Instrument.Stock) {
      return i
    }
    return this.barIndex[i]
  }

  getBarsInDay() {
    if (!this.isIntraday || this.instrument !== Instrument.Stock) {
      return
    }
    return Math.ceil(this.drawMinutesPerDay / this.interval)
  }

  getDataIndexByBarIndex(index: number) {
    if (this.barIndex) {
      for (let i = 0; i < this.barIndex.length; i++) {
        if (this.barIndex[i] === index) {
          return i
        }
      }
    }
    return -1
  }

  getDataByBarIndex(key: keyof Quote, index: number) {
    const dataIndex = this.getDataIndexByBarIndex(index)
    if (dataIndex > -1) {
      const quoteData = this[key as keyof Quote]! as number[]
      return quoteData[dataIndex] ?? null
    }
    return null
  }

  getDateToIndex() {
    const cachedDateToIndex = getCachedQuote({ cid: this.cid, key: 'dateToIndex', fetchedAt: this.fetchedAt })
    if (cachedDateToIndex) {
      return cachedDateToIndex as unknown as ObjectHash<number>
    }
    const dateToIndex: ObjectHash<number> = {}
    for (let i = 0; i < this.date.length; i++) {
      dateToIndex[this.date[i]] = i
    }
    setCachedQuote({ cid: this.cid, key: 'dateToIndex', value: dateToIndex, fetchedAt: this.fetchedAt })
    return dateToIndex
  }

  getTimestampFomPositionX(positionX: number) {
    if (this.date.length === 0) {
      return 0
    }
    return getTimestampFromPositionX({
      positionX,
      quote: this,
    })
  }

  getPositionXFromTimestamp(timestamp: number) {
    if (this.date.length === 0) {
      return 0
    }
    return getPositionXFromTimestamp({
      timestamp,
      quote: this,
    })
  }

  getUrl(options: QuoteUrlOptions) {
    const config: UrlOptions = {
      ticker: this.ticker,
      instrument: this.instrument,
      timeframe: this.timeframe || 'd1',
      aftermarket: this.aftermarket,
      premarket: this.premarket,
      patterns: this.hasPatterns,
      maxBars: this.maxBars,
      ...options,
      financialAttachments: this.financialAttachments?.join(','),
    }

    const isStock = config.instrument === Instrument.Stock
    const isBrowserElite = !getIsSSr() && document.location.host === 'elite.finviz.com'
    let domain = isBrowserElite && isStock ? 'https://api.finviz.com' : ''

    if (getIsSSr()) {
      domain = process.env.BE_API_URL!
    }

    return `${domain}/api/quote.ashx?${queryString.stringify({ rev: Date.now(), ...config })}`
  }

  getRawTicker() {
    return getRawTickerForInstrument(this.instrument, this.ticker)
  }

  clearData() {
    this.updateAttributes(getEmptyQuote())
  }

  toConfig<T extends Partial<RootChartConfigObject> = RootChartConfigObject>(omitKeys = [] as string[]): T {
    return JSON.parse(JSON.stringify(omit(this.toJSON(), [...Quote.computableKeys, ...omitKeys])))
  }
}

Quote.initClass()

export default Quote
