import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class TRIXConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Trix
  static abbreviation = 'trix'
  static label = 'TRIX'
  static shortLabel = 'TRIX'
  static description =
    'The TRIX indicator is a momentum oscillator that shows the percent rate of change of a triple.smoothed EMA. Positive TRIX values indicate upward momentum, while negative values suggest downward momentum, helping to identify trend reversals.'

  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['trix'] as const
  static calculatedValuesLabels = { trix: 'TRIX' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
