import { DrawingBorder, PaneArea } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import { getRoundedObject, getXYOffsetFromLine } from '../controllers/renderUtils'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Line from './line'

export interface IScaledLineAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  dashLength: number
  lineWidth: number
  strokeStyle: string
  border: DrawingBorder
}

class ScaledLine<Attrs extends IScaledLineAttrs = IScaledLineAttrs> extends Line<Attrs> {
  static type = CanvasElementType.scaledLine

  name = 'Line'

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.renderContent = this.renderContent.bind(this)
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    return {
      border: {
        width: 1,
        color: this.getChartLayoutSettings().ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, y1, y2 } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, y1, y2 })
    const translateXY = getXYOffsetFromLine({ lineWidth: this.attrs.border.width, ...roundedXY })

    context.translate(translateXY.x, translateXY.y)
    context.beginPath()
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)

    context.moveTo(roundedXY.x1, roundedXY.y1)
    if (this.attrs.dashLength) {
      context.setLineDash([this.attrs.dashLength])
    }
    context.lineTo(roundedXY.x2, roundedXY.y2)
    context.stroke()
    context.translate(translateXY.x * -1, translateXY.y * -1)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    if (math.distanceToSegment(area.scaled, this.scaled) <= 10) {
      return true
    }
    return this.thumbsAreInArea(area)
  }
}

ScaledLine.prototype.modalConfig = {
  inputs: [{ type: 'line', name: 'border' }],
}

export default ScaledLine
