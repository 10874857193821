import * as d3 from 'd3'

import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import Chart from '../models/chart'
import utils from '../utils'
import { convertColorToHEX, getHEXWithSpecificAplha } from '../utils/colors'
import { getAreNoBarsVisible, getVisibleBarToRenderIndex } from '../utils/draw_in_visible_area'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, VPConfig } from './configs/vp'
import Overlay from './overlay'

const VP_COLORS = {
  UP: '#00FF00',
  DOWN: '#FF0000',
}

const DEFAULT_PARAMETERS = {
  Period: 20,
  Opacity: 0.3,
}

function applyOpacityToColor(color: string, opacity: number) {
  return getHEXWithSpecificAplha(convertColorToHEX(color), opacity)
}

function getColorsWithAppliedOpacity(attrs: Attrs) {
  return [
    applyOpacityToColor(attrs.upColor ?? VP_COLORS.UP, attrs.opacity),
    applyOpacityToColor(attrs.downColor ?? VP_COLORS.DOWN, attrs.opacity),
  ]
}

// Only time when we want value to be able to equal start of segment is first segment,
// because start of next segment is equal to end of previous and value would match two segments
function isInSegment(val: number, start: number, end: number, notEqualStart: boolean) {
  return (notEqualStart ? val > start : val >= start) && val <= end
}

function parsePeriod(periodStr: string) {
  const values = periodStr.split(',')
  const period = parseInt(values[0]) || DEFAULT_PARAMETERS.Period
  const opacity = parseFloat(values[1]) || DEFAULT_PARAMETERS.Opacity

  return [period, opacity]
}

class VolumeProfile extends Overlay<Attrs> {
  static config = VPConfig

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [periodInt = 0] = getParsedIntegersFromPeriodString(period)
    return periodInt + 1 // +1 offsets rendering so it doesn't start on first bar which doesn't look OK in SSR rendered charts
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      const [periodInt, opacity] = parsePeriod(period)
      this.attrs.period = periodInt
      this.attrs.opacity = opacity
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    if (typeof this.attrs.period !== 'number') {
      return
    }
    super.renderContent()

    const chartModel = this.model.chart() as Chart
    const { leftOffset, width } = chartModel
    const { left, right } = chartModel.getChartLayoutSettings().ChartSettings
    const chartWidth = width - left.width - right.width

    if (this.data.close.length === 0) return

    const firstBarToRender = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
    })

    const lastBarToRender = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
      chartWidth,
    })

    const areNoBarsVisible = getAreNoBarsVisible(firstBarToRender, lastBarToRender)
    if (areNoBarsVisible) return

    const [upColor, downColor] = getColorsWithAppliedOpacity(this.attrs)
    const [minClose, maxClose] = utils.minMax(this.data.close.slice(firstBarToRender.index, lastBarToRender.index + 1))

    const segment = Math.abs(maxClose - minClose) / this.attrs.period
    const segments = [] // create an empty array to hold the segments

    for (let i = 0; i < this.attrs.period; i++) {
      const segmentStart = minClose + segment * i // calculate start of current segment
      const segmentEnd = segmentStart + segment // calculate end of current segment
      segments.push({ start: segmentStart, end: segmentEnd, up: 0, down: 0 }) // add segment to array
    }

    // loop through visible bars and assign volumes to segments
    for (let i = firstBarToRender.index; i <= lastBarToRender.index; i++) {
      const segmentIndex: number = segments.findIndex((item, segmentIndex) =>
        isInSegment(this.data.close[i], item.start, item.end, !!segmentIndex)
      )
      if (segmentIndex === -1) continue
      const volumeTrend = this.data.close[i] < this.data.open[i] ? 'down' : 'up'
      segments[segmentIndex][volumeTrend] += this.data.volume[i]
    }

    const max = utils.max(segments.map((item) => item.up + item.down))
    const rangeMax = chartWidth * 0.4 // maximum range in pixels where longest bar would reach (now it's 40% of chart width)
    const volumeFX = d3.scaleLinear().range([0, rangeMax]).domain([0, max])

    segments.forEach((item) => {
      const y = Math.round(this.fy(item.start))
      const segmentHeightRaw = Math.round(this.fy(item.end)) - y // raw segment height without gap between segments
      const segmentHeight = segmentHeightRaw + (Math.abs(segmentHeightRaw) > 5 ? 2 : 1) // apply gap to segment based on how high segment is
      const x = Math.abs(leftOffset) // segments are alligned to left side of chart which equal to absoulute value of leftOffset
      const upSegmentWidth = Math.round(volumeFX(item.up))
      const downSegmentWidth = Math.round(volumeFX(item.down))

      context.fillStyle = upColor
      context.fillRect(x, y, upSegmentWidth, segmentHeight)
      context.fillStyle = downColor
      context.fillRect(x + upSegmentWidth, y, downSegmentWidth, segmentHeight)
    })
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Zones',
        name: 'period',
        value: this.attrs.period ?? DEFAULT_PARAMETERS.Period,
        required: true,
        min: 1,
        max: 999999,
      },
      opacity: {
        type: 'number',
        label: 'Opacity',
        name: 'opacity',
        value: this.attrs.opacity ?? DEFAULT_PARAMETERS.Opacity,
        required: true,
        min: 0,
        max: 1,
        step: 0.1,
      },
      upColor: {
        type: 'color',
        label: 'Up Color',
        name: 'upColor',
        value: this.attrs.upColor ?? VP_COLORS.UP,
      },
      downColor: {
        type: 'color',
        label: 'Down Color',
        name: 'downColor',
        value: this.attrs.downColor ?? VP_COLORS.DOWN,
      },
    }

    return {
      title: VPConfig.label,
      inputs: VPConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        opacity: `${options.opacity.label} must be a number between ${options.opacity.min} and ${options.opacity.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'opacity':
        return this.getIsNumberInputValid({ key, integerOnly: false })
      case 'upColor':
      case 'downColor':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.upColor || VP_COLORS.UP
  }
}

VolumeProfile.prototype.defaults = { upColor: VP_COLORS.UP, downColor: VP_COLORS.DOWN }

export default VolumeProfile
