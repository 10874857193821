import { Delayed, Spinner, localStorage } from '@finviz/website'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { getIsMobilePortrait } from '../../utils'
import { SidebarDirection } from './constants'
import { SidebarContext, SidebarContextType } from './hooks/sidebar-context'
import { ModuleLoadFailed } from './modules/module-load-failed'
import { SidebarModule, SidebarModuleType, sidebarModules } from './modules/modules'
import SidebarContent from './sidebar-content'
import SidebarTabs from './sidebar-tabs'

export const SIDEBAR_QUERY_PARAM = 'sidebar'

// This is a temp function, to remove unused property from localstorage
function removeSidebarFromLocalstorage() {
  const SIDEBAR_LOCAL_STORAGE_KEY = 'sidebarModule'
  localStorage.removeValue(SIDEBAR_LOCAL_STORAGE_KEY)
}
removeSidebarFromLocalstorage()

type SidebarProps = {
  onShouldResize: () => void
  direction: SidebarDirection
  tabsClassName?: string
}

// Todo - this is not correct approach but this component can unmount/mount on mobile orientation change so we can't keep it inside the component
let isInit = false

export function Sidebar({ onShouldResize, direction, tabsClassName }: SidebarProps) {
  const navigate = useNavigate()
  const [urlSearchParams] = useSearchParams()
  const sidebarModuleKey = (urlSearchParams.get(SIDEBAR_QUERY_PARAM) as keyof typeof sidebarModules | null) || null
  const [activeModule, setActiveModule] = React.useState<SidebarModuleType | null>(null)

  const isOpen = !!activeModule
  const handleSelectModule = React.useCallback(
    (key?: SidebarModule) => {
      const newUrlSearchParams = new URLSearchParams(urlSearchParams)
      if (key) {
        newUrlSearchParams.set(SIDEBAR_QUERY_PARAM, key)
      } else {
        newUrlSearchParams.delete(SIDEBAR_QUERY_PARAM)
      }
      navigate({ search: newUrlSearchParams.toString() }, { replace: true })
    },
    [urlSearchParams, navigate]
  )

  const handleCloseSidebar = React.useCallback(() => {
    handleSelectModule(undefined)
  }, [handleSelectModule])

  // Resize charts every time the sidebar is opened/closed or active module is changed
  React.useEffect(() => {
    onShouldResize()
  }, [activeModule, onShouldResize])

  // Set active module based on sidebarModuleKey
  React.useEffect(() => {
    let sidebarModule = sidebarModuleKey && sidebarModules[sidebarModuleKey]

    if (!isInit && getIsMobilePortrait()) {
      sidebarModule = null
      handleCloseSidebar()
    }

    setActiveModule(sidebarModule)
    isInit = true
  }, [sidebarModuleKey, handleCloseSidebar])

  const contextValue: SidebarContextType = React.useMemo(
    () => ({
      direction,
      isOpen,
      isCloseVisible: direction === SidebarDirection.horizontal,
      width: activeModule?.width,
      activeModule,
      onResize: onShouldResize,
      close: handleCloseSidebar,
      selectModule: handleSelectModule,
    }),
    [direction, isOpen, onShouldResize, activeModule, handleCloseSidebar, handleSelectModule]
  )

  return (
    <SidebarContext.Provider value={contextValue}>
      {activeModule && (
        <SidebarContent>
          <ErrorBoundary FallbackComponent={ModuleLoadFailed}>
            <React.Suspense
              fallback={
                <Delayed>
                  <div className="flex h-full w-full items-center justify-center">
                    <Spinner />
                  </div>
                </Delayed>
              }
            >
              <activeModule.content />
            </React.Suspense>
          </ErrorBoundary>
        </SidebarContent>
      )}
      <SidebarTabs className={tabsClassName} />
    </SidebarContext.Provider>
  )
}
