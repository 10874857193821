import { DrawingTool, IToolbarDrawingTool, IToolbarDrawingToolItem } from './interfaces'

export const drawingTools: Record<DrawingTool, IToolbarDrawingTool> = {
  [DrawingTool.Mouse]: {
    id: DrawingTool.Mouse,
    title: 'Mouse',
    icon: 'toolMouse',
  },
  [DrawingTool.Measure]: {
    id: DrawingTool.Measure,
    title: 'Measure',
    icon: 'toolMeasure',
  },
  [DrawingTool.ScaledLine]: {
    id: DrawingTool.ScaledLine,
    title: 'Line',
    icon: 'toolLine',
  },
  [DrawingTool.Arrow]: {
    id: DrawingTool.Arrow,
    title: 'Arrow',
    icon: 'toolArrow',
  },
  [DrawingTool.VerticalLine]: {
    id: DrawingTool.VerticalLine,
    title: 'Vertical line',
    icon: 'toolMidLineV',
  },
  [DrawingTool.HorizontalLine]: {
    id: DrawingTool.HorizontalLine,
    title: 'Horizontal line',
    icon: 'toolMidLineH',
  },
  [DrawingTool.Rectangle]: {
    id: DrawingTool.Rectangle,
    title: 'Rectangle',
    icon: 'toolRect',
  },
  [DrawingTool.RotatedRectangle]: {
    id: DrawingTool.RotatedRectangle,
    title: 'Rotated rectangle',
    icon: 'toolRectRotated',
  },
  [DrawingTool.Triangle]: {
    id: DrawingTool.Triangle,
    title: 'Triangle',
    icon: 'toolTriangle',
  },
  [DrawingTool.Ellipse]: {
    id: DrawingTool.Ellipse,
    title: 'Ellipse',
    icon: 'toolEllipse',
  },
  [DrawingTool.Curve]: {
    id: DrawingTool.Curve,
    title: 'Curve',
    icon: 'toolCurve',
  },
  [DrawingTool.Pitchfork]: {
    id: DrawingTool.Pitchfork,
    title: 'Pitchfork',
    icon: 'toolPitchfork',
  },
  [DrawingTool.Polygon]: {
    id: DrawingTool.Polygon,
    title: 'Polygon',
    icon: 'toolPolygon',
  },
  [DrawingTool.Xabcd]: {
    id: DrawingTool.Xabcd,
    title: 'XABCD',
    icon: 'toolXABCD',
  },
  [DrawingTool.ScaledText]: {
    id: DrawingTool.ScaledText,
    title: 'Text',
    icon: 'toolText',
  },
  [DrawingTool.Brush]: {
    id: DrawingTool.Brush,
    title: 'Brush',
    icon: 'toolBrush',
  },
  [DrawingTool.ElliottWaves]: {
    id: DrawingTool.ElliottWaves,
    title: 'Elliott waves',
    icon: 'toolElliottWave',
  },
  [DrawingTool.FibonacciRetracements]: {
    id: DrawingTool.FibonacciRetracements,
    title: 'Fibonacci retracements',
    icon: 'toolFibonacci',
  },
  [DrawingTool.Position]: {
    id: DrawingTool.Position,
    title: 'Position',
    icon: 'toolPosition',
  },
  [DrawingTool.Callout]: {
    id: DrawingTool.Callout,
    title: 'Callout',
    icon: 'toolCallout',
  },
}

export const toolbarDrawingTools: Array<IToolbarDrawingToolItem> = [
  {
    defaultItem: drawingTools[DrawingTool.Mouse],
  },
  {
    defaultItem: drawingTools[DrawingTool.Measure],
  },
  {
    defaultItem: drawingTools[DrawingTool.ScaledLine],
    toolGroup: [
      drawingTools[DrawingTool.ScaledLine],
      drawingTools[DrawingTool.VerticalLine],
      drawingTools[DrawingTool.HorizontalLine],
      drawingTools[DrawingTool.Curve],
    ],
    toolGroupTitle: 'Line tools',
  },
  {
    defaultItem: drawingTools[DrawingTool.Arrow],
  },
  {
    defaultItem: drawingTools[DrawingTool.Rectangle],
  },
  {
    defaultItem: drawingTools[DrawingTool.RotatedRectangle],
  },
  {
    defaultItem: drawingTools[DrawingTool.Triangle],
  },
  {
    defaultItem: drawingTools[DrawingTool.Ellipse],
  },
  {
    defaultItem: drawingTools[DrawingTool.Polygon],
  },
  {
    defaultItem: drawingTools[DrawingTool.Xabcd],
    toolGroup: [drawingTools[DrawingTool.Xabcd], drawingTools[DrawingTool.Pitchfork]],
    toolGroupTitle: 'XABCD tools',
  },
  {
    defaultItem: drawingTools[DrawingTool.ScaledText],
    toolGroup: [drawingTools[DrawingTool.ScaledText], drawingTools[DrawingTool.Callout]],
    toolGroupTitle: 'Text tools',
  },
  {
    defaultItem: drawingTools[DrawingTool.Brush],
  },
  {
    defaultItem: drawingTools[DrawingTool.ElliottWaves],
  },
  {
    defaultItem: drawingTools[DrawingTool.FibonacciRetracements],
  },
  {
    defaultItem: drawingTools[DrawingTool.Position],
  },
]
