// byteLengthCharCode2 from https://stackoverflow.com/a/63893237

export function getByteLengthOfString(s: string) {
  //assuming the String is UCS-2(aka UTF-16) encoded
  let n = 0
  for (let i = 0, l = s.length; i < l; i++) {
    const hi = s.charCodeAt(i)
    if (hi < 0x0080) {
      n += 1
    } else if (hi < 0x0800) {
      n += 2
    } else if (hi < 0xd800) {
      n += 3
    } else if (hi < 0xdc00) {
      const lo = s.charCodeAt(++i)
      if (i < l && lo >= 0xdc00 && lo <= 0xdfff) {
        n += 4
      } else {
        throw new Error('UCS-2 String malformed')
      }
    } else if (hi < 0xe000) {
      throw new Error('UCS-2 String malformed')
    } else {
      n += 3
    }
  }
  return n
}
