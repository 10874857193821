import { isPositiveFloat } from '../utils/helpers'
import { Attrs, HLineConfig } from './configs/hline'
import Overlay from './overlay'

class HorizontalLine extends Overlay<Attrs> {
  static config = HLineConfig

  static getNumOfBarsBuffer() {
    return 0
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    if (typeof this.attrs.period === 'string' && this.attrs.price === undefined) {
      this.attrs.price = parseFloat(this.attrs.period)
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const y = Math.round(this.fy(this.attrs.price))
    context.set('strokeStyle', this.attrs.color)
    context.translate(0, 0.5)
    context.beginPath()
    context.moveTo(-this.model.chart().leftOffset, y)
    context.lineTo(-this.model.chart().leftOffset + this.model.chart().width, y)
    context.stroke()
    context.translate(0, -0.5)
  }

  getModalConfig() {
    const options = {
      price: {
        type: 'number',
        label: 'Price',
        name: 'price',
        value: this.attrs.price || this.model?.scale?.y?.domain().reduce((a: number, b: number) => a + b, 0) / 2,
        required: true,
        step: 0.1,
      },
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: HLineConfig.label,
      inputs: HLineConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        price: `${options.price.label} must be number`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'price':
        return isPositiveFloat(this.attrs[key])
      case 'color':
        return !!this.attrs[key] // use real color validation, we got isValidHexColor but it might be also RGBA
      default:
        return false
    }
  }
}

HorizontalLine.prototype.defaults = { color: '#0587cd' }

export default HorizontalLine
