// if conditionalCursor provided, cursor is set only if current cursor !== conditionalCursor
// otherwise cursor is set if element exists
export const setElementCursor = ({
  elementId,
  cursor,
  conditionalCursor,
}: {
  elementId: string
  cursor: string
  conditionalCursor?: string
}) => {
  const element = document.getElementById(elementId)
  if (element && (conditionalCursor ? element.style.cursor !== conditionalCursor : true)) {
    element.style.cursor = cursor
  }
}
