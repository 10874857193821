import React from 'react'

import Utils from '../utils'

export function useClickAway(callback: (event: MouseEvent | TouchEvent) => void, isListening = true) {
  const ref = React.useRef<HTMLElement | null>(null)
  const refCallback = React.useRef(callback)

  React.useLayoutEffect(() => {
    refCallback.current = callback
  })

  React.useEffect(() => {
    if (!isListening) {
      return
    }
    const isMobile = Utils.isMobile()
    const handler = (e: MouseEvent | TouchEvent) => {
      const element = ref.current
      if (element && e.target && !element.contains(e.target as HTMLElement)) {
        refCallback.current(e)
      }
    }

    document.addEventListener(isMobile ? 'touchstart' : 'mousedown', handler)

    return () => {
      document.removeEventListener(isMobile ? 'touchstart' : 'mousedown', handler)
    }
  }, [callback, isListening])

  return ref
}
