import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: string | number
  dPeriod: number
  kPeriod: number
}

export type Attrs = Options

export class StofuConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Stofu
  static abbreviation = 'stofu'
  static label = 'Stochastics FULL'
  static shortLabel = 'Stochastics FULL'
  static optionsOrder = ['period', 'dPeriod', 'kPeriod']

  static optionsLabels = { period: 'Period', kPeriod: 'K Period', dPeriod: 'D Period' } as { [key: string]: string }

  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
