import { MFICalculation } from '../indicator-calculation/mfi'
import { Attrs, MFIConfig } from './configs/mfi'
import Indicator from './indicator'

class MFI extends Indicator<Attrs> {
  static config = MFIConfig

  declare mfiCalculation: MFICalculation
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (!this.isComputeNecessary()) return

    this.mfiCalculation = new MFICalculation({
      quote: this.data,
      options: { period: this.period },
    })

    this.mfiCalculation.calculate()

    this.lastValue = this.mfiCalculation.calculatedValues.mfi.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.mfiCalculation.calculatedValues.mfi)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.mfiCalculation.calculatedValues.mfi, this.period, 20, 50, 80)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: MFIConfig.label,
      inputs: MFIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default MFI
