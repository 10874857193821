import math from '../../helpers/math'

interface ICoordinates {
  x: number
  y: number
}
export interface IRectangleSidesCoordinates {
  topLeft: ICoordinates
  topRight: ICoordinates
  rightTop: ICoordinates
  rightBottom: ICoordinates
  bottomRight: ICoordinates
  bottomLeft: ICoordinates
  leftBottom: ICoordinates
  leftTop: ICoordinates
}

export interface ITailCoordinates {
  tailRoot: ICoordinates
  tailTip: ICoordinates
}

export enum TailDirection {
  noTail,
  topLeftCorner,
  topRightCorner,
  bottomLeftCorner,
  bottomRightCorner,
  topSide,
  rightSide,
  bottomSide,
  leftSide,
}

export const getRectangleSidesCoordinates = ({
  padding,
  radius,
  x2,
  y2,
  textWidth,
  measuredHeight,
}: {
  padding: number
  radius: number
  x2: number
  y2: number
  textWidth: number
  measuredHeight: number
}): IRectangleSidesCoordinates => ({
  topLeft: {
    x: x2 - padding + radius,
    y: y2 - padding,
  },
  topRight: {
    x: x2 + textWidth + padding - radius,
    y: y2 - padding,
  },
  rightTop: {
    x: x2 + textWidth + padding,
    y: y2 - padding + radius,
  },
  rightBottom: {
    x: x2 + textWidth + padding,
    y: y2 + padding + measuredHeight - radius,
  },
  bottomRight: {
    x: x2 + textWidth + padding - radius,
    y: y2 + padding + measuredHeight,
  },
  bottomLeft: {
    x: x2 - padding + radius,
    y: y2 + padding + measuredHeight,
  },
  leftBottom: {
    x: x2 - padding,
    y: y2 + padding + measuredHeight - radius,
  },
  leftTop: {
    x: x2 - padding,
    y: y2 - padding + radius,
  },
})

export const getTailCoordinates = ({
  rectangleSides,
  x1,
  y1,
}: {
  rectangleSides: IRectangleSidesCoordinates
  x1: number
  y1: number
}): ITailCoordinates => ({
  tailRoot: {
    x: rectangleSides.topLeft.x + (rectangleSides.topRight.x - rectangleSides.topLeft.x) / 2,
    y: rectangleSides.leftTop.y + (rectangleSides.leftBottom.y - rectangleSides.leftTop.y) / 2,
  },
  tailTip: {
    x: x1,
    y: y1,
  },
})

const getTailHalfWidth = (halfWidth: number, p1: ICoordinates, p2: ICoordinates): number => {
  const dist = math.distance(p1, p2)
  if (dist < halfWidth * 2) {
    return dist / 2
  } else {
    return halfWidth
  }
}

export const getTailToSideIntersection = ({
  tail,
  rectangleSides,
}: {
  tail: ITailCoordinates
  rectangleSides: IRectangleSidesCoordinates
}): TailDirection => {
  const { topLeft, topRight, rightTop, rightBottom, bottomRight, bottomLeft, leftBottom, leftTop } = rectangleSides
  const { tailTip, tailRoot } = tail
  if (math.twoSegmentLinesIntersection(tailTip, tailRoot, topLeft, leftTop)) {
    return TailDirection.topLeftCorner
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, topRight, rightTop)) {
    return TailDirection.topRightCorner
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, bottomLeft, leftBottom)) {
    return TailDirection.bottomLeftCorner
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, bottomRight, rightBottom)) {
    return TailDirection.bottomRightCorner
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, topLeft, topRight)) {
    return TailDirection.topSide
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, rightTop, rightBottom)) {
    return TailDirection.rightSide
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, bottomLeft, bottomRight)) {
    return TailDirection.bottomSide
  } else if (math.twoSegmentLinesIntersection(tailTip, tailRoot, leftTop, leftBottom)) {
    return TailDirection.leftSide
  } else {
    return TailDirection.noTail
  }
}

interface IRenderCallout {
  context: any
  radius: number
  requiredTailHalfWidth: number
  tail: ITailCoordinates
  rectangleSides: IRectangleSidesCoordinates
  tailDirection: TailDirection
}

export const renderCallout = ({
  context,
  radius,
  requiredTailHalfWidth,
  tail,
  rectangleSides,
  tailDirection,
}: IRenderCallout): void => {
  const { topLeft, topRight, rightTop, rightBottom, bottomRight, bottomLeft, leftBottom, leftTop } = rectangleSides
  const { tailTip, tailRoot } = tail

  // draw rounded rectangle from top left corner clockwise
  // !!! ORDER OF RENDER MATTERS !!!

  // renderTopLeftCorner
  if (tailDirection === TailDirection.topLeftCorner) {
    context.moveTo(leftTop.x, leftTop.y)
    context.lineTo(tailTip.x, tailTip.y)
    context.lineTo(topLeft.x, topLeft.y)
  } else {
    context.moveTo(leftTop.x, leftTop.y)
    context.arcTo(leftTop.x, topLeft.y, topLeft.x, topLeft.y, radius)
  }

  // renderTopLine
  if (tailDirection === TailDirection.topSide) {
    const tailLineToTopLineIntersection = math.twoSegmentLinesIntersection(tailTip, tailRoot, topLeft, topRight)
    if (!tailLineToTopLineIntersection) {
      context.lineTo(topRight.x, topRight.y)
      return
    }
    const tailHalfWidth = getTailHalfWidth(requiredTailHalfWidth, topLeft, topRight)
    if (tailLineToTopLineIntersection.x - tailHalfWidth < topLeft.x) {
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(topLeft.x + tailHalfWidth * 2, tailLineToTopLineIntersection.y)
    } else if (tailLineToTopLineIntersection.x + tailHalfWidth > topRight.x) {
      context.lineTo(topRight.x - tailHalfWidth * 2, tailLineToTopLineIntersection.y)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(topRight.x, tailLineToTopLineIntersection.y)
    } else {
      context.lineTo(tailLineToTopLineIntersection.x - tailHalfWidth, tailLineToTopLineIntersection.y)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(tailLineToTopLineIntersection.x + tailHalfWidth, tailLineToTopLineIntersection.y)
      context.lineTo(topRight.x, topRight.y)
    }
  } else {
    context.lineTo(topRight.x, topRight.y)
  }

  // renderTopRightCorner
  if (tailDirection === TailDirection.topRightCorner) {
    context.lineTo(topRight.x, topRight.y)
    context.lineTo(tailTip.x, tailTip.y)
    context.lineTo(rightTop.x, rightTop.y)
  } else {
    context.arcTo(rightTop.x, topRight.y, rightTop.x, rightTop.y, radius)
  }

  // renderRightLine
  if (tailDirection === TailDirection.rightSide) {
    const tailLineToRightLineIntersection = math.twoSegmentLinesIntersection(tailTip, tailRoot, rightTop, rightBottom)
    if (!tailLineToRightLineIntersection) {
      context.lineTo(rightBottom.x, rightBottom.y)
      return
    }
    const tailHalfWidth = getTailHalfWidth(requiredTailHalfWidth, rightTop, rightBottom)
    if (tailLineToRightLineIntersection.y - tailHalfWidth < rightTop.y) {
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(rightTop.x, rightTop.y + tailHalfWidth * 2)
    } else if (tailLineToRightLineIntersection.y + tailHalfWidth > rightBottom.y) {
      context.lineTo(rightBottom.x, rightBottom.y - tailHalfWidth * 2)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(rightBottom.x, rightBottom.y)
    } else {
      context.lineTo(tailLineToRightLineIntersection.x, tailLineToRightLineIntersection.y - tailHalfWidth)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(tailLineToRightLineIntersection.x, tailLineToRightLineIntersection.y + tailHalfWidth)
      context.lineTo(rightBottom.x, rightBottom.y)
    }
  } else {
    context.lineTo(rightBottom.x, rightBottom.y)
  }

  // renderBottomRightCorner
  if (tailDirection === TailDirection.bottomRightCorner) {
    context.lineTo(rightBottom.x, rightBottom.y)
    context.lineTo(tailTip.x, tailTip.y)
    context.lineTo(bottomRight.x, bottomRight.y)
  } else {
    context.arcTo(rightBottom.x, bottomRight.y, bottomRight.x, bottomRight.y, radius)
  }

  // renderBottomLine
  if (tailDirection === TailDirection.bottomSide) {
    const tailLineToBottomLineIntersection = math.twoSegmentLinesIntersection(
      tailTip,
      tailRoot,
      bottomLeft,
      bottomRight
    )
    if (!tailLineToBottomLineIntersection) {
      context.lineTo(bottomLeft.x, bottomLeft.y)
      return
    }
    const tailHalfWidth = getTailHalfWidth(requiredTailHalfWidth, bottomLeft, bottomRight)
    if (tailLineToBottomLineIntersection.x + tailHalfWidth > bottomRight.x) {
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(bottomRight.x - tailHalfWidth * 2, tailLineToBottomLineIntersection.y)
    } else if (tailLineToBottomLineIntersection.x - tailHalfWidth < bottomLeft.x) {
      context.lineTo(bottomLeft.x + tailHalfWidth * 2, tailLineToBottomLineIntersection.y)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(bottomLeft.x, tailLineToBottomLineIntersection.y)
    } else {
      context.lineTo(tailLineToBottomLineIntersection.x + tailHalfWidth, tailLineToBottomLineIntersection.y)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(tailLineToBottomLineIntersection.x - tailHalfWidth, tailLineToBottomLineIntersection.y)
      context.lineTo(bottomLeft.x, bottomLeft.y)
    }
  } else {
    context.lineTo(bottomLeft.x, bottomLeft.y)
  }

  // renderBottomLeftCorner
  if (tailDirection === TailDirection.bottomLeftCorner) {
    context.lineTo(bottomLeft.x, bottomLeft.y)
    context.lineTo(tailTip.x, tailTip.y)
    context.lineTo(leftBottom.x, leftBottom.y)
  } else {
    context.arcTo(leftBottom.x, bottomLeft.y, leftBottom.x, leftBottom.y, radius)
  }

  // renderLeftLine
  if (tailDirection === TailDirection.leftSide) {
    const tailLineToLeftLineIntersection = math.twoSegmentLinesIntersection(tailTip, tailRoot, leftTop, leftBottom)
    if (!tailLineToLeftLineIntersection) {
      context.lineTo(leftTop.x, leftTop.y)
      return
    }
    const tailHalfWidth = getTailHalfWidth(requiredTailHalfWidth, leftTop, leftBottom)
    if (tailLineToLeftLineIntersection.y + tailHalfWidth > leftBottom.y) {
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(leftBottom.x, leftBottom.y - tailHalfWidth * 2)
    } else if (tailLineToLeftLineIntersection.y - tailHalfWidth < leftTop.y) {
      context.lineTo(leftTop.x, leftTop.y + tailHalfWidth * 2)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(leftTop.x, leftTop.y)
    } else {
      context.lineTo(tailLineToLeftLineIntersection.x, tailLineToLeftLineIntersection.y + tailHalfWidth)
      context.lineTo(tailTip.x, tailTip.y)
      context.lineTo(tailLineToLeftLineIntersection.x, tailLineToLeftLineIntersection.y - tailHalfWidth)
      context.lineTo(leftTop.x, leftTop.y)
    }
  } else {
    context.lineTo(leftTop.x, leftTop.y)
  }
}
