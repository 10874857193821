import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  period: number | string
}

export interface Attrs extends Options {
  opacity: number
  upColor?: string
  downColor?: string
}

export class VPConfig extends BaseConfig {
  static type = OverlayType.Vp
  static abbreviation = 'vp'
  static label = 'Volume Profile'
  static shortLabel = 'VP'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = ['period', 'opacity', 'upColor', 'downColor'] as Array<keyof Attrs>

  static inputsLabels = {
    period: 'Zones',
    opacity: 'Opacity',
    upColor: 'Up Color',
    downColor: 'Down Color',
  }
}
