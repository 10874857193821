import { MACDConfig, Options } from '../indicators/configs/macd'
import { ExponentialMovingAverage } from './ema'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  macd: number[]
  signal: number[]
  histogram: number[]
}

export class MACDCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = MACDConfig

  calculate() {
    const { close } = this.quote
    const { fastPeriod, slowPeriod, signalPeriod } = this.options

    if (close.length <= slowPeriod) return

    this._calculatedValues = this.getDefaultCalculatedValues()
    const emaFast = new ExponentialMovingAverage(fastPeriod)
    const emaSlow = new ExponentialMovingAverage(slowPeriod)
    const emaSignal = new ExponentialMovingAverage(signalPeriod)
    let macdCount = 0

    for (let i = 0; i < close.length; i++) {
      const d = close[i]

      emaFast.add(d)
      emaSlow.add(d)

      if (i < slowPeriod - 1) continue

      const macd = emaFast.value - emaSlow.value
      emaSignal.add(macd)
      this._calculatedValues.macd[i] = macd
      macdCount += 1

      if (macdCount < signalPeriod) continue

      this._calculatedValues.signal[i] = emaSignal.value
      this._calculatedValues.histogram[i] = macd - emaSignal.value
    }
  }
}
