import { IconNameType, getTimeframeGroups } from '@finviz/website'
import { type To } from 'react-router-dom'

import { TimeframeAndIntradayType } from '../../constants/common'

interface ITimeFrame {
  value: TimeframeAndIntradayType
  label: string
  labelShort: string
  labelPageTitle?: string
}

interface ITimeframeGroup {
  label: string
  timeframes: ITimeFrame[]
}

export function getTimeframeGroupsWithHref({
  isPremium,
  generatePath,
}: {
  isPremium: boolean
  generatePath: (timeframe: ITimeFrame) => To
}) {
  return getTimeframeGroups(isPremium).map((timeFrameGroup: ITimeframeGroup) => ({
    label: timeFrameGroup.label,
    timeframes: timeFrameGroup.timeframes.map((timeFrame) => ({
      ...timeFrame,
      href: generatePath(timeFrame),
    })),
  }))
}

export enum QuoteNodeChartTypes {
  candlesticksimple = 'candlesticksimple',
  candlestick = 'candlestick',
  line = 'line',
  ohlc = 'ohlc',
  heikinashi = 'heikinashi',
  hollowcandlestick = 'hollowcandlestick',
}

export enum QuoteUrlChartTypes {
  cs = 'cs',
  c = 'c',
  l = 'l',
  o = 'o',
  ha = 'ha',
  hc = 'hc',
}

export const QuoteShortToLongType = {
  [QuoteUrlChartTypes.cs]: QuoteNodeChartTypes.candlesticksimple,
  [QuoteUrlChartTypes.c]: QuoteNodeChartTypes.candlestick,
  [QuoteUrlChartTypes.l]: QuoteNodeChartTypes.line,
  [QuoteUrlChartTypes.o]: QuoteNodeChartTypes.ohlc,
  [QuoteUrlChartTypes.ha]: QuoteNodeChartTypes.heikinashi,
  [QuoteUrlChartTypes.hc]: QuoteNodeChartTypes.hollowcandlestick,
} as const

export const QuoteLongToShortType = {
  [QuoteNodeChartTypes.candlesticksimple]: QuoteUrlChartTypes.cs,
  [QuoteNodeChartTypes.candlestick]: QuoteUrlChartTypes.c,
  [QuoteNodeChartTypes.line]: QuoteUrlChartTypes.l,
  [QuoteNodeChartTypes.ohlc]: QuoteUrlChartTypes.o,
  [QuoteNodeChartTypes.heikinashi]: QuoteUrlChartTypes.ha,
  [QuoteNodeChartTypes.hollowcandlestick]: QuoteUrlChartTypes.hc,
} as const

export type TypeBaseOption = {
  label: string
  value: QuoteNodeChartTypes
  icon: IconNameType
  href?: string
}
export const DEFAULT_TYPES: TypeBaseOption[] = [
  { icon: 'candleBarBasic', value: QuoteNodeChartTypes.candlestick, label: 'Candle' },
  { icon: 'lineChartType', value: QuoteNodeChartTypes.line, label: 'Line' },
  { icon: 'ohlcChartType', value: QuoteNodeChartTypes.ohlc, label: 'OHLC' },
  { icon: 'candleBarHollow', value: QuoteNodeChartTypes.hollowcandlestick, label: 'Hollow Candle' },
  { icon: 'candleBarHeikinAshi', value: QuoteNodeChartTypes.heikinashi, label: 'Heikin Ashi' },
]

export const FREE_TYPES_QUOTE: TypeBaseOption[] = [
  { icon: 'candleBarBasic', value: QuoteNodeChartTypes.candlesticksimple, label: 'Candle - simple' },
  { icon: 'candleBarBasic', value: QuoteNodeChartTypes.candlestick, label: 'Candle - advanced' },
  // Filter out the candle from DEFAULT_TYPES, we’re naming it differently
  ...DEFAULT_TYPES.filter((type) => type.value !== QuoteNodeChartTypes.candlestick),
]

export const FREE_TYPES_QUOTE_VALUES = ['candlesticksimple', 'candlestick', 'line']
