import { Attrs, MFIConfig } from '../indicators/configs/mfi'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  mfi: number[]
}

export class MFICalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = MFIConfig

  calculate() {
    const { period } = this.options
    const { high, low, close, volume } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length <= period) return

    const typicalPrice: number[] = []
    const moneyFlow: number[] = []
    // + && - rolling sum means sum of money flows in interval (i-period, i)
    let positiveMoneyFlowRollingSum = 0
    let negativeMoneyFlowRollingSum = 0
    for (let i = 0; i < close.length; i++) {
      typicalPrice[i] = (close[i] + low[i] + high[i]) / 3

      if (i === 0) continue

      const currentMoneyFlow = typicalPrice[i] * volume[i]

      // while i < period increment money flow period sums
      if (typicalPrice[i] > typicalPrice[i - 1]) {
        positiveMoneyFlowRollingSum += currentMoneyFlow
        moneyFlow[i] = currentMoneyFlow
      } else if (typicalPrice[i] < typicalPrice[i - 1]) {
        negativeMoneyFlowRollingSum += currentMoneyFlow
        moneyFlow[i] = -currentMoneyFlow
      }

      if (i < period) continue

      // from the point when we have n = period data calculated
      // we keep sum as period rolling sum
      const oldMoneyFlow = moneyFlow[i - period]
      if (oldMoneyFlow > 0) {
        positiveMoneyFlowRollingSum -= oldMoneyFlow
      } else if (oldMoneyFlow < 0) {
        negativeMoneyFlowRollingSum += oldMoneyFlow
      }

      // and are able to calculate first mfi value
      this._calculatedValues.mfi[i] = 100 - 100 / (1 + positiveMoneyFlowRollingSum / negativeMoneyFlowRollingSum)
    }
  }
}
