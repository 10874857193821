import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Attrs = {
  period: string
  code: string
}

export class COTConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Cot
  static abbreviation = 'cot'
  static label = 'COT'
  static shortLabel = 'CCI'
}
