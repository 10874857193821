import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../../types/shared'
import { CanvasElementType, TextAlign, TextBaseline } from '../../constants/common'
import { getOffsetFromLineWidth } from '../../controllers/renderUtils'
import math from '../../helpers/math'
import PaneModel from '../../models/pane'
import Element from '../element'
import Text from '../text'
import Thumb from '../thumb'

export interface IElliottWavesAttrs {
  waves: Array<{ x: number; y: number }>
  positionWavesTimestamps?: Array<{ x: number; y: number }>
  border: DrawingBorder
}

class ElliottWaves<Attrs extends IElliottWavesAttrs = IElliottWavesAttrs> extends Element<Attrs> {
  static type = CanvasElementType.elliottWaves

  name = 'Elliott Wave'
  waves = ['', 'I', 'II', 'III', 'IV', 'V']

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
  }

  getDefaults() {
    return {
      border: {
        width: 2,
        color: this.getChartLayoutSettings().ElementSettings.Colors.line,
      },
    } as Partial<Attrs>
  }

  renderContent(context: CanvasRenderingContext2D) {
    const offset = getOffsetFromLineWidth(this.attrs.border.width)
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)

    context.translate(offset, offset)
    context.beginPath()
    context.moveTo(Math.round(this.fx(this.attrs.waves[0].x)), Math.round(this.fy(this.attrs.waves[0].y)))
    for (const p of this.attrs.waves.slice(1)) {
      context.lineTo(Math.round(this.fx(p.x)), Math.round(this.fy(p.y)))
    }
    context.stroke()
    context.translate(offset * -1, offset * -1)

    const text = new Text(
      {
        font: { size: 8, style: 'bold' },
        textBaseline: TextBaseline.middle,
        textAlign: TextAlign.center,
        fillStyle: this.getChartLayoutSettings().ElementSettings.Colors.textWithoutBackground,
      },
      this.model
    )
    for (let i = 0; i < this.attrs.waves.length; i++) {
      let y
      const p = this.attrs.waves[i]
      if (i > 0 && this.attrs.waves[i - 1].y > p.y) {
        y = this.fy(p.y) + 8
      } else {
        y = this.fy(p.y) - 8
      }
      text.set({ text: this.waves[i], x: this.fx(p.x), y })
      text.render(context)
    }

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getThumbs() {
    return this.attrs.waves.map(
      (wave, index) =>
        new Thumb(
          `${index}`,
          () => wave.x,
          () => wave.y,
          this.resize,
          this.model
        )
    )
  }

  cachePointPositionTimestamp = () => {
    const quote = this.model?.chart().quote()
    if (quote) {
      const positionWavesTimestamps = this.attrs.waves.map((wave) => ({
        ...wave,
        x: quote.getTimestampFomPositionX(wave.x),
      }))

      this.set({ positionWavesTimestamps } as Partial<Attrs>)
    }
  }

  updateScales() {
    const quote = this.model.chart().quote()
    const { positionWavesTimestamps } = this.attrs
    if (!quote || !positionWavesTimestamps) {
      // positionWavesTimestamps check is temporary to prevent app from crashing
      // caused by corrupted drawings - https://github.com/finvizhq/charts/pull/1386/files
      return
    }
    const waves = positionWavesTimestamps.map((wave) => ({
      ...wave,
      x: quote.getPositionXFromTimestamp(wave.x),
    }))
    this.set({ waves } as Partial<Attrs>)
  }

  moveBy(x: number, y: number) {
    for (const point of this.attrs.waves) {
      point.x += x
      point.y += y
    }
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    this.attrs.waves[+type].x += difX
    this.attrs.waves[+type].y += difY
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const waves = this.attrs.waves
    for (let i = 0; i < waves.length - 1; i++) {
      if (
        math.distanceToSegment(area.scaled, {
          x1: this.fx(waves[i].x),
          y1: this.fy(waves[i].y),
          x2: this.fx(waves[i + 1].x),
          y2: this.fy(waves[i + 1].y),
        }) <= 10
      ) {
        return true
      }
    }
    return super.isInArea(area)
  }

  getAutosaveOmittedProperties() {
    return ['waves']
  }
}

ElliottWaves.prototype.modalConfig = {
  inputs: [{ type: 'line', name: 'border' }],
}

export default ElliottWaves
