import { Button, Icon, Paragraph, Tooltip, TooltipTrigger, useTooltipState } from '@finviz/website'
import classnames from 'classnames'
import React from 'react'

import { SidebarDirection } from './constants'
import { useSidebarContext } from './hooks/sidebar-context'
import { sidebarModules } from './modules/modules'

export const MOBILE_TOOLBAR_DIV_ID = 'toolbar-mobile'

type SidebarTabsProps = {
  className?: string
}

export default function SidebarTabs({ className }: SidebarTabsProps) {
  const { isOpen, direction, activeModule, selectModule } = useSidebarContext()
  const tooltipState = useTooltipState({ placement: 'left', showTimeout: 300 })

  return (
    <div
      className={classnames(
        'flex shrink-0 border-t border-gray-100 bg-white p-1.5 pb-inset-bottom [--inset-b:0.5rem] dark:border-gray-700 dark:bg-gray-800',
        {
          'border-l': isOpen && direction === SidebarDirection.vertical,
          'flex-col space-y-2': direction === SidebarDirection.vertical,
          'flex-row': direction === SidebarDirection.horizontal,
        },
        className
      )}
    >
      {/* reserved space for drawing button on mobile devices */}
      {direction === SidebarDirection.horizontal && (
        <div
          id={MOBILE_TOOLBAR_DIV_ID}
          data-testid={`${MOBILE_TOOLBAR_DIV_ID}-btn`}
          className="relative ml-0 mr-auto w-20"
        />
      )}

      {Object.entries(sidebarModules).map(([key, value]) => {
        const isActive = activeModule?.key === key
        return (
          <React.Fragment key={key}>
            <TooltipTrigger
              focusable
              render={
                <Button
                  appearance="square"
                  size="medium"
                  active={isActive}
                  theme="chipTransparent"
                  onClick={() => {
                    selectModule(isActive ? undefined : value.key)
                  }}
                  className={classnames({ 'ml-2': direction === SidebarDirection.horizontal })}
                  data-testid={`sidebar-${key}-btn`}
                >
                  <Icon name={value.icon} width={24} className="shrink-0" />
                </Button>
              }
              state={tooltipState}
              aria-label={value.description}
              isHideOnClick
            />

            <Tooltip state={tooltipState}>
              <Paragraph size="small">{value.description}</Paragraph>
            </Tooltip>
          </React.Fragment>
        )
      })}
    </div>
  )
}
