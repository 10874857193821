import { Theme } from '../../../types/shared'
import {
  CHART_MARGIN,
  FONT_SIZE,
  LINE_HEIGHT,
  OFFSET,
  PADDING,
  SETTINGS_HEIGHT,
  TICKER_TIMEFRAME_CHANGE_HEIGHT,
  TextBaseline,
} from '../../constants/common'
import {
  chartDarkTheme,
  chartLightTheme,
  elementDarkTheme,
  elementLightTheme,
  generalColors,
  indicatorDarkTheme,
  indicatorLightTheme,
} from '../constants'
import { ISettings } from './interfaces'

export const INTERACTIVE_CHART_SETTINGS = (theme: Theme): ISettings => ({
  ChartSettings: {
    general: {
      Colors: {
        ...(theme === Theme.light ? chartLightTheme : chartDarkTheme),
        [Theme.light]: chartLightTheme,
        [Theme.dark]: chartDarkTheme,
      },
      settingsHeight: SETTINGS_HEIGHT,
      tickerTimeframeChangeHeight: TICKER_TIMEFRAME_CHANGE_HEIGHT,
    },
    center: {
      barWidth: 3,
      barMargin: 1,
      border: 1,
    },
    top: {
      height: CHART_MARGIN.XL,
      baseline: TextBaseline.alphabetic,
      ticker: {
        margin: {
          top: 30,
          left: 0,
        },
        font: {
          size: FONT_SIZE.XXXL,
          style: 'bold',
        },
      },
      ohlc: {
        margin: {
          top: 30,
          left: 30,
          right: 30,
        },
        font: {
          size: FONT_SIZE.S,
        },
      },
      change: {
        margin: {
          top: 30,
          right: 0,
        },
        font: {
          size: FONT_SIZE.M,
          style: 'bold',
        },
      },
    },
    right: {
      width: CHART_MARGIN.XXL,
      axis: {
        margin: {
          left: 0,
        },
        font: {
          size: FONT_SIZE.S,
          lineHeight: LINE_HEIGHT.S,
          padding: {
            top: PADDING.XXS,
            bottom: PADDING.XXS,
            left: PADDING.XXS,
            right: PADDING.XXS,
          },
        },
      },
    },
    bottom: {
      height: CHART_MARGIN.M,
      baseline: TextBaseline.top,
      axis: {
        margin: {
          top: 8,
        },
        font: {
          size: FONT_SIZE.S,
        },
      },
    },
    left: {
      width: CHART_MARGIN.L,
      overlayLabel: {
        labelSpacing: 12,
        margin: {
          top: -8,
          left: 6,
        },
        font: {
          size: FONT_SIZE.S,
          weight: 'bold',
        },
      },
      timeframe: {
        margin: {
          top: 0,
          left: 28,
        },
        font: {
          size: FONT_SIZE.M,
          weight: '900',
          family: 'Lato, sans-serif',
        },
      },
      volumeAxis: {
        margin: {
          left: 33,
        },
        font: {
          size: FONT_SIZE.S,
        },
      },
    },
  },
  IndicatorSettings: {
    general: {
      height: 140,
      Colors: theme === Theme.light ? indicatorLightTheme : indicatorDarkTheme,
    },
    center: {
      barWidth: 3,
      barMargin: 2,
      border: 1,
    },
    top: {
      height: CHART_MARGIN.S,
    },
    right: {
      width: CHART_MARGIN.XXL,
      axis: {
        margin: {
          left: 0,
        },
        font: {
          size: FONT_SIZE.S,
          lineHeight: LINE_HEIGHT.S,
          padding: {
            top: PADDING.XXS,
            bottom: PADDING.XXS,
            left: PADDING.XXS,
            right: PADDING.XXS,
          },
        },
      },
    },
    bottom: {
      height: CHART_MARGIN.S,
    },
    left: {
      width: CHART_MARGIN.L,
      indicatorLabel: {
        labelSpacing: 4,
        margin: {
          top: OFFSET.S,
          left: OFFSET.M,
        },
        font: {
          size: FONT_SIZE.M,
          style: 'bold',
          lineHeight: LINE_HEIGHT.M,
        },
      },
    },
  },
  MarketSentimentSettings: {
    general: {
      Colors: theme === Theme.light ? chartLightTheme : chartDarkTheme,
    },
    center: {
      barWidth: 3,
      barMargin: 1,
      border: 1,
    },
    top: {
      height: 0,
    },
    bottom: {
      height: 12,
    },
    left: {
      width: 0,
    },
    right: {
      width: 30,
    },
  },
  ColorsSettings: generalColors,
  ThumbSettings: {
    size: 8,
    touchSize: 16,
    color: '#ddd',
    borderWidth: 1,
    borderColor: '#999',
  },
  ElementSettings: {
    Colors: theme === Theme.light ? elementLightTheme : elementDarkTheme,
    defaultFill: '#1e6dc066',
    defaultTransparentFill: '#1e6dc000',
  },
})
