import { Bezier } from 'bezier-js'

import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import Chart from '../models/chart'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

export interface ICurveAttrs {
  x1: number
  x2: number
  x3: number
  cp1x: number
  cp2x: number
  y1: number
  y2: number
  y3: number
  cp1y: number
  cp2y: number
  border: DrawingBorder
}

class Curve<Attrs extends ICurveAttrs = ICurveAttrs> extends Element<Attrs> {
  static type = CanvasElementType.curve

  name = 'Curve'
  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'x3' | 'y1' | 'y2' | 'y3'>
  declare curve: Bezier

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.render = this.render.bind(this)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'a',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'b',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'c',
        () => this.attrs.x3 || this.attrs.x2,
        () => this.attrs.y3 || this.attrs.y2,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    return {
      border: {
        width: 1,
        color: this.getChartLayoutSettings().ElementSettings.Colors.line,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2', 'x3'], y: ['y1', 'y2', 'y3'] })

  drawQuadraticCurve(context: CanvasRenderingContext2D, curve: Bezier) {
    const { points } = curve
    context.beginPath()
    context.moveTo(points[0].x, points[0].y)
    context.quadraticCurveTo(points[1].x, points[1].y, points[2].x, points[2].y)
    context.stroke()
  }

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, x3, y1, y2, y3 } = this.scaled
    context.translate(0.5, 0.5)
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    if (!x3 || !y3) {
      context.beginPath()
      context.moveTo(x1, y1)
      context.lineTo(x2, y2)
      context.lineTo(x3, y3)
      context.stroke()
    } else {
      this.curve = Bezier.quadraticFromPoints({ x: x1, y: y1 }, { x: x2, y: y2 }, { x: x3, y: y3 }, 0.5)
      this.drawQuadraticCurve(context, this.curve)
    }

    context.translate(-0.5, -0.5)
    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.x2 += x
    this.attrs.x3 += x
    this.attrs.cp1x += x
    this.attrs.cp2x += x

    this.attrs.y1 += y
    this.attrs.y2 += y
    this.attrs.y3 += y
    this.attrs.cp1y += y
    this.attrs.cp2y += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'a':
        this.attrs.x1 += difX
        this.attrs.y1 += difY
        break
      case 'b':
        this.attrs.x2 += difX
        this.attrs.y2 += difY
        break
      case 'c':
        this.attrs.x3 += difX
        this.attrs.y3 += difY
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    if (this.curve && this.curve.project(area.scaled).d! <= 10) {
      return true
    }
    return this.thumbsAreInArea(area)
  }

  getIsInChartView(chart: Chart) {
    if (this.getIsCreator()) {
      return true
    }

    const { x1, x2, x3, y1, y2, y3 } = this.scaled
    if (!x3 || !y3) {
      return true
    }
    const isInView =
      Bezier.quadraticFromPoints({ x: x1, y: y1 }, { x: x2, y: y2 }, { x: x3, y: y3 }, 0.5).points.some(
        ({ x, y }) => x <= -chart.leftOffset + chart.width && x >= -chart.leftOffset && y >= 0 && y <= this.model.height
      ) ?? true

    return isInView
  }
}

Curve.prototype.modalConfig = {
  inputs: [{ type: 'line', name: 'border' }],
}

export default Curve
