import { Checkbox } from '@finviz/website'
import React from 'react'

interface CheckBoxProps {
  label: string
  value: boolean
  onChange: (isChecked: boolean) => void
}

export function CheckBox({ label, value, onChange }: CheckBoxProps) {
  return (
    <Checkbox
      checked={value}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange(ev.currentTarget.checked)}
      data-testid="chart-modal-checkbox"
    >
      {label}
    </Checkbox>
  )
}
