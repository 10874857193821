import {
  Button,
  ConfirmationDialog as ConfirmationDialogComponent,
  DialogDismiss,
  DialogStateReturn,
} from '@finviz/website'
import React from 'react'

const getDialogContent = ({
  isAutosaveDialogContent,
  tickers,
}: {
  isAutosaveDialogContent: boolean
  tickers: string[]
}) => {
  if (isAutosaveDialogContent) {
    return {
      title: 'Remove all autosaved drawings?',
      message: (
        <>
          Do you really want to remove all <b>autosaved</b> drawings? This action is irreversible and will remove all
          non-idea drawings for <b>{tickers.join(', ')}</b>.
        </>
      ),
    }
  }
  return {
    title: 'Remove all drawings?',
    message: `Do you really want to remove all drawings? This action only affects the current page and visible tickers. This action doesn't remove autosaved drawings or ideas.`,
  }
}

export interface IConfirmationDialog {
  state: DialogStateReturn
  onAction: () => void
  onCancel?: () => void
  isAutosaveDialogContent: boolean
  tickers: string[]
}

export function ConfirmationDialog({
  state,
  onAction,
  onCancel,
  isAutosaveDialogContent = false,
  tickers,
}: IConfirmationDialog) {
  const dialogContent = getDialogContent({ isAutosaveDialogContent, tickers })

  return (
    <ConfirmationDialogComponent
      state={state}
      aria-label={dialogContent.title}
      title={dialogContent.title}
      actions={
        <>
          <Button data-testid="confirmation-dialog-cancel" as={DialogDismiss} onClick={onCancel}>
            Cancel
          </Button>
          <Button data-testid="confirmation-dialog-delete" onClick={onAction} theme="red">
            Remove
          </Button>
        </>
      }
    >
      {dialogContent.message}
    </ConfirmationDialogComponent>
  )
}
