import { Input } from '@finviz/website'
import React from 'react'

interface StringInputProps {
  label: string
  name: string
  value: string
  required: boolean
  onChange: (value: string) => void
}

export function StringInput(props: StringInputProps) {
  const { label, name, value, required } = props

  return (
    <Input
      type="text"
      label={label}
      name={name}
      required={required}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
      data-testid="chart-modal-string-textinput"
    />
  )
}
