import { PaneArea, ResizeByThumbWithTypeAndDifs } from '../../../types/shared'
import { CanvasElementType } from '../../constants/common'
import { getOffsetFromLineWidth } from '../../controllers/renderUtils'
import math from '../../helpers/math'
import Chart from '../../models/chart'
import PaneModel from '../../models/pane'
import { getHEXWithSpecificAplha } from '../../utils/colors'
import Element from '../element'
import Thumb from '../thumb'
import { getPitchforkCoordinates } from './pitchfork_utils'

export interface ITrendAttrs {
  active: boolean
  trend: number
  color: string
}

export interface IPitchforkAttrs {
  x1: number
  y1: number
  x2: number
  y2: number
  x3: number
  y3: number
  median: { color: string; width: number }
  trend1: ITrendAttrs
  trend2: ITrendAttrs
}

class Pitchfork<Attrs extends IPitchforkAttrs = IPitchforkAttrs> extends Element<Attrs> {
  static type = CanvasElementType.pitchforkV1

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'x3' | 'y1' | 'y2' | 'y3'>
  name = 'Pitchfork'

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.resize = this.resize.bind(this)

    this._thumbs = [
      new Thumb(
        'medianRayOrigin',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'rayDirectionControlPoint1',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'rayDirectionControlPoint2',
        () => this.attrs.x3,
        () => this.attrs.y3,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    return {
      median: { color: '#e72f2f', width: 1 },
      trend1: {
        color: '#004ad31c',
        active: true,
        trend: 1,
      },
      trend2: {
        color: '#00ca4e1f',
        active: true,
        trend: 0.5,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2', 'x3'], y: ['y1', 'y2', 'y3'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, x3, y1, y2, y3 } = this.scaled

    const offset = getOffsetFromLineWidth(this.attrs.median.width)
    context.translate(offset, offset)

    const { medianRay, trend1Ray1, trend1Ray2, trend2Ray1, trend2Ray2 } = getPitchforkCoordinates({
      paneModel: this.model,
      rayOrigin: { x: x1, y: y1 },
      rayDirectionControlPoint1: { x: x2, y: y2 },
      rayDirectionControlPoint2: Number.isNaN(x3) || Number.isNaN(y3) ? undefined : { x: x3, y: y3 },
      trend1: this.attrs.trend1,
      trend2: this.attrs.trend2,
    })

    context.set('lineWidth', this.attrs.median.width)

    const trendLines = [
      { trend: this.attrs.trend1, line1: trend1Ray1, line2: trend1Ray2 },
      { trend: this.attrs.trend2, line1: trend2Ray1, line2: trend2Ray2 },
    ].sort((a, b) => {
      if (Math.abs(a.trend.trend) > Math.abs(b.trend.trend)) {
        return -1
      }
      if (Math.abs(a.trend.trend) < Math.abs(b.trend.trend)) {
        return 1
      }
      return 0
    })

    // Draw trend lines and fill
    trendLines.forEach((trendLine, trendLineIndex) => {
      if (trendLine.line1 && trendLine.line2) {
        const { line1, line2 } = trendLine
        const solidTrendColor = getHEXWithSpecificAplha(trendLine.trend.color)
        const nextTrendLine = trendLines[trendLineIndex + 1]

        context.beginPath()
        context.moveTo(line1.P1.x, line1.P1.y)
        context.lineTo(line1.P2.x, line1.P2.y)
        if (trendLineIndex !== trendLines.length - 1 && nextTrendLine.line1 && nextTrendLine.line2) {
          context.lineTo(nextTrendLine.line1.P2.x, nextTrendLine.line1.P2.y)
          context.lineTo(nextTrendLine.line1.P1.x, nextTrendLine.line1.P1.y)
          context.moveTo(nextTrendLine.line2.P1.x, nextTrendLine.line2.P1.y)
          context.lineTo(nextTrendLine.line2.P2.x, nextTrendLine.line2.P2.y)
        }
        context.lineTo(line2.P2.x, line2.P2.y)
        context.lineTo(line2.P1.x, line2.P1.y)
        context.closePath()
        context.set('fillStyle', trendLine.trend.color)
        context.fill()

        context.beginPath()
        context.moveTo(line1.P1.x, line1.P1.y)
        context.lineTo(line1.P2.x, line1.P2.y)
        context.moveTo(line2.P1.x, line2.P1.y)
        context.lineTo(line2.P2.x, line2.P2.y)
        context.set('strokeStyle', solidTrendColor)
        context.stroke()
      }
    })

    // Draw median ray line
    context.beginPath()
    context.moveTo(medianRay.P1.x, medianRay.P1.y)
    context.lineTo(medianRay.P2.x, medianRay.P2.y)

    // Draw line between outer trend lines origin points or if smaller than 1 between direction control points
    const outerTrendLines =
      trendLines.filter(
        (item) => item.line1 && item.line2 && item.trend.active && Math.abs(item.trend.trend) >= 1
      )[0] ?? {}

    if (outerTrendLines.line1 && outerTrendLines.line2) {
      context.moveTo(outerTrendLines.line1.P1.x, outerTrendLines.line1.P1.y)
      context.lineTo(outerTrendLines.line2.P1.x, outerTrendLines.line2.P1.y)
    } else if (!Number.isNaN(x3) && !Number.isNaN(y3)) {
      context.moveTo(x2, y2)
      context.lineTo(x3, y3)
    }

    context.set('strokeStyle', this.attrs.median.color)
    context.stroke()

    context.translate(offset * -1, offset * -1)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.y1 += y
    this.attrs.x2 += x
    this.attrs.y2 += y
    this.attrs.x3 += x
    this.attrs.y3 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'medianRayOrigin':
        this.attrs.x1 += difX
        this.attrs.y1 += difY
        break
      case 'rayDirectionControlPoint1':
        this.attrs.x2 += difX
        this.attrs.y2 += difY
        break
      case 'rayDirectionControlPoint2':
        this.attrs.x3 += difX
        this.attrs.y3 += difY
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const { x1, x2, x3, y1, y2, y3 } = this.scaled
    const { medianRay, trend1Ray1, trend1Ray2, trend2Ray1, trend2Ray2 } = getPitchforkCoordinates({
      paneModel: this.model,
      rayOrigin: { x: x1, y: y1 },
      rayDirectionControlPoint1: { x: x2, y: y2 },
      rayDirectionControlPoint2: Number.isNaN(x3) || Number.isNaN(y3) ? undefined : { x: x3, y: y3 },
      trend1: this.attrs.trend1,
      trend2: this.attrs.trend2,
    })
    const outerTrendLines =
      Math.abs(this.attrs.trend1.trend) > Math.abs(this.attrs.trend2.trend)
        ? { line1: trend1Ray1, line2: trend1Ray2 }
        : { line1: trend2Ray1, line2: trend2Ray2 }

    if (
      math.distanceToSegment(area.scaled, {
        x1: medianRay.P1.x,
        y1: medianRay.P1.y,
        x2: medianRay.P2.x,
        y2: medianRay.P2.y,
      }) <= 10
    ) {
      // cursor is near median ray
      return true
    } else if (
      !Number.isNaN(x3) &&
      !Number.isNaN(y3) &&
      math.distanceToSegment(area.scaled, {
        x1: x2,
        y1: y2,
        x2: x3,
        y2: y3,
      }) <= 10
    ) {
      // cursor is near line connecting direction control points
      return true
    } else if (
      outerTrendLines.line1 &&
      outerTrendLines.line2 &&
      math.pointInPolygon(area.scaled, [
        outerTrendLines.line1.P1,
        outerTrendLines.line1.P2,
        outerTrendLines.line2.P2,
        outerTrendLines.line2.P1,
      ])
    ) {
      // cursor is on polygon created by outer trend lines
      return true
    }
    return super.isInArea(area)
  }

  getIsInChartView(_: Chart) {
    return true
  }
}

Pitchfork.prototype.modalConfig = {
  inputs: [
    { type: 'line', name: 'median' },
    { type: 'trend', name: 'trend1' },
    { type: 'trend', name: 'trend2' },
  ],
}

export default Pitchfork
