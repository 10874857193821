import {
  Notification,
  Paragraph,
  generatePathForTimeframeDateRange,
  getDateRangeGroupsWithHref,
  timeframeUtm,
  useNotification,
} from '@finviz/website'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { Instrument } from '../../../types/shared'
import { SpecificChartFunctionality, TIMEFRAME, TimeframeAndIntradayType } from '../../constants/common'
import Chart from '../../models/chart'
import Quote from '../../models/quote'
import { dateFromUnixTimestamp } from '../../utils'
import { getChartStartEndDates, getVisibleBarsNumber } from '../renderUtils'
import { useChartsPathLocation } from './use-charts-path-location'
import { getTimeframeGroupsWithHref } from './utils'

interface IPathForTimeframeDateRangeProps {
  timeframe: TimeframeAndIntradayType
  dateRange?: string | null
  ticker?: string
}

export interface IDateRange {
  value: string
  timeframe: TIMEFRAME
}

export function useChartModelCustomDateRangeValues({
  chartModel,
  isPremium,
}: {
  chartModel: Chart | null
  isPremium: boolean
}) {
  const notificationContext = useNotification()
  const location = useLocation()
  const [isFetching, setIsFetching] = React.useState(false)
  const chartLayoutModel = chartModel?.chart_layout() || null
  const chartIndex = chartLayoutModel?.getAllCharts().findIndex((chart) => chartModel!.eql(chart)) ?? -1
  const { getChartsToPath } = useChartsPathLocation({ chartLayoutModel, chartIndex })
  const specificChartFunctionality = chartLayoutModel?.specificChartFunctionality ?? SpecificChartFunctionality.default

  const anchorDate = React.useMemo(() => {
    if (!chartModel) return new Date()
    const quote = chartModel.quote()
    return dateFromUnixTimestamp(quote.date[quote.date.length - 1])
  }, [chartModel])

  const generateChartLayoutRouterPath = React.useCallback(
    ({ ticker, timeframe, dateRange }: IPathForTimeframeDateRangeProps) => {
      if (specificChartFunctionality === SpecificChartFunctionality.chartPage) {
        return getChartsToPath({
          ticker: ticker,
          timeframe: timeframe as TIMEFRAME,
          dateRange: dateRange,
        })
      }

      return generatePathForTimeframeDateRange({
        isPremium,
        pathname: location.pathname,
        timeframe: timeframe,
        dateRange: dateRange,
        utmCampaign: timeframeUtm[specificChartFunctionality],
      })
    },
    [getChartsToPath, isPremium, specificChartFunctionality, location.pathname]
  )

  const timeframeGroupsWithHref = (isPremium: boolean) =>
    getTimeframeGroupsWithHref({
      isPremium,
      generatePath: (timeFrame) => generateChartLayoutRouterPath({ timeframe: timeFrame.value, dateRange: null }),
    })

  const dateRangeGroupsWithHref = ({
    isPremium,
    customRange,
    instrument,
  }: {
    isPremium: boolean
    customRange?: string
    instrument: Instrument
  }) =>
    getDateRangeGroupsWithHref({
      isPremium,
      customRange,
      instrument,
      generatePath: (dateRange: { timeframe?: TIMEFRAME; value: string }) =>
        generateChartLayoutRouterPath({ timeframe: dateRange.timeframe!, dateRange: dateRange.value }),
    })

  const handleCustomDateRangeValidation = async (dateRangeState: { value: string | null; timeframe: TIMEFRAME }) => {
    if (dateRangeState.value !== null) {
      const { timeframe, ticker, instrument, premarket, aftermarket } = chartModel?.quote() || {}
      const patterns = !!chartModel?.getHasPatterns()
      if (!anchorDate || !instrument || !ticker || !timeframe || !dateRangeState.value) return false
      setIsFetching(true)
      const quote = await Quote.get({
        ticker,
        instrument,
        timeframe: dateRangeState.timeframe,
        options: {
          premarket,
          aftermarket,
          patterns,
          cachePredicate: (quote) => quote.ideaID === undefined,
          fetchNewDataOnCachedQuote: true,
        },
      })
      setIsFetching(false)

      const { numOfVisibleBars } = getVisibleBarsNumber({
        ...getChartStartEndDates({ dateRange: dateRangeState.value, quote }),
        quote,
        dateRange: dateRangeState.value,
      })
      if (numOfVisibleBars === 0) {
        notificationContext.show(
          <Notification actions={<></>} timeoutInMs={6000}>
            <Paragraph className="max-w-[17rem]">
              There are no data for the selected date range. Try selecting different dates.
            </Paragraph>
          </Notification>
        )

        return false
      }
    }
    return true
  }

  return {
    anchorDate,
    generateChartLayoutRouterPath,
    timeframeGroupsWithHref,
    dateRangeGroupsWithHref,
    isFetching,
    handleCustomDateRangeValidation,
  }
}
