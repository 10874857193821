import { Attrs, CCIConfig } from '../indicators/configs/cci'
import { MainCalculation } from './main'
import { SimpleMovingAverage } from './sma'

type DefaultCalculatedValuesType = {
  cci: number[]
}
const constant = 0.015

export class CCICalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = CCIConfig

  calculate() {
    const { period } = this.options
    const { high, low, close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return

    const typicalPrice = []
    const tpSma = new SimpleMovingAverage(period)

    for (let i = 0; i < close.length; i++) {
      typicalPrice[i] = (close[i] + low[i] + high[i]) / 3

      tpSma.add(typicalPrice[i])

      if (i < period - 1) {
        continue
      }

      let devSum = 0
      for (let j = i - period + 1; j <= i; j++) {
        devSum += Math.abs(typicalPrice[j] - tpSma.value)
      }

      const dev = devSum / period
      this._calculatedValues.cci[i] = 0
      if (dev > 0) {
        this._calculatedValues.cci[i] = (typicalPrice[i] - tpSma.value) / (constant * dev)
      }
    }
  }
}
