import { DrawingBorder, PaneArea, Point, ResizeByThumbWithTypeAndDifs } from '../../../types/shared'
import { CanvasElementType } from '../../constants/common'
import math from '../../helpers/math'
import PaneModel from '../../models/pane'
import { getContextWithCache } from '../../utils/canvas'
import Element from '../element'
import Thumb from '../thumb'

export interface IEllipseAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  cursorTargetX: number
  cursorTargetY: number

  fill: string
  border: DrawingBorder
}

interface Coordinates {
  P1: Point
  P2: Point
  P3: Point
  P4: Point
  CP1: Point
  CP2: Point
  CP3: Point
  CP4: Point
}

class EllipseV1<Attrs extends IEllipseAttrs = IEllipseAttrs> extends Element<Attrs> {
  static type = CanvasElementType.ellipseV1

  name = 'Ellipse'

  untranslatedContext: CanvasRenderingContext2D | null = null
  declare ellipsePath: Path2D
  declare scaled: Pick<Attrs, 'x1' | 'y1' | 'x2' | 'y2' | 'cursorTargetX' | 'cursorTargetY'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this.untranslatedContext = getContextWithCache(document.createElement('canvas'))
    this._thumbs = [
      new Thumb(
        'left',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'right',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'top',
        () => this.getEllipsePoints(true).P2.x || this.attrs.x1,
        () => this.getEllipsePoints(true).P2.y || this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'bottom',
        () => this.getEllipsePoints(true).P4.x || this.attrs.x2,
        () => this.getEllipsePoints(true).P4.y || this.attrs.y2,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2', 'cursorTargetX'], y: ['y1', 'y2', 'cursorTargetY'] })

  renderContent(context: CanvasRenderingContext2D) {
    const coordinates = this.getEllipsePoints() as Coordinates
    const ellipse = new Path2D()
    context.set('fillStyle', this.attrs.fill)
    context.beginPath()
    if (coordinates.P2.x === coordinates.P3.x || coordinates.P2.y === coordinates.P3.y) {
      ellipse.lineTo(coordinates.P1.x, coordinates.P1.y)
      ellipse.lineTo(coordinates.P2.x, coordinates.P2.y)
    } else {
      ellipse.moveTo(coordinates.P4.x, coordinates.P4.y)
      ellipse.bezierCurveTo(
        coordinates.CP4.x,
        coordinates.CP4.y,
        coordinates.CP1.x,
        coordinates.CP1.y,
        coordinates.P2.x,
        coordinates.P2.y
      )
      ellipse.bezierCurveTo(
        coordinates.CP2.x,
        coordinates.CP2.y,
        coordinates.CP3.x,
        coordinates.CP3.y,
        coordinates.P4.x,
        coordinates.P4.y
      )
      ellipse.closePath()
      this.ellipsePath = ellipse
      context.fill(ellipse)
    }

    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    context.stroke(ellipse)

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getEllipsePoints(inverted = false) {
    const { x1, x2, y1, y2, cursorTargetX, cursorTargetY } = this.scaled

    const P1 = { x: x2, y: y2 }
    const P3 = { x: x1, y: y1 }

    let perpendicularPoint
    if (!isNaN(cursorTargetX) && !isNaN(cursorTargetY)) {
      const cursor = { x: cursorTargetX, y: cursorTargetY }
      const line = { x1, y1, x2, y2 }
      const distance = math.distanceToLine(cursor, line)
      perpendicularPoint = math.perpendicularPointToLine({
        distance,
        line,
      })
    } else {
      return {
        P1: P3,
        P2: P1,
        P3: P1,
        P4: P3,
      }
    }

    const lineCenterX = (P1.x + P3.x) / 2
    const lineCenterY = (P1.y + P3.y) / 2
    const pointOffsetX = perpendicularPoint.x - P1.x
    const pointOffsetY = perpendicularPoint.y - P1.y

    const P2 = { x: lineCenterX + pointOffsetX, y: lineCenterY + pointOffsetY }
    const P4 = { x: lineCenterX + -1 * pointOffsetX, y: lineCenterY + -1 * pointOffsetY }
    const invertX = this.model.scale.x.invert
    const invertY = this.model.scale.y.invert
    if (inverted) {
      return {
        P2: { x: invertX(P2.x), y: invertY(P2.y) },
        P4: { x: invertX(P4.x), y: invertY(P4.y) },
      }
    }

    const perpendicularPointEllipseOffsetX = (perpendicularPoint.x - P2.x) / 0.75 - (perpendicularPoint.x - P2.x)
    const perpendicularPointEllipseOffsetY = (perpendicularPoint.y - P2.y) / 0.75 - (perpendicularPoint.y - P2.y)

    const P1WithOffsetX = P1.x + perpendicularPointEllipseOffsetX
    const P1WithOffsetY = P1.y + perpendicularPointEllipseOffsetY

    const P3WithOffsetX = P3.x - perpendicularPointEllipseOffsetX
    const P3WithOffsetY = P3.y - perpendicularPointEllipseOffsetY

    const CP1 = {
      x: perpendicularPoint.x + perpendicularPointEllipseOffsetX,
      y: perpendicularPoint.y + perpendicularPointEllipseOffsetY,
    }
    const controlPointOffsetX = CP1.x - P1WithOffsetX
    const controlPointOffsetY = CP1.y - P1WithOffsetY

    const CP2 = { x: P3WithOffsetX + controlPointOffsetX, y: P3WithOffsetY + controlPointOffsetY }
    const CP3 = { x: P3WithOffsetX + -1 * controlPointOffsetX, y: P3WithOffsetY + -1 * controlPointOffsetY }
    const CP4 = { x: P1WithOffsetX + -1 * controlPointOffsetX, y: P1WithOffsetY + -1 * controlPointOffsetY }

    return {
      P1,
      P2,
      P3,
      P4,
      CP1,
      CP2,
      CP3,
      CP4,
    }
  }

  getRotatedPointCoordinates = ({
    rotationAxisPoint,
    pointToRotate,
    oldLinePoint,
    newLinePoint,
  }: {
    rotationAxisPoint: { x: number; y: number }
    pointToRotate: { x: number; y: number }
    oldLinePoint: { x: number; y: number }
    newLinePoint: { x: number; y: number }
  }) => {
    const angle = math.sharedPointLinesAngle({
      sharedPoint: rotationAxisPoint,
      oldLinePoint,
      newLinePoint,
    })
    const rotatedPoinCoordinates = math.rotatedPointCoordinates({
      rotationAxisPoint,
      angle,
      pointToRotate,
    })
    return {
      x: this.model.scale.x.invert(rotatedPoinCoordinates.x),
      y: this.model.scale.y.invert(rotatedPoinCoordinates.y),
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.y1 += y
    this.attrs.x2 += x
    this.attrs.y2 += y
    this.attrs.cursorTargetX += x
    this.attrs.cursorTargetY += y
  }

  resize({ type, difX, difY, area }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'left':
        const newLeftPoint = {
          x: this.attrs.x1 + difX,
          y: this.attrs.y1 + difY,
        }
        const leftRotatedCursorPoint = this.getRotatedPointCoordinates({
          rotationAxisPoint: { x: this.scaled.x2, y: this.scaled.y2 },
          pointToRotate: {
            x: this.scaled.cursorTargetX,
            y: this.scaled.cursorTargetY,
          },
          oldLinePoint: { x: this.scaled.x1, y: this.scaled.y1 },
          newLinePoint: { x: this.model.scale.x(newLeftPoint.x), y: this.model.scale.y(newLeftPoint.y) },
        })
        this.attrs = {
          ...this.attrs,
          x1: newLeftPoint.x,
          y1: newLeftPoint.y,
          cursorTargetX: leftRotatedCursorPoint.x,
          cursorTargetY: leftRotatedCursorPoint.y,
        }
        break
      case 'right':
        const newRightPoint = {
          x: this.attrs.x2 + difX,
          y: this.attrs.y2 + difY,
        }
        const rightRotatedCursorPoint = this.getRotatedPointCoordinates({
          rotationAxisPoint: { x: this.scaled.x1, y: this.scaled.y1 },
          pointToRotate: {
            x: this.scaled.cursorTargetX,
            y: this.scaled.cursorTargetY,
          },
          oldLinePoint: { x: this.scaled.x2, y: this.scaled.y2 },
          newLinePoint: { x: this.model.scale.x(newRightPoint.x), y: this.model.scale.y(newRightPoint.y) },
        })
        this.attrs = {
          ...this.attrs,
          x2: newRightPoint.x,
          y2: newRightPoint.y,
          cursorTargetX: rightRotatedCursorPoint.x,
          cursorTargetY: rightRotatedCursorPoint.y,
        }
        break
      case 'top':
      case 'bottom':
        this.attrs = {
          ...this.attrs,
          cursorTargetX: area.x,
          cursorTargetY: area.y,
        }
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    if (this.ellipsePath && this.untranslatedContext?.isPointInPath(this.ellipsePath, area.scaled.x, area.scaled.y)) {
      return true
    }
    return super.isInArea(area)
  }

  getIsInChartView() {
    return true
  }
}

EllipseV1.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, default: {} },
  ],
}

export default EllipseV1
