import { useDialogState } from '@finviz/website'
import React from 'react'

import { publishModalAsyncModule } from '../../modals/publish/async-modules'
import ChartLayout from '../../models/chart_layout'
import { useAsyncModule } from '../../utils/async-modules-utils'

interface PublishOldButtonClickListenerProps {
  isListening: boolean
  layoutModel: ChartLayout
}

export function getPublishChartHtmlElements() {
  const publishChartElements = document.getElementsByClassName('js-publish-chart')
  const publishChartHtmlElements = Array.from(publishChartElements) as HTMLElement[]

  return publishChartHtmlElements
}

function getChartImgUrl(index: number) {
  const imgElement = document.getElementById(`chart${index}`) as HTMLImageElement | null
  return imgElement?.src ?? null
}

export function PublishOldButtonClickListener({ isListening, layoutModel }: PublishOldButtonClickListenerProps) {
  const dialog = useDialogState()
  const isOpen = dialog.useState('open')
  const [dialogConfig, setDialogConfig] = React.useState({
    isCanvas: true,
    chartImgUrl: null as null | string,
    timeframe: '',
    ticker: '',
  })
  const [publishModalModule] = useAsyncModule({
    ...publishModalAsyncModule,
    shouldLoadModule: isOpen,
  })
  const PublishDialog = publishModalModule?.default

  React.useEffect(() => {
    const publishChartHtmlElements = getPublishChartHtmlElements()
    const clickHandler = (event: MouseEvent) => {
      const { index, ticker, timeframe } = (event.currentTarget as HTMLElement).dataset
      if (index && ticker && timeframe) {
        const indexInt = Number.parseInt(index)
        setDialogConfig({
          ticker,
          timeframe,
          isCanvas: indexInt === 0,
          chartImgUrl: indexInt > 0 ? getChartImgUrl(indexInt) : null,
        })
        dialog.show()
      }
    }

    if (isListening) {
      publishChartHtmlElements.forEach((el) => {
        el.addEventListener('click', clickHandler)
      })
    }

    return () => {
      publishChartHtmlElements.forEach((el) => {
        el.removeEventListener('click', clickHandler)
      })
    }
  }, [dialog, isListening])

  return PublishDialog ? <PublishDialog state={dialog} config={dialogConfig} layoutModel={layoutModel} /> : null
}
