import { Button, ButtonGroup, Delayed, Icon, Spinner } from '@finviz/website'
import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import Chart from '../../models/chart'
import { QuoteFetchType } from '../../models/quote/constants'
import { useIsMounted } from '../../utils/use-is-mounted'

interface LoadLatestDataButtonProps {
  chartModel: Chart
}

export function LoadLatestDataButton({ chartModel: chartModelUnwatched }: LoadLatestDataButtonProps) {
  const getIsMounted = useIsMounted()
  const chartModel = useModelState(chartModelUnwatched, { watchProperties: ['quote'] })
  const quoteModel = useModelState(chartModel.quote(), { watchProperties: ['isFetching'] })
  const [hasNewDataAvailable, setHasNewDataAvailable] = React.useState(false)

  React.useEffect(() => {
    if (!chartModel.getRefreshInterval()) {
      // We will do a check here if we have correct new data
      chartModel
        .quote()
        .isPossibleToFetchSequentialData()
        .then((hasNewData) => {
          if (getIsMounted()) {
            setHasNewDataAvailable(hasNewData)
          }
        })
    }
    // We need to run it only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadLatestData = async () => {
    const promises = chartModel
      .getAllQuotes()
      .map(async (quote) => quote.fetchData({ fetchType: QuoteFetchType.NewerData }))
    chartModel.updateAttributes({ refreshData: true })
    await Promise.all(promises)
    if (getIsMounted()) {
      setHasNewDataAvailable(false)
      window.gtag?.('event', 'load-latest-data', { event_category: 'ideas' })
    }
  }

  if (!hasNewDataAvailable) {
    return null
  }

  return (
    <ButtonGroup rounding="full" className="shadow-modal dark:shadow-modal-dark">
      <Button
        leftContent={
          quoteModel?.isFetching ? (
            <Delayed delayComponent={<Icon name="refresh" />}>
              <Spinner width={16} />
            </Delayed>
          ) : (
            'refresh'
          )
        }
        disabled={quoteModel?.isFetching}
        title="Load latest data"
        onClick={handleLoadLatestData}
        data-testid="load-latest-data-button"
      >
        Load latest data
      </Button>
    </ButtonGroup>
  )
}
