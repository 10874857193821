import Spine from '@finviz/spine'

import Chart from './chart'

class Indicator extends Spine.Model {
  static initClass(chartModel: typeof Chart) {
    this.configure('Indicator', 'instance')
    this.belongsTo('chart', chartModel)
  }

  declare chart: () => Chart

  // instance - todo - not sure what instance type to put here
}

export default Indicator
