import { Dialog, DialogBody, DialogHeader, DialogStateReturn, ZIndexContext } from '@finviz/website'
import React from 'react'

import ChartLayout from '../../../models/chart_layout'
import { ChartAutoSaveNotes } from './auto-save-notes'

interface INoteDialog {
  state: DialogStateReturn
  chartLayoutModel: ChartLayout
}

export function AutoSaveNotesDialog({ state, chartLayoutModel }: INoteDialog) {
  const isOpen = state.useState('open')
  const isAnimating = state.useState('animating')
  const isFullyClosed = !isOpen && !isAnimating

  return (
    <ZIndexContext.Provider value="z-10">
      <Dialog
        isDraggable
        aria-label="Chart notes"
        className="w-96"
        state={state}
        backdrop={false}
        hideOnEsc={false}
        modal={false}
      >
        <DialogHeader>Notes</DialogHeader>
        <DialogBody>{!isFullyClosed && <ChartAutoSaveNotes chartLayoutModel={chartLayoutModel} />}</DialogBody>
      </Dialog>
    </ZIndexContext.Provider>
  )
}
