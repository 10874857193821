import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: string
}

export interface Attrs extends Options {
  tickers?: string
}

export class PerfConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Perf
  static abbreviation = 'perf'
  static label = 'Price Performance %'
  static shortLabel = 'PERF %'
  static optionsOrder = ['tickers'] as const
  static optionsLabels = { tickers: 'Tickers' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
