import { IndicatorType } from '../../constants/common'
import { StofaConfig } from './stofa'

export type Options = {
  period: string | number
  dPeriod: number
}

export interface Attrs extends Options {
  kPeriod: number
}

export class StoslConfig extends StofaConfig {
  static type = IndicatorType.Stosl
  static abbreviation = 'stosl'
  static label = 'Stochastics SLOW'
  static shortLabel = 'Stochastics SLOW'
}
