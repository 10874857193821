import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: string | number
  dPeriod: number
}

export interface Attrs extends Options {
  kPeriod: number
}

export class StofaConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Stofa
  static abbreviation = 'stofa'
  static label = 'Stochastics FAST'
  static shortLabel = 'Stochastics FAST'
  static optionsOrder = ['period', 'dPeriod']

  static optionsLabels = { period: 'Period', dPeriod: 'D Period' }

  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
