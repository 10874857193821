import { Delayed, DialogBody, Popover, PopoverAnchor, Spinner, usePopoverState } from '@finviz/website'
import classNames from 'classnames'
import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartModel from '../../models/chart'
import Utils from '../../utils'
import { useAsyncModule } from '../../utils/async-modules-utils'
import { useClickAway } from '../../utils/use-click-away'
import { chartEventsAsyncModule } from '../chart-events/async-modules'

const isMobile = Utils.isMobile()

interface ChartEventPopoverWithStateProps {
  chartModel: ChartModel
}

export function ChartEventPopoverWithState({ chartModel }: ChartEventPopoverWithStateProps) {
  const unwatchedChartLayout = React.useMemo(() => chartModel.chart_layout() ?? null, [chartModel])
  const { activeChartEvent, theme } = useModelState(unwatchedChartLayout, { watchProperties: ['activeChartEvent'] })
  const lastEventId = React.useRef(activeChartEvent?.id)
  const popoverState = usePopoverState({ placement: 'top' })
  const isPopoverMounted = popoverState.useState('mounted')
  const [style, setStyle] = React.useState({ top: 0, left: 0 })
  const [chartEventsModule] = useAsyncModule({
    ...chartEventsAsyncModule,
    shouldLoadModule: isPopoverMounted,
    errorSeverity: 'medium',
    onError: () => popoverState.hide(),
  })
  const isChartEventOfThisChart = activeChartEvent?.pane().chart().eql(chartModel)
  const [data, setData] = React.useState<ObjectHash | null>(null)
  const clickAwayRef = useClickAway(() => {
    const isAnyBadgeHovered = !!chartModel
      .getChartPane()
      ?.getAllChartEvents()
      .some((chartEvent) => chartEvent.instance.isHovered)

    if (!isAnyBadgeHovered && activeChartEvent && isChartEventOfThisChart) {
      activeChartEvent.instance.toggleIsOpen(false)
    }
    // Due to the fact that chart events logic depends heavily on hover state which isn't really present on touch devices
    // we need to mimic that state for touch devices, this enables chart event closed on click outside and counterpart in
    // chart-events.ts allow chart event to be open
    if (activeChartEvent && isMobile) {
      activeChartEvent.instance.setIsHovered(false)
    }
  }, !!activeChartEvent && isChartEventOfThisChart)
  // This is intentional, it doesn't need to be in useEffect, it would just call effect function which is redundant
  clickAwayRef.current = popoverState.useState('popoverElement')
  const popoverShow = popoverState.show
  const popoverHide = popoverState.hide

  React.useEffect(() => {
    lastEventId.current = activeChartEvent?.id
    if (!activeChartEvent?.instance.isOpen || !isChartEventOfThisChart) {
      if (!isPopoverMounted) {
        return setData(null)
      }
      return popoverHide()
    }
    if (!isChartEventOfThisChart) return
    const { top, left } = activeChartEvent.instance
    const newData = activeChartEvent.getChartEventData()
    if (newData?.elementId !== data?.elementId) {
      setData({
        ...newData,
        badgeType: activeChartEvent.instance.getChartEvenBadgeType(),
      })
    }

    if (style.top !== top || style.left !== left) {
      setStyle({ top, left })
    }
    if (!isPopoverMounted) {
      popoverShow()
    }
  }, [
    activeChartEvent,
    data?.elementId,
    isChartEventOfThisChart,
    isPopoverMounted,
    popoverHide,
    popoverShow,
    popoverState,
    style.left,
    style.top,
  ])

  return (
    <>
      <PopoverAnchor
        id="popover-trigger"
        store={popoverState}
        className="absolute -ml-3 h-6 w-6 outline-none"
        style={style}
      />
      {data && (
        <Popover
          key={activeChartEvent?.id ?? lastEventId.current}
          tabIndex={0}
          aria-label="Event"
          state={popoverState}
          data-testid="chart-event-popover"
          backdrop={false}
          modal={false}
          hideOnInteractOutside={false}
          // Make the popover passively show without receiving focus,
          // otherwise re-opening the same event won’t work
          focusable={false}
          autoFocusOnShow={false}
        >
          <DialogBody
            className={classNames('pointer-events-auto select-text', {
              'flex flex-1': !chartEventsModule,
            })}
          >
            {chartEventsModule ? (
              <chartEventsModule.ChartEventPopoverContent badgeType={data.badgeType} data={data} theme={theme} />
            ) : (
              <div className="flex min-w-36 items-center justify-center">
                <Delayed delayComponent={null}>
                  <Spinner width={24} />
                </Delayed>
              </div>
            )}
          </DialogBody>
        </Popover>
      )}
    </>
  )
}
