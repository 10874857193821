import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export enum Calculation {
  standard = 'Standard',
  fibonacci = 'Fibonacci',
}
export type CalculationType = keyof typeof Calculation

export type Options = {
  calculationType: CalculationType
}
export interface Attrs extends Options {
  period?: string
  color: string
}

export class PPConfig extends BaseConfig {
  static type = OverlayType.Pp
  static abbreviation = 'pp'
  static label = 'Pivot Points'
  static shortLabel = 'Pivot Points'
  static optionsOrder = ['calculationType'] as const
  static optionsLabels = { calculationType: 'Calculation Type' }
  static inputsOrder = ['calculationType', 'color'] as Array<keyof Omit<Attrs, 'period'>>
  static inputsLabels = { calculationType: 'Calculation Type', color: 'Color' }
}
