import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import { getOffsetFromLineWidth } from '../controllers/renderUtils'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Element, { EdgeValues } from './element'
import Thumb from './thumb'

type Points = Array<{ x: number; y: number }>

export interface IPolygonAttrs {
  points: Points
  fill: string
  border: DrawingBorder
  positionPointsTimestamps?: Points
}

class Polygon<Attrs extends IPolygonAttrs = IPolygonAttrs> extends Element<Attrs> {
  static type = CanvasElementType.polygon

  name = 'Polygon'

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this.attrs.fill = this.attrs.fill || 'rgba(50, 60, 70, 0.8)'
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  renderContent(context: CanvasRenderingContext2D) {
    context.set('fillStyle', this.attrs.fill)
    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)

    const offset = getOffsetFromLineWidth(this.attrs.border.width)
    context.translate(offset, offset)

    const { points } = this.attrs
    context.beginPath()
    context.moveTo(this.fx(points[0].x), this.fy(points[0].y))
    for (const p of points.slice(1)) {
      context.lineTo(this.fx(p.x), this.fy(p.y))
    }

    if (this.isPolygon()) {
      context.closePath()
      context.fill()
    }
    context.translate(offset * -1, offset * -1)
    if ((this.attrs.border != null ? this.attrs.border.width : undefined) !== 0) {
      context.stroke()
    }

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getThumbs() {
    return this.attrs.points.map(
      (point, index) =>
        new Thumb(
          `${index}`,
          () => point.x,
          () => point.y,
          this.resize,
          this.model
        )
    )
  }

  cachePointPositionTimestamp = () => {
    const quote = this.model?.chart()?.quote()
    if (quote) {
      const positionPointsTimestamps = this.attrs.points.map((point) => ({
        ...point,
        x: quote.getTimestampFomPositionX(point.x),
      }))

      this.set({ positionPointsTimestamps } as Partial<Attrs>)
    }
  }

  updateScales() {
    const quote = this.model.chart().quote()
    const { positionPointsTimestamps } = this.attrs
    if (!quote || !positionPointsTimestamps) {
      // positionWavesTimestamps check is temporary to prevent app from crashing
      // caused by corrupted drawings - https://github.com/finvizhq/charts/pull/1386/files
      return
    }
    const points = positionPointsTimestamps.map((point) => ({
      ...point,
      x: quote.getPositionXFromTimestamp(point.x),
    }))
    this.set({ points } as Partial<Attrs>)
  }

  moveBy(x: number, y: number) {
    for (const point of this.attrs.points) {
      point.x += x
      point.y += y
    }
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    const last = this.attrs.points.length - 1
    if (this.isPolygon() && (+type === 0 || +type === last)) {
      this.attrs.points[0].x += difX
      this.attrs.points[0].y += difY
      this.attrs.points[last].x += difX
      this.attrs.points[last].y += difY
    } else {
      this.attrs.points[+type].x += difX
      this.attrs.points[+type].y += difY
    }
  }

  isPolygon() {
    return (
      this.attrs.points[0].x === this.attrs.points.last()?.x && this.attrs.points[0].y === this.attrs.points.last()?.y
    )
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const scaledPoints = this.attrs.points.map((p) => ({
      x: this.fx(p.x),
      y: this.fy(p.y),
    }))
    if (this.isPolygon()) {
      if (math.pointInPolygon(area.scaled, scaledPoints)) {
        return true
      }
    } else {
      for (let i = 0; i < scaledPoints.length - 1; i++) {
        if (
          math.distanceToSegment(area.scaled, {
            x1: scaledPoints[i].x,
            y1: scaledPoints[i].y,
            x2: scaledPoints[i + 1].x,
            y2: scaledPoints[i + 1].y,
          }) <= 10
        ) {
          return true
        }
      }
    }

    return super.isInArea(area)
  }

  getAutosaveOmittedProperties() {
    return ['points']
  }

  getEdgeXYValues() {
    if (this.edgeXYValues === null) {
      const edgeXYValues = {} as EdgeValues
      this.attrs.points.forEach(({ x, y }) => {
        const xPoint = x
        const yPoint = y
        edgeXYValues.minX = Math.min(xPoint, edgeXYValues.minX ?? xPoint)
        edgeXYValues.minY = Math.min(yPoint, edgeXYValues.minY ?? yPoint)
        edgeXYValues.maxX = Math.max(xPoint, edgeXYValues.maxX ?? xPoint)
        edgeXYValues.maxY = Math.max(yPoint, edgeXYValues.maxY ?? yPoint)
      })

      this.edgeXYValues = edgeXYValues
    }

    return this.edgeXYValues
  }
}

Polygon.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, default: {} },
  ],
}

export default Polygon
