import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number | string
  baseLine: number
}

export type Attrs = Options

export class RVOLConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Rvol
  static abbreviation = 'rvol'
  static label = 'Relative Volume'
  static shortLabel = 'RVOL'
  static optionsOrder = ['period', 'baseLine'] as const
  static optionsLabels = { period: 'Period', baseLine: 'BaseLine' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
