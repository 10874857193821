import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import { MACDCalculation } from '../indicator-calculation/macd'
import { getBarWidthWithMarginByParts } from '../utils/chart'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, MACDConfig } from './configs/macd'
import Indicator from './indicator'

class MACD extends Indicator<Attrs> {
  static config = MACDConfig

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [fastPeriod = 0, slowPeriod = 0, signalPeriod = 0] = getParsedIntegersFromPeriodString(period)
    return Math.max(fastPeriod, slowPeriod, signalPeriod)
  }

  macdCalculation: MACDCalculation | null = null

  declare fastPeriod: number
  declare slowPeriod: number
  declare signalPeriod: number

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [fastPeriod = 0, slowPeriod = 0, signalPeriod = 0] = getParsedIntegersFromPeriodString(this.attrs.period)
      super.set({ ...values, fastPeriod, slowPeriod, signalPeriod })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (this.isComputeNecessary() || this.macdCalculation === null) {
      this.macdCalculation = new MACDCalculation({
        quote: this.data,
        options: {
          signalPeriod: this.signalPeriod,
          fastPeriod: this.fastPeriod,
          slowPeriod: this.slowPeriod,
        },
      })
      this.macdCalculation.calculate()

      this.lastValue = this.macdCalculation.calculatedValues.macd.last() ?? null
    }

    const { macd, histogram, signal } = this.macdCalculation.calculatedValues

    const { min, max } =
      macd.length > 0 || signal.length > 0 || histogram.length > 0
        ? this.computeVisibleMinMax(macd, signal, histogram)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    const { IndicatorSettings } = this.getChartLayoutSettings()
    return {
      histogram: '#79c3e8',
      macd: IndicatorSettings.general.Colors.line,
      signal: IndicatorSettings.general.Colors.signalLine,
    }
  }

  getValueLabelsAtIndex(index: number) {
    if (this.macdCalculation === null || this.data.close.length === 0) return []

    const lineColors = this.getLineColors()
    const { macd, histogram, signal } = this.macdCalculation.calculatedValues
    const dataIndex = this.data.barToDataIndex[index]
    return [
      { color: lineColors.histogram, text: this.getValueLabel(histogram[dataIndex]) },
      { color: lineColors.macd, text: this.getValueLabel(macd[dataIndex]) },
      { color: lineColors.signal, text: this.getValueLabel(signal[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.macdCalculation === null || this.data.close.length === 0) return
    const lineColors = this.getLineColors()
    const y = Math.round(this.fy(0))
    const chartModel = this.model.chart()
    const { zoomFactor } = chartModel
    const { barFillWidth, barBorderWidth } = getBarWidthWithMarginByParts({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })
    const barWidthWithoutMargin = barFillWidth + barBorderWidth * 2
    const halfBarWidth = ~~Math.max(1, barWidthWithoutMargin / 2)
    const { macd, histogram, signal } = this.macdCalculation.calculatedValues
    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
    }

    context.set('fillStyle', lineColors.histogram)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.rect(x - halfBarWidth, y, barWidthWithoutMargin, Math.round(this.fy(histogram[i]) - y))
      },
    })
    context.fill()

    context.translate(0.5, 0.5)
    context.set('strokeStyle', lineColors.macd)
    context.beginPath()

    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(macd[i])))
      },
    })
    context.stroke()

    context.set('strokeStyle', lineColors.signal)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(signal[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      fastPeriod: {
        type: 'number',
        label: 'Period',
        name: 'fastPeriod',
        value: this.fastPeriod ?? 12,
        required: true,
        min: 1,
        max: 999999,
      },
      slowPeriod: {
        type: 'number',
        label: 'Slow',
        name: 'slowPeriod',
        value: this.slowPeriod ?? 26,
        required: true,
        min: 1,
        max: 999999,
      },
      signalPeriod: {
        type: 'number',
        label: 'Signal',
        name: 'signalPeriod',
        value: this.signalPeriod ?? 9,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: MACDConfig.label,
      inputs: MACDConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        fastPeriod: `${options.fastPeriod.label} must be a whole number between ${options.fastPeriod.min} and ${options.fastPeriod.max}`,
        slowPeriod: `${options.slowPeriod.label} must be a whole number between ${options.slowPeriod.min} and ${options.slowPeriod.max}`,
        signalPeriod: `${options.signalPeriod.label} must be a whole number between ${options.signalPeriod.min} and ${options.signalPeriod.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'fastPeriod':
      case 'slowPeriod':
      case 'signalPeriod':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default MACD
