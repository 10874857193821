import { OverlayType } from '../constants/common'
import bb from './bb'
import dch from './dch'
import ema from './ema'
import ema2 from './ema2'
import hilo from './hilo'
import hline from './hline'
import ic from './ic'
import icf from './icf'
import kch from './kch'
import none from './none'
import overlay from './overlay'
import patterns from './patterns'
import pp from './pp'
import sar from './sar'
import sma from './sma'
import sma2 from './sma2'
import str from './str'
import vp from './vp'
import vwap from './vwap'

export const overlaysByType = Object.freeze({
  [OverlayType.Overlay]: overlay,
  [OverlayType.None]: none,
  [OverlayType.Bb]: bb,
  [OverlayType.Dch]: dch,
  [OverlayType.Ema]: ema,
  [OverlayType.Ema2]: ema2,
  [OverlayType.Hilo]: hilo,
  [OverlayType.Hline]: hline,
  [OverlayType.Ic]: ic,
  [OverlayType.Icf]: icf,
  [OverlayType.Kch]: kch,
  [OverlayType.Patterns]: patterns,
  [OverlayType.Pp]: pp,
  [OverlayType.Sar]: sar,
  [OverlayType.Sma]: sma,
  [OverlayType.Sma2]: sma2,
  [OverlayType.Str]: str,
  [OverlayType.Vp]: vp,
  [OverlayType.Vwap]: vwap,
})
