import { ObjectHash } from '../../types/shared'

const requestsAbortControllersCache: ObjectHash<AbortController> = {}

export function removeFromAbortCache(key: string) {
  delete requestsAbortControllersCache[key]
}

export function abortRequest(key: string) {
  requestsAbortControllersCache[key]?.abort()
  removeFromAbortCache(key)
}

export function getIsAbortError(error: Error) {
  return error.name === 'AbortError'
}

export function createRequestAbortController({
  key,
  shouldAbortRunningRequest = false,
}: {
  key: string
  shouldAbortRunningRequest?: boolean
}) {
  if (shouldAbortRunningRequest) {
    abortRequest(key)
  }
  const controller = 'AbortController' in window ? new AbortController() : undefined
  if (controller) {
    requestsAbortControllersCache[key] = controller
  }
  return controller
}
