import { SMA2Config } from './configs/sma2'
import SMA from './sma'

class SMA2 extends SMA {
  static config = SMA2Config
}

SMA2.prototype.defaults = { color: '#ff6400' }

export default SMA2
