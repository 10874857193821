import { ChartElementType } from '../constants/common'
import candleStick from './candle_stick'
import hollowCandleStick from './candle_stick_hollow'
import heikinAshi from './heikin_ashi'
import lineChart from './line_chart'
import marketSentiment from './market_sentiment'
import multiLineCharts from './multi_line_chart'
import ohlcChart from './ohlc_chart'
import perfChart from './perf_chart'

export const chartsByType = Object.freeze({
  [ChartElementType.CandleStick]: candleStick,
  [ChartElementType.HeikinAshi]: heikinAshi,
  [ChartElementType.HollowCandleStick]: hollowCandleStick,
  [ChartElementType.LineChart]: lineChart,
  [ChartElementType.MarketSentiment]: marketSentiment,
  [ChartElementType.MultiLineChart]: multiLineCharts,
  [ChartElementType.OhlcChart]: ohlcChart,
  [ChartElementType.PerfChart]: perfChart,
})
