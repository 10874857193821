import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class FIConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Fi
  static abbreviation = 'fi'
  static label = 'Force Index'
  static shortLabel = 'FORCE'
  static description =
    'The Force Index combines price movement and volume to evaluate the strength of a trend. Positive Force Index values indicate buying pressure, while negative values suggest selling pressure, helping to gauge the intensity of market moves.'

  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['fi'] as const
  static calculatedValuesLabels = { fi: 'Force Index' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
