import { DrawingBorder, PaneArea, Point, ResizeByThumbWithTypeAndDifs } from '../../../types/shared'
import { CanvasElementType } from '../../constants/common'
import { getOffsetFromLineWidth, getRoundedObject } from '../../controllers/renderUtils'
import math from '../../helpers/math'
import PaneModel from '../../models/pane'
import Element from '../element'
import Thumb from '../thumb'

export interface IRotatedRectangleV1Attrs {
  x1: number
  x2: number
  y1: number
  y2: number
  cursorTargetX: number
  cursorTargetY: number
  fill: string
  border: DrawingBorder
}

class RotatedRectangleV1<Attrs extends IRotatedRectangleV1Attrs = IRotatedRectangleV1Attrs> extends Element<Attrs> {
  static type = CanvasElementType.rotatedRectangleV1

  name = 'New Rotated Rectangle'

  declare scaled: Pick<Attrs, 'x1' | 'y1' | 'x2' | 'y2' | 'cursorTargetX' | 'cursorTargetY'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'middleLeft',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'middleRight',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'topRight',
        () => this.getRectCoordinates(true).P1.x || this.attrs.x2,
        () => this.getRectCoordinates(true).P1.y || this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'topLeft',
        () => this.getRectCoordinates(true).P2.x || this.attrs.x2,
        () => this.getRectCoordinates(true).P2.y || this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'bottomLeft',
        () => this.getRectCoordinates(true).P3.x || this.attrs.x1,
        () => this.getRectCoordinates(true).P3.y || this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'bottomRight',
        () => this.getRectCoordinates(true).P4.x || this.attrs.x1,
        () => this.getRectCoordinates(true).P4.y || this.attrs.y1,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2', 'cursorTargetX'], y: ['y1', 'y2', 'cursorTargetY'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { P1, P2, P3, P4 } = this.getRectCoordinates()
    const roundedXY = {
      P1: getRoundedObject(P1),
      P2: getRoundedObject(P2),
      P3: getRoundedObject(P3),
      P4: getRoundedObject(P4),
    }
    const offset = getOffsetFromLineWidth(this.attrs.border.width)
    context.set('fillStyle', this.attrs.fill)

    context.translate(offset, offset)
    context.beginPath()
    if (roundedXY.P1.x === roundedXY.P4.x && roundedXY.P1.y === roundedXY.P4.y) {
      context.lineTo(roundedXY.P1.x, roundedXY.P1.y)
      context.lineTo(roundedXY.P2.x, roundedXY.P2.y)
    } else {
      context.lineTo(roundedXY.P1.x, roundedXY.P1.y)
      context.lineTo(roundedXY.P2.x, roundedXY.P2.y)
      context.lineTo(roundedXY.P3.x, roundedXY.P3.y)
      context.lineTo(roundedXY.P4.x, roundedXY.P4.y)
      context.closePath()
      context.fill()
    }
    context.translate(offset * -1, offset * -1)

    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    context.stroke()

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getRectCoordinates(inverted = false) {
    const { x1, x2, y1, y2, cursorTargetX, cursorTargetY } = this.scaled
    let perpendicularPoint
    if (!isNaN(cursorTargetX) && !isNaN(cursorTargetY)) {
      const cursor = { x: cursorTargetX, y: cursorTargetY }
      const line = { x1, y1, x2, y2 }
      const distance = math.distanceToLine(cursor, line)
      perpendicularPoint = math.perpendicularPointToLine({
        distance,
        line,
      })
    } else {
      return {
        P1: { x: x2, y: y2 },
        P2: { x: x1, y: y1 },
        P3: { x: x1, y: y1 },
        P4: { x: x2, y: y2 },
      }
    }

    const pointOffsetX = perpendicularPoint.x - x2
    const pointOffsetY = perpendicularPoint.y - y2

    const P1 = { x: x2 + pointOffsetX, y: y2 + pointOffsetY }
    const P2 = { x: x1 + pointOffsetX, y: y1 + pointOffsetY }
    const P3 = { x: x1 + -1 * pointOffsetX, y: y1 + -1 * pointOffsetY }
    const P4 = { x: x2 + -1 * pointOffsetX, y: y2 + -1 * pointOffsetY }

    const invertX = this.model.scale.x.invert
    const invertY = this.model.scale.y.invert
    if (inverted) {
      return {
        P1: { x: invertX(P1.x), y: invertY(P1.y) },
        P2: { x: invertX(P2.x), y: invertY(P2.y) },
        P3: { x: invertX(P3.x), y: invertY(P3.y) },
        P4: { x: invertX(P4.x), y: invertY(P4.y) },
      }
    }
    return { P1, P2, P3, P4 }
  }

  getRotatedPointCoordinates = ({
    rotationAxisPoint,
    pointToRotate,
    oldLinePoint,
    newLinePoint,
  }: {
    rotationAxisPoint: Point
    pointToRotate: Point
    oldLinePoint: Point
    newLinePoint: Point
  }) => {
    const angle = math.sharedPointLinesAngle({
      sharedPoint: rotationAxisPoint,
      oldLinePoint,
      newLinePoint,
    })
    const rotatedPoinCoordinates = math.rotatedPointCoordinates({
      rotationAxisPoint,
      angle,
      pointToRotate,
    })
    return {
      x: this.model.scale.x.invert(rotatedPoinCoordinates.x),
      y: this.model.scale.y.invert(rotatedPoinCoordinates.y),
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.y1 += y
    this.attrs.x2 += x
    this.attrs.y2 += y
    this.attrs.cursorTargetX += x
    this.attrs.cursorTargetY += y
  }

  resize({ type, difX, difY, area }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'middleLeft':
        const newMiddleLeftPoint = {
          x: this.attrs.x1 + difX,
          y: this.attrs.y1 + difY,
        }
        const middleLeftRotatedCursorPoint = this.getRotatedPointCoordinates({
          rotationAxisPoint: { x: this.scaled.x2, y: this.scaled.y2 },
          pointToRotate: {
            x: this.scaled.cursorTargetX,
            y: this.scaled.cursorTargetY,
          },
          oldLinePoint: { x: this.scaled.x1, y: this.scaled.y1 },
          newLinePoint: { x: this.model.scale.x(newMiddleLeftPoint.x), y: this.model.scale.y(newMiddleLeftPoint.y) },
        })
        this.attrs = {
          ...this.attrs,
          x1: newMiddleLeftPoint.x,
          y1: newMiddleLeftPoint.y,
          cursorTargetX: middleLeftRotatedCursorPoint.x,
          cursorTargetY: middleLeftRotatedCursorPoint.y,
        }
        break
      case 'middleRight':
        const newMiddleRightPoint = {
          x: this.attrs.x2 + difX,
          y: this.attrs.y2 + difY,
        }
        const middleRightRotatedCursorPoint = this.getRotatedPointCoordinates({
          rotationAxisPoint: { x: this.scaled.x1, y: this.scaled.y1 },
          pointToRotate: {
            x: this.scaled.cursorTargetX,
            y: this.scaled.cursorTargetY,
          },
          oldLinePoint: { x: this.scaled.x2, y: this.scaled.y2 },
          newLinePoint: { x: this.model.scale.x(newMiddleRightPoint.x), y: this.model.scale.y(newMiddleRightPoint.y) },
        })
        this.attrs = {
          ...this.attrs,
          x2: newMiddleRightPoint.x,
          y2: newMiddleRightPoint.y,
          cursorTargetX: middleRightRotatedCursorPoint.x,
          cursorTargetY: middleRightRotatedCursorPoint.y,
        }
        break
      case 'topRight':
      case 'topLeft':
      case 'bottomLeft':
      case 'bottomRight':
        this.attrs = {
          ...this.attrs,
          cursorTargetX: area.x,
          cursorTargetY: area.y,
        }
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const coordinates = this.getRectCoordinates()
    const polygon = Object.keys(coordinates).map((point) => ({
      x: coordinates[point as 'P1' | 'P2' | 'P3' | 'P4'].x,
      y: coordinates[point as 'P1' | 'P2' | 'P3' | 'P4'].y,
    }))
    if (math.pointInPolygon(area.scaled, polygon)) {
      return true
    }
    return super.isInArea(area)
  }

  getIsInChartView() {
    return true
  }
}

RotatedRectangleV1.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, default: {} },
  ],
}

export default RotatedRectangleV1
