import {
  Button,
  ButtonSizeType,
  ButtonThemeType,
  Delayed,
  Icon,
  Spinner,
  TourDefinition,
  useTour,
} from '@finviz/website'
import classnames from 'classnames'
import * as React from 'react'

import { SpecificChartFunctionality } from '../../constants/common'
import { getRequestFullscreen, isRedesignEnabled } from '../../utils'
import { encodeQueryString } from '../../utils/query_string'

const isPremium = window.FinvizSettings.hasUserPremium

// If we need to update / discontinue some tour we can use skip flag on legacy version to ensure that it won't reset users tour but won't include it in new tours
function getTour(page: SpecificChartFunctionality): TourDefinition[] {
  return [
    {
      id: 'chart-tour',
      steps: [
        {
          title: 'Chart Tour',
          body: 'Discover powerful Chart features in the 2-min tour to master your analysis of trading opportunities.',
        },
        ...(isPremium
          ? [
              {
                title: 'Scale the Timeline',
                body: 'Grab the timeline to zoom it in and out. Double-click the timeline to reset the scaling.',
                target: '[data-testid="chart-0-pane-0-zoomer"]',
              },
              {
                title: 'Scale the Price Axis',
                body: 'Grab the Price Axis to zoom it in and out. Move the chart around freely in any direction when Price Axis has custom scale. Double-click the Price Axis to reset the scaling.',
                target: '[data-testid="chart-0-pane-0-scaler"]',
              },
              {
                title: 'Drawing tools & settings',
                body: 'Pick any Drawing Tool to analyze the market. Tailor your drawing experience with additional settings.',
                target: '#toolbar',
              },
              {
                title: 'Fine-tune Drawings',
                body: 'Double-click on a chart drawing to tweak its color and style. Right-click on a drawing to access other options, such as changing its visibility, order, and more.',
                target: '[data-testid="chart-0-pane-0"]',
              },
            ]
          : [
              {
                title: 'Enable Drawing mode',
                body: (
                  <>
                    <a
                      href={`/elite.ashx?${encodeQueryString({
                        utm_source: 'finviz',
                        utm_medium: 'banner',
                        utm_campaign: 'quote-tour',
                      })}`}
                      target="_blank"
                      className="tab-link"
                    >
                      Finviz*Elite
                    </a>{' '}
                    subscribers get access a whole suite of drawing tools which allow them to draw on and annotate the
                    chart. <br />
                    While in drawing mode, you can also pan the chart, scale the timeline and price axis and much more.
                  </>
                ),
                target: '[data-testid="quote-toggle-draw-btn"]',
                placement: 'bottom-start',
              },
            ]),
        {
          title: 'Save your best Ideas',
          body: isPremium
            ? 'Besides your drawings being autosaved, you can manually save ideas that you wish to revisit later.'
            : 'Your drawings will be saved automatically, but you can also manually save ideas that you wish to revisit later.',
          target: '[data-testid="chart-toolbar-ideas"]',
          placement: 'bottom-start',
        },
        ...(page === SpecificChartFunctionality.quotePage
          ? []
          : [
              {
                title: 'Chart Layouts & Charts Sync',
                body: 'Switch between Chart Layouts to view multiple charts on your screen. Synchronize Ticker, Timeframe or Crosshair position across your charts.',
                target: '[data-testid="chart-layout-layout"]',
              },
            ]),
        ...(!getRequestFullscreen(document.documentElement) && page === SpecificChartFunctionality.chartPage
          ? []
          : [
              {
                title: 'Fullscreen mode',
                body:
                  page === SpecificChartFunctionality.quotePage
                    ? 'Open the chart in an expanded view for full-page display and split-screen charts.'
                    : 'Toggle the window to fill your entire screen.',
                target: '[data-testid="chart-layout-fullscreen"]',
                placement: page === SpecificChartFunctionality.chartPage ? 'bottom' : 'bottom-end',
              },
            ]),
        {
          title: 'Share chart',
          body: 'Share a chart together with your analysis and ideas.',
          target: '[data-testid="chart-toolbar-publish"]',
          overlayPadding: page === SpecificChartFunctionality.quotePage ? 3 : undefined,
          placement: 'bottom',
        },
        ...(isRedesignEnabled() || page === SpecificChartFunctionality.chartPage
          ? [
              {
                title: 'Theme',
                body: 'Switch between the Light and Dark theme.',
                target: '[data-testid="chart-layout-theme"]',
              },
            ]
          : []),
        {
          title: 'Study Settings',
          body: 'Customize the Chart Type and appearance. Add Overlays, Indicators and toggle Extended Hours.',
          target: '[data-testid="chart-settings-open-btn"]',
          placement: page === SpecificChartFunctionality.chartPage ? 'bottom' : 'bottom-end',
        },
      ],
    },
    page === SpecificChartFunctionality.chartPage && {
      id: 'sidebar-watchlist',
      steps: [
        {
          title: 'Explore Your Portfolios',
          body: 'Effortlessly navigate through your portfolio holdings. With just a click, visualize individual stock charts, gain insights, and stay updated on your investments.',
          target: '[data-testid="sidebar-portfolio-btn"]',
          placement: 'left-start',
        },
      ],
    },
  ].filter(Boolean) as TourDefinition[]
}

interface TourButtonProps {
  page: SpecificChartFunctionality
  rounding?: 'none' | 'regular'
  size?: ButtonSizeType
  theme?: ButtonThemeType
  className?: string
}

export function TourButton({ page, className, theme, ...props }: React.PropsWithChildren<TourButtonProps>) {
  const tour = useTour()
  const pageTours = getTour(page)
  const [userTour, setCurrentTour] = React.useState(tour.getStepsForUser(pageTours))
  const buttonTheme: ButtonThemeType = userTour.hasNewTours ? 'blue' : (theme ?? 'headerItem')

  return (
    <Button
      {...props}
      theme={buttonTheme}
      leftContent={
        tour.isLoading ? (
          <Delayed
            delayComponent={
              <Icon name="diamondOutlined" className={classnames({ 'text-white': buttonTheme === 'headerItem' })} />
            }
          >
            <Spinner width={16} />
          </Delayed>
        ) : (
          <Icon name="diamondOutlined" className={classnames({ 'text-white': buttonTheme === 'headerItem' })} />
        )
      }
      className={className}
      contentClass={page !== SpecificChartFunctionality.quotePage ? 'hidden lg:flex' : ''}
      title="Explore new features"
      onClick={async () => {
        if (userTour) {
          const activeTour = await tour.startTour(userTour.tour)
          activeTour?.once('inactive', () => {
            setCurrentTour(tour.getStepsForUser(pageTours))
          })
        }
      }}
    />
  )
}
