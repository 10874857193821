import { IndicatorType } from '../../constants/common'
import { AroConfig } from './aro'

export type Options = {
  period: number
}

export type Attrs = Options

export class ArooscConfig extends AroConfig {
  static type = IndicatorType.Aroosc
  static abbreviation = 'aroosc'
  static label = 'Aroon Oscillator'
  static shortLabel = 'Aroon Oscillator'
}
