import {
  Button,
  ButtonComponentProps,
  ConfirmationDialog,
  Delayed,
  DialogDismiss,
  Popover,
  PopoverPlacement,
  PopoverRounding,
  PopoverStateReturn,
  PopoverTheme,
  PopoverTrigger,
  Spinner,
  ZIndexContext,
  usePopoverState,
} from '@finviz/website'
import classNames from 'classnames'
import React from 'react'

import { CHART_ELEMENT_ID, CHART_SETTINGS_DIALOG_TEST_ID, SpecificChartFunctionality } from '../../constants/common'
import ChartLayout from '../../models/chart_layout'
import { useAsyncModule } from '../../utils/async-modules-utils'
import { encodeQueryString } from '../../utils/query_string'
import { useChartLayoutGlobalModelAsync } from '../../utils/useChartLayoutGlobalModelAsync'
import { chartSettingsAsyncModule } from '../chart-settings/async-modules'

const utmCampaign: Partial<Record<SpecificChartFunctionality, string>> = {
  [SpecificChartFunctionality.quotePage]: 'quote-settings-button',
  [SpecificChartFunctionality.futuresPage]: 'futures-settings-button',
  [SpecificChartFunctionality.forexPage]: 'forex-settings-button',
  [SpecificChartFunctionality.cryptoPage]: 'crypto-settings-button',
}

interface ScrollableChartSettingsPopoverProps {
  isOpen: boolean
  state: PopoverStateReturn
}

export function PopoverInner({ hidePopover }: { hidePopover: () => void }) {
  const [chartSettingsModule] = useAsyncModule({
    ...chartSettingsAsyncModule,
    isSuspenseMode: true,
    errorSeverity: 'medium',
    onError: () => hidePopover(),
  })

  const { ChartSettings } = chartSettingsModule ?? {}

  return <ChartSettings onClose={hidePopover} />
}

export const ScrollableChartSettingsPopover = React.memo(function ScrollableChartSettingsPopover({
  state,
  isOpen,
}: ScrollableChartSettingsPopoverProps) {
  const { forceGlobalRerender, getIsConfigChanged, chartLayoutModel } = useChartLayoutGlobalModelAsync()
  const confirmationDialogState = usePopoverState()
  const hidePopover = state.hide

  const checkChangesAndOpenDiscard = React.useCallback(
    (ev: Event | React.MouseEvent) => {
      if (getIsConfigChanged()) {
        ev.preventDefault()
        confirmationDialogState.show()
        return
      }
      hidePopover()
    },
    [confirmationDialogState, getIsConfigChanged, hidePopover]
  )

  const discardChangesAndHide = React.useCallback(() => {
    forceGlobalRerender()
    // We need to defer the hide so that the rerender finishes first
    setTimeout(hidePopover)
  }, [forceGlobalRerender, hidePopover])

  const buttonPosition = React.useMemo(() => {
    const popoverContentElement = state.getState().disclosureElement
    const { top = 0, height = 32 } = popoverContentElement?.getBoundingClientRect() ?? {}
    return top + height + window.scrollY
    // Only recount on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const chartHeight = React.useMemo(() => {
    const chartElement = document.getElementById(CHART_ELEMENT_ID)
    return chartElement?.clientHeight ?? 0
    // Only recount on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Popover
      theme="none"
      state={state}
      onClose={checkChangesAndOpenDiscard}
      style={{ minHeight: chartHeight - 45 }}
      data-testid={CHART_SETTINGS_DIALOG_TEST_ID}
    >
      <ZIndexContext.Provider value={null}>
        <div
          // Limit the popover height to the max available height when *not* scrolled below the fold
          className={classNames('sticky flex max-h-[--popover-available-height]', {
            'pb-inset-bottom': chartLayoutModel?.specificChartFunctionality !== SpecificChartFunctionality.chartPage,
          })}
          style={{ top: -buttonPosition }}
        >
          <div
            className={classNames(
              PopoverRounding.regular,
              PopoverTheme.default,
              // Limit the popover height when scrolled below the fold. We need to subtract the top/bottom gutter and border
              'max-h-[calc(100vh-12px)] w-145 overflow-hidden'
            )}
          >
            <React.Suspense
              fallback={
                <div className="flex h-179 max-h-[--popover-available-height] items-center justify-center">
                  <Delayed>
                    <Spinner width={24} />
                  </Delayed>
                </div>
              }
            >
              <PopoverInner hidePopover={state.hide} />
            </React.Suspense>
          </div>
        </div>
      </ZIndexContext.Provider>

      <ConfirmationDialog
        state={confirmationDialogState}
        aria-label="Unsaved changes"
        title="Unsaved changes"
        actions={
          <>
            <Button data-testid="confirmation-dialog-cancel" as={DialogDismiss}>
              Cancel
            </Button>
            <Button
              theme="red"
              data-testid="confirmation-dialog-delete"
              as={DialogDismiss}
              onClick={discardChangesAndHide}
            >
              Discard changes
            </Button>
          </>
        }
      >
        Are you sure you want to discard changes?
      </ConfirmationDialog>
    </Popover>
  )
})

interface ChartSettingsButtonProps extends ButtonComponentProps {
  isPremium: boolean
  placement?: PopoverPlacement
  model: ChartLayout
}

export function ChartSettingsButton({
  isPremium,
  model,
  placement,
  ...props
}: React.PropsWithChildren<ChartSettingsButtonProps>) {
  const settingsPopoverState = usePopoverState({ placement: placement ?? 'bottom' })
  const isOpen = settingsPopoverState.useState('open')

  const buttonProps = React.useMemo(() => {
    if (model.specificChartFunctionality !== SpecificChartFunctionality.quotePage && !isPremium) {
      return {
        as: 'a',
        href: `/elite.ashx?${encodeQueryString({
          utm_source: 'finviz',
          utm_medium: 'banner',
          utm_campaign: utmCampaign[model.specificChartFunctionality],
        })}`,
      } as ButtonComponentProps<'a'>
    }

    return {
      as: PopoverTrigger,
      state: settingsPopoverState,
      active: isOpen,
    } as ButtonComponentProps<typeof PopoverTrigger>
  }, [model.specificChartFunctionality, isPremium, settingsPopoverState, isOpen])

  return (
    <>
      <Button
        {...buttonProps}
        as={buttonProps.as}
        focusRing={false}
        data-testid="chart-settings-open-btn"
        appearance={props.children ? undefined : 'square'}
        title={props.children ? undefined : 'Chart Settings'}
        leftContent="settingsWheel"
        {...props}
      />
      <ScrollableChartSettingsPopover isOpen={isOpen} state={settingsPopoverState} />
    </>
  )
}
