import { Point } from '../../../types/shared'
import { getRoundedObject } from '../../controllers/renderUtils'
import math from '../../helpers/math'
import PaneModel from '../../models/pane'
import { isInRange } from '../../utils/helpers'
import { ITrendAttrs } from './pitchfork_v1'

interface IGetEdgeIntersectionPointArguments {
  sides: { x: number; y: number }[][]
  rayOrigin: Point
  rayDirection: Point
}

export const getEdgeIntersectionPoint = ({ sides, rayOrigin, rayDirection }: IGetEdgeIntersectionPointArguments) => {
  if (rayOrigin.x === rayDirection.x && rayOrigin.y === rayDirection.y) return rayDirection

  let intersection = rayDirection
  let intersectionDistance = 0
  for (let index = 0; index < sides.length; index++) {
    const rayToLineIntersection = math.getRayToLineSegmentIntersection(
      rayOrigin,
      rayDirection,
      sides[index][0],
      sides[index][1]
    )
    if (rayToLineIntersection) {
      const distance = math.distance(rayToLineIntersection, rayOrigin)
      if (distance > intersectionDistance && isInRange({ value: distance })) {
        intersectionDistance = distance
        intersection = { x: rayToLineIntersection.x, y: rayToLineIntersection.y }
      }
    }
  }

  return intersection
}

export const getChartVisibleAreaBoundaries = (paneModel: PaneModel) => {
  const { left, right, top, bottom } = paneModel.getChartLayoutSettings().ChartSettings
  const { leftOffset, width } = paneModel.chart()

  const chartVisibleAreaLeftX = Math.abs(leftOffset)
  const chartVisibleAreaRightX = chartVisibleAreaLeftX + width - left.width - right.width
  const chartVisibleAreaTopY = 0
  const chartVisibleAreaBottomY = paneModel.height - top.height - bottom.height

  const chartVisibleAreaTopLeftPoint = {
    x: chartVisibleAreaLeftX,
    y: chartVisibleAreaTopY,
  }
  const chartVisibleAreaTopRightPoint = {
    x: chartVisibleAreaRightX,
    y: chartVisibleAreaTopY,
  }
  const chartVisibleAreaBottomLeftPoint = {
    x: chartVisibleAreaLeftX,
    y: chartVisibleAreaBottomY,
  }
  const chartVisibleAreaBottomRightPoint = {
    x: chartVisibleAreaRightX,
    y: chartVisibleAreaBottomY,
  }
  const topSide = [chartVisibleAreaTopLeftPoint, chartVisibleAreaTopRightPoint]
  const rightSide = [chartVisibleAreaTopRightPoint, chartVisibleAreaBottomRightPoint]
  const bottomSide = [chartVisibleAreaBottomLeftPoint, chartVisibleAreaBottomRightPoint]
  const leftSide = [chartVisibleAreaTopLeftPoint, chartVisibleAreaBottomLeftPoint]

  const sides = [topSide, rightSide, bottomSide, leftSide]

  return sides
}

interface IGetPitchforkCoordinates {
  paneModel: PaneModel
  rayOrigin: Point
  rayDirectionControlPoint1: Point
  rayDirectionControlPoint2?: Point
  trend1: ITrendAttrs
  trend2: ITrendAttrs
}

interface ILineSegment {
  P1: Point
  P2: Point
}

interface ICoordinates {
  medianRay: ILineSegment
  trend1Ray1: ILineSegment | undefined
  trend1Ray2: ILineSegment | undefined
  trend2Ray1: ILineSegment | undefined
  trend2Ray2: ILineSegment | undefined
}

export const getPitchforkCoordinates = ({
  paneModel,
  rayOrigin,
  rayDirectionControlPoint1,
  rayDirectionControlPoint2,
  trend1,
  trend2,
}: IGetPitchforkCoordinates) => {
  const sides = getChartVisibleAreaBoundaries(paneModel)

  const coordinates: ICoordinates = {
    medianRay: { P1: rayOrigin, P2: rayDirectionControlPoint1 },
    trend1Ray1: undefined,
    trend2Ray1: undefined,
    trend1Ray2: undefined,
    trend2Ray2: undefined,
  }

  // If rayDirectionControlPoint2 doesn't exist return only medianRay with rayDirectionControlPoint1 as rayDirection
  if (!rayDirectionControlPoint2) {
    coordinates.medianRay = {
      P1: getRoundedObject(rayOrigin),
      P2: getRoundedObject(
        getEdgeIntersectionPoint({
          sides,
          rayOrigin,
          rayDirection: rayDirectionControlPoint1,
        })
      ),
    }
    return coordinates
  }

  const medianRayDirectionPoint = math.getMiddlePointOnLineSegment(rayDirectionControlPoint1, rayDirectionControlPoint2)
  const rayOriginToRayDirectionVector = {
    x: medianRayDirectionPoint.x - rayOrigin.x,
    y: medianRayDirectionPoint.y - rayOrigin.y,
  }

  const rayDirectionControlPoint1ToPoint2Vector = {
    x: rayDirectionControlPoint1.x - rayDirectionControlPoint2.x,
    y: rayDirectionControlPoint1.y - rayDirectionControlPoint2.y,
  }

  const normalizedRayDirectionControlPoint1ToPoint2Vector = math.normalizeVector(
    rayDirectionControlPoint1ToPoint2Vector
  )

  const medianRayDirectionPointToRayDirectionControlPoint1Distance = math.distance(
    medianRayDirectionPoint,
    rayDirectionControlPoint1
  )

  coordinates.medianRay = {
    P1: getRoundedObject(rayOrigin),
    P2: getRoundedObject(
      getEdgeIntersectionPoint({
        sides,
        rayOrigin,
        rayDirection: medianRayDirectionPoint,
      })
    ),
  }
  if (trend1.active) {
    const trend1Ray = getTrendRayCoordinates({
      trend: trend1.trend,
      medianToControlPointDistance: medianRayDirectionPointToRayDirectionControlPoint1Distance,
      normalizedControlToControlPointVector: normalizedRayDirectionControlPoint1ToPoint2Vector,
      rayOriginToRayDirectionVector,
      medianRayDirectionPoint,
      sides,
    })

    coordinates.trend1Ray1 = trend1Ray.trendRay1
    coordinates.trend1Ray2 = trend1Ray.trendRay2
  }
  if (trend2.active) {
    const trend2Ray = getTrendRayCoordinates({
      trend: trend2.trend,
      medianToControlPointDistance: medianRayDirectionPointToRayDirectionControlPoint1Distance,
      normalizedControlToControlPointVector: normalizedRayDirectionControlPoint1ToPoint2Vector,
      rayOriginToRayDirectionVector,
      medianRayDirectionPoint,
      sides,
    })

    coordinates.trend2Ray1 = trend2Ray.trendRay1
    coordinates.trend2Ray2 = trend2Ray.trendRay2
  }

  return coordinates
}

interface IGetTrendRayCoordinates {
  trend: number
  medianToControlPointDistance: number
  normalizedControlToControlPointVector: Point
  rayOriginToRayDirectionVector: Point
  medianRayDirectionPoint: Point
  sides: Point[][]
}

const getTrendRayCoordinates = ({
  trend,
  medianToControlPointDistance,
  normalizedControlToControlPointVector,
  rayOriginToRayDirectionVector,
  medianRayDirectionPoint,
  sides,
}: IGetTrendRayCoordinates) => {
  const medianToTrendLineDistance = medianToControlPointDistance * trend

  const trendRayPoint1Origin = math.addPoints(
    medianRayDirectionPoint,
    math.multiplyVectorByScalar(normalizedControlToControlPointVector, medianToTrendLineDistance)
  )
  const trendPoint2RayOriginPoint2 = math.subtractPoints(
    medianRayDirectionPoint,
    math.multiplyVectorByScalar(normalizedControlToControlPointVector, medianToTrendLineDistance)
  )

  const trendRayPoint1DirectionPoint = {
    x: trendRayPoint1Origin.x + rayOriginToRayDirectionVector.x,
    y: trendRayPoint1Origin.y + rayOriginToRayDirectionVector.y,
  }
  const trendPoint2RayDirectionPoint = {
    x: trendPoint2RayOriginPoint2.x + rayOriginToRayDirectionVector.x,
    y: trendPoint2RayOriginPoint2.y + rayOriginToRayDirectionVector.y,
  }

  return {
    trendRay1: {
      P1: getRoundedObject(trendRayPoint1Origin),
      P2: getRoundedObject(
        getEdgeIntersectionPoint({
          sides,
          rayOrigin: trendRayPoint1Origin,
          rayDirection: trendRayPoint1DirectionPoint,
        })
      ),
    },
    trendRay2: {
      P1: getRoundedObject(trendPoint2RayOriginPoint2),
      P2: getRoundedObject(
        getEdgeIntersectionPoint({
          sides,
          rayOrigin: trendPoint2RayOriginPoint2,
          rayDirection: trendPoint2RayDirectionPoint,
        })
      ),
    },
  }
}
