import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export type Options = {
  emaPeriod: number
  multiplier: number
  atrPeriod: number
}

export interface Attrs extends Options {
  period?: string
  strokeColor: string
  fillColor: string
}

export class KCHConfig extends BaseConfig {
  static type = OverlayType.Kch
  static abbreviation = 'kch'
  static label = 'Keltner Channel'
  static shortLabel = 'Keltner Channel'
  static optionsOrder = ['emaPeriod', 'multiplier', 'atrPeriod'] as const

  static optionsLabels = {
    emaPeriod: 'EMA Period',
    multiplier: 'Multiplier',
    atrPeriod: 'ATR Period',
  }

  static inputsOrder = [...this.optionsOrder, 'strokeColor', 'fillColor'] as Array<keyof Omit<Attrs, 'period'>>

  static inputsLabels = {
    ...this.optionsLabels,
    strokeColor: 'Stroke Color',
    fillColor: 'Fill Color',
  }

  static calculatedValuesOrder = ['upperBand', 'middleLine', 'lowerBand'] as const
  static calculatedValuesLabels = {
    upperBand: 'Upper Band',
    middleLine: 'Middle Line',
    lowerBand: 'Lower Band',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[1]
}
