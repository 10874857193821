import { NoneConfig } from './configs/none'
import Indicator from './indicator'

class None extends Indicator {
  static config = NoneConfig

  compute() {}

  renderIndicator() {}

  toString() {
    return ''
  }
}

export default None
