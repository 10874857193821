import Text from '../canvas/text'
import { OFFSET, PADDING, TextBaseline } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import Pane from '../models/pane'
import utils from '../utils'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, RWIConfig } from './configs/rwi'
import Indicator from './indicator'

class RWI extends Indicator<Attrs> {
  static config = RWIConfig

  tr: number[] = []
  atr: number[] = []
  rwiHi: number[] = []
  rwiLo: number[] = []
  declare period: number

  constructor(attrs: Attrs, model: Pane) {
    super(attrs, model)
    this.renderYAxis = this.renderYAxis.bind(this)
  }

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.tr = []
      this.atr = []
      this.rwiHi = []
      this.rwiLo = []
      let atr = 0

      for (let i = 0; i < this.data.close.length; i++) {
        const tr = utils.max([
          this.data.high[i] - this.data.low[i],
          Math.abs(this.data.high[i] - this.data.close[i - 1]),
          Math.abs(this.data.low[i] - this.data.close[i - 1]),
        ])
        this.tr[i] = tr

        if (i < this.period) {
          atr += tr
          this.atr[i] = atr / (i + 1)
          if (i === this.period - 1) {
            atr /= this.period
          }
        } else {
          this.atr[i] = (this.atr[i - 1] * (this.period - 1) + tr) / this.period
        }
      }

      for (let i = this.period + 2; i < this.data.close.length; i++) {
        const h = this.data.high[i]
        const l = this.data.low[i]
        let trSum = this.tr[i - 1] + this.tr[i - 2]
        let count = 2
        let atrWeight = (trSum / count) * Math.sqrt(count)
        let maxHi = atrWeight > 0 ? (h - this.data.low[i - 1]) / atrWeight : -1
        let maxLo = atrWeight > 0 ? (this.data.high[i - 1] - l) / atrWeight : -1
        for (let j = i - 1; j > i - this.period + 1; j--) {
          trSum += this.tr[j - 2]
          count++
          atrWeight = (trSum / count) * Math.sqrt(count)
          if (atrWeight === 0) {
            continue
          }

          const hi = (h - this.data.low[j - 1]) / atrWeight
          if (hi > maxHi) {
            maxHi = hi
          }

          const lo = (this.data.high[j - 1] - l) / atrWeight
          if (lo > maxLo) {
            maxLo = lo
          }
        }
        this.rwiHi[i] = maxHi
        this.rwiLo[i] = maxLo
      }
    }
    const { min, max } =
      this.rwiHi.length > 0 || this.rwiLo.length > 0
        ? this.computeVisibleMinMax(this.rwiHi, this.rwiLo)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      rwiLo: '#c80000',
      rwiHi: '#00a000',
    }
  }

  getValueLabelsAtIndex(index: number) {
    if (this.data.close.length === 0) return []

    const lineColors = this.getLineColors()
    const dataIndex = this.data?.barToDataIndex[index]
    return [
      { color: lineColors.rwiLo, text: this.getValueLabel(this.rwiLo[dataIndex]) },
      { color: lineColors.rwiHi, text: this.getValueLabel(this.rwiHi[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0) return

    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period,
    }

    context.translate(0.5, 0.5)
    context.set('strokeStyle', '#c80000')
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.rwiLo[i])))
      },
    })
    context.stroke()

    context.set('strokeStyle', '#00a000')
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.rwiHi[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  renderYAxis(context: CanvasRenderingContext2D) {
    super.renderYAxis(context)
    const { IndicatorSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const translate = getTranslate({
      context,
      xOffset: IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })
    translate.do()
    const text = new Text(
      {
        x: this.contentWidth + OFFSET.M - PADDING.XXS + IndicatorSettings.right.axis.margin.left!,
        font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.right.axis.font),
        lineHeight: IndicatorSettings.right.axis.font.lineHeight,
        padding: {
          top: IndicatorSettings.right.axis.font?.padding?.top ?? PADDING.XXS,
          right: IndicatorSettings.right.axis.font?.padding?.right ?? PADDING.XXS,
          bottom: IndicatorSettings.right.axis.font?.padding?.bottom ?? PADDING.XXS,
          left: IndicatorSettings.right.axis.font?.padding?.left ?? PADDING.XXS,
        },
        textBaseline: TextBaseline.middle,
        fillStyle: Colors.indicatorCurrentText,
      },
      this.model
    )
    const textLabels = []
    if (this.rwiLo.length > 0) {
      textLabels.push({
        text: this.rwiLo.last()!.toFixed(2),
        y: Math.round(this.fy(this.rwiLo.last()!)),
        background: '#c80000',
      })
    }
    if (this.rwiHi.length > 0) {
      textLabels.push({
        text: this.rwiHi.last()!.toFixed(2),
        y: Math.round(this.fy(this.rwiHi.last()!)),
        background: '#00a000',
      })
    }

    const minY = 0
    const maxY = this.height - IndicatorSettings.bottom.height - IndicatorSettings.top.height
    textLabels.forEach((labelSetting) => {
      if (labelSetting.y >= minY && labelSetting.y <= maxY) {
        text.set(labelSetting).render(context)
      }
    })

    translate.undo()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 9,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: RWIConfig.label,
      inputs: RWIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default RWI
