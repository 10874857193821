import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, RMIConfig } from './configs/rmi'
import Indicator from './indicator'

class RMI extends Indicator<Attrs> {
  static config = RMIConfig

  rmi: number[] = []
  declare momentum: number
  declare period: number

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [period = 0, momentum = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period, momentum })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (!this.isComputeNecessary()) return

    this.rmi = []
    let count = 0
    let sumu = 0
    let sumd = 0
    let u: number, d: number, ad: number, au: number
    for (let i = this.momentum; i < this.data.close.length; i++) {
      u = d = 0
      if (this.data.close[i] > this.data.close[i - this.momentum]) {
        u = this.data.close[i] - this.data.close[i - this.momentum]
      }
      if (this.data.close[i] < this.data.close[i - this.momentum]) {
        d = this.data.close[i - this.momentum] - this.data.close[i]
      }

      if (count < this.period) {
        sumu += u
        sumd += d
        count++
        au = sumu / count
        ad = sumd / count
      } else {
        au = (au! * (this.period - 1) + u) / this.period
        ad = (ad! * (this.period - 1) + d) / this.period
      }

      const rs = ad > 0 ? au / ad : 1
      this.rmi[i] = 100 - 100 * (1 / (1 + rs))
    }

    this.lastValue = this.rmi.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.rmi)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.rmi, this.momentum, 30, 50, 70)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 20,
        required: true,
        min: 1,
        max: 999999,
      },
      momentum: {
        type: 'number',
        label: 'Momentum',
        name: 'momentum',
        value: this.momentum ?? 5,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: RMIConfig.label,
      inputs: RMIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        momentum: `${options.momentum.label} must be a whole number between ${options.momentum.min} and ${options.momentum.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
      case 'momentum':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default RMI
