import { PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import { getOffsetFromLineWidth, getRoundedObject } from '../controllers/renderUtils'
import math from '../helpers/math'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

export interface ITriangleAttrs {
  x1: number
  x2: number
  x3: number
  y1: number
  y2: number
  y3: number
  fill: string
  border: string
}

class Triangle<Attrs extends ITriangleAttrs = ITriangleAttrs> extends Element<Attrs> {
  static type = CanvasElementType.triangle

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'x3' | 'y1' | 'y2' | 'y3'>
  name = 'Triangle'

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.resize = this.resize.bind(this)

    this._thumbs = [
      new Thumb(
        'a',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'b',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'c',
        () => this.attrs.x3,
        () => this.attrs.y3,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return { fill: ElementSettings.defaultFill, border: ElementSettings.Colors.border } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2', 'x3'], y: ['y1', 'y2', 'y3'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, x3, y1, y2, y3 } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, x3, y1, y2, y3 })

    context.set('fillStyle', this.attrs.fill)

    const offset = this.getShouldRenderThumbs() ? getOffsetFromLineWidth(1) : 0

    context.translate(offset, offset)
    context.beginPath()
    context.moveTo(roundedXY.x1, roundedXY.y1)
    context.lineTo(roundedXY.x2, roundedXY.y2)
    context.lineTo(roundedXY.x3, roundedXY.y3)
    context.lineTo(roundedXY.x1, roundedXY.y1)
    context.fill()
    context.translate(offset * -1, offset * -1)

    if (this.getShouldRenderThumbs()) {
      context.set('strokeStyle', this.attrs.border)
      context.stroke()
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.y1 += y
    this.attrs.x2 += x
    this.attrs.y2 += y
    this.attrs.x3 += x
    this.attrs.y3 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'a':
        this.attrs.x1 += difX
        this.attrs.y1 += difY
        break
      case 'b':
        this.attrs.x2 += difX
        this.attrs.y2 += difY
        break
      case 'c':
        this.attrs.x3 += difX
        this.attrs.y3 += difY
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const polygon = [1, 2, 3].map((i) => ({
      x: this.scaled[`x${i}` as 'x1' | 'x2' | 'x3'],
      y: this.scaled[`y${i}` as 'y1' | 'y2' | 'y3'],
    }))
    if (math.pointInPolygon(area.scaled, polygon)) {
      return true
    }
    return super.isInArea(area)
  }
}

Triangle.prototype.modalConfig = {
  inputs: [{ type: 'background', name: 'fill' }],
}

export default Triangle
