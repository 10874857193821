import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export class OBVConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Obv
  static abbreviation = 'obv'
  static label = 'On-Balance Volume'
  static shortLabel = 'OBV'
  static calculatedValuesOrder = ['obv'] as const
  static calculatedValuesLabels = { obv: 'OBV' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
