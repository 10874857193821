import { ObjectHash } from '../../types/shared'
import { SORT_DIRECTION } from '../constants/common'

export function sortObjects(
  arr: ObjectHash[],
  options: Array<{ sortBy: string; sortDirection?: keyof typeof SORT_DIRECTION }> = []
) {
  return arr.sort((a, b) =>
    options.reduce((acc, option) => {
      const { sortBy, sortDirection = SORT_DIRECTION.ASC } = option
      const directionMultiplier = sortDirection === SORT_DIRECTION.ASC ? 1 : -1
      return acc === 0 ? (a[sortBy] - b[sortBy]) * directionMultiplier : acc
    }, 0)
  )
}
