import { DrawingActions, IToolbarDrawingAction, IToolbarDrawingActionItem } from './interfaces'

export const drawingActions: Record<DrawingActions, IToolbarDrawingAction> = {
  [DrawingActions.AddNote]: {
    id: DrawingActions.AddNote,
    title: 'Add note',
    icon: 'note',
  },
  [DrawingActions.DeleteDrawings]: {
    id: DrawingActions.DeleteDrawings,
    title: 'Remove all drawings',
    icon: 'drawingActionDelete',
  },
  [DrawingActions.DeleteAutoSavedDrawings]: {
    id: DrawingActions.DeleteAutoSavedDrawings,
    title: 'Remove all autosaved drawings',
    icon: 'drawingActionDelete',
  },
}

export const getToolbarDrawingActions = ({
  isAutoSaveActive,
  isAddNoteAvailable = false,
  isIdea,
}: {
  isAutoSaveActive: boolean
  isAddNoteAvailable?: boolean
  isIdea?: boolean
}): Array<IToolbarDrawingActionItem> => [
  ...(isAddNoteAvailable
    ? [
        {
          defaultItem: drawingActions[DrawingActions.AddNote],
          isDisabled: isIdea,
          disabledTooltip: 'Add note is not available in ideas',
        },
      ]
    : []),
  {
    defaultItem:
      drawingActions[isAutoSaveActive ? DrawingActions.DeleteAutoSavedDrawings : DrawingActions.DeleteDrawings],
  },
]
