import { Spinner } from '@finviz/website'
import classnames from 'classnames'
import React from 'react'

export function ChartSpinnerOverlay({ shouldDisplay, className }: { shouldDisplay: boolean; className: string }) {
  const [isVisible, setIsVisible] = React.useState(shouldDisplay)
  React.useEffect(() => {
    let timeout: number
    if (!shouldDisplay) {
      timeout = window.setTimeout(() => {
        setIsVisible(false)
      }, 400)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [shouldDisplay])

  return (
    <>
      {isVisible && (
        <div
          className={classnames(
            'absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center overflow-hidden transition-opacity duration-300 ease-linear',
            {
              'opacity-100': shouldDisplay,
              'opacity-0': !shouldDisplay,
            },
            className
          )}
          data-testid="chart-spinner-overlay"
        >
          <Spinner />
        </div>
      )}
    </>
  )
}
