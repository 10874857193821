import queryString from 'query-string'

import { ObjectHash } from '../../types/shared'

interface FetchApiProps {
  location: string
  queryParameters?: ObjectHash
  method?: string
  body?: RequestInit['body']
  contentType?: ContentType
  abortController?: null | AbortController
  throwOnStatusCodes?: number[]
  throwOnPredicate?: (response: Response) => boolean
  keepalive?: boolean
}

export const getFormDataFromObject = (object: ObjectHash) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key])
    return formData
  }, new FormData())

// Keep in sync with ContentType in https://github.com/finvizhq/Finviz-Website/blob/master/Website/js/main/services/api.ts
// START
export enum ContentType {
  ApplicationJson = 'application/json',
  ApplicationJsonUTF = 'application/json; charset=utf-8', // WriteAsJsonAsync
  FormUrlEncoded = 'application/x-www-form-urlencoded',
  Html = 'text/html; charset=utf-8',
}

function parseResponseForContentType(response: string, contentType: ContentType) {
  switch (contentType) {
    case ContentType.ApplicationJson:
    case ContentType.ApplicationJsonUTF:
    case ContentType.FormUrlEncoded:
      return JSON.parse(response)
    default:
      return response
  }
}
// END

const fetchApi = async <T extends ObjectHash | string = ObjectHash>({
  location,
  queryParameters,
  method,
  body,
  contentType,
  abortController,
  throwOnStatusCodes,
  throwOnPredicate,
  keepalive = false,
}: FetchApiProps) => {
  const url = `${location}${queryParameters ? `?${queryString.stringify(queryParameters)}` : ''}`
  const parameters: RequestInit = {
    method: method || 'GET',
    credentials: 'include',
    headers: contentType && {
      'Content-Type': contentType,
    },
    signal: abortController?.signal,
    body: body,
    keepalive,
  }
  try {
    const response = await fetch(url, parameters)
    const responseContentType: ContentType = (response.headers.get('Content-Type') as any) ?? ContentType.Html
    const responseData = parseResponseForContentType(await response.text(), responseContentType)
    if (throwOnStatusCodes?.includes(response.status) || throwOnPredicate?.(response)) {
      const StatusCodeError = getStatusCodeError(response.status)
      throw new StatusCodeError(`${url} returned ${response.status}`, responseData as T, response.status)
    }
    return responseData as T
  } catch (err) {
    throw err
  }
}

function getStatusCodeError(statusCode: number): typeof GenericStatusCodeError {
  switch (statusCode) {
    case 403:
      return ForbiddenError
    case 404:
      return NotFoundError
    case 410:
      return GoneError
    case 500:
      return InternalServerError
    default:
      return GenericStatusCodeError
  }
}

export class GenericStatusCodeError extends Error {
  response: ObjectHash | string
  statusCode: number

  constructor(message: string, response: ObjectHash | string, statusCode: number) {
    super(message)
    this.response = response
    this.statusCode = statusCode
  }
}
export class ForbiddenError extends GenericStatusCodeError {}
export class NotFoundError extends GenericStatusCodeError {}
export class InternalServerError extends GenericStatusCodeError {}
export class GoneError extends GenericStatusCodeError {}

export default fetchApi
