import type { ObjectHash } from '../../../types/shared'
import ElementBaseConfig from '../../canvas/ElementBaseConfig'
import { OverlayType } from '../../constants/common'

export default class OverlayBaseConfig extends ElementBaseConfig {
  static type = OverlayType.Overlay
  static abbreviation = 'ovl'
  static label = 'Overlay'
  static shortLabel = 'Overlay'
  static optionsOrder = [] as readonly string[]
  static optionsLabels = {}
  static inputsOrder = [] as readonly string[]
  static inputsLabels = {}
  static calculatedValuesOrder = [] as readonly string[]
  static calculatedValuesLabels = {}
  static defaultCalculatedValueOption = undefined as undefined | string

  get defaultCalculatedValueOption() {
    return (this.constructor as typeof OverlayBaseConfig).defaultCalculatedValueOption
  }

  static getShortLabelWithAttrs(attrs: ObjectHash) {
    return `${this.shortLabel} ${this.optionsOrder.map((item) => attrs[item]).join(',')}`
  }

  get getShortLabelWithAttrs() {
    return (this.constructor as typeof OverlayBaseConfig).getShortLabelWithAttrs
  }

  static getDefaultCalculatedValues() {
    return this.calculatedValuesOrder.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {})
  }

  get getDefaultCalculatedValues() {
    return (this.constructor as typeof OverlayBaseConfig).getDefaultCalculatedValues
  }
}
