import { ICConfig, Options } from '../overlays/configs/ic'
import utils from '../utils'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  conversionLine: number[]
  baseLine: number[]
  senkouSpanA: number[]
  senkouSpanB: number[]
}

type CalculationSupportValues = {
  conversionStartIndex: number
  baseStartIndex: number
  spanBIndex: number
  start: number
  end: number
}

export class ICCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = ICConfig
  declare options: Options
  protected _calculationSupportValues = {} as CalculationSupportValues

  get calculationSupportValues() {
    return this._calculationSupportValues
  }

  calculate() {
    const { conversion, base, spanB } = this.options
    const { close, high, low } = this.quote

    this._calculationSupportValues.conversionStartIndex = conversion - 1
    this._calculationSupportValues.baseStartIndex = base - 1
    this._calculationSupportValues.spanBIndex = spanB - 1
    this._calculationSupportValues.start = Math.min(
      this._calculationSupportValues.baseStartIndex,
      this._calculationSupportValues.conversionStartIndex
    )
    this._calculationSupportValues.end = close.length + this._calculationSupportValues.baseStartIndex

    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let i = this._calculationSupportValues.start; i < this._calculationSupportValues.end; i++) {
      if (i >= this._calculationSupportValues.conversionStartIndex && i < close.length) {
        const highestHi = utils.max(high.slice(i - this._calculationSupportValues.conversionStartIndex, i + 1))
        const lowestLo = utils.min(low.slice(i - this._calculationSupportValues.conversionStartIndex, i + 1))
        this._calculatedValues.conversionLine[i] = (highestHi + lowestLo) / 2
      }

      if (i >= this._calculationSupportValues.baseStartIndex && i < close.length) {
        const highestHi = utils.max(high.slice(i - this._calculationSupportValues.baseStartIndex, i + 1))
        const lowestLo = utils.min(low.slice(i - this._calculationSupportValues.baseStartIndex, i + 1))
        this._calculatedValues.baseLine[i] = (highestHi + lowestLo) / 2

        this._calculatedValues.senkouSpanA[i + this._calculationSupportValues.baseStartIndex] =
          (this._calculatedValues.baseLine[i] + this._calculatedValues.conversionLine[i]) / 2 // + baseStart because it needs to be shifted to the future
      }

      if (i >= this._calculationSupportValues.spanBIndex) {
        const highestHi = utils.max(high.slice(i - this._calculationSupportValues.spanBIndex, i + 1))
        const lowestLo = utils.min(low.slice(i - this._calculationSupportValues.spanBIndex, i + 1))
        this._calculatedValues.senkouSpanB[i + this._calculationSupportValues.baseStartIndex] =
          (highestHi + lowestLo) / 2 // + baseStart because it needs to be shifted to the future
      }
    }
  }
}
