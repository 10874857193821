import { QuoteFinancialAttachment } from '../../constants/common'

export enum QuoteFetchType {
  Refetch = 'refetch',
  NewerData = 'newerData',
}

export enum QuoteFetchTypeUrlOption {
  New = 'new',
  Newer = 'newer',
}

export interface QuoteUrlOptions {
  type: QuoteFetchTypeUrlOption
  dataId?: string
  events?: boolean
  financialAttachments?: QuoteFinancialAttachment[]
}
