import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class ROCConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Roc
  static abbreviation = 'roc'
  static label = 'Rate of Change %'
  static shortLabel = 'ROC'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
}
