import React from 'react'

import { DEFAULT_WIDTHS, SidebarDirection } from '../constants'
import { SidebarModule, SidebarModuleType } from '../modules/modules'

export type SidebarContextType = {
  isOpen: boolean
  isCloseVisible: boolean
  direction: SidebarDirection
  width?: {
    min?: number | string
    max?: number | string
    default?: { desktop?: number; mobile?: number }
  }
  activeModule: SidebarModuleType | null
  close: () => void
  onResize: () => void
  selectModule: (module?: SidebarModule) => void
}

const DEFAULT_CONTEXT: SidebarContextType = {
  activeModule: null,
  isOpen: false,
  isCloseVisible: false,
  direction: SidebarDirection.horizontal,
  width: DEFAULT_WIDTHS,
  close: () => {},
  onResize: () => {},
  selectModule: () => {},
}

export const SidebarContext = React.createContext<SidebarContextType>(DEFAULT_CONTEXT)
export const useSidebarContext = () => React.useContext(SidebarContext)
