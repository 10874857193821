import { Button, ButtonComponentProps, setCookieRequest } from '@finviz/website'
import * as React from 'react'

import { SpecificChartFunctionality } from '../../constants/common'
import utils from '../../utils'
import { DRAWING_COOKIE_NAME, getIsDrawingEnabled } from '../../utils/chart'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'

const utmCampaign: Partial<Record<SpecificChartFunctionality, string>> = {
  [SpecificChartFunctionality.quotePage]: 'quote-draw-button',
  [SpecificChartFunctionality.futuresPage]: 'futures-interactive-chart',
  [SpecificChartFunctionality.forexPage]: 'forex-interactive-chart',
  [SpecificChartFunctionality.cryptoPage]: 'crypto-interactive-chart',
}

const testId: Partial<Record<SpecificChartFunctionality, string>> = {
  [SpecificChartFunctionality.quotePage]: 'quote-toggle-draw-btn',
  [SpecificChartFunctionality.futuresPage]: 'futures-interactive',
  [SpecificChartFunctionality.forexPage]: 'forex-interactive',
  [SpecificChartFunctionality.cryptoPage]: 'crypto-interactive',
}

interface DrawButtonProps extends Pick<ButtonComponentProps, 'theme' | 'size' | 'rounding'> {
  isPremium: boolean
  specificChartFunctionality: SpecificChartFunctionality
  active?: boolean
}

export function DrawButton({
  isPremium,
  specificChartFunctionality,
  ...props
}: React.PropsWithChildren<DrawButtonProps>) {
  const buttonProps = React.useMemo(() => {
    if (!isPremium) {
      return {
        as: 'a' as const,
        href: `/elite.ashx?${encodeQueryString({
          utm_source: 'finviz',
          utm_medium: 'banner',
          utm_campaign: utmCampaign[specificChartFunctionality],
        })}`,
      }
    }

    return {
      onClick: (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault()
        const newValue = getIsDrawingEnabled() ? 'off' : 'on'

        setCookieRequest(DRAWING_COOKIE_NAME, newValue)
          .catch(() => {
            // fallback to JS otherwise draw toggle won't work if API don't work
            const expires = new Date()
            expires.setMonth(expires.getMonth() + 1)
            utils.setCookie(DRAWING_COOKIE_NAME, newValue, expires)
          })
          .finally(() => {
            const { i: idea, ...rest } = decodeQueryString()
            if (idea) {
              document.location = `quote.ashx?${encodeQueryString(rest)}`
            } else {
              document.location.reload()
            }
          })
      },
    }
  }, [isPremium, specificChartFunctionality])

  return (
    <Button
      {...buttonProps}
      {...props}
      leftContent="draw"
      data-testid={testId[specificChartFunctionality]}
      appearance={props.children ? undefined : 'square'}
      title={props.children ? undefined : 'Draw'}
    />
  )
}
