import merge from 'lodash.merge'

import { PaneArea, RequireByKey, ResizeByThumbWithTypeAndDifs, TextAttrs } from '../../../types/shared'
import { PADDING } from '../../constants/common'
import { CanvasElementType } from '../../constants/common'
import math from '../../helpers/math'
import Chart from '../../models/chart'
import PaneModel from '../../models/pane'
import Text from '../text'
import Thumb from '../thumb'
import {
  getRectangleSidesCoordinates,
  getTailCoordinates,
  getTailToSideIntersection,
  renderCallout,
} from './callout_utils'

export interface ICalloutAttrs {
  x1: number
  y1: number
  x2: number
  y2: number
}

interface FontFamilyHeightCoefficient {
  Arial: {
    firstLine: number
    otherLines: number
  }
  Verdana: {
    firstLine: number
    otherLines: number
  }
}

type CalloutTextAttrs = ICalloutAttrs & RequireByKey<TextAttrs, 'text' | 'textAlign' | 'fillStyle' | 'border'>

class Callout<Attrs extends CalloutTextAttrs = CalloutTextAttrs> extends Text<Attrs> {
  static type = CanvasElementType.calloutV1

  name = 'Callout'

  declare fontFamilyHeightCoeficient: FontFamilyHeightCoefficient
  declare isActualBoundingBoxAvailable: boolean
  declare lastAttrs: Attrs
  declare scaled: Pick<Attrs, 'x1' | 'y1' | 'x2' | 'y2'>
  declare measuredHeight: number
  declare font: string
  declare context?: CanvasRenderingContext2D
  declare lines: {
    text: string
    metrics: TextMetrics
  }[]

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'tail',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  getModalConfigBase() {
    return {
      inputs: [
        { type: 'multiline_string', name: 'text', required: true },
        { type: 'font', name: 'font' },
        {
          type: 'background',
          name: 'fillStyle',
          label: 'Color',
          default: this.getChartLayoutSettings().ElementSettings.Colors.textWithoutBackground,
        },
        {
          type: 'background',
          name: 'background',
          label: 'Background',
          default: 'rgba(0,0,0,0)',
        },
        {
          type: 'border',
          name: 'border',
          min: 0,
        },
      ],
    }
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      text: '',
      font: {
        size: 15,
        family: 'Arial',
        style: 'normal',
        weight: 'normal',
      },
      fillStyle: ElementSettings.Colors.textWithoutBackground,
      lineHeight: 15,
      // left | right | center | start | end
      textAlign: 'left',
      // top | middle | alphabetic | bottom
      textBaseline: 'alphabetic',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      background: ElementSettings.defaultFill,
      border: { color: ElementSettings.Colors.line, width: 1 },
    } as Partial<Attrs>
  }

  setupContext(context: CanvasRenderingContext2D) {
    this.context = context
    context.set('font', this.font)
    context.set('fillStyle', this.attrs.fillStyle)
    context.set('textAlign', this.attrs.textAlign)
    context.set('textBaseline', this.attrs.textBaseline)
    this.setupTextMetricsCache()
  }

  setupTextMetricsCache() {
    const ctx = this.context

    if (
      (this.attrs.text !== this.lastAttrs?.text ||
        JSON.stringify(this.attrs.font) !== JSON.stringify(this.lastAttrs?.font)) &&
      ctx
    ) {
      this.lastAttrs = merge({}, this.attrs)
      this.lines = this.getLines().map((line) => ({
        text: line,
        metrics: ctx.measureText(line),
      }))
      this.measuredHeight = this.lines.reduce((acc, current, currentIndex) => {
        const lineHeight =
          current.metrics.actualBoundingBoxAscent +
          current.metrics.actualBoundingBoxDescent +
          (currentIndex !== this.lines.length - 1 ? PADDING.S : 0)
        return acc + lineHeight
      }, 0)
    }
  }

  renderContent(context: CanvasRenderingContext2D) {
    this.render(context)
    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  renderText() {
    if (!this.context) return
    let y = this.scaled.y2 + this.attrs.padding.top
    let previousDescent = 0
    for (let i = 0; i < this.lines.length; i++) {
      y += i === 0 ? 0 : this.lines[i].metrics.actualBoundingBoxAscent + previousDescent + PADDING.S
      this.context.fillText(this.lines[i].text, this.scaled.x2 + this.attrs.padding.left, y)
      previousDescent = this.lines[i].metrics.actualBoundingBoxDescent
    }
  }

  getCalloutProperties() {
    const textWidth = this.attrs.text === '' ? this.attrs.font.size * 2 : this.width
    const requiredTailHalfWidth = this.attrs.font.size * 0.5
    const padding = this.attrs.border.width / 2 + this.attrs.font.size * 0.5
    const radius = textWidth < requiredTailHalfWidth ? textWidth / 2 : requiredTailHalfWidth

    const measuredHeight = this.measuredHeight < requiredTailHalfWidth ? radius * 2 : this.measuredHeight
    const y2 = this.scaled.y2 - (this.lines?.[0]?.metrics.actualBoundingBoxAscent || measuredHeight)

    // Points diagram
    //.......X-----------------X.......
    //.................................
    //...X.........................X...
    //...|.........................|...
    //...|.........................|...
    //...X.........................X...
    //.................................
    //.......X-----------------X.......

    const rectangleSides = getRectangleSidesCoordinates({
      padding,
      radius,
      x2: this.scaled.x2,
      y2,
      textWidth,
      measuredHeight,
    })
    const tail = getTailCoordinates({ rectangleSides, x1: this.scaled.x1, y1: this.scaled.y1 })
    const tailDirection = getTailToSideIntersection({ tail, rectangleSides })

    return {
      radius,
      requiredTailHalfWidth,
      rectangleSides,
      tail,
      tailDirection,
    }
  }

  renderBackground() {
    if (!this.attrs.background || !this.context) {
      return
    }

    const { radius, requiredTailHalfWidth, tail, tailDirection, rectangleSides } = this.getCalloutProperties()

    this.context.beginPath()
    this.context.set('fillStyle', this.attrs.background)
    this.context.lineJoin = 'round'
    renderCallout({
      context: this.context,
      radius,
      requiredTailHalfWidth,
      tail,
      rectangleSides,
      tailDirection,
    })
    this.context.closePath()
    this.context.fill()
    this.context.set('fillStyle', this.attrs.fillStyle)
    this.renderBorder()
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    // is in rectangle
    const { tail, rectangleSides, requiredTailHalfWidth } = this.getCalloutProperties()
    if (
      this.lines &&
      rectangleSides &&
      rectangleSides.leftTop.x < area.scaled.x &&
      rectangleSides.topLeft.y < area.scaled.y &&
      rectangleSides.rightBottom.x > area.scaled.x &&
      rectangleSides.bottomRight.y > area.scaled.y
    ) {
      return true
    }
    // is in tail
    if (tail) {
      const tailP1 = math.perpendicularPointToLine({
        distance: requiredTailHalfWidth,
        line: { x1: tail.tailTip.x, y1: tail.tailTip.y, x2: tail.tailRoot.x, y2: tail.tailRoot.y },
      })
      const pointOffsetX = tailP1.x - tail.tailRoot.x
      const pointOffsetY = tailP1.y - tail.tailRoot.y
      const tailP2 = { x: tail.tailRoot.x + -1 * pointOffsetX, y: tail.tailRoot.y + -1 * pointOffsetY }
      const polygon = [tail.tailTip, tailP1, tailP2]
      if (math.pointInPolygon(area.scaled, polygon)) {
        return true
      }
    }
    return super.isInArea(area)
  }

  moveBy(x: number, y: number) {
    this.attrs.x2 += x
    this.attrs.y2 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    if (type === 'tail') {
      this.attrs.x1 += difX
      this.attrs.y1 += difY
    }
  }

  getIsInChartView(chart: Chart) {
    if (this.getIsCreator() || !this.lines) {
      return true
    }

    const { tail, rectangleSides } = this.getCalloutProperties()

    return [...Object.values(rectangleSides), tail.tailRoot, tail.tailTip].some(
      ({ x, y }) => x <= -chart.leftOffset + chart.width && x >= -chart.leftOffset && y >= 0 && y <= this.model.height
    )
  }
}

export default Callout
