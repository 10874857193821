import { ChartConfigChartPaneElement, RequireByKey, Theme } from '../../types/shared'
import { BbCalculation } from '../indicator-calculation/bb'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, BBConfig } from './configs/bb'
import Overlay from './overlay'

const BB_COLORS = {
  [Theme.light]: {
    TOP: '#D386DF',
    BOTTOM: '#69C1EA',
    MIDDLE: '#CFD2F2',
  },
  [Theme.dark]: {
    TOP: '#D38BFB',
    BOTTOM: '#57AEFB',
    MIDDLE: '#E1B6FC',
  },
}

const DEFAULT_PARAMETERS = {
  Period: 20,
  Deviations: 2.0,
}

function parsePeriod(periodStr: string) {
  const values = periodStr.split(',')
  const period = parseInt(values[0]) || DEFAULT_PARAMETERS.Period
  const deviations = parseFloat(values[1]) || DEFAULT_PARAMETERS.Deviations

  return [period, deviations]
}

class BollingerBands extends Overlay<Attrs> {
  static config = BBConfig
  declare bbCalculation: BbCalculation

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [periodInt = 0] = getParsedIntegersFromPeriodString(period)
    return periodInt + 1 // +1 offsets rendering so it doesn't start on first bar which doesn't look OK in SSR rendered charts
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      const [periodInt, deviations] = parsePeriod(period)
      this.attrs.period = periodInt
      this.attrs.deviations = deviations
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    if (typeof this.attrs.period !== 'number') {
      return
    }
    super.renderContent()
    const { Colors } = this.getChartLayoutSettings().ChartSettings.general
    const theme = this.model.chart().chart_layout().theme as Theme
    const { leftOffset, width } = this.model.chart()
    const [top, bottom] = this.model.scale.y.domain()
    const scaledTop = this.fy(top)
    const scaledBottom = this.fy(bottom)
    const { close } = this.data
    const period = Math.min(this.attrs.period, close.length - 1)

    let firstBarToRenderIndex: number | undefined
    let lastBarToRenderIndex = 0

    // middle line && find first & last bar to render
    context.translate(0.5, 0.5)
    context.beginPath()
    context.set('lineWidth', 1)
    context.set('strokeStyle', BB_COLORS[theme].MIDDLE)

    if (this.isComputeNecessary()) {
      this.bbCalculation = new BbCalculation({
        quote: this.data,
        options: {
          period,
          deviations: this.attrs.deviations,
        },
      })
      this.bbCalculation.calculate()
    }

    drawInVisibleArea({
      fromIndexOffset: period - 1,
      leftOffset,
      width,
      quote: this.data,
      paneModel: this.model,
      drawBarCallback: (i, x) => {
        const smaValue = this.bbCalculation.calculatedValues.sma[i]
        if (Number.isFinite(smaValue)) {
          firstBarToRenderIndex ??= i
          context.lineTo(x, Math.round(this.fy(smaValue)))
          lastBarToRenderIndex = i
        }
      },
    })

    if (firstBarToRenderIndex === undefined) return
    context.stroke()
    context.set('fillStyle', Colors.overlayTransparentBackground)

    // top line
    context.beginPath()
    context.moveTo(
      this.fx(firstBarToRenderIndex),
      Math.round(this.fy(this.bbCalculation.calculatedValues.upperBand[firstBarToRenderIndex]))
    )
    context.set('strokeStyle', BB_COLORS[theme].TOP)
    for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
      context.lineTo(this.fx(i), Math.round(this.fy(this.bbCalculation.calculatedValues.upperBand[i])))
    }
    context.stroke()
    context.lineTo(this.fx(lastBarToRenderIndex), scaledTop)
    context.lineTo(this.fx(firstBarToRenderIndex), scaledTop)
    context.fill()

    // bottom line
    context.beginPath()
    context.moveTo(
      this.fx(firstBarToRenderIndex),
      Math.round(this.fy(this.bbCalculation.calculatedValues.lowerBand[firstBarToRenderIndex]))
    )
    context.set('strokeStyle', BB_COLORS[theme].BOTTOM)
    for (let i = firstBarToRenderIndex; i <= lastBarToRenderIndex; i++) {
      context.lineTo(this.fx(i), Math.round(this.fy(this.bbCalculation.calculatedValues.lowerBand[i])))
    }
    context.stroke()
    context.lineTo(this.fx(lastBarToRenderIndex), scaledBottom)
    context.lineTo(this.fx(firstBarToRenderIndex), scaledBottom)
    context.fill()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: BBConfig.inputsLabels.period,
        name: 'period',
        value: this.attrs.period ?? DEFAULT_PARAMETERS.Period,
        required: true,
        min: 1,
        max: 999999,
      },
      deviations: {
        type: 'number',
        label: BBConfig.inputsLabels.deviations,
        name: 'deviations',
        value: this.attrs.deviations ?? DEFAULT_PARAMETERS.Deviations,
        required: true,
        min: 0.1,
        max: 999,
        step: 0.1,
      },
      topColor: {
        type: 'color',
        label: BBConfig.inputsLabels.topColor,
        name: 'topColor',
        value: this.attrs.topColor ?? this.getFreeColor(),
      },
      bottomColor: {
        type: 'color',
        label: BBConfig.inputsLabels.bottomColor,
        name: 'bottomColor',
        value: this.attrs.bottomColor ?? this.getFreeColor(),
      },
    }

    return {
      title: BBConfig.label,
      inputs: BBConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        deviations: `${options.deviations.label} must be a number between ${options.deviations.min} and ${options.deviations.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'deviations':
        return this.getIsNumberInputValid({ key, integerOnly: false })
      case 'topColor':
      case 'bottomColor':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    const theme = this.model.chart().chart_layout().theme as Theme
    return this.attrs.topColor || BB_COLORS[theme].TOP
  }
}

BollingerBands.prototype.defaults = { topColor: '#69c1ea', bottomColor: '#d386df' }

export default BollingerBands
