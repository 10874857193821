// TODO FontAwesome font or similar needs to be loaded before using Symbol
import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

export interface ISymbolAttrs {
  x1: number
  scaleX: number
  y1: number
  scaleY: number
  angle: number
  fill: string
  symbol: string
  border: DrawingBorder
}

class SymbolElement<Attrs extends ISymbolAttrs = ISymbolAttrs> extends Element<Attrs> {
  static type = CanvasElementType.symbol

  name = 'Symbol'

  declare textWidth: number
  declare textHeight: number
  declare scaled: Pick<Attrs, 'x1' | 'y1'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.resize = this.resize.bind(this)

    const sx = (sign: number) =>
      this.getRotatedCoordinates(this.scaled.x1 + sign * (this.textWidth / 2) * this.attrs.scaleX, this.scaled.y1)
    const sy = (sign: number) =>
      this.getRotatedCoordinates(this.scaled.x1, this.scaled.y1 + sign * (this.textHeight / 2) * this.attrs.scaleY)
    this._thumbs = [
      new Thumb(
        'xr',
        () => this.model.scale.x.invert(sx(+1).x),
        () => this.model.scale.y.invert(sx(+1).y),
        this.resize,
        this.model
      ),
      new Thumb(
        'xl',
        () => this.model.scale.x.invert(sx(-1).x),
        () => this.model.scale.y.invert(sx(-1).y),
        this.resize,
        this.model
      ),
      new Thumb(
        'yt',
        () => this.model.scale.x.invert(sy(+1).x),
        () => this.model.scale.y.invert(sy(+1).y),
        this.resize,
        this.model
      ),
      new Thumb(
        'yb',
        () => this.model.scale.x.invert(sy(-1).x),
        () => this.model.scale.y.invert(sy(-1).y),
        this.resize,
        this.model
      ),
      new Thumb(
        'c',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
    this.attrs.angle = this.attrs.angle || 0
  }

  getBoundingPointKeys = () => ({ x: ['x1'], y: ['y1'] })

  renderContent(context: CanvasRenderingContext2D) {
    context.set('font', '36px FontAwesome')
    context.set('fillStyle', this.attrs.fill)
    context.set('textAlign', 'left')
    context.set('textBaseline', 'top')

    this.textWidth = context.measureText(this.attrs.symbol).width
    this.textHeight = 36
    const xScale = this.attrs.scaleX
    const yScale = this.attrs.scaleY

    const xOffset = this.textWidth / -2
    const yOffset = this.textHeight / -2

    context.save()
    context.translate(this.scaled.x1, this.scaled.y1)
    context.rotate(this.attrs.angle)
    context.scale(xScale, yScale)
    context.fillText(this.attrs.symbol, xOffset, yOffset)
    context.restore()

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getRotatedCoordinates(x: number, y: number) {
    const cx = this.scaled.x1
    const cy = this.scaled.y1
    const dx = x - cx
    const dy = y - cy
    const rx = dx * Math.cos(this.attrs.angle) - dy * Math.sin(this.attrs.angle)
    const ry = dy * Math.cos(this.attrs.angle) + dx * Math.sin(this.attrs.angle)
    return { x: cx + rx, y: cy + ry }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    return (this.attrs.y1 += y)
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'xl':
        this.attrs.scaleX -= difX
        break
      case 'xr':
        this.attrs.scaleX += difX
        break
      case 'yt':
        this.attrs.scaleY -= difY
        break
      case 'yb':
        this.attrs.scaleY += difY
        break
      case 'c':
        this.attrs.angle += difX / 5
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const halfWidth = (this.textWidth / 2) * this.attrs.scaleX
    const halfHeight = (this.textHeight / 2) * this.attrs.scaleY
    const left = Math.min(this.scaled.x1 - halfWidth, this.scaled.x1 + halfWidth)
    const right = Math.max(this.scaled.x1 - halfWidth, this.scaled.x1 + halfWidth)
    const top = Math.min(this.scaled.y1 - halfHeight, this.scaled.y1 + halfHeight)
    const bottom = Math.max(this.scaled.y1 - halfHeight, this.scaled.y1 + halfHeight)
    if (left < area.scaled.x && area.scaled.x < right && top < area.scaled.y && area.scaled.y < bottom) {
      return true
    }
    return super.isInArea(area)
  }
}

SymbolElement.prototype.defaults = { fill: 'rgba(0,0,0,1)' }

SymbolElement.prototype.modalConfig = {
  inputs: [{ type: 'background', name: 'fill', label: 'Fill' }],
}

export default SymbolElement
