import { InputColorPicker, convertColorToHEX } from '@finviz/website'
import * as React from 'react'

interface BackgroundInputProps {
  label: string
  value: string
  onChange: (color: string) => void
}

export function BackgroundInput({ label = 'Background', value, onChange }: BackgroundInputProps) {
  return (
    <InputColorPicker
      label={label}
      inputDataTestId={`chart-modal-${label.toLocaleLowerCase()}-fill-color`}
      color={convertColorToHEX(value)}
      onChange={onChange}
    />
  )
}
