import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType } from '../constants/common'
import { getOffsetFromLineWidth, getRoundedObject } from '../controllers/renderUtils'
import PaneModel from '../models/pane'
import Element from './element'
import Thumb from './thumb'

export interface IRectangleAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  fill: string
  border: DrawingBorder
}

class Rectangle<Attrs extends IRectangleAttrs = IRectangleAttrs> extends Element<Attrs> {
  static type = CanvasElementType.rectangle

  name = 'Rectangle'

  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'br',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'tl',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
    ]
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultFill,
      border: {
        width: 1,
        color: ElementSettings.Colors.border,
      },
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, y1, y2 } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, y1, y2 })
    const width = roundedXY.x2 - roundedXY.x1
    const height = roundedXY.y2 - roundedXY.y1

    context.set('fillStyle', this.attrs.fill)

    context.fillRect(roundedXY.x1, roundedXY.y1, width, height)

    if ((this.attrs.border != null ? this.attrs.border.width : undefined) !== 0) {
      context.set('lineWidth', this.attrs.border.width)
      context.set('strokeStyle', this.attrs.border.color)
      const offset = getOffsetFromLineWidth(this.attrs.border.width)
      context.strokeRect(roundedXY.x1 + offset, roundedXY.y1 + offset, width, height)
    }

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.x2 += x
    this.attrs.y1 += y
    this.attrs.y2 += y

    // @todo - Live sync of elements while they are being moved around
    // In order to have live sync across charts we need to trigger change so listeners can update other charts with new values
    // this.set(this.attrs)
    // this.trigger('change', this)
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    if (type === 'tl') {
      this.attrs.x1 += difX
      return (this.attrs.y1 += difY)
    } else {
      this.attrs.x2 += difX
      this.attrs.y2 += difY
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const left = Math.min(this.attrs.x1, this.attrs.x2)
    const right = Math.max(this.attrs.x1, this.attrs.x2)
    const top = Math.min(this.attrs.y1, this.attrs.y2)
    const bottom = Math.max(this.attrs.y1, this.attrs.y2)
    if (left < area.x && area.x < right && top < area.y && area.y < bottom) {
      return true
    }
    return super.isInArea(area)
  }
}

Rectangle.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, default: {} },
  ],
}

export default Rectangle
