import { ChartEventType } from '../constants/common'
import chartEvent from './chart-event'
import dividends from './dividends'
import earnings from './earnings'
import split from './split'

export const chartEventsByType = Object.freeze({
  [ChartEventType.ChartEvent]: chartEvent,
  [ChartEventType.Dividends]: dividends,
  [ChartEventType.Split]: split,
  [ChartEventType.Earnings]: earnings,
})
