import { ATRConfig, Options } from '../indicators/configs/atr'
import utils from '../utils'
import { MainCalculation } from './main'

export function getTrueRange(high: number, low: number, prevClose?: number) {
  return prevClose !== undefined
    ? utils.max([high - low, Math.abs(high - prevClose), Math.abs(low - prevClose)])
    : high - low
}

export class AverageTrueRange {
  period: number
  sum: number
  count: number
  value: number

  constructor(period: number) {
    this.period = period
    this.sum = 0
    this.count = 0
    this.value = 0
  }

  add(high: number, low: number, prevClose?: number) {
    const tr = getTrueRange(high, low, prevClose)

    if (this.count < this.period) {
      this.sum += tr
      this.value = this.sum / (this.count + 1)
      if (this.count === this.period - 1) {
        this.sum /= this.period
      }
    } else {
      this.value = (this.value * (this.period - 1) + tr) / this.period
    }
    this.count++
  }
}

type DefaultCalculatedValuesType = {
  atr: number[]
}

export class ATRCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = ATRConfig

  calculate() {
    const { period } = this.options
    const { close, high, low } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < period) return

    const atr = new AverageTrueRange(period)
    for (let i = 0; i < close.length; i++) {
      atr.add(high[i], low[i], close[i - 1])

      if (i < period - 1) continue

      this._calculatedValues.atr[i] = atr.value
    }
  }
}
