import { IndicatorType, QuoteFinancialAttachment } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export class ShrtraConfig extends IndicatorBaseConfig {
  static quoteFinancialAttachments: QuoteFinancialAttachment[] = [QuoteFinancialAttachment.shortInterest]
  static type = IndicatorType.Shrtra
  static label = 'Short Ratio'
  static shortLabel = 'Short Ratio'
  static abbreviation = 'shrtra'
}
