export const chartSettingsAsyncModule = {
  importFn: () => import(/* webpackChunkName: "chart-settings" */ './module-entry'),
  cacheKey: 'chart-settings',
}

export const useChartLayoutGlobalModelAsyncModule = {
  importFn: () =>
    import(/* webpackChunkName: "use-chart-layout-global-model" */ './useChartLayoutGlobalModel/module-entry'),
  cacheKey: 'use-chart-layout-global-model',
}

export const preventClosingUnsavedChangesAsyncModule = {
  importFn: () =>
    import(/* webpackChunkName: "prevent-closing-unsaved-changes" */ './PreventClosingUnsavedChanges/module-entry'),
  cacheKey: 'prevent-closing-unsaved-changes',
}
