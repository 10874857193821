import { Toolbar as ToolbarComponent, ToolbarDivider, ToolbarGroupProps, ToolbarItem } from '@finviz/website'
import classnames from 'classnames'
import React from 'react'

import { PaneArea } from '../../../types/shared'
import { SpecificChartFunctionality } from '../../constants/common'
import { drawingCreatorsAsyncModule } from '../../creators/async-modules'
import { ElementStyleDialogContext } from '../../modals/element_style'
import { useModelState } from '../../model-hooks/use-model-state'
import { AutoSaveState } from '../../models/constants'
import PaneModel from '../../models/pane'
import { useAsyncModule } from '../../utils/async-modules-utils'
import { getIsPreserveDrawingsAndAutosaveAvailable } from '../../utils/chart'
import { getToolbarDrawingActions } from './drawingActions'
import { getToolbarDrawingSettings } from './drawingSettings'
import { drawingTools, toolbarDrawingTools } from './drawingTools'
import { DrawingSetting, DrawingTool, IToolBarProps } from './interfaces'

export const DISABLED_ITEM_TOOLTIP_LABEL = "Disabled due 'lock/hide' drawings active"

export default function Toolbar({
  isSidebar,
  isHorizontal,
  isMobileOverlay,
  activeTool,
  chartLayoutModel,
  onActiveToolChange,
  onDrawingActionClick,
  onDrawingSettingClick,
  onDrawingEnd,
  drawingSettings,
  isAlternativeTheme,
  areToolsDisabled,
}: IToolBarProps) {
  const ignoreRowId =
    isHorizontal || chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage
  const [drawingCreatorsModule, { isLoading: isDrawingCreatorsModuleLoading }] = useAsyncModule({
    ...drawingCreatorsAsyncModule,
    shouldLoadModule: true,
  })
  const { autoSaveState } = useModelState(
    React.useMemo(() => chartLayoutModel.drawingsInternalStore(), [chartLayoutModel]),
    { watchProperties: ['autoSaveState'] }
  )
  const [isDrawing, setIsDrawing] = React.useState(false)
  const openElementStyleDialog = React.useContext(ElementStyleDialogContext)

  const availableDrawingSettings = getToolbarDrawingSettings({
    isPreserveDrawingsAndAutosaveAvailable: getIsPreserveDrawingsAndAutosaveAvailable(chartLayoutModel),
    isAutoSaveInProgress: autoSaveState === AutoSaveState.Saving,
    isIdea: !!chartLayoutModel.idea?.id,
  })

  const availableDrawingActions = getToolbarDrawingActions({
    isAutoSaveActive: autoSaveState !== AutoSaveState.Off,
    isAddNoteAvailable: chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage,
    isIdea: !!chartLayoutModel.idea?.id,
  })

  React.useEffect(() => {
    if (!drawingCreatorsModule) {
      return
    }

    const onMouseDown = (model: PaneModel, area: PaneArea & { isTouch: boolean }) => {
      const isDrawingModeContinuous = drawingSettings[DrawingSetting.DrawingModeContinuous]
      const hasData = model.chart().quote().close.length > 0
      const hasHiddenDrawings = drawingSettings[DrawingSetting.HideDrawings]
      const tool = drawingTools[activeTool].id

      if (tool === DrawingTool.Mouse || isDrawing || hasHiddenDrawings || !hasData) {
        return
      }

      setIsDrawing(true)
      const { drawingToolsTouchCreatorsByType, drawingToolsCreatorsByType } = drawingCreatorsModule
      const ToolClass = area.isTouch ? drawingToolsTouchCreatorsByType[tool] : drawingToolsCreatorsByType[tool]
      const element = model.elements().create({
        instance: new ToolClass(area, {}, model, openElementStyleDialog, () => {
          if (!isDrawingModeContinuous) {
            onActiveToolChange(DrawingTool.Mouse)
          }
          setIsDrawing(false)
          onDrawingEnd?.()
        }),
      })
      element.one('destroy', ({ instance }) => {
        if (instance.getIsCreator()) {
          setIsDrawing(false)
          onDrawingEnd?.()
        }
      })

      element.trigger('select', element)
    }
    PaneModel.bind('mousedown', onMouseDown)
    return () => {
      PaneModel.unbind('mousedown', onMouseDown)
    }
  }, [
    activeTool,
    onActiveToolChange,
    openElementStyleDialog,
    onDrawingEnd,
    drawingCreatorsModule,
    drawingSettings,
    isDrawing,
  ])

  return (
    <ToolbarComponent
      isMobile={isMobileOverlay}
      direction={isHorizontal && !isSidebar && !isMobileOverlay ? 'horizontal' : 'vertical'}
      theme={isAlternativeTheme ? 'alternative' : undefined}
      className={classnames({ 'grid grid-cols-2 gap-1 space-y-0': isMobileOverlay })}
    >
      {toolbarDrawingTools.map((item, index) => {
        const isActive = activeTool === item.defaultItem.id || item.toolGroup?.some((tool) => tool.id === activeTool)
        const isToolLoading = isDrawingCreatorsModuleLoading && isActive

        return (
          <ToolbarItem
            key={item.defaultItem.id}
            defaultItem={item.defaultItem}
            isActive={!!isActive}
            isLoading={isToolLoading}
            groupItems={item.toolGroup}
            groupTitle={item.toolGroupTitle}
            onChange={onActiveToolChange as ToolbarGroupProps['onChange']}
            disabled={item.defaultItem.id !== DrawingTool.Mouse && areToolsDisabled}
            disabledTooltip={DISABLED_ITEM_TOOLTIP_LABEL}
            useNativeSelect={isMobileOverlay === true ? false : undefined}
            rowId={ignoreRowId ? undefined : Math.floor(index / 2) + 1}
          />
        )
      })}
      <ToolbarDivider className={classnames({ 'col-span-2': isMobileOverlay })} />
      {availableDrawingSettings.map((item, index) => (
        <ToolbarItem
          key={item.defaultItem.id}
          defaultItem={item.defaultItem}
          isActive={drawingSettings[item.defaultItem.id]}
          isInAction={item.isInAction}
          disabledTooltip={item.disabledTooltip}
          disabled={item.isDisabled}
          onChange={onDrawingSettingClick as ToolbarGroupProps['onChange']}
          rowId={ignoreRowId ? undefined : Math.floor(index / 2) + 1 + Math.ceil(toolbarDrawingTools.length / 2)}
        />
      ))}
      {availableDrawingActions.map((item, index) => (
        <ToolbarItem
          key={item.defaultItem.id}
          defaultItem={item.defaultItem}
          isActive={false}
          disabled={item.isDisabled}
          disabledTooltip={item.disabledTooltip}
          onChange={onDrawingActionClick as ToolbarGroupProps['onChange']}
          rowId={
            ignoreRowId
              ? undefined
              : Math.floor(index / 2) +
                2 +
                Math.ceil(toolbarDrawingTools.length / 2) +
                Math.ceil(availableDrawingActions.length / 2)
          }
        />
      ))}
    </ToolbarComponent>
  )
}
