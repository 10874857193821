import Line from '../canvas/line'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, StofuConfig } from './configs/stofu'
import Indicator from './indicator'

class Stochastics<T extends Attrs = Attrs> extends Indicator<T> {
  static config = StofuConfig

  k: number[] = []
  k1: number[] = []
  d: number[] = []
  declare kPeriod: number
  declare period: number
  declare dPeriod: number

  set(values: Partial<T>) {
    if (typeof values.period === 'string') {
      const [period = 0, kPeriod = 0, dPeriod = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period, dPeriod, kPeriod })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (!this.isComputeNecessary()) return

    const period = Math.min(this.period, this.data.close.length - 1)
    let dCount, dSum, k
    this.k1 = []
    this.k = []
    this.d = []
    let kSum = (dSum = 0)
    let kCount = (dCount = 0)
    for (let i = 0; i < this.data.close.length; i++) {
      let highestHigh = this.data.high[i]
      let lowestLow = this.data.low[i]

      for (let j = i - period + 1; j <= i; j++) {
        if (this.data.high[j] > highestHigh) {
          highestHigh = this.data.high[j]
        }
        if (this.data.low[j] < lowestLow) {
          lowestLow = this.data.low[j]
        }
      }

      k = ((this.data.close[i] - lowestLow) / (highestHigh - lowestLow)) * 100
      if (highestHigh === lowestLow) {
        k = 100
      }
      this.k1[i] = k
      if (this.kPeriod > 1) {
        kSum += k
        kCount++
        if (kCount > this.kPeriod) {
          kSum -= this.k1[i - this.kPeriod]
          kCount--
        }
        k = kSum / kCount
      }

      dSum += k
      dCount++
      if (dCount > this.dPeriod) {
        dSum -= this.k[i - this.dPeriod]
        dCount--
      }

      const d = dSum / dCount
      this.k[i] = k
      this.d[i] = d
    }

    this.lastValue = this.k.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      k: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line,
      d: '#c80000',
    }
  }

  getValueLabelsAtIndex(index: number) {
    const lineColors = this.getLineColors()
    const dataIndex = this.data.barToDataIndex[index]
    return [
      { color: lineColors.k, text: this.getValueLabel(this.k[dataIndex]) },
      { color: lineColors.d, text: this.getValueLabel(this.d[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0) return

    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(50)),
        y2: Math.round(this.fy(50)),
        strokeStyle: '#ff8787',
        dashLength: 3,
      },
      this.model
    ).render(context)
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(20)),
        y2: Math.round(this.fy(20)),
        strokeStyle: '#69c1ea',
      },
      this.model
    ).render(context)
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(80)),
        y2: Math.round(this.fy(80)),
        strokeStyle: '#d386df',
      },
      this.model
    ).render(context)

    const lineColors = this.getLineColors()
    context.set('strokeStyle', lineColors.k)
    context.translate(0.5, 0.5)
    context.beginPath()
    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period,
    }
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.k[i])))
      },
    })
    context.stroke()

    context.set('strokeStyle', lineColors.d)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.d[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
      kPeriod: {
        type: 'number',
        label: 'K Period',
        name: 'kPeriod',
        value: this.kPeriod ?? 3,
        required: true,
        min: 1,
        max: 999999,
      },
      dPeriod: {
        type: 'number',
        label: 'D Period',
        name: 'dPeriod',
        value: this.dPeriod ?? 3,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: StofuConfig.label,
      inputs: StofuConfig.inputsOrder.map((item) => options[item as keyof Attrs]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        dPeriod: `${options.dPeriod.label} must be a whole number between ${options.dPeriod.min} and ${options.dPeriod.max}`,
        kPeriod: `${options.kPeriod.label} must be a whole number between ${options.kPeriod.min} and ${options.kPeriod.max}`,
      } as {
        period: string
        dPeriod: string
        kPeriod?: string
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
      case 'dPeriod':
      case 'kPeriod':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default Stochastics
