import * as React from 'react'
import { useLocation } from 'react-router-dom'

import * as tracking from '../../app/shared/tracking'

export function useTrackPageView(props?: { trackInitial?: boolean; trackAutomatically?: boolean }) {
  const { trackInitial = false, trackAutomatically = true } = props ?? {}
  const initialRender = React.useRef(trackInitial)
  const location = useLocation()

  const handleTrackView = React.useCallback(() => {
    tracking.trackPageView({ pathname: location.pathname, query: location.search })
  }, [location.pathname, location.search])

  React.useEffect(() => {
    if (!trackAutomatically) return
    if (initialRender.current) handleTrackView()
    initialRender.current = true
  }, [trackAutomatically, handleTrackView, trackInitial])

  return handleTrackView
}
