import Spine from '@finviz/spine'

import { AutoSaveElement } from '../controllers/autosave/constants'
import { getUuid } from '../utils/helpers'
import { HLC, initHlc } from '../utils/hlc'
import { AutoSaveState } from './constants'

interface DrawingsInternalStateAttrs {
  elements: AutoSaveElement[]
  latestRemoteUpdateTimestamp: HLC
  latestLocalUpdateTimestamp: HLC
  instanceUUID: string
  autoSaveState: AutoSaveState
}

class DrawingsInternalStore extends Spine.Model {
  static initClass() {
    this.configure(
      'DrawingsInternalState',
      'elements',
      'latestRemoteUpdateTimestamp',
      'latestLocalUpdateTimestamp',
      'autoSaveState',
      'instanceUUID'
    )
  }

  elements: AutoSaveElement[]
  latestRemoteUpdateTimestamp: HLC
  latestLocalUpdateTimestamp: HLC
  instanceUUID: string
  autoSaveState: AutoSaveState

  constructor(attrs: Partial<DrawingsInternalStateAttrs> = {}) {
    super(attrs)

    this.elements = attrs.elements ?? []
    this.instanceUUID = attrs.instanceUUID ?? getUuid()
    this.latestLocalUpdateTimestamp = attrs.latestLocalUpdateTimestamp ?? initHlc(this.instanceUUID, 0)
    this.latestRemoteUpdateTimestamp = attrs.latestRemoteUpdateTimestamp ?? initHlc(this.instanceUUID, 0)
    this.autoSaveState = attrs.autoSaveState ?? AutoSaveState.Off
  }

  setAutoSaveState = (newAutoSaveState: AutoSaveState) => {
    if (this.autoSaveState !== newAutoSaveState) {
      this.updateAttributes({ autoSaveState: newAutoSaveState })
    }
  }
}

export default DrawingsInternalStore
