/*
 * We want to be able to render only bars/parts of line that are visible to users,
 * but without introducing visual artifacts like bars missing in front of users while scrolling
 * or lines that start in middle of chart for sparse intraday stock data.
 *
 * In general we want to start rendering lines one bar sooner than what is visible to user and stop rendering
 * one bar outside of visible area to create a continuous line.
 */
import type Pane from '../models/pane'
import type Quote from '../models/quote'
import { getHalfBarWidth } from './chart'

interface IBarToRender {
  index: number
  isBarVisible: boolean
}

export const drawInVisibleArea = ({
  drawBarCallback,
  fromIndexOffset = 0,
  fxOverride,
  leftOffset,
  paneModel,
  quote,
  toIndexOffset = 0,
  width,
}: {
  drawBarCallback: (i: number, x: number) => void
  fromIndexOffset?: number
  fxOverride?: (index: number) => number
  leftOffset: number
  paneModel: Pane
  quote: Quote
  toIndexOffset?: number
  width: number
}) => {
  const fx = fxOverride ?? ((index: number) => paneModel.scale.x(quote.barIndex[index]))

  // We want to start rendering one bar outside of the visible area for two reasons:
  // 1. To create a seamless transition for bars entering/leaving the view, avoiding them from
  //    abruptly dis/appearing when half the bar is outside.
  // 2. To prevent disconnected lines in case there's a bar gap.
  // However, if a `fromIndex` is provided and it's higher than the first visible bar,
  // we need to start rendering from that specific index. This ensures correct starting
  // points for elements like indicators that rely on this value.
  const firstBarToRenderIndex = getCompensatedFirstBarToRenderIndex({ quote, paneModel, leftOffset, fromIndexOffset })

  let lastBarToRender = -1
  for (let i = firstBarToRenderIndex; i < quote.close.length + toIndexOffset; i++) {
    const x = fx(i)
    if (x + leftOffset > width) {
      lastBarToRender = i
      break
    }
    drawBarCallback(i, x)
  }

  if (lastBarToRender > -1) {
    const x = fx(lastBarToRender)
    drawBarCallback(lastBarToRender, x)
  }
}

/**
 * Get first or last visible bar index.
 * IMPORTANT: If chartWidth provided return is lastVisibleBarIndex
 */
export function getVisibleBarToRenderIndex({
  chartWidth,
  leftOffset,
  paneModel,
  quote,
}: {
  chartWidth?: number
  leftOffset: number
  paneModel: Pane
  quote: Quote
}) {
  const chartModel = paneModel.chart()

  // not all the time there is bar present on barIndex calculated via inverted leftOffset
  // there might be a gap between bars, if there is, barToDataIndex on that gap spot will have value of
  // previous bar which isn't visible

  const barIndex = Math.round(paneModel.scale.x.invert(-leftOffset + (chartWidth !== undefined ? chartWidth : 0)))
  const dataBarIndex = quote.barToDataIndex[Math.min(Math.max(barIndex, 0), quote.barToDataIndex.length - 1)]
  const halfBarWidth = getHalfBarWidth(chartModel, false)

  const isBarVisible = Math.round(paneModel.scale.x(quote.barIndex[dataBarIndex]) + halfBarWidth) + leftOffset > 0

  return {
    barIndex,
    index:
      chartWidth === undefined
        ? isBarVisible
          ? dataBarIndex
          : Math.max(Math.min(dataBarIndex + 1, quote.barIndex.length - 1), 0)
        : dataBarIndex,
    isBarVisible,
  }
}

// Get first bar to render compensated for fromIndexOffset
export function getCompensatedFirstBarToRenderIndex({
  fromIndexOffset = 0,
  leftOffset,
  paneModel,
  quote,
}: {
  fromIndexOffset?: number
  leftOffset: number
  paneModel: Pane
  quote: Quote
}) {
  return Math.max(0, getVisibleBarToRenderIndex({ quote, leftOffset, paneModel }).index - 1, fromIndexOffset)
}

/**
 * Checks if there are no visible bars between the specified first and last bars to render.
 * IMPORTANT: Provided indexes should be without bar gap compensation.
 *
 * @param {IBarToRender} firstBarToRenderIndex The index of the first bar to render.
 * @param {IBarToRender} lastBarToRenderIndex The index of the last bar to render.
 * @returns {boolean} True if there are no visible bars between the first and last bars, false otherwise.
 */
export function getAreNoBarsVisible(firstBarToRenderIndex: IBarToRender, lastBarToRenderIndex: IBarToRender): boolean {
  return !firstBarToRenderIndex.isBarVisible && !lastBarToRenderIndex.isBarVisible
}
