import { ChartElementType } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import Chart from './base_chart'

class LineChart extends Chart {
  static type = ChartElementType.LineChart
  static label = 'Line'
  static iconName = 'lineChartType'

  renderChart() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    let first: number | undefined, last: number | undefined
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width + 0.5,
      yOffset: ChartSettings.top.height + 0.5,
    })
    translate.do()
    this.context.beginPath()
    this.context.set('lineWidth', 1)
    this.context.set('strokeStyle', Colors.line)
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.paneModel,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        const y = Math.round(this.fy(this.data.close[i]))
        this.context.lineTo(x, y)
        last = x
        if (first === undefined) {
          first = x
        }
      },
    })
    if (first !== undefined && last !== undefined) {
      this.context.stroke()
      this.context.set('fillStyle', Colors.background)
      this.context.lineTo(last!, this.height)
      this.context.lineTo(first!, this.height)
      this.context.fill()
    }

    translate.undo()
  }

  toString() {
    return `Line chart ${this.data.ticker}`
  }
}

export default LineChart
