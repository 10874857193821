export function roundedRect({
  ctx,
  x,
  y,
  width,
  height,
  radius,
  color,
}: {
  ctx: CanvasRenderingContext2D
  x: number
  y: number
  width: number
  height: number
  radius: number
  color: string
}) {
  ctx.translate(0.5, 0.5)
  ctx.set('strokeStyle', color)
  ctx.set('fillStyle', color)
  ctx.beginPath()
  ctx.moveTo(x, y + radius)
  ctx.lineTo(x, y + height - radius)
  ctx.arcTo(x, y + height, x + radius, y + height, radius)
  ctx.lineTo(x + width - radius, y + height)
  ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius)
  ctx.lineTo(x + width, y + radius)
  ctx.arcTo(x + width, y, x + width - radius, y, radius)
  ctx.lineTo(x + radius, y)
  ctx.arcTo(x, y, x, y + radius, radius)
  ctx.stroke()
  ctx.fill()
  ctx.translate(-0.5, -0.5)
}
