import * as dateFns from 'date-fns'

import { ChartConfigChart } from '../../server/chartConfig'
import { ChartConfigChartPane, Instrument, ObjectHash } from '../../types/shared'
import Line from '../canvas/line'
import Text from '../canvas/text'
import { chartsByType } from '../charts/charts'
import {
  CRYPTOS,
  ChartEditorEnum,
  ChartElementType,
  IndicatorType,
  OFFSET,
  PADDING,
  ScaleType,
  SpecificChartFunctionality,
  TIMEFRAME,
  TIMEFRAME_SECONDS,
  TextBaseline,
} from '../constants/common'
import { getPercentageFromValue } from '../controllers/renderUtils'
import math from '../helpers/math'
import { indicatorsByType } from '../indicators/indicators'
import Chart from '../models/chart'
import ChartLayoutModel from '../models/chart_layout'
import { IChartSettings } from '../models/chart_settings/interfaces'
import { Mouse } from '../models/mouse'
import Pane from '../models/pane'
import Quote, { QuoteGetParams } from '../models/quote'
import { getChartLayoutSettings } from '../models/settings'
import Utils, { dateFromUnixTimestamp } from '../utils'
import { captureException } from './helpers'

const DAY_IN_SECONDS = 86400
const TWO_MIN_IN_MS = 2 * 60 * 1000
const virtualDatesCache: ObjectHash<{
  fromSeconds: number
  toSeconds: number
  lastUsed: number
  virtualDates: number[]
}> = {}

// The reason to use this func instead of dateFns.isSameDay is performance as this is way faster
function areTimestampsFromSameDay({ ts1, ts2 }: { ts1: number; ts2: number }) {
  const ts1Utc = ts1
  const ts2Utc = ts2
  return Math.floor(ts1Utc / DAY_IN_SECONDS) === Math.floor(ts2Utc / DAY_IN_SECONDS)
}

function getVirtualDatesCacheId({
  idRevision,
  ticker,
  timeframe,
}: {
  idRevision: number
  ticker: string
  timeframe: string
}) {
  return `${ticker}-${timeframe}-${idRevision}`
}

function getCachedVirtualDates({
  idRevision,
  ticker,
  timeframe,
  fromSeconds,
  toSeconds,
}: {
  idRevision: number
  ticker: string
  timeframe: string
  fromSeconds: number
  toSeconds: number
}) {
  const id = getVirtualDatesCacheId({ idRevision, ticker, timeframe })
  const cache = virtualDatesCache[id]
  if (cache?.fromSeconds <= fromSeconds && cache?.toSeconds >= toSeconds) {
    cache.lastUsed = Date.now()
    if (cache.fromSeconds === fromSeconds && cache.toSeconds === toSeconds) {
      return cache.virtualDates
    }
    return cache.virtualDates.filter(
      (virtualDateSeconds) => virtualDateSeconds >= fromSeconds && virtualDateSeconds <= toSeconds
    )
  }
  return null
}

function checkVirtualCacheSize() {
  const now = Date.now()
  Object.keys(virtualDatesCache).forEach((virtualDatesCacheId) => {
    if (virtualDatesCache[virtualDatesCacheId].lastUsed < now - TWO_MIN_IN_MS) {
      delete virtualDatesCache[virtualDatesCacheId]
    }
  })
}

function setCachedVirtualDates({
  idRevision,
  ticker,
  timeframe,
  fromSeconds,
  toSeconds,
  virtualDates,
}: {
  idRevision: number
  ticker: string
  timeframe: string
  fromSeconds: number
  toSeconds: number
  virtualDates: number[]
}) {
  const id = getVirtualDatesCacheId({ idRevision, ticker, timeframe })
  checkVirtualCacheSize()
  virtualDatesCache[id] = {
    fromSeconds,
    toSeconds,
    virtualDates,
    lastUsed: Date.now(),
  }
}

const MAX_BAR_BORDER_WIDTH = 1
const MIN_BAR_MARGIN_WIDTH = 0
const MAX_BAR_MARGIN_WIDTH = 16

export function getBarWidthWithMarginByParts({
  zoomFactor = 1,
  chartLayout,
}: {
  zoomFactor?: number
  chartLayout: ChartLayoutModel
}) {
  const { ChartSettings } = chartLayout.settings
  const settingsCenter = ChartSettings.center
  const barMargin =
    zoomFactor > 1 && settingsCenter.barMargin === 0
      ? Math.min(settingsCenter.barWidth, 1 - 1 / zoomFactor)
      : settingsCenter.barMargin

  const barFillWidth = settingsCenter.barWidth * zoomFactor
  const barBorderWidth = Math.min(settingsCenter.border * zoomFactor, MAX_BAR_BORDER_WIDTH)
  const barMarginWidth = Math.max(MIN_BAR_MARGIN_WIDTH, Math.min(barMargin * zoomFactor, MAX_BAR_MARGIN_WIDTH))

  return {
    barFillWidth,
    barBorderWidth,
    barMarginWidth,
  }
}

export function getBarWidthWithMargin({
  zoomFactor = 1,
  chartLayout,
  shouldRound = true,
}: {
  zoomFactor?: number
  chartLayout: ChartLayoutModel
  shouldRound?: boolean
}) {
  const { barFillWidth, barBorderWidth, barMarginWidth } = getBarWidthWithMarginByParts({ zoomFactor, chartLayout })

  const barWidth = barFillWidth + barBorderWidth * 2 + barMarginWidth
  if (!shouldRound) {
    return barWidth
  }
  const roundDecimalPlaces = barWidth > 100 ? 10 : 10000
  return Math.round(barWidth * roundDecimalPlaces) / roundDecimalPlaces
}

export function getBarWithoutMarginWidth(chartModel: Chart) {
  const { barFillWidth, barBorderWidth } = getBarWidthWithMarginByParts({
    chartLayout: chartModel.chart_layout(),
    zoomFactor: chartModel.zoomFactor,
  })
  return barFillWidth + barBorderWidth * 2
}

export function getHalfBarWidth(chartModel: Chart, floor = true) {
  // Math.max(1, num) is kept from previous implementation, it is highly likely
  // that some function which use getHalfBarWidth expect return to be >= 1,
  // because we never render bar with width < 1 but at the same time when we have and odd
  // width like 5, wa want to floor because of pixel perfect rendering
  // but some function require raw not floored number to be able to do it's calculations
  // TODO: it would be good to possibly revisit this and check usage if we do need
  // to perform floor as well as limit result to be >= 1 but it is most likely necessary
  const halfBarWidthRaw = Math.max(1, getBarWithoutMarginWidth(chartModel) / 2)
  return floor ? ~~halfBarWidthRaw : halfBarWidthRaw
}

const DEFAULT_ZOOM_GUESS_INCREMENT = 0.001

const zoomFactorForBarsToDisplayCache: ObjectHash = {}
const zoomFactorForBarsToDisplayCacheKeys: string[] = []
const NUMBER_OF_CACHED_ZOOM_FACTORS = 10
const getZoomFactorFromCache = (key: string) => zoomFactorForBarsToDisplayCache[key] ?? null
const setZoomFactorToCache = (key: string, value: number) => {
  if (!zoomFactorForBarsToDisplayCacheKeys.includes(key)) {
    zoomFactorForBarsToDisplayCacheKeys.unshift(key)
    if (zoomFactorForBarsToDisplayCacheKeys.length > NUMBER_OF_CACHED_ZOOM_FACTORS) {
      const poppedKey = zoomFactorForBarsToDisplayCacheKeys.pop()
      if (poppedKey) {
        delete zoomFactorForBarsToDisplayCache[poppedKey]
      }
    }
  }

  zoomFactorForBarsToDisplayCache[key] = value
}

export function getZoomFactorForBarsToDisplay({
  chartVisibleWidth,
  numOfVisibleBars,
  chartLayout,
}: {
  chartVisibleWidth: number
  numOfVisibleBars: number
  chartLayout: ChartLayoutModel
}) {
  const defaultBarWidthWithMargin = getBarWidthWithMargin({ chartLayout })
  const barWidthWithMargin = chartVisibleWidth / numOfVisibleBars
  const cacheKey = `${defaultBarWidthWithMargin}-${barWidthWithMargin}`
  let zoomIncrement = 0
  let currentZoomFactor = 1
  let finalZoomFactor = getZoomFactorFromCache(cacheKey)
  let iteration = 0
  if (barWidthWithMargin <= defaultBarWidthWithMargin) {
    finalZoomFactor = barWidthWithMargin / defaultBarWidthWithMargin
  }
  while (finalZoomFactor === null) {
    iteration += 1
    const threshold = 0.01
    currentZoomFactor += zoomIncrement
    const barWidth = getBarWidthWithMargin({ zoomFactor: currentZoomFactor, chartLayout, shouldRound: false })
    const barWidthDiff = barWidthWithMargin - barWidth
    if (barWidthDiff > threshold) {
      zoomIncrement = zoomIncrement > 0 ? zoomIncrement * 2 : DEFAULT_ZOOM_GUESS_INCREMENT
    } else if (barWidthDiff < -threshold) {
      zoomIncrement = zoomIncrement < 0 ? zoomIncrement * 2 : -DEFAULT_ZOOM_GUESS_INCREMENT
    } else {
      finalZoomFactor = currentZoomFactor
    }

    if (iteration === 1000) {
      captureException(new Error('Invalid zoom factor from getZoomFactorForBarsToDisplay'), {
        extra: {
          iteration,
          zoomIncrement,
          barWidthWithMargin,
          barWidth,
          barWidthDiff,
          currentZoomFactor,
          chartLayout: chartLayout.toConfig(['colors', 'panes']),
        },
      })
      return currentZoomFactor
    }
  }

  setZoomFactorToCache(cacheKey, finalZoomFactor)

  return finalZoomFactor
}

export function getTimeframeSeconds({
  lowerIndex,
  dates,
  timeframe,
}: {
  lowerIndex: number
  dates: number[]
  timeframe: TIMEFRAME
}) {
  const upperIndex = lowerIndex + 1
  const areBoundsDefined = ![dates[upperIndex], dates[lowerIndex]].some((index) => index === undefined)
  return areBoundsDefined ? dates[upperIndex] - dates[lowerIndex] : TIMEFRAME_SECONDS[timeframe]
}

function getVirtualTradedDays({
  dateStartSeconds,
  dateEndSeconds,
  datesSeconds,
  marketStartMinutes,
}: {
  dateStartSeconds: number
  dateEndSeconds: number
  datesSeconds: number[]
  marketStartMinutes: number
}) {
  const dateStart = dateFromUnixTimestamp(dateStartSeconds)
  const dateEnd = dateFromUnixTimestamp(dateEndSeconds)
  const isStartBeforeDates = dateStartSeconds < datesSeconds[0]
  const isEndAfterDates = datesSeconds.length > 0 && datesSeconds[datesSeconds.length - 1] < dateEndSeconds
  const firstDate = dateFromUnixTimestamp(datesSeconds[0])
  const lastDate = dateFromUnixTimestamp(datesSeconds[datesSeconds.length - 1])
  return dateFns
    .eachDayOfInterval({
      start:
        isStartBeforeDates && (dateFns.isWeekend(dateStart) || dateStart.getMinutes() < marketStartMinutes)
          ? dateFns.addBusinessDays(dateStart, -1)
          : dateStart,
      end: isEndAfterDates ? dateFns.addBusinessDays(dateEnd, 1) : dateEnd,
    })
    .filter((date) => {
      const ts2 = date.getTime() / 1000 - date.getTimezoneOffset() * 60
      return (
        !dateFns.isWithinInterval(date, { start: dateFns.startOfDay(firstDate), end: dateFns.endOfDay(lastDate) }) ||
        datesSeconds.some((dateTime) =>
          areTimestampsFromSameDay({
            ts1: dateTime - Utils.getQuoteDateTimestampOffsetInSeconds(new Date(dateTime * 1000)),
            ts2,
          })
        )
      )
    })
}

export function getVirtualTimeframeTradedDates({
  dateStart,
  dateEnd,
  quote,
}: {
  dateStart: number
  dateEnd: number
  quote: Quote
}) {
  const { date: dates, ticker, timeframe, instrument, isIntraday, marketStartMinutes } = quote
  const barsInDay = quote.getBarsInDay() ?? 0

  // Currently we're passing first and last quote dates to dateStart / dateEnd so this is always true
  // but this function can be used on other places in the future where it will work with dates out of quote dates range
  const isWithinRangeOfQuoteDate = dateStart === dates[0] && dateEnd === dates[dates.length - 1]

  const commonVirtualCacheProps = {
    idRevision: dates.length,
    ticker,
    timeframe,
    fromSeconds: dateStart,
    toSeconds: dateEnd,
  }
  const cachedDates = getCachedVirtualDates(commonVirtualCacheProps)
  if (cachedDates) {
    return cachedDates
  }
  if (!isIntraday || instrument !== Instrument.Stock) {
    if (isWithinRangeOfQuoteDate) {
      return dates
    }
    const timeframeSeconds = TIMEFRAME_SECONDS[timeframe]
    const firstDate = dates[0]
    const lastDate = dates[dates.length - 1]
    const numOfDatesBefore = Math.ceil((firstDate - dateStart) / timeframeSeconds)
    const numOfDatesAfter = Math.ceil((dateEnd - lastDate) / timeframeSeconds)
    return [
      ...Array.from({ length: numOfDatesBefore })
        .map((_, index) => firstDate - (index + 1) * timeframeSeconds)
        .reverse(),
      ...dates,
      ...Array.from({ length: numOfDatesAfter }).map((_, index) => lastDate + (index + 1) * timeframeSeconds),
    ]
  }

  const virtualTradedDays = isWithinRangeOfQuoteDate
    ? quote.getDaysInQuote()
    : getVirtualTradedDays({
        dateStartSeconds: dateStart,
        dateEndSeconds: dateEnd,
        datesSeconds: dates,
        marketStartMinutes,
      })

  const marketStartSeconds = marketStartMinutes * 60
  const barTimeframeSeconds = Array.from({ length: barsInDay }).map((_, i) => i * TIMEFRAME_SECONDS[timeframe])
  const quoteDatesTimestampOffset = Utils.getQuoteDateTimestampOffsetInSeconds(new Date(dateStart * 1000))
  const virtualDates = virtualTradedDays.flatMap((date) => {
    const daySeconds =
      date.getTime() / 1000 + Utils.getQuoteDateTimestampOffsetInSeconds(date) - date.getTimezoneOffset() * 60
    const dayStartSeconds = daySeconds + marketStartSeconds
    return barTimeframeSeconds.map(
      // Hourly market starts at 9:30 but all other time ticks should be rounded down to :00 of the current hour
      (barSeconds, index) =>
        ([TIMEFRAME.h, TIMEFRAME.h2, TIMEFRAME.h4].includes(timeframe) && index > 0 ? -1800 : 0) +
        dayStartSeconds +
        barSeconds
    )
  })

  if (!isWithinRangeOfQuoteDate) {
    virtualDates.filter((date, index, virtualTimeframeTradedDates) => {
      const isLowerBounded =
        date >= dateStart ||
        areTimestampsFromSameDay({
          ts1: date - Utils.getQuoteDateTimestampOffsetInSeconds(new Date(date * 1000)),
          ts2: dateStart - quoteDatesTimestampOffset,
        })
      const isOneBeforeLowerBoundStarts = dateStart > date && dateStart < virtualTimeframeTradedDates[index + 1]
      return isLowerBounded || isOneBeforeLowerBoundStarts
    })
  }

  setCachedVirtualDates({
    ...commonVirtualCacheProps,
    virtualDates,
  })

  return virtualDates
}

interface TimestampPositionGettersProps {
  quote: Quote
}

export function getTimestampFromPositionX({ positionX, quote }: TimestampPositionGettersProps & { positionX: number }) {
  const { date, timeframe } = quote
  const dates = getVirtualTimeframeTradedDates({
    dateStart: date[0],
    dateEnd: date[date.length - 1],
    quote,
  })
  const dateStart = dates[0]
  const dateEnd = dates[dates.length - 1]
  const lastDateIndex = dates.length - 1
  if (positionX >= 0 && positionX <= lastDateIndex) {
    const lowerIndex = Math.floor(positionX)
    const timeframeSeconds = getTimeframeSeconds({ lowerIndex, dates, timeframe })
    return dates[lowerIndex] + (positionX - lowerIndex) * timeframeSeconds
  }
  const isLookingBack = positionX < 0
  const averageBarDate = (dateEnd - dateStart) / dates.length
  const numOfSecondsOutOfBounds = isLookingBack
    ? Math.abs(positionX) * averageBarDate
    : (positionX - lastDateIndex) * averageBarDate
  return Math.round(isLookingBack ? dateStart - numOfSecondsOutOfBounds : dateEnd + numOfSecondsOutOfBounds)
}

export function getPositionXFromTimestamp({ timestamp, quote }: TimestampPositionGettersProps & { timestamp: number }) {
  const { date, timeframe } = quote
  const dates = getVirtualTimeframeTradedDates({
    dateStart: date[0],
    dateEnd: date[date.length - 1],
    quote,
  })
  const dateStart = dates[0]
  const dateEnd = dates[dates.length - 1]
  const lastDateIndex = dates.length - 1
  if (timestamp >= dates[0] && timestamp <= dates[dates.length - 1]) {
    const upperIndex = timestamp === dateEnd ? lastDateIndex : dates.findIndex((date) => date > timestamp)
    const timeframeSeconds = getTimeframeSeconds({ lowerIndex: upperIndex - 1, dates, timeframe })
    const positionDiffUpperTimestamp = (dates[upperIndex] - timestamp) / timeframeSeconds
    return upperIndex - positionDiffUpperTimestamp
  }
  const isLookingBack = timestamp < dateStart
  const averageBarDate = (dateEnd - dateStart) / dates.length
  return isLookingBack
    ? -((dateStart - timestamp) / averageBarDate)
    : lastDateIndex + (timestamp - dateEnd) / averageBarDate
}

// counterpart in Finviz-Website 'Finviz-Website/js/main/util.ts'
export function getInstrument(tickerRaw: string) {
  if (tickerRaw.indexOf('@') === 0) {
    const ticker = tickerRaw.substring(1) as keyof typeof CRYPTOS

    if (CRYPTOS[ticker]) {
      return Instrument.Crypto
    } else if (ticker.length === 6) {
      return Instrument.Forex
    } else {
      return Instrument.Futures
    }
  }
  return Instrument.Stock
}

export function getPerfIndicatorTickerAndTimeframes({
  paneWithPerfIndicator,
  timeframe,
}: {
  paneWithPerfIndicator: ChartConfigChartPane
  timeframe: TIMEFRAME
}) {
  const { elements } = paneWithPerfIndicator
  const perfIndicatorElement = elements.find(({ type }) => type === 'indicators/perf')
  const tickers = perfIndicatorElement?.period?.split(',') ?? []
  return tickers.map((ticker) => ({
    ticker,
    instrument: getInstrument(ticker),
    timeframe,
  }))
}

interface PrefetchPerfIndicatorAllQuotesProps extends Pick<QuoteGetParams, 'timeframe' | 'options'> {
  paneWithPerfIndicator: ChartConfigChartPane
  chartUuid?: string
}

export async function prefetchPerfIndicatorAllQuotes({
  paneWithPerfIndicator,
  timeframe,
  options,
  chartUuid,
}: PrefetchPerfIndicatorAllQuotesProps) {
  const tickersAndTimeframes = getPerfIndicatorTickerAndTimeframes({ paneWithPerfIndicator, timeframe })

  if (tickersAndTimeframes.length > 0) {
    const promises = tickersAndTimeframes.map(({ ticker, instrument, timeframe }) =>
      Quote.get({
        ticker,
        instrument,
        timeframe,
        options,
        chartUuid,
      })
    )
    return Promise.all(promises)
  }
  return Promise.resolve([])
}

interface RenderCrossProps {
  context: CanvasRenderingContext2D
  mouseModel: Mouse
  paneModel: Pane
  quote: Quote
  contentWidth: number
  contentHeight: number
  onRenderCrossText?: (context: CanvasRenderingContext2D, crossIndex: number) => void
  getRoundDecimalPlaces?: (price?: number) => number | undefined
  isIndicator?: boolean
  isPerfIndicator?: boolean
}

export function renderCross({
  context,
  mouseModel,
  paneModel,
  quote,
  contentWidth,
  contentHeight,
  onRenderCrossText,
  getRoundDecimalPlaces,
  isIndicator = false,
  isPerfIndicator = false,
}: RenderCrossProps) {
  const crossIndex = mouseModel.getCrossIndexForPane(paneModel)
  const chartModel = paneModel.chart()
  const { ChartSettings, IndicatorSettings } = getChartLayoutSettings(chartModel.chart_layout())
  const settings = isIndicator ? IndicatorSettings : ChartSettings
  const { Colors } = ChartSettings.general

  if (!mouseModel.getShouldRenderCrossInPane(paneModel)) {
    return false
  }

  const price = mouseModel.getPriceForPane(paneModel)
  const middle = paneModel.scale.x(crossIndex) + chartModel.leftOffset
  const isXAxisInView = middle > 0 && middle <= contentWidth
  if (isXAxisInView) {
    const x = middle + settings.left.width
    new Line(
      {
        x1: x,
        x2: x,
        y1: settings.top.height,
        y2: settings.top.height + contentHeight,
        strokeStyle: Colors.cross,
      },
      paneModel
    ).render(context)

    onRenderCrossText?.(context, crossIndex)
  }

  const domainRange = paneModel.scale.y
    .domain()
    .slice()
    .sort((a, b) => a - b)

  if (price === null || !(domainRange[0] <= price && price <= domainRange[1])) {
    return isXAxisInView
  }

  const priceFy = paneModel.scale.y(price)
  const y = Math.round(priceFy + settings.top.height)
  new Line(
    {
      x1: settings.left.width,
      x2: settings.left.width + contentWidth,
      y1: y,
      y2: y,
      strokeStyle: Colors.cross,
    },
    paneModel
  ).render(context)
  const YAxisLeftMargin = quote.instrument === Instrument.Crypto && quote.lastClose <= 0.001 ? 3 : 8

  const getCrossText = () => {
    if (chartModel.scale === ScaleType.Percentage && !isIndicator) {
      return `${math.round({
        value: getPercentageFromValue({ number: price, base: chartModel.firstBarClose }),
        lastClose: quote.lastClose,
        instrument: quote.instrument,
        overridePlaces: 2,
      })}%`
    }
    return `${math.round({
      value: price,
      lastClose: quote.lastClose,
      instrument: quote.instrument,
      overridePlaces: getRoundDecimalPlaces?.(price) ?? (paneModel.getIsChartPane() ? undefined : 2),
    })}${isPerfIndicator ? '%' : ''}`
  }

  new Text(
    {
      text: getCrossText(),
      x:
        settings.left.width +
        settings.right.axis.margin.left! +
        contentWidth +
        (isIndicator ? OFFSET.S : YAxisLeftMargin - PADDING.XXS),
      padding: isIndicator
        ? Text.getMergedPropsWithDefaults('padding', IndicatorSettings.right.axis.font.padding)
        : {
            top: PADDING.XXS,
            right: PADDING.XXS,
            bottom: PADDING.XXS,
            left: PADDING.XXS,
          },
      ...{
        y,
        textBaseline: TextBaseline.middle,
        fillStyle: Colors.crossText,
        background: Colors.crossTextBackground,
        font: Text.getMergedPropsWithDefaults('font', { ...settings.right.axis.font, style: 'bold' }),
        lineHeight: settings.right.axis.font.lineHeight,
      },
    },
    paneModel
  ).render(context)

  return true
}

/**
 * Set canvas width and height to 0 which fixes Safari memory problems
 *
 * @see https://stackoverflow.com/questions/52532614/total-canvas-memory-use-exceeds-the-maximum-limit-safari-12
 */
export function unmountCanvas(canvas?: HTMLCanvasElement | null) {
  if (!canvas) return

  canvas.width = 0
  canvas.height = 0
  canvas.style.width = '0px'
  canvas.style.height = '0px'
}

export function getChangeColor({ change, ChartSettings }: { change: number; ChartSettings: IChartSettings }) {
  const { Colors } = ChartSettings.general
  if (change === 0) {
    return Colors.zeroChange
  }
  return change > 0 ? Colors.positiveChange : Colors.negativeChange
}

export function round({ data, num, overridePlaces }: { data: Quote; num: number; overridePlaces?: number }) {
  return math.round({
    value: num,
    lastClose: data.lastClose,
    instrument: data.instrument,
    overridePlaces: overridePlaces,
  })
}

export interface ITickerChange {
  points: number
  string: string
  percentString: string
}
interface ITickerChangeResult {
  tickerChange: ITickerChange | null
  tickerAfterChange: ITickerChange | null
}

export function getTickerChangeFromCloseValues({
  anchorClose,
  prevClose,
  data,
}: {
  anchorClose: number
  prevClose: number
  data: Quote
}): ITickerChange {
  const changePoints = anchorClose - prevClose
  const changePercent = (anchorClose / prevClose - 1) * 100
  const sign = changePoints < 0 ? '-' : changePoints > 0 ? '+' : ''
  const changePercentString = `${round({
    data,
    num: Math.abs(changePercent),
    overridePlaces: 2,
  })}%`
  const changePointsString = `${sign}${round({
    data,
    num: Math.abs(changePoints),
  })} (${changePercentString})`

  return {
    points: changePoints,
    string: changePointsString,
    percentString: `${sign}${changePercentString}`,
  }
}
export function getTickerChange({ data }: { data: Quote }) {
  const result: ITickerChangeResult = { tickerChange: null, tickerAfterChange: null }
  const isChange = Number.isFinite(data.prevClose) // if not new stock always present
  if (isChange) {
    result.tickerChange = getTickerChangeFromCloseValues({
      data,
      anchorClose: data.lastClose,
      prevClose: data.prevClose,
    })
  }

  const isAfterChange = Number.isFinite(data.afterClose) && data.afterClose !== data.lastClose
  if (isAfterChange) {
    const tickerAfterChange = getTickerChangeFromCloseValues({
      data,
      anchorClose: data.afterClose,
      prevClose: data.lastClose,
    })
    result.tickerAfterChange = {
      ...tickerAfterChange,
      string: `AH: ${tickerAfterChange.string}`,
    }
  }

  return result
}

export function isForexFuturesCryptoPage(specificChartFunctionality: SpecificChartFunctionality) {
  return [
    SpecificChartFunctionality.forexPage,
    SpecificChartFunctionality.cryptoPage,
    SpecificChartFunctionality.futuresPage,
  ].includes(specificChartFunctionality)
}

export function isQuoteForexFuturesCryptoPage(specificChartFunctionality: SpecificChartFunctionality) {
  return (
    isForexFuturesCryptoPage(specificChartFunctionality) ||
    specificChartFunctionality === SpecificChartFunctionality.quotePage
  )
}

export function getShouldUseDarkerWickColors({
  specificChartFunctionality,
  totalBarWidth,
}: {
  specificChartFunctionality: SpecificChartFunctionality
  timeFrame?: TIMEFRAME
  totalBarWidth: number
}) {
  const isForexFuturesCrypto = isForexFuturesCryptoPage(specificChartFunctionality)
  const isOffscreenChartWithNarrowBars =
    specificChartFunctionality === SpecificChartFunctionality.offScreen && totalBarWidth < 5
  const isQuoteOrQuoteFinancials = [
    SpecificChartFunctionality.quotePage,
    SpecificChartFunctionality.quoteFinancials,
  ].includes(specificChartFunctionality)
  const isQuoteChartWithNarrowBars = isQuoteOrQuoteFinancials && totalBarWidth < 5

  return isForexFuturesCrypto || isOffscreenChartWithNarrowBars || isQuoteChartWithNarrowBars
}

export const DRAWING_COOKIE_NAME = 'charts-draw'
export function getIsDrawingEnabled() {
  return Utils.getCookie(DRAWING_COOKIE_NAME) === 'on'
}

export function setIsDrawingEnabled(value: boolean) {
  const expires = new Date()
  expires.setMonth(expires.getMonth() + 1)
  Utils.setCookie(DRAWING_COOKIE_NAME, value ? 'on' : 'off', expires)
}

export function getIsSidebarEnabled(specificChartFunctionality?: SpecificChartFunctionality) {
  return specificChartFunctionality === SpecificChartFunctionality.chartPage
}

export const handleTypeChange = ({
  type,
  chartLayoutModel,
}: {
  type: ChartElementType
  chartLayoutModel: ChartLayoutModel
}) => {
  const elements = chartLayoutModel.getAllElements()
  elements.forEach((element) => {
    if (element.isChart() && element.instance.attrs.type !== type) {
      const pane = element.pane()
      const instance = chartsByType[type].fromObject(
        {
          ...element.instance.attrs,
          type,
        },
        pane
      )
      element.replace(instance)
    }
  })
}

export const getIsPreserveDrawingsAndAutosaveAvailable = (args: {
  specificChartFunctionality: SpecificChartFunctionality
  editable: boolean
  editors: ChartEditorEnum[]
}) => {
  const { specificChartFunctionality, editable, editors } = args

  return (
    editable &&
    editors?.includes(ChartEditorEnum.tools) &&
    specificChartFunctionality &&
    [
      SpecificChartFunctionality.chartPage,
      SpecificChartFunctionality.quotePage,
      SpecificChartFunctionality.forexPage,
      SpecificChartFunctionality.futuresPage,
      SpecificChartFunctionality.cryptoPage,
    ].includes(specificChartFunctionality)
  )
}

export function getQuoteFinancialAttachmentsFromChartConfig({ panes }: Pick<ChartConfigChart, 'panes'>) {
  return panes
    .flatMap((pane) =>
      pane.elements?.flatMap(
        (element) => indicatorsByType[element.type as IndicatorType]?.config.quoteFinancialAttachments ?? []
      )
    )
    .filter((quoteFinancialAttachment, index, arr) => arr.indexOf(quoteFinancialAttachment) === index)
}
