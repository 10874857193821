import * as d3 from 'd3'
import * as dateFns from 'date-fns'

import { Instrument, ObjectHash } from '../../types/shared'
import type PerfChart from '../charts/perf_chart'
import {
  CHART_MARGIN,
  DateRangeType,
  PREMARKET_AFTERMARKET_HOURS,
  ScaleType,
  SpecificChartFunctionality,
  TIMEFRAME,
} from '../constants/common'
import Chart from '../models/chart'
import ChartEventElement from '../models/chart-event-element'
import ChartLayout from '../models/chart_layout'
import { IChartSettings, IIndicatorSettings, ISettings } from '../models/chart_settings/interfaces'
import { CHART_EVENT_BADGE_SIZE } from '../models/constants'
import Element from '../models/element'
import Pane, { ScaleAxis } from '../models/pane'
import Quote from '../models/quote'
import Utils from '../utils'
import utils, { convertLocalToNyTime, dateFromUnixTimestamp, isRedesignedPage } from '../utils'
import { getBarWidthWithMargin, getZoomFactorForBarsToDisplay } from '../utils/chart'
import {
  DATE_RANGE_PREFIX_DELMITER,
  getParsedDateRangeMetaData,
  parseCustomDateRangeUrlParam,
} from '../utils/chart-date-range-utils'

interface VisibleBarNumberProps {
  start: Date | null
  end: Date
  quote: Quote
  dateRange: string
}

interface ChartZoomAndLeftOffsetProps extends Omit<VisibleBarNumberProps, 'dateRange'> {
  chartVisibleWidth: number
  numOfBarsToRender?: number
  chartModel: Chart
}

interface ChartStartEndDatesProps {
  dateRange: string
  quote: Quote
}

export const getVisibleBarsNumber = ({ start, end, quote, dateRange }: VisibleBarNumberProps) => {
  const startInSec = start ? start.getTime() / 1000 : 0
  const endInSec = end.getTime() / 1000
  const isQuoteDateInRange = (quoteDate: number) => quoteDate >= startInSec && quoteDate <= endInSec
  const lastQuoteDateIndex = quote.barIndex[quote.barIndex.length - 1]
  let lastVisibleBarIndex = lastQuoteDateIndex
  let numOfVisibleBars = 0
  if (
    [DateRangeType.d1, DateRangeType.d5].includes(dateRange as DateRangeType) &&
    quote.instrument === Instrument.Stock
  ) {
    numOfVisibleBars = quote.drawMinutesPerDay
  } else {
    let firstVisibleBarIndex = -1
    lastVisibleBarIndex = -1
    quote.date.forEach((quoteDate, index) => {
      if (isQuoteDateInRange(quoteDate)) {
        if (firstVisibleBarIndex === -1) {
          firstVisibleBarIndex = quote.barIndex[index]
        }
        lastVisibleBarIndex = quote.barIndex[index]
      }
    })
    numOfVisibleBars = lastVisibleBarIndex - firstVisibleBarIndex + 1

    if (firstVisibleBarIndex === -1 && lastVisibleBarIndex === -1) {
      return { numOfVisibleBars: 0, numOfBarsOffset: 0 }
    }
  }

  return { numOfVisibleBars, numOfBarsOffset: lastQuoteDateIndex - lastVisibleBarIndex }
}

export const getChartZoomAndLeftOffset = ({
  chartVisibleWidth,
  start,
  end,
  quote,
  chartModel,
  numOfBarsToRender,
}: ChartZoomAndLeftOffsetProps) => {
  const { numOfVisibleBars, numOfBarsOffset } = getVisibleBarsNumber({
    start,
    end,
    quote,
    dateRange: chartModel.dateRange,
  })
  if (numOfVisibleBars === 0) {
    return { zoomFactor: chartModel.zoomFactor, leftOffset: chartModel.leftOffset }
  }

  const chartLayout = chartModel.chart_layout()
  const zoomFactor = getZoomFactorForBarsToDisplay({
    chartLayout,
    chartVisibleWidth,
    numOfVisibleBars: numOfBarsToRender ?? numOfVisibleBars,
  })
  const barWidth = getBarWidthWithMargin({ chartLayout, zoomFactor })
  const leftOffset =
    getLeftOffset({
      quote,
      chartModel,
      config: {},
      barWidth,
      numberOfBarsToDraw: numOfBarsToRender,
    }) +
    numOfBarsOffset * barWidth
  return { zoomFactor, leftOffset }
}

export const getChartStartEndDates = ({ dateRange, quote }: ChartStartEndDatesProps) => {
  const daysInQuote = quote.getDaysInQuote()
  const lastDate = dateFromUnixTimestamp(quote.date[quote.date.length - 1])

  if (dateRange.includes(DATE_RANGE_PREFIX_DELMITER)) {
    const range = parseCustomDateRangeUrlParam({ dateRange, anchorDate: lastDate })
    const end = convertLocalToNyTime(
      range.end && range.end.getTime() <= lastDate.getTime() ? range.end : lastDate,
      false
    )
    return {
      start: range.start && convertLocalToNyTime(range.start, false),
      end,
    }
  }

  let start
  // No condition is needed here, but this would be dateRangePrefix === DateRangePrefixEnum.Enum
  switch (dateRange) {
    case DateRangeType.d1:
      lastDate.setHours(23, 59, 59, 999)
      start = daysInQuote.length === 0 ? lastDate : daysInQuote[daysInQuote.length - 1]
      break
    case DateRangeType.d5:
      lastDate.setHours(23, 59, 59, 999)
      const startDateIndex = daysInQuote.length === 0 ? -1 : Math.max(daysInQuote.length - 5, 0)
      start = startDateIndex === -1 ? lastDate : daysInQuote[startDateIndex]
      break
    case DateRangeType.m1:
      start = dateFns.subMonths(lastDate, 1)
      break
    case DateRangeType.m3:
      start = dateFns.subMonths(lastDate, 3)
      break
    case DateRangeType.m6:
      start = dateFns.subMonths(lastDate, 6)
      break
    case DateRangeType.ytd:
      start = dateFns.startOfYear(lastDate)
      break
    case DateRangeType.y1:
      start = dateFns.subYears(lastDate, 1)
      break
    case DateRangeType.y2:
      start = dateFns.subYears(lastDate, 2)
      break
    case DateRangeType.y5:
      start = dateFns.subYears(lastDate, 5)
      break
    case DateRangeType.max:
    default:
      start = null
      break
  }

  return { start: start && convertLocalToNyTime(start, false), end: convertLocalToNyTime(lastDate, false) }
}

interface GetLeftOffsetProps {
  quote: Quote
  chartModel: Chart
  config?: { stretch?: boolean }
  barWidth?: number
  barIndex?: number
  numberOfBarsToDraw?: number
  shouldAlignDaysToLeftApplied?: boolean
}

export const getMinMaxLeftOffset = ({
  newLeftOffset,
  chartModel,
  barWidth = getBarWidthWithMargin({ zoomFactor: chartModel.zoomFactor, chartLayout: chartModel.chart_layout() }),
  barIndex,
}: {
  newLeftOffset: number
  chartModel: Chart
  barWidth?: number
  barIndex: number
}) => {
  const { ChartSettings } = chartModel.getChartLayoutSettings()
  const width = chartModel.width - ChartSettings.left.width - ChartSettings.right.width
  const numOfBarsInView = Math.floor(width / barWidth)
  const fx = (index: number) => index * barWidth
  return ~~Utils.min(barWidth / 2, Utils.max(newLeftOffset, width - fx(barIndex + numOfBarsInView)))
}

export function getFullChartWidth({
  hasAftermarket,
  hasPremarket,
  timeframeMinutes,
  barWidthWithMargin,
}: {
  hasPremarket: boolean
  hasAftermarket: boolean
  timeframeMinutes: number
  barWidthWithMargin: number
}) {
  const premarketHoursLength = hasPremarket ? PREMARKET_AFTERMARKET_HOURS : 0
  const aftermarketHoursLength = hasAftermarket ? PREMARKET_AFTERMARKET_HOURS : 0
  const marketMinutesLength = (6.5 + premarketHoursLength + aftermarketHoursLength) * 60
  // CHART_MARGIN.L as left offset + CHART_MARGIN.XXL as right offset
  return CHART_MARGIN.L + CHART_MARGIN.XXL + (marketMinutesLength / timeframeMinutes) * barWidthWithMargin
}

export function getIntradayChartConfig({
  timeframeMinutes,
  barWidthWithMargin,
  hasPremarket,
  hasAftermarket,
  maxWidth,
  canCropChart,
}: {
  hasPremarket: boolean
  hasAftermarket: boolean
  timeframeMinutes: number
  barWidthWithMargin: number
  maxWidth: number
  canCropChart: boolean
}) {
  const fullChartWidth = getFullChartWidth({ hasPremarket, hasAftermarket, barWidthWithMargin, timeframeMinutes })
  const offsetChartAfter = 16 // Int from 0-23 , if last quote date is >= this constant `offsetInPx` is applied (chart is moved to the left)
  const cropChartMinutesLength = PREMARKET_AFTERMARKET_HOURS * 60
  const offsetInPx = (cropChartMinutesLength / timeframeMinutes) * barWidthWithMargin
  const chartWidthWithOffset = fullChartWidth - offsetInPx
  const shouldCropChart =
    canCropChart && hasAftermarket && hasPremarket && maxWidth >= chartWidthWithOffset && maxWidth < fullChartWidth

  return {
    fullChartWidth,
    offsetInPx: shouldCropChart ? offsetInPx : 0,
    chartWidthWithOffset: shouldCropChart ? fullChartWidth - offsetInPx : fullChartWidth,
    offsetChartAfter,
  }
}

export const getLeftOffset = ({
  quote,
  chartModel,
  config,
  barWidth = getBarWidthWithMargin({ zoomFactor: chartModel.zoomFactor, chartLayout: chartModel.chart_layout() }),
  barIndex = getBarIndex(quote),
  numberOfBarsToDraw,
  shouldAlignDaysToLeftApplied = true,
}: GetLeftOffsetProps) => {
  const { ChartSettings } = chartModel.getChartLayoutSettings()
  const width = chartModel.width - ChartSettings.left.width - ChartSettings.right.width
  const specificChartFunctionality = chartModel.chart_layout().specificChartFunctionality
  if (config?.stretch) {
    return 0
  }
  if (getIsSmallIndexChart(specificChartFunctionality)) {
    return barWidth
  }
  const fx = (index: number) => index * barWidth
  let leftOffset = width - fx(barIndex) - Math.round(barWidth / 2)
  leftOffset = Math.round(Utils.min(barWidth / 2, Utils.max(leftOffset, width - fx(barIndex + 45))))
  if (
    shouldAlignDaysToLeftApplied &&
    shouldAlignDaysToLeft({
      quote,
      numberOfBarsToDraw,
      specificChartFunctionality,
      dateRange: chartModel.dateRange,
    })
  ) {
    let drawDaysInOneChart = 1
    if (numberOfBarsToDraw) {
      drawDaysInOneChart = Math.ceil(numberOfBarsToDraw / quote.getBarsInDay()!)
    } else if (quote.interval === 10 || chartModel.dateRange === DateRangeType.d5) {
      drawDaysInOneChart = 5
    } else if (quote.interval === 15) {
      drawDaysInOneChart = 6
    } else if (quote.interval === 30) {
      drawDaysInOneChart = 12
    } else if (quote.interval === 60) {
      drawDaysInOneChart = 22
    } else if (quote.interval === 120) {
      drawDaysInOneChart = 39
    } else if (quote.interval === 240) {
      drawDaysInOneChart = 78
    } else if (quote.interval > 240) {
      drawDaysInOneChart = (quote.interval / 30) * 10
    }
    const { offsetInPx, offsetChartAfter } = getIntradayChartConfig({
      hasPremarket: quote.premarket,
      hasAftermarket: quote.aftermarket,
      barWidthWithMargin: barWidth,
      timeframeMinutes: quote.interval,
      maxWidth: chartModel.width,
      canCropChart: !chartModel.dateRange,
    })
    const drawDaysWidth = drawDaysInOneChart * quote.getBarsInDay()! * barWidth - offsetInPx

    // +1 is a buffer as it often happens drawDaysWidth is wider than width by tiny amount (< 1/10000 of px)
    if (drawDaysWidth <= width + 1) {
      const lastQuoteDate = quote.date.last() ? utils.dateFromUnixTimestamp(quote.date.last()!) : null
      // Align start of day to left if we have enough space to show all bars in day
      const barsToOffset = (Object.keys(quote.getDayToOffset()).length - drawDaysInOneChart) * quote.getBarsInDay()!
      const offsetChartInPx = (lastQuoteDate?.getHours() ?? 0) >= offsetChartAfter ? offsetInPx : 0
      // Math.max(1, ~~num) is to mimic imlementation from getHalfBarWidth(),
      // we never render bar with width < 1 but at the same time when we have and odd
      // width like 5, wa want to floor because of pixel perfect rendering
      leftOffset = Math.round(-fx(barsToOffset) + Math.max(1, ~~(barWidth / 2))) - offsetChartInPx
    }
  }
  // getMinMaxLeftOffset move left offset to keep at least one bar in view what isn't desired for offscreen charts
  if (specificChartFunctionality === SpecificChartFunctionality.offScreen) {
    return leftOffset
  }
  return getMinMaxLeftOffset({
    newLeftOffset: leftOffset,
    chartModel,
    barWidth,
    barIndex,
  })
}

export const getBarIndex = (quote: Quote) => quote?.getBarIndex(quote.open?.length ? quote.open.length - 1 : 0) || 0

export const getIsSmallIndexChart = (specificChartFunctionality: SpecificChartFunctionality) =>
  specificChartFunctionality === SpecificChartFunctionality.smallIndex

interface ShouldAlignDaysToLeftProps {
  quote: Quote
  numberOfBarsToDraw?: number
  specificChartFunctionality: SpecificChartFunctionality
  dateRange: DateRangeType | null
}

/**
 * Evaluate if days should be aligned to the left
 * @param {object} object.quote - Quote model
 * @param {string} object.specificChartFunctionality - SpecificChartFunctionality enum
 * @returns {boolean} boolean
 * */
export const shouldAlignDaysToLeft = ({
  quote,
  dateRange,
  numberOfBarsToDraw,
  specificChartFunctionality,
}: ShouldAlignDaysToLeftProps) => {
  const isStockIntraday = quote.isIntraday && quote.instrument === Instrument.Stock
  const isIntradayDateRange = !!dateRange && [DateRangeType.d1, DateRangeType.d5].includes(dateRange)
  const isOffScreen = specificChartFunctionality === SpecificChartFunctionality.offScreen
  const isOffScreenWithNoDateRange = isOffScreen && !dateRange
  const isOffScreenIntradayDateRange = isOffScreen && isIntradayDateRange
  const isOffScreenWithFixedBars = isOffScreen && numberOfBarsToDraw

  const isQuoteOrQuoteFinancials = [
    SpecificChartFunctionality.quotePage,
    SpecificChartFunctionality.quoteFinancials,
  ].includes(specificChartFunctionality)
  const isQuotePageWithNoDateRange = isQuoteOrQuoteFinancials && !dateRange
  const isQuotePageIntradayDateRange = isQuoteOrQuoteFinancials && isIntradayDateRange
  const isQuotePageWithFixedBars = isQuoteOrQuoteFinancials && numberOfBarsToDraw

  const isChartPage = specificChartFunctionality === SpecificChartFunctionality.chartPage
  const isChartsInradayDateRange = isChartPage && isIntradayDateRange
  const isChartPageWithFixedBars = isChartPage && numberOfBarsToDraw
  return (
    isStockIntraday &&
    (isOffScreenWithNoDateRange ||
      isOffScreenIntradayDateRange ||
      isOffScreenWithFixedBars ||
      isQuotePageWithNoDateRange ||
      isQuotePageIntradayDateRange ||
      isQuotePageWithFixedBars ||
      isChartsInradayDateRange ||
      isChartPageWithFixedBars)
  )
}

interface ClipProps {
  canvasCtx: RenderingContext2DType
  width: number
  height: number
  Settings: ISettings['ChartSettings'] | ISettings['IndicatorSettings']
}
// pane canvasCtx clip
export const clip = ({ canvasCtx, width, height, Settings }: ClipProps) => {
  const contentWidth = width - Settings.left.width - Settings.right.width
  const contentHeight = height - Settings.top.height - Settings.bottom.height
  canvasCtx.beginPath()
  canvasCtx.rect(Settings.left.width, Settings.top.height, contentWidth, contentHeight)
  canvasCtx.clip()
}

export const getSettings = (paneModel: Pane): IIndicatorSettings | IChartSettings => {
  const { ChartSettings, IndicatorSettings } = paneModel.chart().getChartLayoutSettings()
  for (const element of paneModel.elements().all()) {
    if (element.isIndicator()) {
      return IndicatorSettings
    }
  }
  return ChartSettings
}

interface RecountScaleProps {
  chartModel: Chart
  paneModel: Pane
}

type ScaledFn = Partial<ScaleAxis> & (d3.ScaleLinear<number, number> | d3.ScaleSymLog<number, number>)

const getShouldOffsetChartDomain = (elementModel: Element | ChartEventElement) => {
  const quote = elementModel.pane().chart().quote()

  return quote.chartEvents.length > 0 && ![TIMEFRAME.w, TIMEFRAME.m].includes(quote.timeframe)
}

export const recountScale = ({ chartModel, paneModel }: RecountScaleProps) => {
  const { ChartSettings, IndicatorSettings, MarketSentimentSettings } = chartModel.getChartLayoutSettings()
  const chartLayout = chartModel.chart_layout()
  const barWidthWithMargin = getBarWidthWithMargin({ chartLayout, zoomFactor: chartModel.zoomFactor })
  for (const elementModel of [...paneModel.getAllElements(), ...paneModel.getAllChartEvents(false)]) {
    const el = elementModel.instance
    const isChart = elementModel.isChart()
    if (isChart || elementModel.isIndicator()) {
      let fx: ScaledFn, Settings: ISettings['MarketSentimentSettings' | 'ChartSettings' | 'IndicatorSettings'], width
      const scaleType = isChart ? chartModel.scale : ScaleType.Linear
      if (isChart) {
        Settings = chartModel.instrument === Instrument.MarketSentiment ? MarketSentimentSettings : ChartSettings
      } else {
        Settings = IndicatorSettings as ISettings['IndicatorSettings']
      }
      const contentHeight = paneModel.height - Settings.top.height - Settings.bottom.height

      if (chartModel.stretch) {
        width = chartModel.width - Settings.left.width - Settings.right.width
        const chartElement = chartModel.getChartElement()
        // PerfChart can be only imported as type because of circular dependency, that's why it's not possible to do instanceof check
        const isPerfChart = !!chartElement?.instance && 'getCompleteChartNumOfBars' in chartElement?.instance
        const perfChartInstance = isPerfChart ? (chartElement.instance as PerfChart) : null
        const numOfBars = perfChartInstance?.getCompleteChartNumOfBars() ?? chartModel.quote().close.length
        fx = d3
          .scaleLinear()
          .range([0, width])
          .domain([0, Math.max(0, numOfBars - 1)]) as ScaledFn
      } else {
        fx = ((index: number) => index * barWidthWithMargin) as ScaledFn
        fx.invert = (x: number) => x / barWidthWithMargin
        fx.width = function (w) {
          return this.invert(w) - this.invert(0)
        }
      }
      paneModel.updateAttribute('scale', { x: fx }) // some elements need fx to count getMinMax

      const { min, max } = paneModel.scaleRange || el.getMinMax!()
      let fy

      const handleDomainOffset = (currentScale: ScaledFn, scaleCreator: any) => {
        if (isChart && getShouldOffsetChartDomain(elementModel)) {
          const protectedHeightPx = contentHeight - CHART_EVENT_BADGE_SIZE
          const minPx = currentScale(min)
          const domain = currentScale.domain()
          if (minPx > protectedHeightPx) {
            const tempScale = scaleCreator().range([0, protectedHeightPx]).domain([max, min])
            const extendedMin = tempScale.invert(contentHeight)
            currentScale.domain([domain[0], extendedMin])
          }
        }
      }

      switch (scaleType) {
        case ScaleType.Logarithmic:
          fy = d3.scaleSymlog().range([0, contentHeight]).domain([max, min]) as ScaledFn
          handleDomainOffset(fy, d3.scaleSymlog)
          break
        default:
          fy = d3.scaleLinear().range([0, contentHeight]).domain([max, min]) as ScaledFn
          if (!paneModel.scaleRange) {
            fy = fy.nice(10)
            handleDomainOffset(fy, d3.scaleLinear)
          }
          break
      }
      fy.height = function (h: number) {
        return this.invert(h) - this.invert(0)
      }
      paneModel.updateAttribute('scale', {
        x: fx,
        y: fy,
      })

      if (isChart) {
        el.setupAxis!(fx as ScaleAxis)
      }
      break
    }
  }
}

// general, only when blurry ends won't be visible or didn't matter
export const getOffsetFromLineWidth = (lineWidth: number) => (lineWidth % 2 === 0 ? 0 : 0.5)

interface IXYOffsetFromLine {
  lineWidth: number
  x1: number
  y1: number
  x2: number
  y2: number
}

// return x,y offsets for pixel perfect line rendering
export const getXYOffsetFromLine = ({ lineWidth, x1, y1, x2, y2 }: IXYOffsetFromLine) => {
  // don't add offset is lineWidth is even
  if (lineWidth % 2 === 0) {
    return { x: 0, y: 0 }
  }
  if (y1 === y2) {
    return { x: 0, y: 0.5 } // horizontal line
  } else if (x1 === x2) {
    return { x: 0.5, y: 0 } // vertical line
  } else {
    return { x: 0.5, y: 0.5 } // diagonal line
  }
}

export const getRoundedObject = <T extends ObjectHash>(obj: T): T => {
  const rounded = Object.entries<number>(obj)
  for (let i = 0; i < rounded.length; i++) {
    rounded[i][1] = Math.round(rounded[i][1])
  }
  return Object.fromEntries(rounded) as T
}

interface ITranslate {
  context: CanvasRenderingContext2D
  xOffset: number
  yOffset: number
}

interface ITranslateObj {
  do: () => void
  undo: () => void
}

export const getTranslate = ({ context, xOffset, yOffset }: ITranslate): ITranslateObj => ({
  do: () => context.translate(xOffset, yOffset),
  undo: () => context.translate(xOffset * -1, yOffset * -1),
})

interface IPercentageScaleValue {
  number: number
  base: number
}

export const getPercentageFromValue = ({ number, base }: IPercentageScaleValue) => ((number - base) / base) * 100

export const getValueFromPercentage = ({ number, base }: IPercentageScaleValue) => (number / 100) * base + base

interface UpdateZoomAndLeftOffsetByDateRangeProps {
  chartModel: Chart
  quote: Quote
}

export function updateZoomAndLeftOffsetByDateRange({ chartModel, quote }: UpdateZoomAndLeftOffsetByDateRangeProps) {
  const { ChartSettings } = chartModel.getChartLayoutSettings()
  const chartVisibleWidth = chartModel.width - (ChartSettings.left.width + ChartSettings.right.width)

  const { start, end, numOfBarsToRender } = getParsedDateRangeMetaData({
    dateRange: chartModel.dateRange,
    quote,
  })

  const { zoomFactor, leftOffset } = getChartZoomAndLeftOffset({
    chartVisibleWidth,
    start,
    end,
    quote,
    numOfBarsToRender,
    chartModel: chartModel,
  })

  if (chartModel.zoomFactor !== zoomFactor || chartModel.leftOffset !== leftOffset) {
    chartModel.updateAttributes({ zoomFactor, leftOffset })
  }
}

export function renderPane({
  chartLayoutModel,
  chartModel,
  paneModel,
  canvasCtx,
  shouldRenderCross = false,
}: {
  chartLayoutModel: ChartLayout
  chartModel: Chart
  paneModel: Pane
  canvasCtx: CanvasRenderingContext2D
  shouldRenderCross?: boolean
}) {
  if (paneModel.chart().instrument === Instrument.MarketSentiment) {
    canvasCtx.clearRect(0, 0, chartModel.width, paneModel.height)
  } else {
    canvasCtx.set('fillStyle', chartModel.getChartLayoutSettings().ChartSettings.general.Colors.canvasFill)
    canvasCtx.fillRect(0, 0, chartModel.width, paneModel.height)
  }
  const elements = paneModel.getAllElements()
  for (const { instance: elementInstance } of elements) {
    if (typeof elementInstance.renderGrid === 'function') {
      canvasCtx.save()
      elementInstance.renderGrid(canvasCtx)
      canvasCtx.restore()
    }
  }

  const chartEvents = paneModel.getAllChartEvents()
  const Settings = getSettings(paneModel)
  for (const { instance: elementInstance } of [...elements, ...chartEvents]) {
    const isDrawing = elementInstance.getIsDrawing()
    const isChartEvent = elementInstance.getIsChartEvent()

    if (isDrawing && (chartLayoutModel.isHideDrawingsActive || !elementInstance.getIsVisible())) {
      continue
    } else if ((isDrawing || isChartEvent) && !elementInstance.getIsInChartView(chartModel)) {
      continue
    }

    canvasCtx.save()
    if (elementInstance.renderContent != null) {
      canvasCtx.save()
      clip({
        canvasCtx,
        width: chartModel.width,
        height: paneModel.height,
        Settings,
      })
      canvasCtx.translate(paneModel.chart().leftOffset + Settings.left.width, Settings.top.height)
      elementInstance.renderContent(canvasCtx)
      canvasCtx.restore()
      if (typeof elementInstance.renderLabels === 'function') {
        elementInstance.renderLabels(canvasCtx)
      }
    } else {
      elementInstance.render(canvasCtx)
    }
    if (
      shouldRenderCross &&
      (chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage ||
        isRedesignedPage(chartLayoutModel)) &&
      elementInstance.renderCross != null
    ) {
      elementInstance.renderCross(canvasCtx)
    }
    canvasCtx.restore()
  }
}
