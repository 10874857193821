import { Options, SARConfig } from '../overlays/configs/sar'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  sar: number[]
}

type CalculationSupportValues = {
  isLong: boolean[]
}

export class SARCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = SARConfig
  declare options: Options
  protected _calculationSupportValues: CalculationSupportValues = { isLong: [] }

  get calculationSupportValues() {
    return this._calculationSupportValues
  }

  calculate() {
    const { step, maxStep } = this.options
    const { high, low, close } = this.quote

    this._calculatedValues = this.getDefaultCalculatedValues()

    const deltaHigh = high[1] - high[0]
    const deltaLow = low[0] - low[1]
    const plusDirectionalMovement = deltaHigh > 0 && deltaHigh > deltaLow ? deltaHigh : 0
    const minusDirectionalMovement = deltaLow > 0 && deltaLow > deltaHigh ? deltaLow : 0
    // Trend direction long = up, short = down
    let isLong = plusDirectionalMovement >= minusDirectionalMovement

    let sar = isLong ? low[0] : high[0]
    let extremePrice = isLong ? high[1] : low[1]
    let accelerationFactor = step

    for (let i = 1; i < close.length; i++) {
      const prevHigh = high[i - 1]
      const prevLow = low[i - 1]
      const newHigh = high[i]
      const newLow = low[i]

      if (isLong) {
        // Switch to short if the low penetrates the SAR value.
        if (newLow <= sar) {
          // Switch and Overide the SAR with the extremePrice
          isLong = false
          sar = extremePrice

          // Make sure the overide SAR is within yesterday's and today's range.
          if (sar < prevHigh) {
            sar = prevHigh
          }
          if (sar < newHigh) {
            sar = newHigh
          }

          // Output the overide SAR
          this._calculatedValues.sar[i] = sar

          // Adjust accelerationFactor and extremePrice
          accelerationFactor = step
          extremePrice = newLow

          // Calculate the new SAR
          sar = sar + accelerationFactor * (extremePrice - sar)

          // Make sure the new SAR is within yesterday's and today's range
          if (sar < prevHigh) {
            sar = prevHigh
          }
          if (sar < newHigh) {
            sar = newHigh
          }
        } else {
          // No switch

          // Output the SAR (was calculated in the previous iteration)
          this._calculatedValues.sar[i] = sar

          // Adjust accelerationFactor and extremePrice
          if (newHigh > extremePrice) {
            extremePrice = newHigh
            accelerationFactor += step
            if (accelerationFactor > maxStep) {
              accelerationFactor = maxStep
            }
          }

          // Calculate the new SAR
          sar = sar + accelerationFactor * (extremePrice - sar)

          // Make sure the new SAR is within yesterday's and today's range
          if (sar > prevLow) {
            sar = prevLow
          }
          if (sar > newLow) {
            sar = newLow
          }
        }
      } else {
        // Switch to long if the high penetrates the SAR value
        if (newHigh >= sar) {
          // Switch and Overide the SAR with the extremePrice
          isLong = true
          sar = extremePrice

          // Make sure the overide SAR is within yesterday's and today's range
          if (sar > prevLow) {
            sar = prevLow
          }
          if (sar > newLow) {
            sar = newLow
          }

          // Output the overide SAR
          this._calculatedValues.sar[i] = sar

          // Adjust accelerationFactor and extremePrice
          accelerationFactor = step
          extremePrice = newHigh

          // Calculate the new SAR
          sar = sar + accelerationFactor * (extremePrice - sar)

          // Make sure the overide SAR is within yesterday's and today's range
          if (sar > prevLow) {
            sar = prevLow
          }
          if (sar > newLow) {
            sar = newLow
          }
        } else {
          // No switch

          // Output the SAR (was calculated in the previous iteration)
          this._calculatedValues.sar[i] = sar

          // Adjust accelerationFactor and extremePrice
          if (newLow < extremePrice) {
            extremePrice = newLow
            accelerationFactor += step
            if (accelerationFactor > maxStep) {
              accelerationFactor = maxStep
            }
          }

          // Calculate the new SAR
          sar = sar + accelerationFactor * (extremePrice - sar)

          // Make sure the overide SAR is within yesterday's and today's range
          if (sar < prevHigh) {
            sar = prevHigh
          }
          if (sar < newHigh) {
            sar = newHigh
          }
        }
      }

      this._calculationSupportValues.isLong[i] = isLong
    }
  }
}
