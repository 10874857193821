import { Checkbox, Input, InputColorPicker, convertColorToHEX } from '@finviz/website'
import React from 'react'

interface InputProps {
  active: boolean
  trend: number
  color: string
}

interface trendInputProps {
  value: InputProps
  onChange: (values: InputProps) => void
}

export function Trend({ value, onChange }: trendInputProps) {
  return (
    <div className="flex items-end space-x-2">
      <Checkbox
        checked={value.active}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onChange({ ...value, active: ev.currentTarget.checked })}
        data-testid="chart-modal-trend-active"
      />
      <Input
        type="number"
        step="0.1"
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          onChange({ ...value, trend: ev.currentTarget.valueAsNumber })
        }
        value={value.trend}
        data-testid="chart-modal-trend-value"
      />
      <InputColorPicker
        color={convertColorToHEX(value.color)}
        onChange={(color: string) => onChange({ ...value, color: color })}
        inputDataTestId="chart-modal-trend-color"
      />
    </div>
  )
}
