import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { ChartsPageQueryParams, LAYOUTS, LayoutType, TIMEFRAME } from '../../constants/common'
import { useModelState } from '../../model-hooks/use-model-state'
import { ChartSyncablePart } from '../../models/chart/contstants'
import ChartLayout from '../../models/chart_layout'
import { getIsMobilePortrait } from '../../utils'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'

export function useChartsPathLocation({
  chartLayoutModel: unwatchedChartLayoutModel,
  chartIndex = -1,
}: {
  chartLayoutModel: ChartLayout | null
  chartIndex?: number
}) {
  const location = useLocation()
  const chartLayoutModel = useModelState(unwatchedChartLayoutModel, {
    watchProperties: ['syncChartParts'],
  })
  const hasSyncedTimeframe = React.useMemo(
    () => !!chartLayoutModel?.getIsChartPartInSync(ChartSyncablePart.Timeframe),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartLayoutModel?.syncChartParts]
  )
  const hasSyncedTicker = React.useMemo(
    () => !!chartLayoutModel?.getIsChartPartInSync(ChartSyncablePart.Ticker),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartLayoutModel?.syncChartParts]
  )

  const getChartsToPath = React.useCallback(
    ({
      ticker,
      timeframe,
      dateRange,
      layout,
    }: {
      timeframe?: TIMEFRAME
      dateRange?: string | null
      ticker?: string
      layout?: LayoutType
    }) => {
      if (!chartLayoutModel) {
        return {
          pathname: location.pathname,
          search: '',
        }
      }

      const decodedSearch = decodeQueryString<ChartsPageQueryParams>(location.search)
      const { count } = LAYOUTS[(layout ?? chartLayoutModel.layout) as LayoutType]
      const urlTickers = decodedSearch.t?.split(',') ?? []
      const urlTimeframes = decodedSearch.p?.split(',') ?? []
      const urlDateRanges = decodedSearch.r?.split(',')

      const chartsArrayPlaceholder = Array.from({ length: count })
      const tickers = chartsArrayPlaceholder
        .map((_, index) => {
          const currentTicker = urlTickers[index] ?? urlTickers[urlTickers.length - 1]
          return (chartIndex === index || hasSyncedTicker) && ticker ? ticker : currentTicker
        })
        .join(',')
      const timeframes = chartsArrayPlaceholder
        .map((_, index) => {
          const currentTimeframe = urlTimeframes[index] ?? urlTimeframes[urlTimeframes.length - 1]
          return (chartIndex === index || hasSyncedTimeframe) && timeframe ? timeframe : currentTimeframe
        })
        .join(',')
      const ranges = chartsArrayPlaceholder
        .map((_, index) => {
          const currentDaterange = urlDateRanges
            ? (urlDateRanges[index] ?? urlDateRanges[urlDateRanges.length - 1])
            : undefined
          return (chartIndex === index || hasSyncedTimeframe) && dateRange !== undefined ? dateRange : currentDaterange
        })
        .join(',')

      return {
        pathname: location.pathname,
        search: `?${encodeQueryString({
          t: tickers,
          p: timeframes,
          r: ranges.split(',').some(Boolean) ? ranges : undefined,
          l: layout ?? chartLayoutModel.layout,
          sidebar: getIsMobilePortrait() ? undefined : decodedSearch.sidebar,
        })}`,
      }
    },
    [chartLayoutModel, chartIndex, location, hasSyncedTicker, hasSyncedTimeframe]
  )

  return { getChartsToPath }
}
