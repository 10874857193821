import queryString from 'query-string'

import { Instrument, ObjectHash } from '../../../types/shared'
import { LayoutType } from '../../constants/common'
import { getTickerNoteText } from '../../controllers/autosave/utils'
import ChartLayout from '../../models/chart_layout'
import Quote from '../../models/quote'
import fetchApi, { getFormDataFromObject } from '../../utils/fetch_api'
import { captureException } from '../../utils/helpers'

export interface IIdea {
  ticker: string
  instrument: string
  period: string
  company?: string
  date: string
  time: string
  note: string
  id: string
}

export interface IIdeaCategory {
  ticker: string
  ideas: IIdea[]
}

export function getIdeaUrl({ ticker, instrument, period, id }: Pick<IIdea, 'ticker' | 'instrument' | 'period' | 'id'>) {
  const searchObject = { t: ticker, p: period, i: id }
  const isStock = instrument === Instrument.Stock
  const isQuotePage = /\/quote\b/.test(window.location.pathname)
  const isStockOnQute = isQuotePage && isStock
  const path = !isQuotePage || isStock ? window.location.pathname : '/charts.ashx'

  return `${path}?${queryString.stringify({ ...searchObject, ...(isStockOnQute ? { ta: '2' } : { l: '1h1v' }) })}`
}

export async function saveIdea(layoutModel: ChartLayout) {
  const activeChart = layoutModel.activeChart
  const activeChartQuote = activeChart.quote().toJSON()
  const { ticker, instrument, cid } = activeChart
  const period = activeChartQuote.timeframe
  const { ChartSettings } = layoutModel.settings
  const layout: ObjectHash = layoutModel.toObject()
  layout.layout = LayoutType['1h1v']
  layout.barWidth = ChartSettings.center.barWidth
  layout.barMargin = ChartSettings.center.barMargin
  layout.charts = layout.charts
    .filter((c: ObjectHash) => c.chartId === cid)
    .map((c: ObjectHash) => ({ ...c, dateRange: null, refreshData: false }))

  delete layout.charts[0].chartId
  if (!activeChart.isScrolled) delete layout.charts[0].leftOffset

  // TODO: replace with better solution after indicators overhaul
  const getChartTickers = (layoutModelObjectPanes: { elements: { type: string; tickers: string }[] }[]) => {
    let tickers = []
    let tickersString = ''
    for (const pane of layoutModelObjectPanes) {
      for (const element of pane.elements) {
        if (element.type === 'indicators/perf') {
          tickersString = element.tickers
        }
      }
    }
    tickers = tickersString.split(',').filter((item) => item)
    if (!tickers.includes(ticker)) {
      tickers.push(ticker.replace('@', ''))
    }
    return tickers
  }
  const tickers = getChartTickers(layout.charts[0].panes)
  const quotes = Quote.select(
    (quote: Quote) =>
      tickers.includes(quote.ticker) && quote.timeframe === activeChart.timeframe && layoutModel.isIdeaId(quote.ideaID)
  ).filter((element, index, array) => array.findIndex((t) => t.ticker === element.ticker) === index)
  const quotesJsonString = JSON.stringify(quotes.map((q) => q.toConfig(['ideaID', 'chartUuid'])))

  const chart = JSON.stringify(layout)
  const note =
    layoutModel.idea?.note ||
    getTickerNoteText({ ticker, elements: layoutModel.drawingsInternalStore().elements }) ||
    ''
  window.gtag?.('event', 'save', { event_category: 'ideas' })

  if (tickers.length !== quotes.length) {
    captureException(new Error('Idea saved with inconsistent quotes data'), {
      extra: {
        tickers,
        quoteTickersTimeframes: quotes.map((quote) => ({ ticker: quote.ticker, timeframe: quote.timeframe })),
        chartLayout: layoutModel.toConfig(['colors', 'panes']),
      },
    })
  }

  const data = await fetchApi<{ id: string }>({
    location: '/api/idea_save.ashx',
    method: 'POST',
    body: getFormDataFromObject({ ticker, period, chart, quotes: quotesJsonString, note }),
  })

  const ideaUrl = getIdeaUrl({
    ticker,
    period,
    instrument,
    id: data.id,
  })

  if (process.env.IS_E2E_TESTING) {
    window.location.href = ideaUrl
  } else {
    // need to be in setTimeout because Safari block any call to window.open made in async,
    // this is hack to force run window.open in main thread
    setTimeout(() => {
      window.open(ideaUrl, 'FinvizSavedIdea')
    })
  }
}

export function categorizeIdeas(ideas: IIdea[], tickers: string[]) {
  if (ideas.length <= 0) return []

  const categorizedIdeas = ideas.reduce((processed, idea) => {
    const tickerIdeas = processed.find((i) => i.ticker === idea.ticker)

    if (tickerIdeas) {
      tickerIdeas.ideas.push(idea)
    } else if (tickers.includes(idea.ticker)) {
      processed.push({
        ticker: idea.ticker,
        ideas: [idea],
      })
    }

    return processed
  }, [] as IIdeaCategory[])
  categorizedIdeas.unshift({
    ticker: 'All Ideas',
    ideas: ideas,
  })

  return categorizedIdeas
}
