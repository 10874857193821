import { DrawingSetting, IToolbarDrawingSetting, IToolbarDrawingsSettingItem } from './interfaces'

const drawingSettings: Record<DrawingSetting, IToolbarDrawingSetting> = {
  [DrawingSetting.PreserveDrawings]: {
    id: DrawingSetting.PreserveDrawings,
    icon: 'drawingSettingPreserveDrawings',
    iconActive: 'drawingSettingPreserveDrawingsActive',
    title: 'Preserve drawings: Off',
    titleActive: 'Preserve drawings: On',
  },
  [DrawingSetting.DrawingModeContinuous]: {
    id: DrawingSetting.DrawingModeContinuous,
    icon: 'drawingSettingDrawingMode',
    iconActive: 'drawingSettingDrawingModeActive',
    title: 'Drawing mode: Single',
    titleActive: 'Drawing mode: Continuous',
  },
  [DrawingSetting.HideDrawings]: {
    id: DrawingSetting.HideDrawings,
    icon: 'drawingSettingHide',
    iconActive: 'drawingSettingShow',
    title: 'Hide drawings',
    titleActive: 'Show drawings',
  },
  [DrawingSetting.LockDrawings]: {
    id: DrawingSetting.LockDrawings,
    icon: 'drawingSettingLock',
    iconActive: 'drawingSettingUnlock',
    title: 'Lock drawings',
    titleActive: 'Unlock drawings',
  },
}

const preserveDrawingsAutosaveOverride: IToolbarDrawingSetting = {
  id: DrawingSetting.PreserveDrawings, //  DrawingSetting.PreserveDrawings so it works with current settings sync to DB
  icon: 'drawingSettingAutosaveOff',
  iconActive: 'drawingSettingAutosaveOn',
  iconAction: 'drawingSettingAutosaveSaving',
  title: 'Drawings autosave: Off',
  titleActive: 'Drawings Autosave: On',
  titleAction: 'Drawings Autosave: Saving',
}

export const getToolbarDrawingSettings = ({
  isPreserveDrawingsAndAutosaveAvailable,
  isIdea,
  isAutoSaveInProgress,
}: {
  isPreserveDrawingsAndAutosaveAvailable?: boolean
  isIdea?: boolean
  isAutoSaveInProgress?: boolean
}): Array<IToolbarDrawingsSettingItem> => {
  const toolbarSettings = [
    {
      defaultItem: drawingSettings[DrawingSetting.DrawingModeContinuous],
    },
    ...(isPreserveDrawingsAndAutosaveAvailable
      ? [
          {
            defaultItem: preserveDrawingsAutosaveOverride,
            isDisabled: isIdea,
            isInAction: isAutoSaveInProgress,
            disabledTooltip: 'Autosave is not available in ideas',
          },
        ]
      : []),
    {
      defaultItem: drawingSettings[DrawingSetting.HideDrawings],
    },
    {
      defaultItem: drawingSettings[DrawingSetting.LockDrawings],
    },
  ]

  return toolbarSettings
}
