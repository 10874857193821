import { NoneConfig } from './configs/none'
import Overlay from './overlay'

// This indicator is used as a placeholder when we need to render TA like chart but don't need any overlay or indicator in the chart
class None extends Overlay {
  static config = NoneConfig

  static getNumOfBarsBuffer() {
    return 0
  }

  isRendered() {
    return false
  }

  getIsValid() {
    return true
  }
}

export default None
