import { Input } from '@finviz/website'
import React from 'react'

interface NumberInputProps {
  label: string
  name: string
  value: number
  required: boolean
  min?: number
  max?: number
  allowDecimal?: boolean
  step?: number
  onChange: (value: number) => void
}

export function NumberInput(props: NumberInputProps) {
  const { label, name, value, required, min, max, step, allowDecimal = false } = props

  return (
    <Input
      type="number"
      label={label}
      name={name}
      required={required}
      pattern={allowDecimal ? '([0-9]*[.])?[0-9]+' : '[0-9]*'}
      value={value}
      min={min ?? 1} /* TODO do we need to check if set? What is lowest allowed value??? */
      max={max}
      step={step}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange(allowDecimal ? parseFloat(e.target.value) : parseInt(e.target.value, 10))
      }
      data-testid="chart-modal-number-input"
    />
  )
}
