import { Paragraph, withSuspense } from '@finviz/website'
import classnames from 'classnames'
import * as React from 'react'

import math from '../../helpers/math'
import { useModelState } from '../../model-hooks/use-model-state'
import { QuoteFetchType } from '../../models/quote/constants'
import { getTickerChange } from '../../utils/chart'
import { useChartLayoutGlobalModelAsync } from '../../utils/useChartLayoutGlobalModelAsync'

interface Props {
  chartIndex: number
}

interface ChangeLabelProps {
  children: React.ReactNode
  changePoints: number
  isTiny: boolean
}

const CHANGE_UPATE_EVENTS = ['change', 'update']
const QUOTE_MODEL_CHANGE_EVENTS = [...CHANGE_UPATE_EVENTS, ...Object.values(QuoteFetchType)]

function ChangeLabel({ children, changePoints, isTiny }: ChangeLabelProps) {
  return (
    <Paragraph
      size={isTiny ? 'tiny' : 'small'}
      className={classnames('text-right leading-none font-medium', {
        'text-green-400': changePoints > 0,
        'text-red-400': changePoints < 0,
      })}
    >
      {children}
    </Paragraph>
  )
}

export function DailyChangeDisplayComponent({ chartIndex }: Props) {
  const { chartLayoutModel } = useChartLayoutGlobalModelAsync()
  const chartModel = useModelState(chartLayoutModel?.getAllCharts()[chartIndex] ?? null, {
    watchProperties: ['quote'],
    listenOnEvents: CHANGE_UPATE_EVENTS,
  })
  const quoteModel = useModelState(chartModel?.quote() ?? null, {
    watchProperties: ['lastClose', 'prevClose', 'afterClose'],
    listenOnEvents: QUOTE_MODEL_CHANGE_EVENTS,
  })

  const tickerChange = quoteModel && getTickerChange({ data: quoteModel })
  const isTiny =
    !!tickerChange?.tickerChange &&
    math.getInstrumentDecimalPlaces({
      instrument: quoteModel!.instrument,
      value: quoteModel!.lastClose,
    }) >= 6

  return (
    <div
      className={classnames('my-auto flex h-8 max-w-40 flex-none flex-col items-stretch justify-around', {
        'min-w-20': !tickerChange?.tickerChange, // This fix E2E tests issue when on narrow chart timeframe-bar is rendered expanded at first because tickerChange isn't yet ready and div width is 0 at first
      })}
    >
      {tickerChange?.tickerChange && (
        <>
          {tickerChange.tickerAfterChange && (
            <ChangeLabel changePoints={tickerChange.tickerAfterChange.points} isTiny={isTiny}>
              {tickerChange.tickerAfterChange.string}
            </ChangeLabel>
          )}
          <ChangeLabel changePoints={tickerChange.tickerChange.points} isTiny={isTiny}>
            {tickerChange.tickerChange.string}
          </ChangeLabel>
        </>
      )}
    </div>
  )
}

export const DailyChangeDisplay = React.memo(
  withSuspense(DailyChangeDisplayComponent),
  (prevProps, nextProps) => prevProps.chartIndex === nextProps.chartIndex
)
