import { Button, ButtonGroup, ButtonSizeType, Icon } from '@finviz/website'
import classnames from 'classnames'
import debounce from 'lodash.debounce'
import React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import Chart from '../../models/chart'
import Utils from '../../utils'
import { getLeftOffset } from '../renderUtils'
import { LoadLatestDataButton } from './load-latest-data-button'

export const ZOOM_CONTROLS_ACTIVE_CLASS = 'is-active'
export const ZOOM_CONTROLS_IS_IN_AREA_CLASS = 'is-in-area'

const buttonCommonProps: { size: ButtonSizeType; className: string; contentClass: string } = {
  size: 'none',
  className: 'w-7 h-6',
  contentClass: 'flex justify-center',
}

const isMobile = Utils.isMobile()

interface ZoomControlsProps {
  zoomControlsRef: React.RefObject<any>
  isVisible: boolean
  chartModel: Chart
  onZoomIn: () => void
  onZoomOut: () => void
  onZoomReset: () => void
}

const getDefaultLeftOffset = (chartModel: Chart) =>
  getLeftOffset({
    quote: chartModel.quote(),
    chartModel,
    config: {},
  })

export function ZoomControls({
  zoomControlsRef,
  chartModel,
  isVisible,
  onZoomIn,
  onZoomOut,
  onZoomReset,
}: ZoomControlsProps) {
  const chartLayoutModel = useModelState(chartModel.chart_layout(), { watchProperties: ['idea'] })
  const [isGoToRecentVisible, setIsGoToRecentVisible] = React.useState(
    () => chartModel.leftOffset !== getDefaultLeftOffset(chartModel)
  )
  const { defaultZoomFactor } = chartLayoutModel
  const [isZoomResetVisible, setIsZoomResetVisible] = React.useState(chartModel.zoomFactor !== defaultZoomFactor)
  const handleGoToMostRecent = React.useCallback(() => {
    chartModel.updateAttributes({ leftOffset: getDefaultLeftOffset(chartModel), dateRange: null })
  }, [chartModel])

  React.useEffect(() => {
    const handleChartModelChangeDebounced = debounce(
      () => {
        setIsZoomResetVisible(chartModel.zoomFactor !== defaultZoomFactor)
        setIsGoToRecentVisible(chartModel.leftOffset !== getDefaultLeftOffset(chartModel))
      },
      100,
      { maxWait: 333 }
    )
    chartModel.bind('change', handleChartModelChangeDebounced)
    return () => {
      handleChartModelChangeDebounced.cancel()
      chartModel.unbind('change', handleChartModelChangeDebounced)
    }
  }, [defaultZoomFactor, chartModel])

  return (
    <div
      ref={zoomControlsRef}
      className={classnames(
        'zoom-controls pointer-events-none absolute bottom-[15px] left-[34px] right-[42px] z-10 flex h-[75px] items-end justify-center',
        { hidden: !isVisible }
      )}
    >
      {!isMobile && (
        <div className="ease zoom-buttons pointer-events-auto visible relative flex translate-y-3 opacity-75 transition-all duration-150 hover:opacity-100">
          {isZoomResetVisible && (
            <div className="jump-to-recent pointer-events-auto visible absolute left-[-36px] opacity-75 hover:opacity-100">
              <ButtonGroup rounding="full" className="shadow-modal dark:shadow-modal-dark">
                <Button
                  {...buttonCommonProps}
                  data-testid="chart-zoom-controls-reset-zoom"
                  title="Reset zoom"
                  onClick={onZoomReset}
                  className="h-6 w-6"
                >
                  <Icon name="restore" />
                </Button>
              </ButtonGroup>
            </div>
          )}
          <ButtonGroup rounding="full" className="shadow-modal dark:shadow-modal-dark">
            <Button
              {...buttonCommonProps}
              data-testid="chart-zoom-controls-zoom-out"
              title="Zoom out"
              onClick={onZoomOut}
            >
              <Icon name="remove" />
            </Button>

            <Button {...buttonCommonProps} data-testid="chart-zoom-controls-zoom-in" title="Zoom in" onClick={onZoomIn}>
              <Icon name="add" />
            </Button>
          </ButtonGroup>
        </div>
      )}

      <div className="jump-to-recent pointer-events-auto visible absolute right-8 space-x-2 opacity-75 hover:opacity-100">
        {!!chartLayoutModel.idea?.id && <LoadLatestDataButton chartModel={chartModel} />}

        {isGoToRecentVisible && (
          <ButtonGroup rounding="full" className="shadow-modal dark:shadow-modal-dark">
            <Button
              appearance="square"
              data-testid="chart-zoom-controls-go-to-recent"
              title="Jump to the most recent bar"
              onClick={handleGoToMostRecent}
            >
              <Icon name="tabKey" />
            </Button>
          </ButtonGroup>
        )}
      </div>
    </div>
  )
}
