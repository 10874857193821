import { Delayed, Spinner } from '@finviz/website'
import React from 'react'

import { useAsyncModule } from '../../../utils/async-modules-utils'
import { ModuleLoadFailed } from './module-load-failed'

export function withModuleAsyncLoad<
  T extends { default: React.ComponentType<{ setIsComponentReady: React.Dispatch<React.SetStateAction<boolean>> }> },
>(asyncModule: { importFn: () => Promise<T>; cacheKey: string }, withInitialLoading = false) {
  return function InnerFunction() {
    const [isComponentReady, setIsComponentReady] = React.useState(false)
    const [sidebarModule, { isError }] = useAsyncModule({
      ...asyncModule,
      isSuspenseMode: true,
    })

    const SidebarModule = sidebarModule?.default

    if (isError) {
      return <ModuleLoadFailed />
    }

    return (
      <>
        {!isComponentReady && withInitialLoading && (
          <Delayed>
            <div className="flex h-full w-full items-center justify-center">
              <Spinner />
            </div>
          </Delayed>
        )}
        {!!SidebarModule && <SidebarModule setIsComponentReady={setIsComponentReady} />}
      </>
    )
  }
}
