import { DrawingBorder, PaneArea, RequireByKey, ResizeByThumbFuncProps } from '../../../types/shared'
import { CanvasElementType } from '../../constants/common'
import math from '../../helpers/math'
import PaneModel from '../../models/pane'
import { getContextWithCache } from '../../utils/canvas'
import Element, { EdgeValues } from '../element'
import Thumb from '../thumb'

export type BrushPoints = Array<[x: number, y: number]>

export interface IBrushAttrs {
  points: BrushPoints
  fill: string
  border: DrawingBorder
  isPolyLineClosed: boolean
  positionPointsTimestamps: BrushPoints
}

class Brush<Attrs extends IBrushAttrs = IBrushAttrs> extends Element<Attrs> {
  static type = CanvasElementType.brushV1

  untranslatedContext: CanvasRenderingContext2D | null = null

  name = 'Brush'

  brushPath: Path2D | null = null

  cachedFill = {
    previousFill: '',
    shouldRenderFill: false,
  }

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.untranslatedContext = getContextWithCache(document.createElement('canvas'))
    this.setFillCache()
  }

  getDefaults() {
    const { ElementSettings } = this.getChartLayoutSettings()
    return {
      fill: ElementSettings.defaultTransparentFill,
      border: {
        width: 3,
        color: ElementSettings.Colors.border,
      },
      isPolyLineClosed: false,
    } as Partial<Attrs>
  }

  setFillCache() {
    this.cachedFill.previousFill = this.attrs.fill
    if (this.attrs.fill.length === 9) {
      this.cachedFill.shouldRenderFill = this.attrs.fill.slice(7) !== '00'
    } else {
      this.cachedFill.shouldRenderFill = true
    }
  }

  getShouldRenderFill() {
    if (this.attrs.fill !== this.cachedFill.previousFill) {
      this.setFillCache()
    }
    return this.cachedFill.shouldRenderFill
  }

  renderContent(context: CanvasRenderingContext2D) {
    if (this.attrs.border != null) {
      context.set('strokeStyle', this.attrs.border.color)
    }
    context.set('lineCap', 'round')
    context.set('lineJoin', 'round')

    const brushPath = new Path2D()
    const { points } = this.attrs
    context.beginPath()
    brushPath.moveTo(this.fx(points[0][0]), this.fy(points[0][1]))
    let i
    if (points.length > 3) {
      for (i = 0; i < points.length - 2; i++) {
        const xc = (this.fx(points[i][0]) + this.fx(points[i + 1][0])) / 2
        const yc = (this.fy(points[i][1]) + this.fy(points[i + 1][1])) / 2
        brushPath.quadraticCurveTo(this.fx(points[i][0]), this.fy(points[i][1]), xc, yc)
      }
      brushPath.quadraticCurveTo(
        this.fx(points[i][0]),
        this.fy(points[i][1]),
        this.fx(points[i + 1][0]),
        this.fy(points[i + 1][1])
      )
    } else {
      for (const point of points) {
        brushPath.lineTo(this.fx(point[0]), this.fy(point[1]))
      }
    }

    if (this.attrs.isPolyLineClosed) {
      brushPath.closePath()
    }
    this.brushPath = brushPath
    if (this.attrs.fill && this.getShouldRenderFill()) {
      context.set('fillStyle', this.attrs.fill)
      context.fill(brushPath)
    }
    if (this.attrs?.border?.width) {
      context.set('lineWidth', this.attrs.border.width)
      context.stroke(brushPath)
    }
    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getThumbs() {
    const [firstX, firstY] = this.attrs.points[0]
    const [lastX, lastY] = this.attrs.points[this.attrs.points.length - 1]

    return [
      new Thumb(
        'first',
        () => firstX,
        () => firstY,
        this.moveByThumb,
        this.model
      ),
      new Thumb(
        'last',
        () => lastX,
        () => lastY,
        this.moveByThumb,
        this.model
      ),
    ]
  }

  moveByThumb = ({ difX, difY }: RequireByKey<ResizeByThumbFuncProps, 'difX' | 'difY'>) => {
    this.moveBy(difX, difY)
  }

  moveBy(x: number, y: number) {
    if (this.getIsCreator()) return
    for (const point of this.attrs.points) {
      point[0] += x
      point[1] += y
    }
  }

  onMouseUp() {
    const [currentDomainMax, currentDomainMin] = this.model.scale.y.domain()
    for (const point of this.attrs.points) {
      point[0] = this.roundToFixed(point[0], 2)
      point[1] = this.roundY(point[1], currentDomainMax - currentDomainMin)
    }
    super.onMouseUp()
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const { points } = this.attrs
    if (
      (this.getShouldRenderFill() || this.attrs.isPolyLineClosed) &&
      this.brushPath &&
      this.untranslatedContext?.isPointInPath(this.brushPath, area.scaled.x, area.scaled.y)
    ) {
      return true
    } else if (points.length > 1) {
      for (let i = 0; i < points.length - 1; i++) {
        if (
          math.distanceToSegment(area.scaled, {
            x1: this.fx(points[i][0]),
            y1: this.fy(points[i][1]),
            x2: this.fx(points[i + 1][0]),
            y2: this.fy(points[i + 1][1]),
          }) <= 10
        ) {
          return true
        }
      }
    }
    return super.isInArea(area)
  }

  cachePointPositionTimestamp = () => {
    const quote = this.model?.chart()?.quote()
    if (quote) {
      const positionPointsTimestamps = this.attrs.points.map(([x, y]) => [quote.getTimestampFomPositionX(x), y])
      this.set({ positionPointsTimestamps } as Partial<Attrs>)
    }
  }

  updateScales() {
    const quote = this.model.chart().quote()
    const { positionPointsTimestamps } = this.attrs
    if (!quote || !positionPointsTimestamps) {
      // positionPointsTimestamps check is temporary to prevent app from crashing
      // caused by corrupted drawings - https://github.com/finvizhq/charts/pull/1386/files
      return
    }
    const points = positionPointsTimestamps.map(([x, y]) => [quote.getPositionXFromTimestamp(x), y])
    this.set({ points } as Partial<Attrs>)
  }

  roundToFixed = (value: number, places: number) => Number.parseFloat(math.round({ value, overridePlaces: places }))

  roundY = (y: number, domainDiff: number) => {
    if (domainDiff < 1) {
      const numberOfLeadingZeros = Math.abs(Math.floor(Math.log10(Math.abs(domainDiff)) + 1))
      if (numberOfLeadingZeros > 0) {
        return this.roundToFixed(y, numberOfLeadingZeros + 4)
      } else {
        return this.roundToFixed(y, numberOfLeadingZeros + 3)
      }
    } else if (domainDiff < 10) {
      return this.roundToFixed(y, 3)
    }
    return this.roundToFixed(y, 2)
  }

  getAutosaveOmittedProperties() {
    return ['points']
  }

  getEdgeXYValues() {
    if (this.edgeXYValues === null) {
      const edgeXYValues = {} as EdgeValues
      this.attrs.points.forEach(([x, y]) => {
        edgeXYValues.minX = Math.min(x, edgeXYValues.minX ?? x)
        edgeXYValues.minY = Math.min(y, edgeXYValues.minY ?? y)
        edgeXYValues.maxX = Math.max(x, edgeXYValues.maxX ?? x)
        edgeXYValues.maxY = Math.max(y, edgeXYValues.maxY ?? y)
      })

      this.edgeXYValues = edgeXYValues
    }

    return this.edgeXYValues
  }
}

Brush.prototype.modalConfig = {
  inputs: [
    { type: 'background', name: 'fill' },
    { type: 'border', name: 'border', min: 1, max: 20, default: {} },
    { type: 'checkbox', name: 'isPolyLineClosed', label: 'Close line' },
  ],
}

export default Brush
