import { Checkbox, Input, Label, Select } from '@finviz/website'
import React, { useCallback, useState } from 'react'

const FONT_VALUES = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Verdana', label: 'Verdana' },
]

interface InputProps {
  family: string
  size: string
  style: 'italic' | 'normal'
  weight: 'bold' | 'normal'
}

interface FontInputProps {
  value: InputProps
  onChange: (values: InputProps) => void
}

export function FontInput({ value, onChange }: FontInputProps) {
  const [family, setFamily] = useState(value.family)
  const [size, setSize] = useState(value.size)
  const [style, setStyle] = useState(value.style)
  const [weight, setWeight] = useState(value.weight)

  const setValue = useCallback(
    function setValue<ValueType>(
      key: string,
      valueSetter: React.Dispatch<React.SetStateAction<ValueType>>,
      value: ValueType
    ) {
      valueSetter(value)
      onChange({ family, size, style, weight, [key]: value })
    },
    [family, size, style, weight, onChange]
  )

  return (
    <div>
      <div className="flex items-end space-x-2">
        <Label title="Font" className="w-28 shrink-0">
          <Select
            className="w-full"
            aria-label="Font"
            items={FONT_VALUES}
            value={value.family}
            onChange={(selected) => setValue('family', setFamily, selected.value)}
            data-testid="chart-modal-font-select"
          />
        </Label>
        <Input
          label="Size"
          type="number"
          min="5"
          max="72"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setValue('size', setSize, ev.currentTarget.value)}
          value={size}
          data-testid="chart-modal-font-size"
        />

        <Checkbox
          checked={weight === 'bold'}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            setValue('weight', setWeight, ev.currentTarget.checked ? 'bold' : 'normal')
          }
          data-testid="chart-modal-font-weight"
        >
          Bold
        </Checkbox>
        <Checkbox
          checked={style === 'italic'}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            setValue('style', setStyle, ev.currentTarget.checked ? 'italic' : 'normal')
          }
          data-testid="chart-modal-font-style"
        >
          Italic
        </Checkbox>
      </div>
    </div>
  )
}
