import { Button, setCookieRequest } from '@finviz/website'
import * as React from 'react'

import { useModelState } from '../../model-hooks/use-model-state'
import ChartLayout from '../../models/chart_layout'
import { updateSettings } from '../../models/settings'

export function ThemeToggleButton({ model }: { model: ChartLayout }) {
  const chartLayoutModel = useModelState(model, { watchProperties: ['theme'] })
  const isDarkMode = model.theme === 'dark'

  const onClick = () => {
    const theme = isDarkMode ? 'light' : 'dark'
    chartLayoutModel.updateAttributes({ theme, settings: updateSettings(chartLayoutModel, theme) })
    chartLayoutModel.trigger('theme', chartLayoutModel)
    setCookieRequest('chartsTheme', theme).catch(() => {})

    if (isDarkMode) {
      document.documentElement.classList.remove('dark')
    } else {
      document.documentElement.classList.add('dark')
    }
  }

  if (!chartLayoutModel) {
    return null
  }

  return (
    <Button
      theme="headerItem"
      appearance="square"
      data-testid="chart-layout-theme"
      title={isDarkMode ? 'Toggle light mode' : 'Toggle dark mode'}
      onClick={onClick}
      leftContent={isDarkMode ? 'lightMode' : 'darkMode'}
    />
  )
}
