import { Notification, Paragraph, useNotification } from '@finviz/website'
import React from 'react'

import fetchApi, {
  ContentType,
  ForbiddenError,
  GenericStatusCodeError,
  GoneError,
  InternalServerError,
} from '../../utils/fetch_api'
import { HLC, compareHlc, incrementHlc } from '../../utils/hlc'
import { getByteLengthOfString } from '../../utils/string-byte-length'
import { AutoSaveElement, AutoSaveElementDBRecord, DrawingContainerType } from './constants'
import { getAutosaveElementFromDBRecord, getDBRecordFromAutosaveElement } from './utils'

const RETRY_LIMIT = 5

export function useDrawingAutoSaveApi() {
  const postRetryCountRef = React.useRef(0)
  const deleteRetryCountRef = React.useRef(0)
  const notificationContext = useNotification()

  const fetchDrawings = React.useCallback(
    async ({
      tickers,
      containerTypes,
      lastChange,
    }: {
      tickers: string[]
      containerTypes: DrawingContainerType[]
      lastChange: HLC
    }) => {
      try {
        const data = await fetchApi<{
          drawings: AutoSaveElementDBRecord[]
        }>({
          location: '/api/auto_save.ashx',
          queryParameters: {
            ticker: tickers.join(','),
            containerTypes: containerTypes.join(',') || undefined,
            lastChangeTimestamp: lastChange.ts,
            lastChangeCounter: lastChange.count,
            lastChangeNodeUUID: lastChange.uuid,
          },
          throwOnStatusCodes: [403, 404],
        })
        return data.drawings.map((drawing) => getAutosaveElementFromDBRecord(drawing))
      } catch {
        // ignore error
      }

      return []
    },
    []
  )

  const saveDrawings = React.useCallback(
    async (drawings: AutoSaveElement[]): Promise<HLC | undefined> => {
      if (drawings.length === 0) {
        return undefined
      }

      try {
        const bodyJsonString = JSON.stringify(drawings.map((item) => getDBRecordFromAutosaveElement(item)))
        let keepalive = false
        try {
          keepalive = getByteLengthOfString(bodyJsonString) < 64000
        } catch (error: any) {
          window.Sentry?.captureException(error)
        }
        await fetchApi({
          location: '/api/auto_save.ashx',
          method: 'POST',
          throwOnStatusCodes: [400, 403, 404, 410, 500],
          contentType: ContentType.ApplicationJson,
          body: bodyJsonString,
          keepalive,
        })
        const [latestChangeHlc] = [...drawings].sort((a, b) => compareHlc(b.lastChange, a.lastChange))
        postRetryCountRef.current = 0
        return latestChangeHlc.lastChange
      } catch (error) {
        if (error instanceof InternalServerError) {
          // try again
          if (postRetryCountRef.current < RETRY_LIMIT) {
            postRetryCountRef.current = postRetryCountRef.current + 1
            await new Promise((r) => setTimeout(r, 1000))
            return saveDrawings(drawings)
          } else {
            // continue to report to Sentry
            postRetryCountRef.current = 0
          }
        } else if (error instanceof ForbiddenError) {
          // ignore error
          return undefined
        } else if (error instanceof GoneError) {
          const newTicker = (error.response as ObjectHash).newTicker
          const message = newTicker
            ? `Ticker "${drawings[0].ticker}" was renamed to "${newTicker}"`
            : `Ticker "${drawings[0].ticker}" no longer exists.`
          notificationContext.show(
            <Notification actions={<></>} timeoutInMs={6000}>
              <Paragraph className="max-w-80">{message}</Paragraph>
            </Notification>
          )
          return undefined
        }

        // report error to Sentry
        window.Sentry?.captureException(error, {
          extra: (error instanceof GenericStatusCodeError
            ? (error.response as ObjectHash).invalidItems
            : drawings
          ).reduce(
            (acc: ObjectHash, cur: ObjectHash, index: number) => ({ ...acc, [`item-${index}`]: JSON.stringify(cur) }),
            {} as ObjectHash
          ),
        })

        return undefined
      }
    },
    [notificationContext]
  )

  const deleteAllDrawings = React.useCallback(
    async ({ tickers, lastLocalChange }: { tickers: string[]; lastLocalChange: HLC }): Promise<void> => {
      try {
        const newHlc = incrementHlc(lastLocalChange, Date.now())
        await fetchApi({
          location: `/api/auto_save.ashx?ticker=${tickers.join(',')}&lastChangeTimestamp=${
            newHlc.ts
          }&lastChangeCounter=${newHlc.count}&lastChangeNodeUUID=${newHlc.uuid}`,
          method: 'DELETE',
          throwOnStatusCodes: [403, 404, 500],
          keepalive: true,
        })
        deleteRetryCountRef.current = 0
      } catch (error) {
        if (error instanceof InternalServerError) {
          // try again
          if (deleteRetryCountRef.current < RETRY_LIMIT) {
            deleteRetryCountRef.current = deleteRetryCountRef.current + 1
            await new Promise((r) => setTimeout(r, 1000))
            return deleteAllDrawings({ tickers, lastLocalChange })
          } else {
            // continue to report to Sentry
            deleteRetryCountRef.current = 0
          }
        } else if (error instanceof ForbiddenError) {
          // ignore error
          return
        }

        // report error to Sentry
        window.Sentry?.captureException(error)
      }
    },
    []
  )

  return { fetchDrawings, saveDrawings, deleteAllDrawings }
}
