import { HILOCalculation } from '../indicator-calculation/hilo'
import PaneModel from '../models/pane'
import { getBarWidthWithMargin } from '../utils/chart'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, HILOConfig } from './configs/hilo'
import Overlay from './overlay'

class HighLow extends Overlay<Attrs> {
  static config = HILOConfig
  declare hiloCalculation: HILOCalculation

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.renderContent = this.renderContent.bind(this)
  }

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      this.attrs.period = parseInt(period, 10)
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const chartModel = this.model.chart()
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const { leftOffset, width, zoomFactor } = chartModel
    const barWidth = getBarWidthWithMargin({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })
    const halfWidth = barWidth / 2
    const period = Math.min(this.attrs.period, this.data.close.length - 1)
    let firstBarToRender: undefined | number
    let lastBarToRender = 0
    context.translate(0.5, 0.5)
    context.set('fillStyle', Colors.overlayTransparentBackground)
    context.beginPath()
    context.set('strokeStyle', this.attrs.highColor)

    if (this.isComputeNecessary()) {
      this.hiloCalculation = new HILOCalculation({
        quote: this.data,
        options: { period },
      })

      this.hiloCalculation.calculate()
    }

    let prevHigh = -1
    drawInVisibleArea({
      leftOffset,
      width,
      quote: this.data,
      paneModel: this.model,
      drawBarCallback: (i, x) => {
        firstBarToRender ??= i
        const highestHigh =
          this.hiloCalculation.calculatedValues.highestHigh[i] ||
          (prevHigh !== -1 ? prevHigh : undefined) ||
          this.hiloCalculation.calculatedValues.highestHigh[
            this.hiloCalculation.calculatedValues.highestHigh.findIndex((item) => item !== undefined)
          ]
        const diff = this.data.barIndex[i] - this.data.barIndex[lastBarToRender]
        if (diff > 1 && prevHigh !== highestHigh && prevHigh > 0) {
          context.lineTo(Math.round(x - halfWidth), Math.round(this.fy(prevHigh)))
        }
        const y = Math.round(this.fy(highestHigh))
        context.lineTo(Math.round(x - halfWidth), y)
        context.lineTo(Math.round(x + halfWidth), y)

        prevHigh = highestHigh
        lastBarToRender = i
      },
    })

    context.stroke()
    if (firstBarToRender === undefined) return

    // return to start and fill with background color
    context.lineTo(Math.round(this.fx(lastBarToRender)) + halfWidth, 0)
    context.lineTo(Math.round(this.fx(firstBarToRender)) - halfWidth, 0)
    context.fill()

    context.beginPath()
    context.set('strokeStyle', this.attrs.lowColor)
    let prevLow = -1
    let prevBar = firstBarToRender
    for (let i = firstBarToRender; i <= lastBarToRender; i++) {
      const lowestLow =
        this.hiloCalculation.calculatedValues.lowestLow[i] ||
        (prevLow !== -1 ? prevLow : undefined) ||
        this.hiloCalculation.calculatedValues.lowestLow[
          this.hiloCalculation.calculatedValues.lowestLow.findIndex((item) => item !== undefined)
        ]

      const x = this.fx(i)
      const diff = this.data.barIndex[i] - this.data.barIndex[prevBar]
      if (diff > 1 && prevLow !== lowestLow && prevLow > 0) {
        context.lineTo(Math.round(x - halfWidth), Math.round(this.fy(prevLow)))
      }
      const y = Math.round(this.fy(lowestLow))
      context.lineTo(Math.round(x - halfWidth), y)
      context.lineTo(Math.round(x + halfWidth), y)

      prevLow = lowestLow
      prevBar = i
    }
    context.stroke()
    context.translate(-0.5, -0.5)

    // return to start and fill with background color
    context.lineTo(Math.round(this.fx(lastBarToRender)) + halfWidth, context.canvas.height)
    context.lineTo(Math.round(this.fx(firstBarToRender)) - halfWidth, context.canvas.height)
    context.fill()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.attrs.period ?? 50,
        required: true,
        min: 1,
        max: 999999,
      },
      highColor: {
        type: 'color',
        label: 'High Color',
        name: 'highColor',
        value: this.attrs.highColor ?? this.getFreeColor(),
      },
      lowColor: {
        type: 'color',
        label: 'Low Color',
        name: 'lowColor',
        value: this.attrs.lowColor ?? this.getFreeColor(),
      },
    }

    return {
      title: HILOConfig.label,
      inputs: HILOConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'highColor':
      case 'lowColor':
        // Some users have wrong colors which break the form validation
        return true
      default:
        return false
    }
  }

  getLabelColor() {
    return this.attrs.highColor
  }
}

HighLow.prototype.defaults = {
  lowColor: '#69c1ea',
  highColor: '#d386df',
}

export default HighLow
