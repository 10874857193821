import math from '../helpers/math'
import { OBVCalculation } from '../indicator-calculation/obv'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { OBVConfig } from './configs/obv'
import Indicator from './indicator'

class OBV extends Indicator {
  static config = OBVConfig
  declare obvCalculation: OBVCalculation

  compute() {
    if (this.isComputeNecessary()) {
      this.obvCalculation = new OBVCalculation({
        quote: this.data,
        options: undefined,
      })

      this.obvCalculation.calculate()

      this.lastValue = this.obvCalculation.calculatedValues.obv.last() ?? null
    }
    const { min, max } =
      this.obvCalculation.calculatedValues.obv.length > 0
        ? this.computeVisibleMinMax(this.obvCalculation.calculatedValues.obv)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    const dataIndex = this.data.barToDataIndex[index]
    return [
      {
        color: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!,
        text: this.getValueLabel(this.obvCalculation.calculatedValues.obv[dataIndex]),
      },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    context.set('strokeStyle', this.getChartLayoutSettings().IndicatorSettings.general.Colors.line)
    context.translate(0.5, 0.5)
    context.beginPath()
    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.obvCalculation.calculatedValues.obv[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  formatAxis(value: number) {
    return math.formatBigNumber(value, 2)
  }

  getModalConfig() {
    return {
      title: OBVConfig.label,
      inputs: [],
      inputsErrorMessages: {},
    }
  }
}

export default OBV
