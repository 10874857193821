import merge from 'lodash.merge'
import React from 'react'

import { RootChartConfigObject, Theme } from '../../../types/shared'
import {
  ChartEditorEnum,
  MOBILE_BARS_TO_VIEW,
  MOBILE_VIEW_BREAKPOINT_WIDTH,
  SpecificChartFunctionality,
} from '../../constants/common'
import ChartLayoutModel from '../../models/chart_layout'
import DrawingsInternalStore from '../../models/drawings-internal-store'
import { getSettings } from '../../models/settings'
import utils, { isRedesignedPage } from '../../utils'
import { getIsPreserveDrawingsAndAutosaveAvailable, getZoomFactorForBarsToDisplay } from '../../utils/chart'
import { getContainerTypeFromPaneConfig, getInitialStoreDrawings } from '../autosave/utils'
import { DrawingSetting } from '../toolbar/interfaces'
import { CHART_LAYOUT_CHILD_CHANGE_EVENT } from './constants'

interface Props {
  config: RootChartConfigObject
  onRegisterChartLayoutModel?: (chartLayoutModel: ChartLayoutModel) => void
  parentElement?: HTMLElement
}

function getDefaultZoomFactor(chartLayout: ChartLayoutModel) {
  const innerWidth = window.innerWidth
  if (
    innerWidth >= MOBILE_VIEW_BREAKPOINT_WIDTH ||
    chartLayout.specificChartFunctionality !== SpecificChartFunctionality.chartPage
  ) {
    return 1
  }

  return getZoomFactorForBarsToDisplay({
    chartLayout,
    numOfVisibleBars: MOBILE_BARS_TO_VIEW,
    chartVisibleWidth: innerWidth,
  })
}

function OnInitListener({
  chartLayoutModel,
  config,
  onRegisterChartLayoutModel,
  parentElement,
}: Props & { chartLayoutModel: ChartLayoutModel; parentElement?: React.RefObject<HTMLElement> | HTMLElement }) {
  const [isInit, setIsInit] = React.useState(false)

  /*
   * This entire effect is used to check for updates in entire chart layout obj tree.
   * Once config object matches object generated from chart layout, the layout model is completely initialised.
   *
   * We couldn't just add a callback to `componentDidMount` (or equivalent) because its children are rendered asynchronously.
   *
   * */
  React.useEffect(() => {
    if ((onRegisterChartLayoutModel || parentElement) && chartLayoutModel && !isInit) {
      const checkIfInitIsComplete = () => {
        const { charts } = chartLayoutModel.toConfig()
        const isReady =
          charts.length === config.charts.length &&
          charts.every(
            (chart, chartIndex) =>
              chart.panes.length === config.charts[chartIndex].panes.length &&
              chart.panes.every(
                (pane, paneIndex) =>
                  pane.elements.length === config.charts[chartIndex].panes[paneIndex].elements.length &&
                  pane.elements.every(
                    (element, elementIndex) =>
                      element.overlays?.length ===
                      config.charts[chartIndex].panes[paneIndex].elements[elementIndex].overlays?.length
                  )
              )
          )

        if (isReady) {
          setIsInit(true)
          chartLayoutModel.updateAttributes({ isInit: true })
          onRegisterChartLayoutModel?.(chartLayoutModel)

          const containerElement = parentElement?.hasOwnProperty('current')
            ? (parentElement as React.RefObject<HTMLElement>).current
            : parentElement

          if (containerElement) {
            containerElement.style.opacity = 'unset'
            containerElement.style.minHeight = 'unset'
            containerElement.style.minWidth = 'unset'
          }
        }
      }

      chartLayoutModel.bind(CHART_LAYOUT_CHILD_CHANGE_EVENT, checkIfInitIsComplete)

      checkIfInitIsComplete()

      return () => {
        chartLayoutModel.unbind(CHART_LAYOUT_CHILD_CHANGE_EVENT, checkIfInitIsComplete)
      }
    }
  }, [chartLayoutModel, config, isInit, onRegisterChartLayoutModel, parentElement])

  return null
}

export const withCompleteLayoutModelInit = (Component: any) => (props: Props) => {
  const config = React.useMemo(() => {
    const newConfig = merge({}, props.config, {
      editable: props.config.editable ?? true,
      canChangeTicker: props.config.canChangeTicker ?? true,
      scrollable: props.config.scrollable ?? true,
      cross: props.config.cross ?? true,
      theme:
        props.config.theme && [Theme.light, Theme.dark].includes(props.config.theme) ? props.config.theme : Theme.light,
      editors: props.config.editors ?? [ChartEditorEnum.publish, ChartEditorEnum.tools, ChartEditorEnum.ideas],
      ideas: props.config.ideas ?? true,
      specificChartFunctionality: props.config.specificChartFunctionality ?? SpecificChartFunctionality.default,
      initialStoreDrawings: [],
    })

    if (!newConfig.ideas) {
      newConfig.editors = newConfig.editors.filter((x) => x !== 'ideas')
    }

    if (newConfig.specificChartFunctionality === SpecificChartFunctionality.quoteFinancials) {
      newConfig.editors = [ChartEditorEnum.timeframe, ChartEditorEnum.publish]
    } else if (isRedesignedPage(newConfig, [SpecificChartFunctionality.quotePage])) {
      // We need to add these because ideas might not have them and chart controls wouldn’t render properly
      newConfig.editors = newConfig.editors.concat([ChartEditorEnum.settings, ChartEditorEnum.timeframe])
    }

    const initialStoreDrawings = getInitialStoreDrawings()?.filter(({ changeType }) => changeType !== 'destroy') ?? []
    const isQuotePage = newConfig.specificChartFunctionality === SpecificChartFunctionality.quotePage
    const isQuotePageWithDrawingsOff = isQuotePage && utils.getCookie('charts-draw') !== 'on'

    if (initialStoreDrawings.length === 0 || isQuotePageWithDrawingsOff) {
      return newConfig
    }

    const noteDrawing = initialStoreDrawings.find(
      ({ containerType, ticker }) =>
        containerType === 'note' && props.config.charts.some((chart) => chart.ticker === ticker)
    )

    const note = noteDrawing && JSON.parse(noteDrawing.elementAttrs)?.text
    const ideaObject = isQuotePage && note ? { note } : undefined

    return {
      ...newConfig,
      initialStoreDrawings,
      idea: newConfig.idea ?? ideaObject,
      charts: newConfig.charts.map((chart) => {
        const { ticker, panes } = chart
        return {
          ...chart,
          panes: panes.map((pane) => {
            const containerType = getContainerTypeFromPaneConfig(pane)
            const newElements = initialStoreDrawings
              .filter(
                (drawing) =>
                  drawing.containerType === containerType &&
                  drawing.ticker === ticker &&
                  !pane.elements.some((paneElement) => paneElement.elementId === drawing.elementId)
              )
              .map(({ elementId, zIndex, elementAttrs }) => ({
                elementId,
                zIndex,
                ...JSON.parse(elementAttrs),
              }))
            return {
              ...pane,
              elements: [...pane.elements, ...newElements],
            }
          }),
        }
      }),
    }
    // We need to disable the eslint check because the hook is supposed to run only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const chartLayoutModel = React.useMemo(() => {
    const settings = getSettings({ ...config, theme: config.theme! })
    const chartLayout = ChartLayoutModel.create<ChartLayoutModel>({
      layout: config.layout,
      width: config.width,
      height: config.height,
      editable: config.editable,
      canChangeTicker: config.canChangeTicker,
      scrollable: config.scrollable,
      initialScrollable: config.scrollable,
      cross: config.cross,
      editors: config.editors,
      theme: config.theme,
      idea: config.idea,
      settings,
      isWheelZoomEnabled: config.specificChartFunctionality === SpecificChartFunctionality.chartPage,
      specificChartFunctionality: config.specificChartFunctionality,
      isHideDrawingsActive: false,
      isPreserveDrawingsActive: !!(
        getIsPreserveDrawingsAndAutosaveAvailable(config) &&
        window.FinvizSettings.toolsState?.[DrawingSetting.PreserveDrawings]
      ),
      isLockDrawingsActive: false,
      uuid: config.uuid,
      drawingsInternalStore: DrawingsInternalStore.create<DrawingsInternalStore>({
        elements: config.initialStoreDrawings,
      }),
    })

    chartLayout.updateAttributes({ defaultZoomFactor: getDefaultZoomFactor(chartLayout) })

    return chartLayout

    // We need to disable the eslint check because the hook is supposed to run only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return chartLayoutModel ? (
    <>
      <OnInitListener
        config={config}
        chartLayoutModel={chartLayoutModel}
        onRegisterChartLayoutModel={props.onRegisterChartLayoutModel}
        parentElement={props?.parentElement}
      />
      <Component chartLayoutModel={chartLayoutModel} {...props} config={config} />
    </>
  ) : null
}
