const NUM_OF_RETRIES = 3

export function importWithRetry<T = unknown>(importFn: () => Promise<T>, retries = 0) {
  const result = importFn().catch(async (err) => {
    if (retries > NUM_OF_RETRIES) {
      throw err
    }

    await new Promise((r) => setTimeout(r, 1000 * retries))

    return importWithRetry(importFn, retries + 1)
  }) as Promise<T>

  return result
}
