import { Checkbox, Input, Paragraph } from '@finviz/website'
import React from 'react'

import { MacroTimeframe } from '../../constants/common'
import { DEFAULTS } from './index'

export interface InputProps {
  macroKey: MacroTimeframe
  isOff: boolean
  from: number
  to: number
}

interface VisibilityInputProps {
  value: InputProps
  onChange: (value: InputProps) => void
  isCheckboxOnly: boolean
}

export function VisibilityInput({ value, onChange, isCheckboxOnly }: VisibilityInputProps) {
  const [isOff, setIsOff] = React.useState(value.isOff)
  const [from, setFrom] = React.useState(value.from)
  const [to, setTo] = React.useState(value.to)
  const { macroKey } = value
  const macroLabel = macroKey.charAt(0).toUpperCase() + macroKey.slice(1)

  const setValue = React.useCallback(
    function <ValueType>(key: string, valueSetter: React.Dispatch<React.SetStateAction<ValueType>>, value: ValueType) {
      valueSetter(value)
      onChange({ macroKey, isOff, from, to, [key]: value })
    },
    [macroKey, isOff, from, to, onChange]
  )
  return (
    <div key={macroKey} className="flex items-center">
      <div className="flex items-center space-x-2">
        <Checkbox
          className="cursor-pointer"
          checked={!isOff}
          data-testid={`visibility-checkbox-${macroKey}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue('isOff', setIsOff, !e.target.checked)}
        >
          {isCheckboxOnly && macroLabel}
        </Checkbox>
        {!isCheckboxOnly && (
          <>
            <Input
              disabled={isOff}
              className="w-16"
              type="number"
              name={`${macroKey}-from`}
              value={from}
              min={DEFAULTS[macroKey].from}
              max={Math.min(DEFAULTS[macroKey].to, to)}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                setValue('from', setFrom, Number(ev.currentTarget.value))
              }
              data-testid={`visibility-input-${macroKey}-from`}
            />
            <span>-</span>
            <Input
              disabled={isOff}
              className="w-16"
              type="number"
              name={`${macroKey}-to`}
              value={to}
              min={Math.max(DEFAULTS[macroKey].from, from)}
              max={DEFAULTS[macroKey].to}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                setValue('to', setTo, Number(ev.currentTarget.value))
              }
              data-testid={`visibility-input-${macroKey}-to`}
            />
            <Paragraph>{macroLabel}</Paragraph>
          </>
        )}
      </div>
    </div>
  )
}
